// MASKAPAI
export const MASKAPAI_FETCH_SET_LOADING = "app/calculator/price-update/MASKAPAI_FETCH_SET_LOADING"
export const MASKAPAI_FETCH_START = "app/calculator/price-update/MASKAPAI_FETCH_START"
export const MASKAPAI_FETCH_SUCCESS = "app/calculator/price-update/MASKAPAI_FETCH_SUCCESS"
export const MASKAPAI_FETCH_ERROR = "app/calculator/price-update/MASKAPAI_FETCH_ERROR"

export const MASKAPAI_SAVE_SET_LOADING = "app/calculator/price-update/MASKAPAI_SAVE_SET_LOADING"
export const MASKAPAI_SAVE_START = "app/calculator/price-update/MASKAPAI_SAVE_START"
export const MASKAPAI_SAVE_SUCCESS = "app/calculator/price-update/MASKAPAI_SAVE_SUCCESS"
export const MASKAPAI_SAVE_ERROR = "app/calculator/price-update/MASKAPAI_SAVE_ERROR"

export const MASKAPAI_REMOVE_SET_LOADING = "app/calculator/price-update/MASKAPAI_REMOVE_SET_LOADING"
export const MASKAPAI_GET_PRICE_SET_LOADING = "app/calculator/price-update/MASKAPAI_GET_PRICE_SET_LOADING"