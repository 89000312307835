import { Pagination as AntdPagination } from "antd";
import PropTypes from "prop-types";

import "./style.css";

const Pagination = ({
  defaultCurrent,
  total,
  current,
  pageSize,
  customPaginationClass,
  onChange,
  onShowSizeChange,
  showSizeChanger
}) => {
  return (
    <div
      className={`flex justify-end py-4 mosleme-pagination !text-xs ${customPaginationClass}`}
    >
      <AntdPagination  
        showSizeChanger={showSizeChanger}
        defaultCurrent={defaultCurrent}
        total={total}
        pageSize={pageSize}
        onChange={onChange}
        onShowSizeChange={onShowSizeChange}
        />
    </div>
  )
}

Pagination.propTypes = {
  defaultCurrent: PropTypes.number,
  total: PropTypes.number,
  current: PropTypes.number,
  pageSize: PropTypes.number,
  customPaginationClass: PropTypes.string,
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
  showSizeChanger: PropTypes.bool
}

Pagination.defaultProps = {
  defaultCurrent: 1,
  total: 0,
  current: 1,
  pageSize: 10,
  customPaginationClass: "",
  onChange: () => {},
  onShowSizeChange: () => {},
  showSizeChanger: true
}



export default Pagination