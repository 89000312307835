import {
    SET_LOADING
} from '../../actions/profile'

const initialState = {
    isLoading: false
}

export default (state = initialState, { payload, type }) => {
    switch(type) {
      case SET_LOADING:
        return {
          ...state,
          isLoading: payload
        }
      default:
        return state;
    }
}