import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Badge from "components/badge";
import Places from "components/map";

import IconLocation from "assets/icons/location-black.svg";
import IconTanggal from "assets/icons/Tanggal.svg";
import IconJam from "assets/icons/Jam.svg";

import moment from "moment";
import "moment/locale/id";

const LocationDetailHover = ({ data = [], label }) => {
  const [selected, setSelected] = useState({
    lat: data?.lotitude,
    lng: data?.langitude,
  });
  console.log(selected);
  const [address, setAdress] = useState("");
  const handleSetAdress = (data) => {
    setAdress(data);
  };

  const handleSelected = (data) => {
    console.log("data:", data);
    setSelected(data);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="bg-white normal-shadow rounded-lg absolute z-[99999] left-[57%]  top-[50%] translate-x-[-50%] translate-y-[-50%] h-fit w-[626px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="p-4">
          <Badge label={label} />
          {data?.map((item, idx) => {
            return (
              <div
                className="border-[1px] border-[#4048521a] rounded-lg p-4 mt-4"
                key={idx}
              >
                <div className="bg-[#F3CFCE] rounded-lg px-4 py-1 w-min">
                  <div className="txt-maroon font-medium">{idx + 1}</div>
                </div>
                <div className="flex flex-row justify-between mt-6">
                  <div className="flex flex-col">
                    <div className="flex flex-col">
                      <div className="font-semibold">Koordinat Lokasi</div>
                      <div className="flex flex-row gap-2 mt-2">
                        <img src={IconLocation} alt="location" />
                        <div>{item?.lokasi}</div>
                      </div>
                    </div>
                    <div className="flex flex-row mt-5 gap-8">
                      <div className="flex flex-row items-center gap-3">
                        <img src={IconTanggal} alt="date" />
                        <div className="flex flex-col">
                          <div className="text-[#888888]">
                            Tanggal Pelaksanaan
                          </div>
                          <div>{moment().format("dddd, D MMMM YYYY")}</div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center gap-3">
                        <img src={IconJam} alt="date" />
                        <div className="flex flex-col">
                          <div className="text-[#888888]">Mulai Jam</div>
                          <div>
                            {item?.start_at
                              ? item?.start_at + " - Selesai"
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Places
                    style={{ width: 183, height: 120 }}
                    selected={{
                      lat: parseFloat(item?.lotitude),
                      lng: parseFloat(item?.langitude),
                    }}
                    setSelected={handleSelected}
                    address={item?.lokasi}
                    setAdress={handleSetAdress}
                    withSearch={false}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default React.memo(LocationDetailHover);
