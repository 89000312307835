import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getSettingService, saveSettingService } from "utils/api/setting";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";


import Toast from "components/toast";
import { useFormik } from "formik";
import Inputs from "components/form/inputs";
import Buttons from "components/buttons";

const MobileJamaah = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      "setting_mobile-links_admin_playstore": "",
      "setting_mobile-links_admin_appstore": "",
    },
  });

  const handleGetSettingMobileLink = async () => {
    const res = await getSettingService({ category: 'mobile-links' });
    if (res.status === 200) {
      formik.setValues({
        "setting_mobile-links_admin_playstore": res.data.data["mobile-links_admin_playstore"],
        "setting_mobile-links_admin_appstore": res.data.data["mobile-links_admin_appstore"],
      });
    } else {
      dispatch(handleError(res.data));
    }
  };

  const handleSaveSettingMobileLink = async () => {
    setIsLoading(true);
    const res = await saveSettingService(formik.values);
    setIsLoading(false);

    if (res.status === 200) {
      dispatch(handleSuccess(null, { message: "Berhasil mengupdate mobile apss link" }));
    } else {
      dispatch(handleError(res.data));
    }

  };

  useEffect(() => {
    handleGetSettingMobileLink();
  }, []);

  return (
    <div className="mt-4">
      <Inputs
        value={formik.values["setting_mobile-links_admin_playstore"]}
        name="setting_mobile-links_admin_playstore"
        label="Link Playstore"
        placeholder="Masukkan Link Play Store"
        onChange={formik.handleChange}
        containerClass="mt-4"
      />
      <Inputs
        value={formik.values["setting_mobile-links_admin_appstore"]}
        name="setting_mobile-links_admin_appstore"
        containerClass="mt-4"
        label="Link Appstore"
        placeholder="Masukkan Link App Store"
        onChange={formik.handleChange}
      />
      <div className="flex flex-row justify-end mt-4">
        <Buttons
          loading={isLoading}
          onClick={() => handleSaveSettingMobileLink()}
          // onClick={onSubmit}
          text="Simpan"
          customClass="btn-primary btn-medium"
        />
      </div>
    </div>
  );
};

export default React.memo(MobileJamaah);
