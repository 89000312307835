import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import uuid from "react-uuid";

import AppsMitraAction from "stores/actions/apps/mitra";
import { resetDataUpload, setDataUpload } from "stores/actions/uploadFile";

import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import ImageField from "components/image_field";
import { handleErrorCustom, handleSuccess } from "stores/actions/errorGeneral";
import UploadArea from "components/Uploadarea";

const AppsAddMitra = ({ setOpenPopup, edit }) => {
  const dispatch = useDispatch();
  const [logoMitra, setLogoMitra] = useState({
    value: `${edit ? edit?.logo_url : ""}`,
    error: false,
  });

  const formikAddOrEditMitra = useFormik({
    initialValues: {
      mitra: `${edit ? edit?.name : ""}`,
    },
    validationSchema: Yup.object().shape({
      mitra: Yup.string().required("Nama Mitra Wajib diisi."),
    }),
    onSubmit: async (values, actions) => {
      if (logoMitra.value === "" || logoMitra.value === undefined) {
        setLogoMitra({
          value: logoMitra.value,
          error:
            logoMitra.value === "" || logoMitra.value === undefined
              ? true
              : false,
        });
      } else {
        if (edit) {
          const status = await dispatch(
            AppsMitraAction.postData({
              id_mitra: edit.id_mitra,
              name: values.mitra,
              logo_url: logoMitra.value,
              edit: true,
            })
          );
          if (status === 200) {
            setOpenPopup({
              show: false,
              edit: null,
            });
            actions.resetForm();
            dispatch(resetDataUpload());
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate mitra", code: 200 }));
          } else {
            dispatch(handleErrorCustom("Gagal mengupdate mitra"));
          }
        } else {
          if (Object.keys(values).length !== 0) {
            const status = await dispatch(
              AppsMitraAction.postData({
                name: values.mitra,
                logo_url: logoMitra.value,
              })
            );
            if (status === 200) {
              setOpenPopup({
                show: false,
                edit: null,
              });
              actions.resetForm();
              dispatch(resetDataUpload());
              dispatch(handleSuccess(null, { message: "Berhasil menambahkan mitra", code: 200 }));
            }
          } else {
            actions.setSubmitting(false);
            setLogoMitra({
              value: "",
              error: true,
            });
            dispatch(handleErrorCustom("Gagal menambahkan mitra"));
          }
        }
      }
    },
  });

  useEffect(() => {
    if (edit && edit?.logo_url) {
      dispatch(
        setDataUpload([
          {
            id: uuid(), // di import dari library 'uuid'
            fileName: "Mitra Logo",
            path: edit?.logo_url,
            name: "logo_mitra", // harus unique
          },
        ])
      );
    } else {
      dispatch(resetDataUpload());
    }
  }, [edit]);

  return (
    <>
      <div className="bg-white border-[1px] border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <Inputs
            id="mitra"
            name="mitra"
            label="Nama Mitra"
            placeholder="Masukkan Nama Mitra"
            value={formikAddOrEditMitra.values.mitra}
            error={formikAddOrEditMitra.errors.mitra}
            customClass={formikAddOrEditMitra.errors.mitra ? "input-error" : ""}
            onChange={formikAddOrEditMitra.handleChange}
          />
          <div className="mt-3">
            <UploadArea 
              name="logo_mitra"
              label="Logo Mitra"
              onChange={(e) => setLogoMitra({
                value: e,
                error: false,
              })}
              defaultUrl={edit?.logo_url}
              customClass="!h-[350px] !w-[350px]"
              />
            {/* <ImageField
              name="logo_mitra"
              label="Logo Mitra"
              customContainer="h-[300px]"
              error={logoMitra.error}
              errorMesssage={logoMitra.error ? "Gambar Wajib dipilih" : ""}
              onRemove={() =>
                setLogoMitra({
                  value: "",
                  error: false,
                })
              }
              setFile={(file) =>
                setLogoMitra({
                  value: file?.path,
                  error: false,
                })
              }
            /> */}
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end gap-2 p-4">
          <Buttons
            text="Kembali"
            customClass="btn-outline btn-medium !w-fit"
            onClick={() =>
              setOpenPopup({
                show: false,
                edit: null,
              })
            }
          />
          <Buttons
            text={`${edit ? "Edit" : "Tambah"}`}
            loading={formikAddOrEditMitra.isSubmitting}
            customClass={`btn-primary btn-medium !w-fit ${
              formikAddOrEditMitra.isSubmitting && "loading"
            }`}
            onClick={() => {
              setLogoMitra({
                value: logoMitra.value,
                error:
                  logoMitra.value === "" || logoMitra.value === undefined
                    ? true
                    : false,
              });
              formikAddOrEditMitra.handleSubmit();
            }}
          />
        </div>
      </div>
    </>
  );
};

AppsAddMitra.propTypes = {
  setOpenPopup: PropTypes.func,
  edit: PropTypes.object,
};

export default React.memo(AppsAddMitra);
