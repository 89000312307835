// dashboard Actions
// --------------------------------------------------------

import {
  belumDPApi,
  belumLunasApi,
  dataKelengkapanJamaahApi,
  kelengkapanDataApi,
  statistikApi,
  totalJamaahApi,
  totalMemberOnlineApi,
  totalPaketAktifApi,
  totalPaketApi,
} from "utils/api/dashboard";
import { handleError, handleErrorBE } from "../errorGeneral";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "dashboard/SET_LOADING";
export const CLEAR_ERROR = "dashboard/CLEAR_ERROR";
export const SET_ERROR = "dashboard/SET_ERROR";
export const INIT_DATA = "dashboard/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "dashboard/SET_DOUBLE_SUBMIT";
export const SET_TOTAL_JAMAAH = "dashboard/SET_TOTAL_JAMAAH";
export const SET_TOTAL_PAKET = "dashboard/SET_TOTAL_PAKET";
export const SET_TOTAL_MEMBER_ONLINE = "dashboard/SET_TOTAL_MEMBER_ONLINE";
export const SET_TOTAL_PAKET_AKTIF = "dashboard/SET_TOTAL_PAKET_AKTIF";
export const SET_STATISTIK = "dashboard/SET_STATISTIK";
export const SET_BELUM_DP = "dashboard/SET_BELUM_DP";
export const SET_BELUM_LUNAS = "dashboard/SET_BELUM_LUNAS";
export const SET_CLEAR_DATA = "dashboard/SET_CLEAR_DATA";
export const SET_KELENGKAPAN_DATA = "dashboard/SET_KELENGKAPAN_DATA";
export const SET_KELENGKAPAN_DATA_JAMAAH =
  "dashboard/SET_KELENGKAPAN_DATA_JAMAAH";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setTotalJamaah = (payload) => ({
  type: SET_TOTAL_JAMAAH,
  payload,
});

export const setTotalPaket = (payload) => ({
  type: SET_TOTAL_PAKET,
  payload,
});

export const setTotalMemberOnline = (payload) => ({
  type: SET_TOTAL_MEMBER_ONLINE,
  payload,
});

export const setStatistik = (payload) => ({
  type: SET_STATISTIK,
  payload,
});

export const setBelumDp = (payload) => ({
  type: SET_BELUM_DP,
  payload,
});

export const setBelumLunas = (payload) => ({
  type: SET_BELUM_LUNAS,
  payload,
});

export const setKelengkapanData = (payload) => ({
  type: SET_KELENGKAPAN_DATA,
  payload,
});

export const setKelengkapanDataJamaah = (payload) => ({
  type: SET_KELENGKAPAN_DATA_JAMAAH,
  payload,
});

export const setTotalPaketAktif = (payload) => ({
  type: SET_TOTAL_PAKET_AKTIF,
  payload,
});

export const clearData = (payload) => ({
  type: SET_CLEAR_DATA,
  payload,
});

export const getDataJamaah = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await totalJamaahApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setTotalJamaah(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDataPaket = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await totalPaketApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setTotalPaket(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDataPaketAktif = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await totalPaketAktifApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setTotalPaketAktif(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDataMemberOnline = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await totalMemberOnlineApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setTotalMemberOnline(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getStatistik = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await statistikApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setStatistik(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getBelumDp = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await belumDPApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setBelumDp(res.data));
      return res.data;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getBelumLunas = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await belumLunasApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setBelumLunas(res.data));
      return res.data;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};


export const getKelengkapanData = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await kelengkapanDataApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setKelengkapanData(res.data));
      return res.data;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getdataKelengkapanJamaah = (payload) => async (dispatch) => {
  try {
    console.log("data logg", payload);
    if (!payload) dispatch(setKelengkapanDataJamaah());
    dispatch(setLoading(true));
    const res = await dataKelengkapanJamaahApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setKelengkapanDataJamaah(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const resetDataDashboard = () => async (dispatch) => {
  dispatch(clearData());
};
