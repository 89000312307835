import {
  CLEAR_ERROR,
  INIT_DATA,
  SET_ALL_FLIGHT,
  SET_ERROR,
  SET_LOADING,
  SET_ALL_FLIGHT_INTERNATIONAL,
} from "../../../actions/paket/penerbangan";

const initialState = {
  isLoading: false,
  isSubmitting: false,
  error: {},
  allFlight: [],
  penerbanganInternational: {},
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case INIT_DATA:
      return {
        ...initialState,
      };
    case SET_ALL_FLIGHT:
      return {
        ...state,
        allFlight: [...payload],
      };

    case SET_ALL_FLIGHT_INTERNATIONAL:
      return {
        ...state,
        penerbanganInternational: payload,
      };
    default:
      return state;
  }
};
