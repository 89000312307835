import {
  ERROR_FETCHING_MITRA,
  SET_ADD_OR_EDIT_MITRA,
  SET_DELETE_MITRA,
  SET_PAGE_MITRA,
  SET_SIZE_MITRA,
  SET_TOTAL_MITRA,
  START_FETCHING_MITRA,
  SUCCESS_FETCHING_MITRA,
} from "stores/actions/apps/mitra/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  total: 100,
  per_page: 10,
  page_number: 1,
  sort_column: "",
  sort_order: "Desc",
  keyword: "",
  status: statuslist.idle,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_MITRA:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_MITRA:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_MITRA:
      return {
        ...state,
        data: action.payload,
        status: statuslist.success,
      };
    case SET_PAGE_MITRA:
      return { ...state, page_number: action.page };

    case SET_SIZE_MITRA:
      return { ...state, per_page: action.size };

    case SET_TOTAL_MITRA:
      return { ...state, total: action.total };
    case SET_ADD_OR_EDIT_MITRA:
      if (action.payload && action.payload.data && !action.edit) {
        state["data"].push(action.payload.data);
      } else {
        state["data"].map((val, idx) => {
          if (val.id_mitra === action.payload.data.id_mitra) {
            state["data"][idx] = action.payload.data;
          }
        });
      }
      return state;
    case SET_DELETE_MITRA:
      state["data"] = state["data"].filter(
        (list) => list.id_mitra !== action.id
      );
      return state;
    default:
      return state;
  }
}
