import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Dropdowns } from "components";
import IconMore from "assets/icons/More.svg";
// import { Spin } from "components";
import { getDataFAQ } from "stores/actions/faq";

import { Spin } from "antd"
import { Edit, Trash } from "iconsax-react";
import { removeHtmlTags } from "utils/helpers";
import Buttons from "components/buttons";
import Dropdowns from "components/dropdowns";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";
import { Grid } from "@material-ui/core";
import DropdownsKu from "components/DropdownsKu";

const ListFAQ = ({ category, handleEdit, handleRemove }) => {
  const dispatch = useDispatch();
  const { dataFAQ, isLoading } = useSelector((state) => state.faq);

  useEffect(() => {
      dispatch(getDataFAQ({
        "page_number": 1,
        "per_page": 1000,
        "keyword": "",
        "sort_column": "",
        "sort_order": "ASC",
        "category": category
      }));
  }, [getDataFAQ]);
  

  return (
      <>
          <div className="mt-4">
              <div className="">
                  <Grid container spacing={2}>
                      {isLoading ? (
                      <div className="flex justify-center items-center py-10 z-50 w-full">
                          <Spin
                          size="large"
                          wrapperClassName="m-auto"
                          rootClassName="m-auto"
                          />
                      </div>
                      ) : (
                      dataFAQ.length ?
                        dataFAQ.map((item, index) => (
                            <Grid item md={4} key={item.id_faq}>
                            <div className="normal-shadow rounded-2xl bg-white">
                                <div className="rounded-tl-2xl rounded-tr-2xl bg-[#F2F2F2] p-4">
                                <div className="flex flex-row justify-between">
                                    <div className="green-bg rounded-[32px] px-1 text-white font-semibold">
                                    {index + 1}
                                    </div>
                                    <DropdownsKu
                                      arrow={false}
                                      costumLabel={(
                                        <>
                                          <img src={IconMore} />
                                        </>
                                      )}
                                      >
                                      <div className="flex flex-row gap-6 bg-white hard-shadow rounded-[6px] px-4 py-2">
                                          {/* <Edit
                                            className="cursor-pointer"
                                            onClick={() => handleEdit(item.id_faq)}
                                            size="24"
                                            color="#141414"
                                          /> */}
                                          <Trash
                                            className="cursor-pointer"
                                            onClick={() =>
                                              handleRemove(item.id_faq)
                                            }
                                            size="24"
                                            color="#141414"
                                          />
                                      </div>
                                    </DropdownsKu>
                                </div>
                                <div className="text-[16px] font-semibold line-clamp-2 mt-2">
                                    {item.pertanyaan}
                                </div>
                                </div>
                                <div className="p-4">
                                <div className="font-semibold">Jawaban</div>
                                <div className="text-base line-clamp-2 mt-2">
                                    {removeHtmlTags(item.jawaban)}
                                </div>
                                <Buttons
                                    text="Edit"
                                    customClass="btn-secondary btn-medium mt-4"
                                    onClick={() => handleEdit(item.id_faq)}
                                />
                                </div>
                            </div>
                            </Grid>
                        )) : (
                          <>
                            <div className="flex flex-row justify-center items-center w-full h-full bg-white py-[5%]">
                            <div className="flex flex-col h-full">
                              <img src={IconJamPasir} alt="jam_pasir" />
                              <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
                                Belum ada FAQ
                              </div>
                            </div>
                            </div>
                          </>
                        )
                      )}
                  </Grid>
              </div> 
          </div>
      </>
    )
}

export default ListFAQ;