import styled from "styled-components";

const AccordionsStyle = styled.div`
  .custom-accordion {
    background: #ffffff;
    box-shadow: 0px 4px 80px -4px rgba(0, 0, 0, 0.04), 0px 10px 16px -6px rgba(0, 0, 0, 0.04);

    .MuiAccordionSummary-root {
      // border-bottom: 1px solid #e0e0e0;
      min-height: min-content;

      .Mui-expanded {
        margin: 12px 0;
      }
    }

    .MuiAccordionDetails-root {
      padding: 16px;
    }
  }

  .summary-m-4 {
    .MuiAccordionSummary-content {
      margin-top: 16px!important;
      margin-bottom: 16px!important;
    }
  }
`;

export default AccordionsStyle;
