import React, { useEffect, useState } from "react";

import Buttons from "../../../../../../../components/buttons";
import Badge from "../../../../../../../components/badge";
import Tab from "../../../../../../../components/tab";
import TabTagihan from "./components/TabTagihan";
import { useSelector, useDispatch } from "react-redux";
import { NumericFormat } from "react-number-format";
import { useParams } from 'react-router-dom'

import IconBack from "../../../../../../../assets/icons/arrow-left.svg";
import IconPrint from "../../../../../../../assets/icons/Print.svg";
import Photo from "../../../../../../../assets/images/Photo.png";
import IconEdit from "../../../../../../../assets/icons/edit.svg";
import IconDelete from "../../../../../../../assets/icons/trash.svg";
import TabTagihanStyle from "./TabTagihan.Style";
import TabPembayaran from "./components/TabPembayaran";
import { Grid } from "@material-ui/core";
import Avatar from "components/avatar";

import { getDetailOrder } from "utils/api/paket/bills";
import { handleError } from "stores/actions/errorGeneral";
import moment from "moment";
import { IDRFormater, toCapital } from "utils/helpers";

import IconSistemMaroon from 'assets/icons/icon-sistem-maroon.svg'
import IconAdminMaroon from 'assets/icons/icon-admin-maroon.svg'
import { InfoCircle } from "iconsax-react";
import Popup from "components/popup";

const PaketTagihanDetail = ({ id, orderNumber, setShowDetail }) => {

  const dispatch = useDispatch()
  const params = useParams()
  const [activeTab, setActiveTab] = useState("1");
  const { detailData } = useSelector((state) => state.paketDetail);
  const [detailOrder, setDetailOrder] = useState({})
  const [isLoadingDetailOrder, setIsloadingDetailOrder] = useState(false)
  const [totalJamaah, setTotalJamaah] = useState(0)

  const [openCancelReason, setOpenCancelReason] = useState(false)
  const [cancelReasonInfo, setCancelReasonInfo] = useState("")

  let datas = detailData.data;

  useEffect(() => {
    handleGetDetailOrder()
  }, [])

  const handleGetDetailOrder = async () => {
    const payload = {
      order_id: id
    }
    const response = await getDetailOrder(params.id, payload)
    const { status, data } = response
    if(status === 200) {
      setDetailOrder(data.data)
    } else {
      dispatch(handleError(data))
    }
  }

  return (
    <TabTagihanStyle>
      <Popup
        open={openCancelReason}
        handleClose={() => {
          setCancelReasonInfo('')
          setOpenCancelReason(false)
        }}
        title={`Alasan dibatalkan`}
        width="550px"
        containerClass=""
        children={
          <>
            <div className="p-4 border rounded-2xl">
              {cancelReasonInfo}
            </div>
          </>
        }
          
        />
      <div className="flex flex-row gap-2">
        <img
          className="cursor-pointer"
          src={IconBack}
          alt="back"
          onClick={() => setShowDetail(false)}
        />
        <div className="text-base font-semibold">Tagihan Jamaah</div>
      </div>
      <div className="border-[1px] border-[#E0E0E0] p-4 rounded-2xl mt-4">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center gap-4">
            <div className="text-base font-semibold">#{detailOrder?.order_number}</div>
            <div className="w-[1px] h-4 bg-gray-4"></div>
            <div className="text-base">{moment(detailOrder?.ordered_at).format('dddd, DD MMMM YYYY')}</div>
            <div className="text-base">{moment(detailOrder?.ordered_at).format('HH:mm')} WIB</div>
          </div>
          <div>

          <>
            {detailOrder?.payment_status === 'Belum DP' && (
              <Badge 
                backgroundColor={'#F7A399'}
                color={"#FE0000"}
                label={detailOrder?.payment_status.toUpperCase()} />
            )}
            {detailOrder?.payment_status === 'Sudah DP' && (
              <Badge 
                backgroundColor={'#BBDEFB'}
                color={"#0000FE"}
                label={`SUDAH DP`} />
            )}
            {detailOrder?.payment_status === 'Belum Lunas' && (
              <Badge 
                backgroundColor={'#FEDCED'}
                color={"#F20089"}
                label={`BELUM LUNAS`} />
            )}
            {detailOrder?.payment_status === 'Lunas' && (
              <Badge 
                backgroundColor={'#92E6A7'}
                color={"#1A7431"}
                label={detailOrder?.payment_status.toUpperCase()} />
            )}
            {detailOrder?.payment_status === 'Di Batalkan' && (
              <>
                <div className="flex flex-row mr-2">
                  <Badge 
                    backgroundColor={'#CED4DA'}
                    color={"#6C757D"}
                    label={<>
                      <div className="flex justify-center items-center gap-2">
                        <span>
                          {`DIBATALKAN`}
                        </span>
                        <div>
                          {detailOrder.cancel_by > 0 ? <img src={IconAdminMaroon} /> : <img src={IconSistemMaroon} />}
                        </div>
                      </div>
                    </>} />
                    
                    <Buttons 
                      text={
                        <>
                          <InfoCircle size={16} />
                        </>
                      } 
                      onClick={() => {
                        setOpenCancelReason(true)
                        setCancelReasonInfo(detailOrder.cancel_reason)
                      }}
                      customClass="btn-secondary btn-small !py-[6px] !px-[10px] ml-2" />
                </div>
              </>
            )}
          </>

          </div>
        </div>
        <div className="flex flex-row mt-4 gap-[10px] text-base">
          <div className="w-2/3">
            <div className="w-full flex justify-between pink-bg-shine p-4 mb-[10px]">
              <div className="flex justify-start gap-3 items-center">
                <Avatar 
                  value={detailOrder?.user_order}
                  containerClass="items-center justify-center  w-[56px] h-[56px] rounded-[100%]  bg-slate-100 border-white border"
                  customClass="object-cover w-[56px] h-[56px] rounded-[100%]"
                  />
                  {/* <img 
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/600px-Cat03.jpg" /> */}
                <div className="flex flex-col text-white gap-2">
                    <h3 className=" text-xl font-semibold">
                      {detailOrder?.user_order?.title ? `${toCapital(detailOrder?.user_order?.title)}.` : ''} {detailOrder?.user_order?.name}
                    </h3>
                    <div className="flex gap-2">
                      <Badge 
                        label={detailOrder?.user_order?.role}
                        color={detailOrder?.user_order?.color_text_role}
                        backgroundColor="rgba(255, 255, 255, 0.50)"
                        customClass="!py-[6px] !px-4"
                        type="blue light"
                        />
                      <Badge 
                        label={detailOrder?.user_order?.branch?.name?.toUpperCase()}
                        color={detailOrder?.user_order?.branch?.text_color}
                        backgroundColor="rgba(255, 255, 255, 0.50)"
                        customClass="!py-[6px] !px-4"
                        />
                    </div>
                </div>
              </div>
              <div className="">
                <div className="bg-pink-transparent text-white py-3 px-4 flex flex-col gap-1 rounded-lg">
                  <span>
                    Waktu Booking :
                  </span>
                  <span className="font-semibold">
                    {moment(detailOrder?.ordered_at).format('dddd, DD MMMM YYYY HH:mm')} WIB
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full pink-bg-shine pt-4 px-4 !pb-7 text-white">
              <div className="mb-4">
                Tagihan :
              </div>
              <div className="flex flex-row gap-[72px]">
                  <div>
                    <div className="mb-1">Total Biaya :</div>
                    <div className="text-xl font-semibold">
                      {IDRFormater(detailOrder?.total_bill)}
                    </div>
                  </div>
                  <div>
                    <div className="mb-1">Sudah Dibayarkan :</div>
                    <div className="text-xl font-semibold">
                      {IDRFormater(detailOrder?.paid_bill)}
                    </div>
                  </div>
                  <div>
                    <div className="mb-1">Diskon :</div>
                    <div className=" text-xl font-semibold">
                      {IDRFormater(detailOrder?.diskon)}
                    </div>
                  </div>
                  <div>
                    <div className="mb-1">Kekurangan :</div>
                    <div className="text-xl font-semibold">
                      {IDRFormater(detailOrder?.remaining_payment)}
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="w-1/3">
            <div className="w-full h-full pink-bg-shine p-4 text-white">
              <div>
                Catatan :
              </div>
              <div>
                -
              </div>
            </div>
          </div>
        </div>
      </div>

      <Tab
        customClass="custom-tab-jamaah mt-2"
        defaultActiveKey="1"
        onChange={(e) => setActiveTab(e)}
        items={[
          {
            label: `Tagihan (${totalJamaah} Jamaah)`,
            key: "1",
            children: (
              <TabTagihan 
                setTotalJamaah={(val) => setTotalJamaah(val)}
                orderID={id} 
                
                />
            ),
          },
          { 
            label: "Pembayaran", 
            key: "2", 
            children: (
              <TabPembayaran 
                orderNumber={orderNumber}
                handleGetBill={handleGetDetailOrder} 
                activeTab={activeTab} 
                orderID={id} />
            ) },
        ]}
      />
    </TabTagihanStyle>
  );
};

  export default React.memo(PaketTagihanDetail);
