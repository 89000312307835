import React, { useEffect, useState } from "react";
import Card from "../../../../../../components/cards";
import Tab from "../../../../../../components/tab";
import { Spin } from "antd";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import "./tabs.css";
import EditInfo from "./info";
import EditGallery from "./galery";
import EditAddress from "./address";
import { editDataBandara } from "stores/actions/datamaster";
import { IMAGE } from "constant/constanta";

function EditBandara({ setOpen }) {
  const { detailData } = useSelector((state) => state.datamaster);
  const { dataMultiple } = useSelector((state) => state.uploadFile);
  const dispatch = useDispatch();
  const [dataVideo, setDataVideo] = useState();
  const [time, setTime] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: "",
      domestic_code: "",
      name: "",
      logo: "",
      thumbnail: "",
      description: "",
      address_details: "",
      address_lat_long: "",
      latitude: "",
      longitude: "",
      url_image: "",
    },
  });

  useEffect(() => {
    if (detailData) {
      formik.setFormikState({
        values: {
          id: detailData?.data?.id_bandara,
          domestic_code: detailData?.data?.domestic_code,
          name: detailData?.data?.name,
          logo: detailData?.data?.logo,
          thumbnail: detailData?.data?.thumbnail,
          description: detailData?.data?.description,
          address_details: detailData?.data?.address_details,
          address_lat_long: detailData?.data?.address_lat_long,
          latitude: detailData?.data?.latitude,
          longitude: detailData?.data?.longitude,
          city: detailData?.data?.city,
          images: dataMultiple
            .filter((x) => x.type === "image")
            .map((x) => x.path),
        },
      });
      setDataVideo(
        detailData?.data?.gallery
          .filter((x) => x.type === "video")
          .map((x) => x.link)
      );
    }
    setTimeout(() => {
      setTime(true);
    }, 1000);
  }, [detailData]);

  const handleSave = () => {
    dispatch(
      editDataBandara({
        ...formik.values,
        url_video: dataVideo,
        images: dataMultiple
          .filter((x) => x.type === "image" && x.path.split("/")[2] === IMAGE)
          .map((x) => x.path),
      })
    );
    setOpen(false);
  };

  return (
    <>
      {time === true ? (
        <Card containerClass=" !shadow-none !rounded-2xl !border !border-gray-200">
          <Tab
            defaultActiveKey="1"
            customClass="!mb-4"
            items={[
              {
                label: "Info Dasar",
                key: "1",
                children: (
                  <EditInfo
                    valueName={formik.values.name}
                    valueCode={formik.values.domestic_code}
                    valueDescription={formik.values.description}
                    descriptionOnChange={(value) => {
                      formik.setFieldValue("description", value);
                    }}
                    formikOnChange={formik.handleChange}
                    formikValue={formik.setFieldValue}
                    handleSave={handleSave}
                  />
                ),
              },

              {
                label: "Alamat & Koordinat",
                key: "2",
                children: (
                  <EditAddress
                    valueAddress={formik?.values.address_lat_long}
                    valueAddressDetail={formik?.values.address_details}
                    valueLat={formik?.values?.latitude}
                    valueLong={formik?.values?.longitude}
                    formikOnChange={formik.handleChange}
                    handleSave={handleSave}
                    formikValue={formik.setFieldValue}
                    valueCity={formik.values.city}
                  />
                ),
              },
              {
                label: "Galeri & Deskripsi",
                key: "3",
                children: (
                  <EditGallery
                    valueImage={formik.values.images}
                    valueVideo={formik.values.url_video}
                    formikValue={formik.setFieldValue}
                    handleSave={handleSave}
                    setDataVideo={setDataVideo}
                    dataVideo={dataVideo}
                  />
                ),
              },
            ]}
          />
        </Card>
      ) : (
        <div className="w-full h-[534px] flex justify-center items-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
}

export default EditBandara;
