import React, { useCallback, useState } from "react";
import SidebarChild from "components/template_layout/Siderbar/SidebarChild";
import { LayananIslamiMenuList } from "configuration/layananIslamiMenuList";
import Doa from "./components/Doa";

const LayananIslami = () => {
  const [showContent, setShowContent] = useState("shalat");

  const getContent = useCallback(() => {
    switch (showContent) {
      case "shalat":
        return <div>Shalat</div>;
      case "quran":
        return <div>Quran</div>;
      case "kiblat":
        return <div>Arah Kiblat</div>;
      case "masjid":
        return <div>Masjid</div>;
      case "doa":
        return <Doa />;
      default:
        return <div>Shalat</div>;
    }
  }, [showContent]);

  return (
    <>
      <SidebarChild
        menuList={LayananIslamiMenuList}
        handleClick={setShowContent}
        showContent={showContent}
      />
      <div style={{ marginLeft: "174px", paddingLeft: "16px" }}>
        {getContent()}
      </div>
    </>
  );
};

export default LayananIslami;
