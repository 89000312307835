import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ArticleAction from "stores/actions/apps/artikel";
import ArticleCategoryAction from "stores/actions/apps/artikel/category";

import Popup from "components/popup";
import Buttons from "components/buttons";
import Selects from "components/form/selects";
import MoslemeTable from "components/Table";
import AddOrEditArtikel from "./components/AddOrEditArtikel";
import AddOrEditKategori from "./components/AddOrEditKategori";
import AppsKelolaKategori from "./components/KelolaKategoriArtikel";
import AlertPopup from "components/popup/alert";

import { Edit, Trash } from "iconsax-react";
import IconLike from "assets/icons/Like.svg";
import { handleErrorCustom, handleSuccess } from "stores/actions/errorGeneral";

const AppsArtikel = () => {
  const dispatch = useDispatch();
  const [dataArtikel, setDataArtikel] = useState([]);
  const store = useSelector(({ artikel }) => artikel);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const [openPopupAddKategori, setOpenPopupAddKategori] = useState({
    show: false,
    edit: null,
  });
  const [openPopupAddArtikel, setOpenPopupAddArtikel] = useState({
    show: false,
    edit: null,
  });
  const [openPopupKelolaKategori, setOpenPopupKelolaKategori] = useState(false);
  const [successEditOrDeleteArtikel, setSuccessEditOrDeleteArtikel] =
    useState(false);

  const [typePost, setTypePost] = useState([
    {
      id: 0,
      value: "semua",
      label: "Semua",
    },
    {
      id: 1,
      value: "publish",
      label: "Publish",
    },
    {
      id: 2,
      value: "draft",
      label: "Draft",
    },
  ]);
  const [selectedTypePost, setSelectedTypePost] = useState(1);
  const [selectedKategoriArtikel, setSelectedKategoriArtikel] = useState(null);
  const [category, setCategory] = useState([]);
  const [openDeleteArtikel, setOpenDeleteArtikel] = useState(false);
  const [artikelIdDelete, setArtikelIdDelete] = useState();
  const [loading, setLoading] = useState(false);

  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(true);

  const columns = [
    {
      title: "Judul",
      dataIndex: "title",
      className: "whitespace-nowrap",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Kategori",
      dataIndex: "kategori",
      render: (kategori) => (
        <div className="flex flex-row gap-1">
          <div className="bg-[#fe990133] rounded-lg px-4 py-1">
            <div className="text-[#FE9A00] font-semibold">{kategori}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Like",
      dataIndex: "like",
      render: (like) => (
        <div className="flex flex-row gap-1">
          <img src={IconLike} alt="like" />
          <div>{like}</div>
        </div>
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
    },
    {
      title: "Tanggal Post",
      dataIndex: "date",
      render: (date) => <div>{moment(date).format("DD/MM/YYYY HH:mm:ss")}</div>,
    },
    {
      title: "Author",
      dataIndex: "author",
      render: (author) => (
        <div>
          <div>
            <div>{author}</div>
            <div className="flex flex-row gap-1">
              <div className=""></div>
            </div>
          </div>
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      render: ({ id, status, data }) => (
        <div>
          {status === 1 ? (
            <div className="bg-[#2eb24333] rounded-lg px-4 py-1 w-fit">
              <div className="text-[#333D29] font-semibold">Publish</div>
            </div>
          ) : (
            <div className="bg-[#CED4DA] rounded-lg px-4 py-1 w-fit">
              <div className="text-[#6C757D] font-semibold">Draft</div>
            </div>
          )}
          <div className="action">
            <div className="action-content">
              {permissions?.includes("update_apps") && (
                <div className="action-content-item">
                  <Edit
                    className="cursor-pointer"
                    size="15"
                    color="#141414"
                    onClick={() => {
                      setOpenPopupAddArtikel({
                        show: true,
                        edit: data,
                      });
                    }}
                  />
                </div>
              )}
              {permissions?.includes("delete_apps") && (
                <div className="action-content-item">
                  <Trash
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenDeleteArtikel(true);
                      setArtikelIdDelete(id);
                    }}
                    size="15"
                    color="#141414"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setDataArtikel(store.data);
  }, [store.data, store.data.length]);

  useEffect(() => {
    dispatch(ArticleAction.fetchData());
  }, [
    dispatch,
    store.keyword,
    store.per_page,
    store.page_number,
    store.id_role,
  ]);

  useEffect(() => {
    dispatch(ArticleCategoryAction.fetchData());
  }, []);

  useEffect(() => {
    dispatch(
      ArticleAction.fetchData({
        kategori_id: selectedKategoriArtikel,
        status: selectedTypePost,
      })
    );
  }, [selectedTypePost, selectedKategoriArtikel]);

  useEffect(() => {
    let list = [
      {
        value: "all",
        label: "Semua",
        id: 0,
      }
    ];
    store.listCategory.map((val, idx) => {
      let value = {
        value: val.nama_kategori,
        label: val.nama_kategori,
        id: val.id_kategori_artikel,
      };
      return list.push(value);
    });
    setCategory(list);
    setOpenPopupAddKategori({
      show: false,
      edit: null,
    });
    setSuccessEditOrDeleteArtikel(false);
  }, [store.listCategory.length, successEditOrDeleteArtikel]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: store.total,
    current: store.page_number,
    pageSize: store.per_page,
    defaultPageSize: store.per_page,
    pageSizeOptions: ["10", "20"],
  };

  const confirmDeleteArtikel = async () => {
    setLoading(true);
    const status = await dispatch(ArticleAction.deleteData(artikelIdDelete));

    if (status === 200) {
      setDataArtikel(store.data);
      setOpenDeleteArtikel(false);
      setLoading(false);
      dispatch(
        handleSuccess(null, { message: "Artikel Berhasil dihapus", code:200 })
      )
    } else {
      dispatch(
        handleErrorCustom("Terjadi kesalahan", 400)
      )
    }
  };

  return (
    <>
      <Popup
        open={openPopupAddKategori.show}
        handleClose={() =>
          setOpenPopupAddKategori({
            show: false,
            edit: null,
          })
        }
        title={`${
          openPopupAddKategori.edit && openPopupAddKategori.edit.nama_kategori
            ? "Edit Kategori Artikel"
            : "Tambah Kategori Artikel"
        }`}
        width="982px"
        children={
          <AddOrEditKategori
            setOpenPopupAddKategori={setOpenPopupAddKategori}
            setSuccessEditOrDeleteArtikel={setSuccessEditOrDeleteArtikel}
            edit={openPopupAddKategori.edit}
          />
        }
      />
      <Popup
        open={openPopupAddArtikel.show}
        handleClose={() =>
          setOpenPopupAddArtikel({
            show: false,
            edit: null,
          })
        }
        title={`${
          openPopupAddArtikel.edit ? "Edit Artikel" : "Tambah Artikel"
        }`}
        width="982px"
        children={
          <AddOrEditArtikel
            category={category}
            edit={openPopupAddArtikel.edit}
            setOpenPopupAddArtikel={setOpenPopupAddArtikel}
          />
        }
      />
      <Popup
        open={openPopupKelolaKategori}
        handleClose={() => setOpenPopupKelolaKategori(false)}
        title="Kelola Kategori Artikel"
        width="982px"
        children={
          <AppsKelolaKategori
            setOpenPopupKelolaKategori={setOpenPopupKelolaKategori}
            setOpenPopupAddKategori={setOpenPopupAddKategori}
            setSuccessEditOrDeleteArtikel={setSuccessEditOrDeleteArtikel}
            listCategory={store.listCategory}
          />
        }
      />
      <AlertPopup
        open={openDeleteArtikel}
        subtitle="Apakah anda yakin untuk menghapus artikel?"
        handleClose={() => setOpenDeleteArtikel(false)}
        handleContinue={confirmDeleteArtikel}
        loading={loading}
      />
      <div className="bg-white soft-shadow rounded-[20px]">
        <div className="flex flex-row justify-between p-4">
          <div className="text-[20px] font-semibold">Artikel</div>
          <div className="flex flex-row gap-4">
            <Buttons
              text="Kelola Kategori Artikel"
              customClass="btn-secondary btn-small"
              onClick={() => setOpenPopupKelolaKategori(true)}
            />
            {permissions?.includes('create_apps') && (
              <Buttons
                text="Tambah Artikel"
                customClass="btn-primary btn-small"
                onClick={() =>
                  setOpenPopupAddArtikel({
                    show: true,
                    edit: null,
                  })
                }
              />
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <div className="flex flex-row gap-4">
            <Selects
              label="Tipe Post"
              placeholder="Semua Tipe"
              containerClass="!w-[200px]"
              options={typePost}
              searchable
              itemRenderer={({ state, item, methods }) => {
                return (
                  <>
                    <div
                      className={`react-dropdown-select-item ${
                        state.values.length !== 0 &&
                        state.values[0].value === item.value &&
                        "react-dropdown-select-item-selected"
                      }`}
                      onClick={() => {
                        methods.addItem(item);
                        setSelectedTypePost(item.id);
                      }}
                    >
                      {item.label}
                    </div>
                  </>
                );
              }}
            />
            <Selects
              label="Kategori Artikel"
              placeholder="Semua Kategori"
              containerClass="!w-[200px]"
              options={category}
              searchable
              itemRenderer={({ state, item, methods }) => {
                return (
                  <>
                    <div
                      className={`react-dropdown-select-item ${
                        state.values.length !== 0 &&
                        state.values[0].value === item.value &&
                        "react-dropdown-select-item-selected"
                      }`}
                      onClick={() => {
                        methods.addItem(item);
                        setSelectedKategoriArtikel(item.id);
                      }}
                    >
                      {item.label}
                    </div>
                    {state.searchResults[state.searchResults.length - 1]
                      .value === item.value && (
                      <Buttons
                        text="Tambah Kategori"
                        customClass="btn-primary btn-medium mt-1"
                        onClick={() =>
                          setOpenPopupAddKategori({
                            show: true,
                            edit: null,
                          })
                        }
                      />
                    )}
                  </>
                );
              }}
            />
          </div>
          <MoslemeTable
            customClass="mt-4"
            loading={store.status === "process"}
            columns={columns}
            data={dataArtikel.map((item, i) => {
              return {
                ...item,
                key: `${i + 1}`,
                title: item.judul,
                kategori: item.nama_kategori,
                like: item.jumlah_like,
                comment: item.jumlah_komentar,
                date: item.created_at,
                author: item.author_name,
                status: {
                  id: item.id_artikel,
                  status: item.status,
                  data: item,
                },
              };
            })}
            withSelection={withSelection}
            selectionType={selectionType}
            pagination={pagination}
            onPageChange={(page) => dispatch(ArticleAction.setPage(page))}
            onSizeChange={(currentpage, pageSize) => {
              dispatch(ArticleAction.setPage(currentpage));
              dispatch(ArticleAction.setSize(pageSize));
            }}
          />
        </div>
      </div>
      <div id="area-modal"></div>
    </>
  );
};

export default React.memo(AppsArtikel);
