import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import MoslemeTable from "components/Table";
import Badge from "components/badge";

import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import BankBCA from "assets/images/bca-rounded.svg";

import { getHistoryFeeService } from "utils/api/fee";
import { handleError } from "stores/actions/errorGeneral";
import moment from "moment";
import { IDRFormater } from "utils/helpers";

const HistoryPendapatanFee = () => {
  const dispatch = useDispatch();
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Expaneble
  // Selection & type state
  const [feeHistories, setFeeHistories] = useState([]);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: size,
    pageSizeOptions: [10, 20, 50, 100],
  };

  const handleGetPenarikanFee = async () => {
    setIsLoading(true);
    const payload = {
      offset: size,
      page: currentpage,
    }

    const res = await getHistoryFeeService(payload);
    setIsLoading(false);

    if (res?.status === 200) {
      setFeeHistories(res?.data?.data);

      setTotal(res?.data?.total_data);

    } else {
      // gettingFeeHistory(false);
      dispatch(handleError(res?.data));
    }

  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };

  useEffect(() => {
    handleGetPenarikanFee();
  }, []);

  useEffect(() => {
    handleGetPenarikanFee();
  }, [size, currentpage]);

  const columns = [
    {
      title: "Nama Paket",
      dataIndex: "package",
      render: (record) => (
        <div>
          <div>{record?.judul_paket}</div>
          {record?.tipe_paket == 1 && <Badge backgroundColor={"#BBDEFB"} color={"#0000FE"} label="Haji" customClass="mt-[6px]" />}
          {record?.tipe_paket == 2 && <Badge backgroundColor="#92E6A7" color="#1B662E" label="Umroh" customClass="mt-[6px]" />}
          {record?.tipe_paket == 3 && <Badge backgroundColor={"#F7A399"} color="#FE0000" label="Tour" customClass="mt-[6px]" />}
          {record?.tipe_paket == 4 && <Badge label="Tabungan" customClass="mt-[6px]" />}
          {record?.tipe_paket == 5 && <Badge label="Jasa Badal" customClass="mt-[6px]" />}
        </div>
      ),
    },
    {
      title: "Tanggal Pendapatan",
      dataIndex: "received_at",
      render: (record) => (
        moment(record).format("dddd, DD MMMM YYYY")
      ),
    },
    {
      title: "Jumlah Pax",
      dataIndex: "count_pax",
      render: (record) => (
        <div>
          <div>{record} pax</div>
        </div>
      ),
    },
    {
      title: "Nominal Pendapatan",
      dataIndex: "fee_total",
      render: (record) => (
        <div>
          <div className="font-semibold">
            {IDRFormater(record)}
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <MoslemeTable
        loading={isLoading}
        columns={columns}
        data={feeHistories}
        withSelection={false}
        pagination={pagination}
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
        customClass="!mt-4 !overflow-hidden !rounded-2xl !shadow-soft"
      />
    </>
  );
};

export default React.memo(HistoryPendapatanFee);
