import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Buttons from "components/buttons";
import MoslemeTable from "components/Table";

import IconCSV from "assets/icons/CSV.svg";
import BCA from "assets/images/bca.png";

import { onHandleGetOrderPaymentsReportAPI } from "utils/api/paket/laporan";
import { handleError } from "stores/actions/errorGeneral";

const PaketLaporanPembayaranDaftarPopup = ({
  orderID
}) => {
  const dispatch = useDispatch();
  const params = useParams();

  // Loading
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const {total, setTotal} = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const columns = [
    {
      title: "Tanggal Pembayaran",
      dataIndex: "date",
      render: (date) => <div>{date}</div>,
    },
    // {
    //   title: "Nomor Pembayaran",
    //   dataIndex: "payment_number",
    //   render: (number) => <div>{number}</div>,
    // },
    // {
    //   title: "Nominal Pembayaran",
    //   dataIndex: "payment_nominal",
    //   render: (nominal) => <div className="font-semibold">Rp {nominal}</div>,
    // },
    // {
    //   title: "Akun Penerima",
    //   dataIndex: "receipent_account",
    //   render: (account) => (
    //     <>
    //       <div className="flex flex-row items-center gap-2">
    //         <img className="flex w-[16px] h-[16px]" src={BCA} alt="bank" />
    //         <div>{account.bank}</div>
    //       </div>
    //       <div className="font-semibold mt-1">{account.name}</div>
    //     </>
    //   ),
    // },
    // {
    //   title: "Akun Pengirim",
    //   dataIndex: "receipent_sender",
    //   className: "whitespace-nowrap",
    //   render: (sender) => (
    //     <>
    //       <div className="flex flex-row items-center gap-2">
    //         <img className="flex w-[16px] h-[16px]" src={BCA} alt="bank" />
    //         <div>{sender.bank}</div>
    //       </div>
    //       <div className="font-semibold mt-1">{sender.name}</div>
    //     </>
    //   ),
    // },
  ];

  const data = [
    {
      key: "1",
      payment_date: "21/08/2022 14:07 WIB",
      payment_number: "#UMR-123-1",
      payment_nominal: "900.000.000",
      receipent_account: {
        name: "Dimas Andreyan Pradana Putra",
        bank: "7625 3464 756",
      },
      receipent_sender: {
        name: "Dimas Andreyan Pradana Putra",
        bank: "7625 3464 756",
      },
    },
    {
      key: "2",
      payment_date: "21/08/2022 14:07 WIB",
      payment_number: "#UMR-123-1",
      payment_nominal: "900.000.000",
      receipent_account: {
        name: "Dimas Andreyan Pradana Putra",
        bank: "7625 3464 756",
      },
      receipent_sender: {
        name: "Dimas Andreyan Pradana Putra",
        bank: "7625 3464 756",
      },
    },
  ];

  const handleGetPayments = async () => {
    setIsLoading(true);
    const payload = {
      "keyword" : null, // payment_number
      "sort_column" : "id",
      "sort_order" : "asc",
      "per_page" : currentpage,
      "page_number" : size,
  }
    const response = await onHandleGetOrderPaymentsReportAPI(params.id, orderID, payload);
    setIsLoading(false);

    if (response.status === 200) {
      setPayments(response.data.data);
      setTotal(response.data.total_data);
    } else {
      dispatch(
        handleError(response.data)
      )
    }

  }

  // manipulated data with action
  useEffect(() => {
    handleGetPayments();
  }, [])

  useEffect(() => {
    handleGetPayments();
  }, [currentpage,size])

  return (
    <div className="bg-white normal-shadow px-6 py-5 rounded-3xl">
      <div className="flex flex-row justify-end">
        <Buttons
          text="Export CSV"
          customClass="btn-outline btn-small"
          icon={IconCSV}
        />
      </div>
      <div className="mt-4">
        <MoslemeTable
          loading={isLoading}
          columns={columns}
          data={payments}
          pagination={pagination}
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      </div>
    </div>
  );
};

export default React.memo(PaketLaporanPembayaranDaftarPopup);
