import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import { appSidebarList } from "configuration/appMenuList";

import AppStyle from "./Apps.Style";
import SidebarChild from "components/template_layout/Siderbar/SidebarChild";
import Galeri from "./components/Galeri";
import Testimoni from "./components/Testimoni";
import Slide from "./components/Slide";
import Artikel from "./components/Artikel";
import Guide from "./components/Guide";
import Staff from "./components/Staff";
import Mitra from "./components/Mitra";

const AppsPage = () => {
  const [showContent, setShowContent] = useState("galeri");

  const getContent = useCallback(() => {
    switch (showContent) {
      case "galeri":
        return <Galeri />;
      case "testimoni":
        return <Testimoni />;
      case "slide":
        return <Slide />;
      case "artikel":
        return <Artikel />;
      case "guide":
        return <Guide />;
      case "staff":
        return <Staff />;
      case "mitra":
        return <Mitra />;
      default:
        return <Galeri />;
    }
  }, [showContent]);

  return (
    <AppStyle>
          <SidebarChild
            menuList={appSidebarList}
            handleClick={setShowContent}
            showContent={showContent}
          />
          <div style={{ marginLeft: '174px', paddingLeft: '16px' }}>
            {getContent()}
          </div>
    </AppStyle>
  );
};

export default AppsPage;
