import React from "react";

import Avatar from "components/avatar";

import Photo from "assets/images/Photo.png";
import Badge from "components/badge";

import { Edit, Trash } from "iconsax-react";

import Quad from 'assets/icons/Quad.svg'
import Triple from 'assets/icons/Triple.svg'
import Double from 'assets/icons/Double.svg'
import Single from 'assets/icons/Single.svg'

import IconPhoneMaroon from 'assets/icons/icon-phone-maroon.svg'
import IconEmailMaroon from 'assets/icons/icon-email-maroon.svg'

import { IDRFormater, toCapital } from "utils/helpers";

import './style.css'

const Step2JamaahCard= ({ bookingForm, formData, index, triggerRemove, triggerEdit }) => {
  
  const handleRemoveJamaah = () => {
    triggerRemove(index)
  }

  const changeRoomName = (roomName) => {
    roomName = roomName.replace('tripple', 'Triple')
    roomName = roomName.replace('_bussiness', ' Business')  
    return roomName
  }

  return (
    <>
      <div className="bg-white p-4 mt-4 rounded-xl">
        <div className="flex justify-between items-start">
          <div className="flex items-center gap-3">
            <Avatar 
              customGender={formData.values.order_kamars[index].jamaah_title?.toLowerCase() == 'tn' ? 'ikhwan' : 'akhwat'}
              // imageTemp={Photo}
              containerClass={`items-center justify-center w-[56px] h-[56px] rounded-[100%]  bg-slate-100`}
              customClass="object-cover w-[56px] h-[56px] rounded-[100%]"
              />
            <div>
              <span className=" font-semibold text-xs">
                {formData?.values?.order_kamars[index]?.jamaah_title?.toUpperCase()}. {formData.values.order_kamars[index].jamaah_name?.toUpperCase()}
              </span>
              <div className="flex items-center gap-2 mt-2">
                <Badge 
                  label={bookingForm.values.pemesan.branch.name}
                  backgroundColor={bookingForm.values.pemesan.branch.bg_color}
                  color={bookingForm.values.pemesan.branch.text_color}
                  />
                <Badge 
                  label={formData.values.order_kamars[index].starting.name}
                  backgroundColor={formData.values.order_kamars[index].starting.bg_color}
                  color={formData.values.order_kamars[index].starting.text_color}
                  />
                {formData.values.order_kamars[index].without_ticket && (
                  <>
                    <Badge 
                      label="Tanpa Tiket"
                      />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-6">
            <Edit
                className="cursor-pointer"
                onClick={() => triggerEdit(index)}
                size="24"
                color="#141414"
              />
            {formData.values.order_kamars.length > 1 && (
              <>
                <Trash
                  className="cursor-pointer"
                  onClick={handleRemoveJamaah}
                  size="24"
                  color="#141414"
                />
              </>
            )}
          </div>
        </div>

        <div className="p-4 rounded-2xl flex justify-between items-start border border-gray-4 mt-[18px]">
          <div className="flex gap-11.5 justify-between w-[40%]">
            <div>
              <div className="text-xs text-think mb-1">
                Berangkat
              </div>
              <div className="font-semibold">
                {formData.values.order_kamars[index].starting.departure}
              </div>
            </div>
            <div>
              <div className="text-xs text-think mb-1">
                Pulang
              </div>
              <div className="font-semibold">
                {formData.values.order_kamars[index].starting.arrival}
              </div>
            </div>
          </div>
          <Badge 
            label={formData.values.order_kamars[index].starting.name}
            backgroundColor={formData.values.order_kamars[index].starting.bg_color}
            color={formData.values.order_kamars[index].starting.text_color}
            />
        </div>

        <div className="p-4 rounded-2xl flex justify-between items-start border border-gray-4 mt-[18px]">
          <div className="flex gap-2 items-center">
            {(formData.values.order_kamars[index]?.paket_kamar?.kamar_name === 'quad' || formData.values.order_kamars[index]?.paket_kamar?.kamar_name === 'quad_bussiness') && (
                <>
                    <img 
                    src={Quad}
                    />
                </>
            )}
            {(formData.values.order_kamars[index]?.paket_kamar?.kamar_name === 'tripple' || formData.values.order_kamars[index]?.paket_kamar?.kamar_name === 'tripple_bussiness') && (
                <>
                    <img 
                    src={Triple}
                    />
                </>
            )}
            {(formData.values.order_kamars[index]?.paket_kamar?.kamar_name === 'double' || formData.values.order_kamars[index]?.paket_kamar?.kamar_name === 'double_bussiness') && (
                <>
                    <img 
                    src={Double}
                    />
                </>
            )}
            {(formData.values.order_kamars[index]?.paket_kamar?.kamar_name === 'single' || formData.values.order_kamars[index]?.paket_kamar?.kamar_name === 'single_bussiness') && (
                <>
                    <img 
                    src={Single}
                    />
                </>
            )}
            <div className="text-base font-semibold">
              {toCapital(changeRoomName(formData.values.order_kamars[index]?.paket_kamar?.kamar_name))}
            </div>
          </div>
          <div className="green-money font-semibold text-xl">
            {IDRFormater(formData.values.order_kamars[index].paket_kamar.price)}
          </div>
        </div>

        <div className="p-4 rounded-2xl flex justify-start items-start border border-gray-4 mt-[18px] gap-10">
          <div className="flex gap-4">
            <img 
              src={IconPhoneMaroon}
              />
            <div>
              {formData.values.order_kamars[index].jamaah_phone}
            </div>
          </div>
          <div className="flex gap-4">
            <img 
              src={IconEmailMaroon}
              />
            <div>
              {formData.values.order_kamars[index].jamaah_email}
            </div>
          </div>
        </div>
      </div>  
    </>
  )
}

export default React.memo(Step2JamaahCard);