import {
  START_FETCHING_SETTING_ARTICLE,
  ERROR_FETCHING_SETTING_ARTICLE,
  SUCCESS_FETCHING_SETTING_ARTICLE,
  SET_ID_ARTICLE,
} from './actionType';

import { SettingArticleAPI } from '../../../utils/api/setting';
import debounce from 'debounce-promise';
import { useBase64 } from 'utils/helpers/useBase64';
import { handleError, handleSuccess } from '../errorGeneral';
let debounceGetData = debounce(SettingArticleAPI.getListPage, 1500);

const startFetchingSettingArticle = () => ({
  type: START_FETCHING_SETTING_ARTICLE,
});

const errorFetchingSettingArticle = () => ({
  type: ERROR_FETCHING_SETTING_ARTICLE,
});

const successFetchingSettingArticle = (data) => ({
  type: SUCCESS_FETCHING_SETTING_ARTICLE,
  data,
});

const setId = (id) => ({
  type: SET_ID_ARTICLE,
  id,
});

const fetchData = () => {
  return async (dispatch, getState) => {
    dispatch(startFetchingSettingArticle());
    try {
      let kategori_id = getState().articleSetting.kategori_id;
      const payload = {};

      if (kategori_id !== '0') {
        payload.kategori_id = kategori_id;
      }
      let res = await debounceGetData(payload);
      //   console.log('res: ', res);
      if (res.data.code === 401) {
        window.location.href = '/example';
      }
      let data =
        res.data.data === ''
          ? []
          : res.data.data.map((v) => {
              return {
                ...v,
                isi: v.isi,
              };
            });
      dispatch(successFetchingSettingArticle(data));
    } catch (error) {
      console.log('error: ', error);
      dispatch(errorFetchingSettingArticle());
    }
  };
};

const createSetting = (payload, action) => {
  return async (dispatch) => {
    try {
      let res = await SettingArticleAPI.postData(payload);
      if (res.status === 200) {
        dispatch(handleSuccess(null, { message: 'Berhasil menambahkan artikel', code: 200 }));
        action();
      } else {
        dispatch(handleError(res.data));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  }
};  

const deleteSetting = (id) => {
  return async (dispatch) => {
    // dispatch(startFetchingSettingArticle());
    try {
      let res = await SettingArticleAPI.deleteData(id);
      if (res.status === 200) {
        dispatch(fetchData());
        dispatch(handleSuccess(null, { message: 'Berhasil menghapus artikel', code: 200 }));
      } else {
        dispatch(handleError(res.data));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const SettingAction = {
  fetchData: fetchData,
  createData: createSetting,
  deleteData: deleteSetting,
  setId: setId,
};

export default SettingAction;
