import { BASE_URL_ENDPOINT, BASE_URL_ENDPOINT_V2 } from "constant/constanta";
import axios from "axios";

export const getManifestJamaahServiceV2 = (id, params, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/jamaah-orders?data=${params.data}`,
    payload
  );

export const postUploadManifestServiceV2 = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/manifest/upload`,
    payload
  );

export const handleUpdatePembimbingManfiest = (package_id, pembimbing_id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/paket/${package_id}/pembimbing/${pembimbing_id}/manifests/update`,
    payload
  )

export const handleGetPembimbingManifestsAPI = (package_id) =>
  axios.get(
    `${BASE_URL_ENDPOINT}/admin/paket/${package_id}/pembimbing/manifests`
  )

export const exportManifestJamaah = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/manifest/export`
  );

export const exportManifestPembimbing = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/pembimbing/manifests/exports`
  );