import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import SidebarChild from "../../components/template_layout/Siderbar/SidebarChild";
import { menuMarketingSidebarList } from "configuration/MarketingMenuList";
import Marketing from "./detail/marketing";

import ILCoomingSoon from "assets/images/cooming-soon.svg";

const MarketingKit = () => {
  const [showContent, setShowContent] = useState("marketing");

  const getContent = useCallback(() => {
    switch (showContent) {
      case "marketing":
        return <Marketing />;
      default:
        return <>
          <div className="flex flex-row justify-center items-center w-full !h-[calc(100vh-135px)] bg-white rounded-2xl">
            <div className="flex flex-col items-center">
              <img src={ILCoomingSoon} alt="comming soon" />
              <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
                Masih dalam pengerjaan
              </div>
            </div>
          </div>
        </>
    }
  }, [showContent]);

  return (
    <>
      <SidebarChild
        menuList={menuMarketingSidebarList}
        handleClick={setShowContent}
        showContent={showContent}
      />
      <div style={{ marginLeft: '174px', paddingLeft: '16px' }}>
        {getContent()}
      </div>

    </>
  );
};

export default MarketingKit;
