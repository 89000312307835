import React from "react";
import Badge from "../../components/badge";
import TableDocs from "../../components/Table/docs";
import CheckboxDocs from "../../components/checkbox/docs";
import Tab from "../../components/tab";
import TabDocs from "../../components/tab/docs";
import ButtonDocs from "../../components/buttons/docs";
import InputsDocs from "../../components/form/inputs/docs";
import SelectsDocs from "../../components/form/selects/docs";
import RadiosDocs from "../../components/form/radio/docs";
import SwitchsDocs from "../../components/form/switch/docs";
import PopupDocs from "../../components/popup/docs";
import TextAreasDocs from "../../components/form/textarea/docs";
import DatePickerDocs from "../../components/datepicker/docs";
import ProgressBarsDocs from "../../components/progressbars/docs";
import SliderDocs from "../../components/slider/docs";
import PlacesDocs from "../../components/map/docs";
import ChartDocs from "../../components/chart/docs";
import VideoPlayerDocs from "../../components/video/docs";
import DocsPopupConfirm from "../../components/popup/error-handling/docs";
import RekeningCard from "../../components/RekeningCard/index";
import StepsDoc from "./../../components/steps/doc";
import RichTextEditor from "./../../components/richTextEditor/index";
import DocsAlertPopup from "../../components/popup/alert/docs";
import DocScanQR from "../../components/scanQR/docs";
import InputOTPDocs from "../../components/form/inputOTP/docs";
import ImageFieldDocs from "../../components/image_field/docs";
import FileFieldDocs from "../../components/file_field/docs";
import DropwndonDocs from "../../components/dropdowns/docs";
import SegmentDoc from "../../components/segment/docs";
import RatesDocs from "../../components/form/rate/docs";
import DrawersDoc from "../../components/drawer/docs";
import SteppersDocs from "../../components/steps/Steppers.docs";
import ColorPickerDocs from "../../components/color_picker/docs";
import AccordionsDocs from "../../components/accordions/docs";
import TimePickersDoc from "../../components/timepickers/docs";
import { imageBase64, imageLink } from "./data";
import AntdSelectsDocs from "components/form/antdSelect/docs";

const Badges = () => {
  return (
    <div>
      <div>
        <Badge label={"Badge"} />
      </div>
      <div>
        <Badge type={"dark brown"} label={"Badge"} />
      </div>
    </div>
  );
};

const Documentation = () => {
  return (
    <>
      <h1 className="mb-2 font-semibold text-lg">Documentation</h1>
      <div className="max-w-[1500px] overflow-x-scroll pb-10 bg-white px-5">
        <Tab
          defaultActiveKey="25"
          items={[
            {
              label: "Button",
              key: "1",
              children: <ButtonDocs />,
            },
            {
              label: "Input",
              key: "2",
              children: <InputsDocs />,
            },
            {
              label: "Antd Select",
              key: "3",
              children: <AntdSelectsDocs />,
            },
            {
              label: "Radio Button",
              key: "4",
              children: <RadiosDocs />,
            },
            {
              label: "Switch",
              key: "5",
              children: <SwitchsDocs />,
            },
            {
              label: "Charts",
              key: "6",
              children: <ChartDocs />,
              disabled: false,
            },
            {
              label: "Badges",
              key: "7",
              children: <Badges />,
            },
            {
              label: "Tables",
              key: "8",
              children: <TableDocs />,
            },
            {
              label: "Checkbox",
              key: "9",
              children: <CheckboxDocs />,
            },
            {
              label: "Tabs",
              key: "10",
              children: <TabDocs />,
            },
            {
              label: "Popups",
              key: "11",
              children: <PopupDocs />,
            },
            {
              label: "Textarea",
              key: "12",
              children: <TextAreasDocs />,
            },
            {
              label: "Date Picker",
              key: "13",
              children: <DatePickerDocs />,
            },
            {
              label: "Progress Bar",
              key: "14",
              children: <ProgressBarsDocs />,
            },
            {
              label: "Slider",
              key: "15",
              children: <SliderDocs />,
            },
            {
              label: "Map",
              key: "16",
              children: <PlacesDocs />,
            },
            {
              label: "Video Player",
              key: "17",
              children: <VideoPlayerDocs />,
            },
            {
              label: "Rekening Card",
              key: "18",
              children: <RekeningCard />,
            },
            {
              label: "Steps",
              key: "19",
              children: <StepsDoc />,
            },
            {
              label: "Rich Text Editor",
              key: "20",
              children: <RichTextEditor />,
            },
            {
              label: "Error Handling",
              key: "21",
              children: <DocsPopupConfirm />,
            },
            {
              label: "Alert Confirm",
              key: "22",
              children: <DocsAlertPopup />,
            },
            {
              label: "Scan QR",
              key: "23",
              children: <DocScanQR />,
            },
            {
              label: "Input OTP",
              key: "24",
              children: <InputOTPDocs />,
            },
            {
              label: "Image Field",
              key: "25",
              children: <ImageFieldDocs />,
            },
            {
              label: "File Field",
              key: "26",
              children: <FileFieldDocs />,
            },
            {
              label: "Dropdown",
              key: "27",
              children: <DropwndonDocs />,
            },
            {
              label: "Segment",
              key: "28",
              children: <SegmentDoc />,
            },
            {
              label: "Rate",
              key: "29",
              children: <RatesDocs />,
            },
            {
              label: "Drawer",
              key: "30",
              children: <DrawersDoc />,
            },
            {
              label: "Stepper",
              key: "31",
              children: <SteppersDocs />,
            },
            {
              label: "Color Picker",
              key: "32",
              children: <ColorPickerDocs />,
            },
            {
              label: "Accordion",
              key: "33",
              children: <AccordionsDocs />,
            },
            {
              label: "Time Picker",
              key: "34",
              children: <TimePickersDoc />,
            },
            {
              label: "Select",
              key: "35",
              children: <SelectsDocs />,
            },
          ]}
        />
      </div>
      <div>
        <h4>Testing image bas64 dan image Link setelah dibuild ama React</h4>

        <div>
          <h5>Image base 64</h5>
          <img
            src={imageBase64}
            alt="image base64"
            style={{
              width: "400px",
              height: "400px",
            }}
          />
        </div>

        <div>
          <h5>Imagelink</h5>
        </div>
        <img
          src={imageLink}
          alt="image link"
          style={{
            width: "400px",
            height: "400px",
          }}
        />
      </div>
    </>
  );
};

export default Documentation;
