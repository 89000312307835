import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import IconExit from "../../../../assets/icons/icon_exit.svg";
import PropTypes from "prop-types";
import Buttons from "../../../../components/buttons";

const PopupDataMaster = ({
  children,
  handleClose,
  open,
  width,
  height,
  title,
  onClick,
}) => {
  const useStyles = makeStyles(() => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: width,
      height: height,
      borderRadius: "8px",
      background: "#fff",
      padding: "20px",
      "& .card_title": {
        height: "auto",
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "16px",
        justifyContent: "space-between",
        borderRadius: "16px",
        boxShadow:
          "0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.04)",
        "& .title": {
          fontFamily: "GeneralSansSemibold",
          fontSize: "18px",
          fontWeight: "600",
          color:
            "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
        },
        "& .icon": {
          width: "24px",
          height: "24px",
          cursor: "pointer",
          marginRight: "10px",
        },
      },
      "& .content": {
        marginTop: "40px",
        height: "100%",
        width: "100%",
      },
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className="card_title">
              <div className="flex ">
                <div className="icon" onClick={handleClose}>
                  <img src={IconExit} height={24} width={24} alt="icon_exit" />
                </div>
                <div className="title">
                  <span>{title}</span>
                </div>
              </div>
              <Buttons
                onClick={onClick}
                text="Simpan"
                customClass="btn-primary btn-medium"
              />
            </div>
            <div className="content">{children}</div>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

PopupDataMaster.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

PopupDataMaster.defaultProps = {
  open: false,
  handleClose: () => {},
  width: "608px",
  height: "476px",
  title: "Duplicate Coupon",
};

export default PopupDataMaster;
