import React, { useEffect, useState } from "react";
import moment from "moment";

import MoslemeTable from "../../../../../components/Table";
import Badge from "../../../../../components/badge";
import PaketTagihanDetail from "./components/Detail";
import { useSelector, useDispatch } from "react-redux";

import { DocumentCopy, Edit, Eye, InfoCircle, Trash } from "iconsax-react";
import AvatarRed from "../../../../../assets/images/Avatar-ikhwan-red.svg";
import IconEye from "../../../../../assets/icons/eye.svg";
import {
  getAllDataTagihan,
  getDetailDataTagihan,
} from "stores/actions/paketDetail";

import { useParams } from "react-router-dom";
import Avatar from "components/avatar";

import { toCapital, IDRFormater } from "utils/helpers";

import IconSistemMaroon from 'assets/icons/icon-sistem-maroon.svg'
import IconAdminMaroon from 'assets/icons/icon-admin-maroon.svg'
import Popup from "components/popup";

const PaketTagihan = () => {
  const params = useParams()
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [showDetail, setShowDetail] = useState(false);
  const [detailId, setDetailId] = useState("");
  
  const [billSelectedID, setBillIDSelected] = useState({})
  const [billSelectedNumber, setBillSelectedNumber] = useState({})

  const [openCancelReason, setOpenCancelReason] = useState(false)
  const [cancelReason, setCancelReason] = useState('')

  // data table
  const { data, status } = useSelector((state) => state.paketDetail);
  let dataTable = data.data;
  const dispatch = useDispatch();

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    handleGet()
  }, [dispatch, currentpage, size]);

  const handleGet = () => {
    dispatch(
      getAllDataTagihan(params.id, {
        page_number: currentpage,
        per_page: size,
        sort_column: "created_at",
        sort_order: "DESC"
      }, (successData) => {
        setTotal(successData.total_data)
      })
    );
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleGet()
    }, 600000); // 600000 milidetik = 10 menit

    return () => clearInterval(interval); // Membersihkan interval saat komponen di-unmount
  }, []);

  const columns = [
    {
      title: "Foto",
      width: "40px",
      render: (record) => (
        <>
          <Avatar value={record?.user_order} />
        </>
      ),
    },
    {
      title: "Nama Pemesan",
      // dataIndex: "user_jamaah",
      render: (record) => (
        <>
          <div className="w-max">{record?.user_order?.title && `${toCapital(record?.user_order?.title)}. `}{record?.user_order?.name}</div>
          <div className="flex flex-row mt-1 gap-1">
              <Badge 
               label={record?.user_order?.role} 
               color={record?.user_order?.color_text_role} 
               backgroundColor={record?.user_order?.color_bg_role} 
               type="orange old" />
              <Badge 
                label={record?.user_order?.branch?.name} 
                color={record?.user_order?.branch?.text_color} 
                backgroundColor={record?.user_order?.branch?.bg_color} 
                type="blue light" />
          </div>
        </>
      ),
    },
    {
      title: "Nomor Tagihan",
      dataIndex: "order_number",
    },
    {
      title: "Waktu Booking",
      dataIndex: "ordered_at",
      render: (record) => (
        <>
          <div className="">
            {record && moment(record).format("dddd, DD MMMM YYYY HH:mm") + " WIB"}
          </div>
        </>
      ),
    },
    {
      title: "Jumlah Jamaah",
      render: (record) => (
        <div 
          onClick={() => {
            setBillIDSelected(record.id_order)
            setBillSelectedNumber(record.order_number)
            // dispatch(getDetailDataTagihan(record.id));
            setShowDetail(true);
          }}
          className="border-[1px] border-gray-4 rounded-lg px-3 py-[6px]  flex flex-row items-center gap-2 w-max text-xs cursor-pointer">
          <img src={IconEye} className="w-4 h-4" alt="eye" />
          <div>{record && record?.amount_jamaah_order} pax</div>
        </div>
      ),
    },
    {
      title: "Status Pembayaran",
      dataIndex: "payment_status",
      render: (record, payment) => (
        <>
          {record === 'Belum DP' && (
            <Badge 
              backgroundColor={'#F7A399'}
              color={"#FE0000"}
              label={record.toUpperCase()} />
          )}
          {record === 'Sudah DP' && (
            <Badge 
              backgroundColor={'#BBDEFB'}
              color={"#0000FE"}
              label={`SUDAH DP`} />
          )}
          {record === 'Belum Lunas' && (
            <Badge 
              backgroundColor={'#FEDCED'}
              color={"#F20089"}
              label={`BELUM LUNAS`} />
          )}
          {record === 'Lunas' && (
            <Badge 
              backgroundColor={'#92E6A7'}
              color={"#1A7431"}
              label={record.toUpperCase()} />
          )}
          {record === 'Di Batalkan' && (
            <Badge 
              backgroundColor={'#CED4DA'}
              color={"#6C757D"}
              label={<>
                <div className="flex justify-center items-center gap-2">
                  <span>
                    {`DIBATALKAN`}
                  </span>
                  <div>
                    {payment.cancel_by > 0 ? <img src={IconAdminMaroon} /> : <img src={IconSistemMaroon} />}
                  </div>
                </div>
              </>} />
          )}
        </>
      ),
    },
    {
      title: "Total Tagihan",
      dataIndex: "total_bill",
      render: (record) => (
        <>
          <span className="text-sm font-semibold">{IDRFormater(record)}</span>
        </>
      )
    },
    {
      title: "Kekurangan",
      dataIndex: "remaining_payment",
      render: (record) => (
        <>
          <span className="text-sm font-semibold">{IDRFormater(record)}</span>
        </>
      )
    },
    {
      title: "Cabang",
      dataIndex: "branch",
      render: (record, billData) => (
        <>
          <Badge 
            label={record.name} 
            backgroundColor={record.bg_color}
            color={record.text_color}
            />
            {billData.payment_status === 'Di Batalkan' && (
              <div className="action">
                <div className="action-content">
                  <div className="action-content-item">
                    <InfoCircle 
                      size="15"
                      color="#141414"
                      className="cursor-pointer"
                      onClick={() => {
                        setOpenCancelReason(true)
                        setCancelReason(billData.cancel_reason)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
        </>
      ),
    },
  ];

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };

  // action button
  const ButtonAction = ({ value }) => {
    return (
      <div className="cursor-pointer flex justify-around">
        <Eye
          //   onClick={() => router.push("/tagihan/detail")}
          size="15"
          color="#141414"
        />
        <Trash
          onClick={() => alert(`delete id:`, value)}
          size="15"
          color="#141414"
        />
        <DocumentCopy
          onClick={() => alert(`copy id:`, value)}
          size="15"
          color="#141414"
        />
        <Edit
          onClick={() => alert(`edit id:`, value)}
          size="15"
          color="#141414"
        />
      </div>
    );
  };

  // manipulated data with action
  // const sourceData = data.map((v) => {
  //   v.actions = <ButtonAction value={v} />;
  //   return v;
  // });

  return (
    <>
      <Popup
        open={openCancelReason}
        handleClose={() => {
          setCancelReason('')
          setOpenCancelReason(false)
        }}
        title={`Alasan dibatalkan`}
        width="550px"
        containerClass=""
        children={
          <>
            <div className="p-4 border rounded-2xl">
              {cancelReason}
            </div>
          </>
        }
        
      />
      {showDetail ? (
        <PaketTagihanDetail 
          id={billSelectedID}
          orderNumber={billSelectedNumber}
          setShowDetail={setShowDetail} />
      ) : (
        <MoslemeTable
          columns={columns}
          data={dataTable}
          withSelection={true}
          selectionType={selectionType}
          pagination={pagination}
          customPaginationClass="pr-4"
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
          loading={status === 'process'}
        />
      )}
    </>
  );
};

export default React.memo(PaketTagihan);
