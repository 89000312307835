import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const AppsStaffAPI = {
  getList: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-staff`, payload),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-staff`, payload),
  deleteData: (id) => axios.delete(`${BASE_URL_ENDPOINT}/admin/staff/${id}`),
};

export { AppsStaffAPI };
