import {
  ERROR_FETCHING_CALCULATOR,
  SET_ADD_CALCULATOR,
  SET_DELETE_CALCULATOR,
  START_FETCHING_CALCULATOR,
  SUCCESS_FETCHING_CALCULATOR,
} from "stores/actions/calculator/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  status: statuslist.idle,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_CALCULATOR:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_CALCULATOR:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_CALCULATOR:
      return {
        ...state,
        data: action.payload.data,
        status: statuslist.success,
      };
    case SET_ADD_CALCULATOR:
      state["data"].push(action.payload.data);

      return state;
    case SET_DELETE_CALCULATOR:
      state["data"] = state["data"].filter((list) => list.id !== action.id);
      return state;
    default:
      return state;
  }
}
