import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import uuid from "react-uuid";
import {
  editDataMaster,
  getDetailDataMaskapai,
  getDetailDataPembimbing,
} from "../../../../../stores/actions/datamaster";
import Badge from "components/badge";

export const columns = (
  handleOpen,
  setActionHandle,
  setIsModal,
  openModal,
  dispatch,
  formikValue,
  setCheckName,
  permissions
) => [
  {
    title: "Foto",
    dataIndex: "photo",
    render: (img) => (
      <img
        className="rounded-full w-10 h-10 object-cover"
        alt="foto pembimbing"
        src={img}
      />
    ),
  },
  {
    title: "Nama ",
    dataIndex: "name",
  },
  {
    title: "Tipe",
    dataIndex: "type",
    render: (type) => (
      <div className="text-sm">
        {type === "pembimbing" ? (
          <div>
            <Badge
              customClass="!bg-[#2eb24333] !text-[#333D29] capitalize"
              label={type}
            />
          </div>
        ) : (
          <div>
            <Badge
              customClass="!bg-[#f07ef733] !text-[#973AA8] capitalize"
              label={type}
            />
          </div>
        )}
      </div>
    ),
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    className: "whitespace-nowrap",
    render: (s, record) => (
      <div className="w-full relative">
        {s.length > 150 ? `${s.substr(0, 150)}...` : s}

        <div className="action">
          <div className="action-content">
            {permissions?.includes("delete_master-data") && (
              <div className="action-content-item">
                <Trash
                  onClick={() => {
                    handleOpen();
                    setActionHandle(record.id_mentor);
                  }}
                  size="16"
                  color="#141414"
                  className="cursor-pointer"
                />
              </div>
            )}
            {permissions?.includes("create_master-data") && (
              <div className="action-content-item">
                <DocumentCopy
                  onClick={() => {
                    formikValue("name", `${record.name} - CLONE`);
                    formikValue("id", record.id_mentor);
                    setCheckName(record.name);
                    setIsModal(true);
                  }}
                  size="16"
                  color="#141414"
                  className="cursor-pointer"
                />
              </div>
            )}
            {permissions?.includes("update_master-data") && (
              <div className="action-content-item">
                <Edit
                  className="cursor-pointer"
                  onClick={() => {
                    openModal(true);
                    dispatch(editDataMaster("edit pembimbing & guide"));
                    dispatch(getDetailDataPembimbing(record.id_mentor, uuid()));
                  }}
                  size="16"
                  color="#141414"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ),
  },
];
