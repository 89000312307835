import React from "react";
import PropType from "prop-types";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import ArticleCategoryAction from "stores/actions/apps/artikel/category";

import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import { handleErrorCustom, handleSuccess } from "stores/actions/errorGeneral";

const AppsAddOrdEditKategori = ({
  setOpenPopupAddKategori,
  setSuccessEditOrDeleteArtikel,
  edit,
}) => {
  const dispatch = useDispatch();

  const formikAddCategory = useFormik({
    initialValues: {
      name: `${edit ? edit.nama_kategori : ""}`,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama Kategori wajib diisi."),
    }),
    onSubmit: async (val, actions) => {
      if (edit) {
        const status = await dispatch(
          ArticleCategoryAction.postData({
            id: edit.id,
            nama_kategori: val.name,
            edit: true,
          })
        );
        if (status === 200) {
          dispatch(handleSuccess(null, { message: "Berhasil mengupdate kategori", code: 200 }))
          return setSuccessEditOrDeleteArtikel(true);
        } else {
          return dispatch(handleErrorCustom("Gagal mengupdate kategori"));
        }
      } else {
        const status = await dispatch(
          ArticleCategoryAction.postData({
            nama_kategori: val.name,
          })
        );
        if (status === 200) {
          dispatch(handleSuccess(null, { message: "Berhasil menambahkan kategori", code: 200 }))
          return setSuccessEditOrDeleteArtikel(true);
        } else {
          return dispatch(handleErrorCustom("Gagal menambahkan kategori"));
        }
      }
    },
  });

  return (
    <div className="bg-white border-[1px] border-[#E0E0E0] rounded-2xl">
      <div className="p-4">
        <Inputs
          id="name"
          name="name"
          label="Nama Kategori"
          placeholder="Masukkan Nama Kategori"
          value={formikAddCategory.values.name}
          error={formikAddCategory.errors.name}
          customClass={formikAddCategory.errors.name ? "input-error" : ""}
          onChange={formikAddCategory.handleChange}
        />
      </div>
      <div className="divider"></div>
      <div className="flex flex-row justify-end gap-2 p-4">
        <Buttons
          text="Kembali"
          customClass="btn-outline btn-medium !w-fit"
          onClick={() =>
            setOpenPopupAddKategori({
              show: false,
              edit: null,
            })
          }
        />
        <Buttons
          text={`${edit ? "Edit" : "Tambah"}`}
          loading={formikAddCategory.isSubmitting}
          customClass={`btn-primary btn-medium !w-fit ${
            formikAddCategory.isSubmitting && "loading"
          }`}
          onClick={formikAddCategory.handleSubmit}
        />
      </div>
    </div>
  );
};

AppsAddOrdEditKategori.propTypes = {
  setOpenPopupAddKategori: PropType.func,
  setSuccessEditOrDeleteArtikel: PropType.func,
};

AppsAddOrdEditKategori.defaultProps = {
  setOpenPopupAddKategori: () => {},
  setSuccessEditOrDeleteArtikel: () => {},
};

export default React.memo(AppsAddOrdEditKategori);
