const initialState = {
  isLoading: false,
  isLoadingHidePhoto: false,
  isLoadingSaveQuestion: false,
  isLoadingEvisa: false,
  dataAlbumJamaah: [],
  dataRatingQuestion: [],
  dataEvisaJamaah: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_LOADING_DATA_ALBUM_JAMAAH":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_HIDE_PHOTO":
      return {
        ...state,
        isLoadingHidePhoto: action.payload,
      };
    case "SET_LOADING_SAVE_QUESTION":
      return {
        ...state,
        isLoadingSaveQuestion: action.payload,
      };
    case "SET_LOADING_EVISA":
      return {
        ...state,
        isLoadingEvisa: action.payload,
      };
    case "SET_DATA_ALBUM_JAMAAH":
      return {
        ...state,
        dataAlbumJamaah: action.payload,
      };
    case "SET_DATA_RATING_QUESTION":
      return {
        ...state,
        dataRatingQuestion: action.payload,
      };
    case "SET_DATA_EVISA_JAMAAH":
      return {
        ...state,
        dataEvisaJamaah: action.payload,
      };
    default:
      return state;
  }
}
