import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AntdSelect from "components/form/antdSelect";
import InputsCurrency from "components/form/inputsCurrency";
import Buttons from "components/buttons";

// import { getWithdrawBankAccountService } from 'stores/actions/indonesianBanks'

import { withdrawFe, getWithdrawBankAccountService } from 'utils/api/fee'

import Inputs from "components/form/inputs";
import { useFormik } from "formik";

import * as Yup from 'yup'
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

const PopupWithdrawFee = ({
  onSuccess
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState([]);

  const [optionsCurrency, setOptionsCurrency] = useState([
    {
      value: "idr",
      label: "IDR",
    },
    {
      value: "dollar",
      label: "USD",
    },
  ]);

  const formik = useFormik({
    initialValues: {
      account_bank_id: null,
      nominal_withdraw: 0
    },
    validationSchema: Yup.object().shape({
      account_bank_id: Yup.string().required("Account Bank wajib diisi."),
      nominal_withdraw: Yup.number().required("Nominal wajib diisi."),
    })
  });

  const handleWithdrawSubmit = async () => {
    // console.log(formik.values);
    setIsLoading(true);

    const res = await withdrawFe(formik.values);
    if (res.status === 200) {
      console.log("hayoo");
      setIsLoading(false);
      onSuccess();
      dispatch(handleSuccess(res));
    } else {
      setIsLoading(false);
      dispatch(handleError(res?.data));
    }
  }

  const handleGetAccountBanks = async () => {
    const res = await getWithdrawBankAccountService();
    // console.log(res);
    if (res.status == 200) {
        let _tempAccountBank = []

        _tempAccountBank = res.data.data.map((account) => {
          return { value: account.id, label: `${account.account_bank_name} (${account.bank.name}) - ${account.account_bank_number}` };
        })

        // console.log(_tempAccountBank);

        setOptions(_tempAccountBank)
    }
  }

  useEffect(() => {
    // dispatch(handleGetIndonesianBanks())
    handleGetAccountBanks()
  }, [])

  return (
    <>
      <div className="bg-white soft-shadow rounded-2xl p-4">
        <AntdSelect
          label="Account Bank"
          placeholder="Pilih Bank"
          options={options}
          defaultValue={formik.values.account_bank_id}
          onChange={(e) => {
            formik.setFieldValue("account_bank_id", e?.value)
          }}
        />
        <div className="flex flex-row items-center gap-4 mt-4">
          <Inputs
            disabled={true}
            value={"IDR"}
            label="Mata Uang"
            />
          <InputsCurrency
            label="Nominal"
            placeholder="Masukkan nominal"
            containerClass="!w-[80%]"
            name="nominal_withdraw" 
            onChange={(e) => {
              formik.setFieldValue("nominal_withdraw", e)
            }}
          />
        </div>
        <Buttons 
          disabled={!(formik.isValid && formik.dirty)}
          text="Withdraw" 
          customClass="btn-primary btn-large mt-4" 
          onClick={handleWithdrawSubmit}
          loading={isLoading}
          />
      </div>
    </>
  );
};

export default React.memo(PopupWithdrawFee);
