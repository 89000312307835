import React, { useEffect, useState } from "react";
import Inputs from "../../../../../../components/form/inputs";
import ImageField from "../../../../../../components/image_field";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../../../../../components/buttons";
import { editDataMaskapai } from "../../../../../../stores/actions/datamaster";
import TextAreas from "components/form/textarea";
import { ReactComponent as Kategori } from "../../../../../../assets/icons/Kategory.svg";
import Radios from "../../../../../../components/form/radio";
import Rates from "components/form/rate";
import RichTextEditor from "components/richTextEditor";

function EditInfo({
  valuvalueKategorieName,
  valueBintang,
  valueDescription,
  descriptionOnChange,
  valueKategori,
  formikOnChange,
  formikValue,
  handleSave,
  valueName,
  loading,
}) {
  const [radio, setRadio] = useState([
    {
      value: "hotel",
      label: "Hotel",
    },
    {
      value: "tenda",
      label: "Tenda",
    },
  ]);

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={12}>
          <Inputs
            placeholder="Pilih hotel"
            label="Hotel"
            id="name"
            name="name"
            onChange={(e) => formikValue("name", e.target.value)}
            defaultValue={valueName}
            // defaultValue={valueName}
            value={valueName}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <ImageField
            name="logo"
            label="Logo Akomodasi"
            customContainer="!rounded-2xl"
            width="16rem"
            height="16rem"
            setFile={(file) => {
              formikValue("image_logo", file?.path);
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <ImageField
            label="Thumbnail Akomodasi"
            customContainer="!rounded-2xl"
            width="100%"
            height="16rem"
            setFile={(file) => {
              formikValue("image_thumbnail", file?.path);
            }}
            name="thumbnail"
          />
        </Grid>
      </Grid>

      <div className="mt-4 border  rounded-2xl px-4 border-gray-200 py-2 ">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6}>
            <div className="flex items-center">
              <Kategori className="w-7 h-7 mr-2" />
              <Radios
                options={radio}
                defaultValue={
                  radio
                    .filter((x) => x.value === valueKategori)
                    .map((x) => x.value)[0]
                }
                label="Kategori"
                onChange={(e) => {
                  formikValue("kategori", e.target.value);
                }}
              />
            </div>
          </Grid>

          <Grid item sm={12} md={6}>
            <div className="flex items-center h-full">
              <label className="mr-4">Bintang</label>
              <div className="border border-gray-200 pb-[4px] px-2 rounded-lg flex items-center">
                <Rates
                  setValue={(value) => {
                    formikValue("bintang", value);
                  }}
                  value={valueBintang}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="mt-4">
        <Grid item sm={12}>
          <RichTextEditor
            placeholder="Masukkan deskripsi akomodasi"
            data={{ value: valueDescription }}
            onChange={descriptionOnChange}
          />
        </Grid>
      </div>

      <div className="w-full flex justify-end pt-4 mt-4 items-center border-t border-gray-300">
        <div className="w-64">
          <Buttons
            onClick={handleSave}
            text="Simpan"
            customClass="btn-primary btn-large"
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}

export default EditInfo;
