import React, { useEffect, useState } from "react";

import Inputs from "components/form/inputs";
import AntdSelect from "components/form/antdSelect";
import Buttons from "components/buttons";

import { useDispatch, useSelector } from "react-redux";

import { handleGetIndonesianBanks } from 'stores/actions/indonesianBanks'
import { createAccountWithdrawService, updateAccountWithdrawService } from 'utils/api/fee'

import { useFormik } from "formik";

import * as Yup from 'yup'
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

const AddOrEditRekening = ({
  onSuccess,
  data
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [optionsBank, setOptionsBank] = useState([]);
  const { indonesianBanks } = useSelector(({ indonesianBanks }) => indonesianBanks)

  const getIndonesianBanks = () => {
    dispatch(handleGetIndonesianBanks())
  }

  const formik = useFormik({
    initialValues: {
      bank_id: data?.bank?.id,
      bank_account_name: data?.account_bank_name,
      bank_account_number: data?.account_bank_number,
    },
    validationSchema: Yup.object().shape({
      bank_id: Yup.number().required("Bank wajib diisi."),
      bank_account_name: Yup.string().required("Nama Akun wajib diisi."),
      bank_account_number: Yup.string().required("No Rekening wajib diisi."),
    }),
  });

  useEffect(() => {
    let _tempIndonesiaBanks = []

    _tempIndonesiaBanks = indonesianBanks.map((bank) => {
      return { value: bank.id, label: bank.name };
    })

    setOptionsBank(_tempIndonesiaBanks)
  }, [indonesianBanks])

  const handleAddRekeningSubmit = async () => {
    setIsLoading(true);
    const res = await createAccountWithdrawService(formik.values);
    if (res.status === 200) {
      setIsLoading(false);
      onSuccess();
      dispatch(handleSuccess(res));
    } else {
      setIsLoading(false);
      dispatch(handleError(res?.data));
    }
  };

  const handleUpdateRekeningSubmit = async () => {
    setIsLoading(true);
    const res = await updateAccountWithdrawService(data.id, formik.values);
    if (res.status === 200) {
      setIsLoading(false);
      onSuccess();
      dispatch(handleSuccess(res));
    } else {
      setIsLoading(false);
      dispatch(handleError(res?.data));
    }
  };

  useEffect(() => {
    getIndonesianBanks()
  }, [])
  

  return (
    <>
      <div className="bg-white border border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <Inputs 
            name="bank_account_name"
            label="Nama Akun" 
            placeholder="Masukkan Nama"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.bank_account_name}
            />
          <div className="flex flex-row items-center gap-4 mt-4">
            <AntdSelect
              label="Bank"
              containerClass="!w-[30%]"
              placeholder="Pilih Bank"
              options={optionsBank}
              defaultValue={formik.values.bank_id}
              onChange={(e) => {
                formik.setFieldValue("bank_id", e?.value)
              }}
            >
              {optionsBank.map((val, idx) => {
                return (
                  <div value={val.value} data={val} label={val.label} key={idx}>
                    {val.label}
                  </div>
                );
              })}
            </AntdSelect>
            <Inputs
              label="Nama Rekening"
              placeholder="Masukkan nomer rekening"
              containerClass="!w-[70%]"
              customClass="h-[54px]"
              name="bank_account_number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bank_account_number}
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end p-4">
          {!data && (
            <>
              <Buttons 
                loading={isLoading}
                text="Tambah" 
                customClass="btn-primary btn-large" 
                disabled={!(formik.isValid && formik.dirty)}
                onClick={handleAddRekeningSubmit}
                />
            </>
          )}
          {data && (
            <>
              <Buttons 
                loading={isLoading}
                text="Edit" 
                customClass="btn-primary btn-large" 
                disabled={!(formik.isValid && formik.dirty)}
                onClick={handleUpdateRekeningSubmit}
                />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(AddOrEditRekening);
