import axios from "axios";
import { BASE_URL_ENDPOINT } from "../../../../constant/constanta";

// get all kontak data
export const getKontakService = (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/pusat-bantuan/get-kontak`, payload);

// save kontak data
export const saveKontakService = (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/pusat-bantuan/save-kontak`, payload);

// delete kontak data
export const deleteKontakService = (payload) =>
    axios.delete(
        `${BASE_URL_ENDPOINT}/admin/pusat-bantuan/kontak/${payload.id}`
    );
