import React, { useState, useEffect } from "react";
import Popup from "../../../../components/popup";
import Card from "../../../../components/cards";
import { menuModal } from "../routes/menuModal";
import MaskapaiComponentModal from "./maskapai";
import BandaraComponentModal from "./bandara";
import TransportComponentModal from "./transport";
import AkomodasiComponentModal from "./akomodasi";
import MusimComponentModal from "./musim";
import RekeningComponentModal from "./rekening";
import AdditionalComponentModal from "./additional";
import StartingComponentModal from "./starting";
import PerlengkapanComponentModal from "./perlengkapan";
import PembimbingComponentModal from "./pembimbing";
import { useDispatch, useSelector } from "react-redux";
import {
  editDataMaster,
  setMasterId,
  keyDataMaster,
} from "../../../../stores/actions/datamaster";
import EditMaskapai from "./maskapai/edit";
import { resetDataUpload } from "stores/actions/uploadFile";
import { setValidation, set_after_validation } from "stores/actions/general";
import EditPerlengkapan from "./perlengkapan/edit";
import EditBandara from "./bandara/edit";
import EditTransport from "./transport/edit";
import EditAkomodasi from "./akomodasi/edit";

function ModalDatamaster({ open, setOpen, ...props }) {
  const { editData, keyData, masterId } = useSelector(
    (state) => state.datamaster
  );
  const [isActive, setIsActive] = useState(0);
  let [title, setTitle] = useState("maskapai");
  const [edit, setEdit] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setEdit(editData);
  });

  // add data
  const modalComponentRender = () => {
    switch (keyData) {
      case "maskapai":
        return <MaskapaiComponentModal setOpen={setOpen} />;

      case "bandara":
        return <BandaraComponentModal setOpen={setOpen} />;

      case "transportasi":
        return <TransportComponentModal setOpen={setOpen} />;
      case "akomodasi":
        return <AkomodasiComponentModal setOpen={setOpen} />;
      case "musim":
        return <MusimComponentModal setOpen={setOpen} />;
      case "rekening":
        return <RekeningComponentModal setOpen={setOpen} />;
      case "additional":
        return <AdditionalComponentModal setOpen={setOpen} />;
      case "starting":
        return <StartingComponentModal setOpen={setOpen} />;
      case "perlengkapan":
        return <PerlengkapanComponentModal setOpen={setOpen} />;
      case "pembimbing & guide":
        return <PembimbingComponentModal setOpen={setOpen} />;

      default:
        return null;
    }
  };

  // edit data
  const editComponentRender = () => {
    switch (keyData) {
      case "maskapai":
        return <EditMaskapai setOpen={setOpen} />;

      case "bandara":
        return <EditBandara setOpen={setOpen} />;

      case "transportasi":
        return <EditTransport setOpen={setOpen} />;

      case "akomodasi":
        return <EditAkomodasi setOpen={setOpen} />;

      case "perlengkapan":
        return <EditPerlengkapan setOpen={setOpen} />;

      case "pembimbing & guide":
        return <PembimbingComponentModal setOpen={setOpen} />;

      default:
        return null;
    }
  };

  const menuFilter =
    editData === "" ? menuModal : menuModal.filter((x) => x.key === keyData);

  return (
    <>
      <Popup
        width="80%"
        height="auto"
        open={open || masterId?.modalEdit}
        handleClose={() => {
          setOpen(false);
          setTitle("maskapai");
          setIsActive(0);
          dispatch(editDataMaster(""));
          dispatch(setMasterId({}));
          dispatch(resetDataUpload());
          dispatch(setValidation({}));
          dispatch(set_after_validation({}));
        }}
        title={
          masterId?.modalEdit !== true && editData === ""
            ? "Tambah data " + keyData
            : "Edit data " + keyData
        }
      >
        <div className="w-full flex py-[7px]">
          {keyData !== "starting" &&
          keyData !== "rekening" &&
          keyData !== "additional" ? (
            <div className="flex flex-col mr-4 max-w-[174px]">
              <Card containerClass={editData !== "" ? "hidden" : ""}>
                {menuFilter.map((data, i) => (
                  <ul key={i} className={`${i !== 9 ? "mb-5" : ""}`}>
                    <li
                      onClick={() => {
                        setIsActive(i);
                        setTitle(data.key);
                        masterId?.modalEdit !== true &&
                          dispatch(keyDataMaster(data?.key));
                      }}
                      className={`text-sm flex items-center cursor-pointer`}
                    >
                      {data?.key === keyData ? (
                        <data.icon_actived className="w-6 h-6 text-green-500 mr-2" />
                      ) : (
                        <data.icon className="w-6 h-6 text-green-500 mr-2" />
                      )}
                      <label
                        className={
                          data?.key === keyData
                            ? "text-red-600 text-sm font-semibold cursor-pointer"
                            : "text-gray-400 text-sm cursor-pointer"
                        }
                      >
                        {data.title}
                      </label>
                    </li>
                  </ul>
                ))}
              </Card>
            </div>
          ) : null}
          <div className={editData !== "" ? "w-full" : "w-[100%] mr-4"}>
            {edit === "" ? modalComponentRender() : editComponentRender()}
          </div>
        </div>
      </Popup>
    </>
  );
}

export default ModalDatamaster;
