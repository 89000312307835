import { useState } from "react";
import ImageField from "../../../../../components/image_field";
import Grid from "@mui/material/Grid";
import Radios from "../../../../../components/form/radio";
import { ReactComponent as Kategori } from "../../../../../assets/icons/Kategory.svg";
import Rates from "../../../../../components/form/rate";
import Inputs from "components/form/inputs";
import RichTextEditor from "components/richTextEditor";

function InfoAkomodasiComponent({
  formikOnChange,
  formikValue,
  bintang,
  valueDescription,
  descriptionOnChange,
  formik,
  errors,
  touched,
}) {
  const [radio, setRadio] = useState([
    {
      value: "hotel",
      label: "Hotel",
    },
    {
      value: "tenda",
      label: "Tenda",
    },
  ]);
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={12}>
          <Inputs
            placeholder="Masukan nama hotel"
            label="Nama Akomodasi"
            id="name"
            name="name"
            onChange={formikOnChange}
            value={formik.values.name}
            error={errors.name && touched.name ? errors.name : ""}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <ImageField
            label="Logo Akomodasi"
            name="Logo Akomodasi"
            customContainer="!rounded-2xl"
            width="16rem"
            height="16rem"
            setFile={(file) => {
              formikValue("image_logo", file?.path);
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <ImageField
            label="Thumbnail Akomodasi"
            name="Thumbnail Akomodasi"
            width="100%"
            height="16rem"
            customContainer="!rounded-2xl"
            setFile={(file) => {
              formikValue("image_thumbnail", file?.path);
            }}
          />
        </Grid>
      </Grid>
      <div className="mt-4 border  rounded-2xl px-4 border-gray-200 py-2 ">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6}>
            <div className="flex items-center">
              <Kategori className="w-7 h-7 mr-2" />
              <Radios
                options={radio}
                label="Kategori"
                onChange={(e) => {
                  formikValue("kategori", e.target.value);
                }}
              />
            </div>
          </Grid>

          <Grid item sm={12} md={6}>
            <div className="flex items-center h-full">
              <label className="mr-4">Bintang</label>
              <div className="border border-gray-200 pb-[4px] px-2 rounded-lg flex items-center">
                <Rates
                  setValue={(value) => {
                    formikValue("bintang", value);
                  }}
                  value={bintang}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="mt-4">
        <Grid item sm={12}>
          <RichTextEditor
            placeholder="Masukkan deskripsi akomodasi"
            data={{ value: valueDescription }}
            onChange={descriptionOnChange}
          />
        </Grid>
      </div>
    </>
  );
}

export default InfoAkomodasiComponent;
