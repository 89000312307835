import React, { useState } from "react";

import Tab from "../../../../../../../components/tab";

import IconJamaahGrey from "../../../../../../../assets/icons/icon-jamaah-grey.svg";
import IconJamaahActive from "../../../../../../../assets/icons/icon-jamaah-maroon.svg";
import IconLocationGrey from "../../../../../../../assets/icons/icon-location-grey.svg";
import IconLocationActive from "../../../../../../../assets/icons/icon-location-maroon.svg";
import Absensi from "./components/Absensi";
import Location from "./components/Location";

const Manasik = () => {
  const [activeTab, setActiveTab] = useState("1");

  return (
    <div className="bg-white border-[1px] border-[#4048521a]">
      <Tab
        defaultActiveKey="1"
        onChange={(e) => setActiveTab(e)}
        items={[
          {
            label: (
              <div className="flex flex-row items-center gap-2 px-4">
                {activeTab === "1" ? (
                  <img src={IconJamaahActive} alt="jamaah" />
                ) : (
                  <img src={IconJamaahGrey} alt="jamaah" />
                )}
                <div>Absensi</div>
              </div>
            ),
            key: "1",
            children: <Absensi />,
          },
          {
            label: (
              <div className="flex flex-row items-center gap-2 px-4">
                {activeTab === "2" ? (
                  <img src={IconLocationActive} alt="location" />
                ) : (
                  <img src={IconLocationGrey} alt="location" />
                )}

                <div>Lokasi Manasik</div>
              </div>
            ),
            key: "2",
            children: <Location />,
          },
        ]}
      />
    </div>
  );
};

export default React.memo(Manasik);
