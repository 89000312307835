export const SET_LOADING = "paket/detail/generalInfo/SET_LOADING";
export const SET_LOADING_DETAIL = "paket/detail/generalInfo/SET_LOADING_DETAIL";
export const CLEAR_ERROR = "paket/detail/generalInfo/CLEAR_ERROR";
export const SET_ERROR = "paket/detail/generalInfo/SET_ERROR";
export const SET_UPDATE_TGL = "paket/detail/generalInfo/SET_UPDATE_TGL";
export const SET_COU_DATA = "paket/detail/generalInfo/SET_COU_DATA";
export const SET_LIST_BUS_BY_ID = "paket/detail/generalInfo/SET_LIST_BUS_BY_ID";
export const START_FETCHING_PAKET_DETAIL_GENERAL_INFO =
  "paket/detail/generalInfo/START_FETCHING_PAKET_DETAIL_GENERAL_INFO";
export const ERROR_FETCHING_PAKET_DETAIL_GENERAL_INFO =
  "paket/detail/generalInfo/ERROR_FETCHING_PAKET_DETAIL_GENERAL_INFO";
export const SUCCESS_FETCHING_PAKET_DETAIL_GENERAL_INFO =
  "paket/detail/generalInfo/SUCCESS_FETCHING_PAKET_DETAIL_GENERAL_INFO";

export const SET_DATA_PEMBIMBING =
  "paket/detail/generalInfo/SET_DATA_PEMBIMBING ";
export const SET_CHANGE_STATUS_LOADING = 
  "paket/detail/generalInfo/SET_CHANGE_STATUS_LOADING"