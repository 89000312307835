import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Buttons from "../../../components/buttons";
import Cards from "../../../components/cards";
import Inputs from "../../../components/form/inputs";

import IconLeftArrow from "../../../assets/icons/Left-Arrow.svg";

import FlagIndonesia from "assets/icons/flag-indonesia.svg";
import IconSearch from "assets/icons/Search.svg";
import Selects from "components/form/selects";
import InputPhone from "components/form/inputPhone";

const CardForgotPassword = ({
  values,
  touched,
  errors,
  handleBlur,
  setForgotPage,
  setFieldValue,
  setOtpPage,
  isSubmitting,
  forgotPasswordByEmail,
  dataForgotPassword,
  validOTP,
  submitChangePassword,
  setPhoneRegion,
  setSelectPhoneCode,
  phoneRegion,
  options,
  isPhoneRender,
}) => {
  useEffect(() => {
    if (dataForgotPassword && !validOTP) {
      setForgotPage(false);
      setOtpPage(true);
    }
  }, [dataForgotPassword]);

  const [isShowConfirm, setIshowConfirm] = useState(false);

  return (
    <Cards containerClass="w-full">
      <div className="flex flex-row items-center">
        <img
          className="cursor-pointer"
          src={IconLeftArrow}
          onClick={() => setForgotPage(false)}
          alt="arrow-left"
        />
        <h3 className="card-login-title ml-1">Lupa Kata Sandi</h3>
      </div>
      {validOTP ? (
        <>
          {isShowConfirm ? (
            <Inputs
              id="confirmNewPassword"
              name="confirmNewPassword"
              type="password"
              label="Konfirmasi Kata Sandi Baru"
              value={values.confirmNewPassword}
              onChange={(e) => {
                setFieldValue("confirmNewPassword", e.target.value);
              }}
              onBlur={handleBlur}
              error={
                touched.confirmNewPassword && errors.confirmNewPassword
                  ? errors.confirmNewPassword
                  : ""
              }
              placeholder="Konfirmasi Sandi baru"
              containerClass="mt-6"
              customClass={
                errors.confirmNewPassword && touched.confirmNewPassword
                  ? "input-error"
                  : ""
              }
            />
          ) : (
            <Inputs
              id="newPassword"
              name="newPassword"
              type="password"
              label="Kata Sandi Baru"
              value={values.newPassword}
              onChange={(e) => {
                setFieldValue("newPassword", e.target.value);
              }}
              onBlur={handleBlur}
              error={
                touched.newPassword && errors.newPassword
                  ? errors.newPassword
                  : ""
              }
              placeholder="Sandi baru"
              containerClass="mt-6"
              customClass={
                errors.newPassword && touched.newPassword ? "input-error" : ""
              }
            />
          )}

          {isShowConfirm ? (
            <Buttons
              text="Reset Password"
              containerClass="mt-4"
              disabled={
                isSubmitting ||
                values.newPassword === "" ||
                errors.newPassword ||
                values.confirmNewPassword === "" ||
                errors.confirmNewPassword
              }
              loading={isSubmitting}
              customClass={`btn-primary btn-large ${
                isSubmitting && "loading"
              } ${
                (values.newPassword === "" ||
                  errors.newPassword ||
                  values.confirmNewPassword === "" ||
                  errors.confirmNewPassword) &&
                "disabled"
              }`}
              onClick={() => {
                if (
                  values.newPassword &&
                  values.confirmNewPassword &&
                  !errors.newPassword &&
                  !errors.confirmNewPassword
                ) {
                  submitChangePassword();
                }
              }}
            />
          ) : (
            <Buttons
              text="Selanjutnya"
              containerClass="mt-4"
              disabled={
                isSubmitting || values.newPassword === "" || errors.newPassword
              }
              loading={isSubmitting}
              customClass={`btn-primary btn-large `}
              onClick={() => {
                setIshowConfirm(true);
              }}
            />
          )}
        </>
      ) : (
        <>
          <h5 className="card-login-desc">
            Jangan khawatir, nomor telepon anda untuk mencari akun anda dan
            membuat kata sandi baru
          </h5>

          {isPhoneRender ? (
            <InputPhone
              id="test"
              name="telephone"
              customClass={
                errors.forgotEmail && touched.forgotEmail ? "input-error" : ""
              }
              value={values.forgotEmail}
              phoneRegion={phoneRegion}
              placeholder="Inputkan no telephone"
              containerClass="mt-6"
              onChange={(e) => {
                setFieldValue("forgotEmail", e);
              }}
              error={
                touched.forgotEmail && errors.forgotEmail
                  ? errors.forgotEmail
                  : ""
              }
              onBlur={handleBlur}
              icon={FlagIndonesia}
              enableSearch
              onClickIcon={() => {
                setSelectPhoneCode(true);
              }}
            />
          ) : (
            <Inputs
              id="forgotEmail"
              name="forgotEmail"
              type="text"
              label="Email atau Nomor Telepon"
              value={values.forgotEmail}
              onChange={(e) => {
                setFieldValue("forgotEmail", e.target.value);
              }}
              onBlur={handleBlur}
              error={
                touched.forgotEmail && errors.forgotEmail
                  ? errors.forgotEmail
                  : ""
              }
              placeholder="Inputkan email atau nomor telepon"
              containerClass="mt-6"
              customClass={
                errors.forgotEmail && touched.forgotEmail ? "input-error" : ""
              }
            />
          )}

          <Buttons
            text="Cari Akun"
            containerClass="mt-6"
            disabled={
              isSubmitting || values.forgotEmail === "" || errors.forgotEmail
            }
            loading={isSubmitting}
            customClass={`btn-primary btn-large ${
              (values.forgotEmail === "" || errors.forgotEmail) && "disabled"
            } ${isSubmitting && "loading"}`}
            onClick={() => {
              if (values.forgotEmail && !errors.forgotEmail) {
                forgotPasswordByEmail({
                  account: values.forgotEmail,
                });
              }
            }}
          />
        </>
      )}
    </Cards>
  );
};

CardForgotPassword.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  setForgotPage: PropTypes.func,
  setFieldValue: PropTypes.func,
  setOtpPage: PropTypes.func,
  isSubmitting: PropTypes.bool,
  forgotPasswordByEmail: PropTypes.func,
  validOTP: PropTypes.bool,
  submitChangePassword: PropTypes.func,
};

export default React.memo(CardForgotPassword);
