import React, { useState } from "react";
import AllAddminIC from "../icons/filter/all_adm.svg";
import AllAddminActiveIC from "../icons/filter/all_adm_active.svg";
import Inputs from "../../../components/form/inputs";
import SearchIC from "../icons/filter/search.svg";
import Buttons from "../../../components/buttons";
import CreateAdmin from "./CreateAdmin";
import CreateAgen from "./CreateAgen";
import CreateJamaah from "./CreateJamaah";
import { Activity } from "iconsax-react";
import MemberAction from "../../../stores/actions/member/admin";
import AgenAction from "../../../stores/actions/member/agen";
import JamaahAction from "../../../stores/actions/member/jamaah";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../../../components/slider";
import { clearData } from "stores/actions/uploadFile";

function FilterAdmin({ setIdAdmin }) {
  const dispatch = useDispatch();
  const keyword = useSelector(({ admin }) => admin.keyword);
  const role = useSelector(({ role }) => role.dataRole.data);
  const auth = useSelector(({ auth }) => auth)

  const [listFilter, setListFilter] = React.useState([]);
  const [isAllAdmin, setIsAllAdmin] = React.useState(true);

  const permissions = auth?.user?.permissions;

  React.useEffect(() => {
    if (role) {
      setListFilter(
        role.map((v, i) => {
          return {
            ...v,
            active: false,
            icActive: AllAddminActiveIC,
            icInactive: AllAddminIC,
          };
        })
      );
    }
  }, [role]);

  const handleFilter = (id) => {
    let temp = [...listFilter];
    temp.forEach((v) => {
      if (v.key === id) {
        v.active = true;
      } else {
        v.active = false;
      }
    });
    dispatch(MemberAction.setRoleId(`${id}`));
    setListFilter(temp);
    setIsAllAdmin(false);
  };

  const handleAllFilter = () => {
    let temp = [...listFilter];
    temp.forEach((v) => {
      v.active = false;
    });
    dispatch(MemberAction.setRoleId(`0}`));
    setIsAllAdmin(true);
    setListFilter(temp);
  };
  const [isCreateModal, setIsCreateModal] = useState(false);
  return (
    <div className="flex justify-between items-center p-3">
      <CreateAdmin
        show={isCreateModal}
        close={() => {
          setIsCreateModal(false);
          setIdAdmin("0");
        }}
      />
      <div className="w-[160px] mr-2">
        <Inputs
          onChange={(e) => dispatch(MemberAction.setKeyword(e.target.value))}
          value={keyword}
          customClass="h-[44px]"
          placeholder="Search"
          iconPosition="left"
          icon={SearchIC}
        />
      </div>
      <div className="flex-grow">
        {/* <Slider customClass="member-filter">
          <div
            onClick={handleAllFilter}
            className={`!w-auto rounded-lg items-center cursor-pointer px-3 py-2.5 border mr-3 ${
              isAllAdmin
                ? 'bg-[#FAAB36] bg-opacity-30 border-[#FAAB36]'
                : 'bg-white border-[#e6e6e6]'
            }`}
          >
            <div className='flex items-center'>
              <img src={isAllAdmin ? AllAddminActiveIC : AllAddminIC} alt="filter" />
              <p className="ml-2">All admin</p>
            </div>
          </div>
          {listFilter.length > 0 &&
            listFilter.map((list) => {
              return (
                <div
                  onClick={() => handleFilter(list.key)}
                  className={`!w-auto rounded-lg items-center cursor-pointer px-3 py-2.5 border mr-3 ${
                    list.active
                      ? 'bg-[#FAAB36] bg-opacity-30 border-[#FAAB36]'
                      : 'bg-white border-[#e6e6e6]'
                  }`}
                  key={list.id}
                >
                  <div className='flex items-center'>
                    <img src={list.active ? list.icActive : list.icInactive} alt="filter" />
                    <p className="ml-2">{list.role.tags}</p>
                  </div>
                </div>
              );
            })}
        </Slider> */}
      </div>

      <div className="flex items-center">
        {permissions?.includes("create_member") && (
          <Buttons
            onClick={() => {
              setIsCreateModal(true);
              setIdAdmin("0");
              dispatch(clearData());
            }}
            text="Tambah Member"
            customClass="btn-primary btn-small"
          />
        )}
        <Activity className="ml-5" size="20" color="#595959" />
      </div>
    </div>
  );
}
function FilterAgen() {
  const dispatch = useDispatch();
  const keyword = useSelector(({ agen }) => agen.keyword);
  const role = useSelector(({ role }) => role.dataRole.data);
  const auth = useSelector(({ auth }) => auth)

  const [listFilter, setListFilter] = React.useState([]);
  const [isAllAdmin, setIsAllAdmin] = React.useState(true);

  const [isCreateModal, setIsCreateModal] = useState(false);

  const permissions = auth?.user?.permissions;

  const handleFilter = (id) => {
    let temp = [...listFilter];
    temp.forEach((v) => {
      if (v.key === id) {
        v.active = true;
      } else {
        v.active = false;
      }
    });
    dispatch(AgenAction.setRoleId(`${id}`));
    setListFilter(temp);
    setIsAllAdmin(false);
  };

  const handleAllFilter = () => {
    let temp = [...listFilter];
    temp.forEach((v) => {
      v.active = false;
    });
    dispatch(MemberAction.setRoleId(`0}`));
    setIsAllAdmin(true);
    setListFilter(temp);
  };

  React.useEffect(() => {
    if (role) {
      setListFilter(
        role.map((v, i) => {
          return {
            ...v,
            active: false,
            icActive: AllAddminActiveIC,
            icInactive: AllAddminIC,
          };
        })
      );
    }
  }, [role]);
  return (
    <div className="flex justify-between items-center p-3">
      <CreateAgen show={isCreateModal} close={() => setIsCreateModal(false)} />

      <div className="w-[160px] mr-3">
        <Inputs
          onChange={(e) => dispatch(AgenAction.setKeyword(e.target.value))}
          value={keyword}
          customClass="h-[44px]"
          placeholder="Search"
          iconPosition="left"
          icon={SearchIC}
        />
      </div>
      <div className="flex-grow">
        {/* <Slider>
          <div
            onClick={handleAllFilter}
            className={`!flex h-12 rounded-lg items-center cursor-pointer px-3 py-1 border mr-3 ${
              isAllAdmin
                ? 'bg-[#FAAB36] bg-opacity-30 border-[#FAAB36]'
                : 'bg-white border-[#e6e6e6]'
            }`}
          >
            <img src={isAllAdmin ? AllAddminActiveIC : AllAddminIC} alt="filter" />
            <p className="ml-2">All agen</p>
          </div>
          {listFilter.length > 0 &&
            listFilter.map((list) => {
              return (
                <div
                  onClick={() => handleFilter(list.key)}
                  className={`!flex h-12 rounded-lg items-center cursor-pointer px-3 py-1 border mr-3 ${
                    list.active
                      ? 'bg-[#FAAB36] bg-opacity-30 border-[#FAAB36]'
                      : 'bg-white border-[#e6e6e6]'
                  }`}
                  key={list.id}
                >
                  <img src={list.active ? list.icActive : list.icInactive} alt="filter" />
                  <p className="ml-2">{list.role.tags}</p>
                </div>
              );
            })}
        </Slider> */}
      </div>
      <div className="flex items-center">
        {permissions?.includes("create_member") && (
          <Buttons
            onClick={() => setIsCreateModal(true)}
            text="Tambah Member"
            customClass="btn-primary btn-small"
          />
        )}
        <Activity className="ml-5" size="20" color="#595959" />
      </div>
    </div>
  );
}
function FilterJamaah() {
  const dispatch = useDispatch();
  const keyword = useSelector(({ jamaah }) => jamaah.keyword);
  const auth = useSelector(({ auth }) => auth)
  const [isCreateModal, setIsCreateModal] = useState(false);

  const permissions = auth?.user?.permissions;

  return (
    <div className="flex justify-between items-center p-3">
      <CreateJamaah
        show={isCreateModal}
        close={() => setIsCreateModal(false)}
      />

      <div className="w-[160px] mr-3">
        <Inputs
          onChange={(e) => dispatch(JamaahAction.setKeyword(e.target.value))}
          value={keyword}
          customClass="h-[44px]"
          placeholder="Search"
          iconPosition="left"
          icon={SearchIC}
        />
      </div>
      <div className="flex"></div>

      <div className="flex items-center">
        {permissions?.includes("create_member") && (
          <Buttons
            onClick={() => setIsCreateModal(true)}
            text="Tambah Member"
            customClass="btn-primary btn-small"
          />
        )}
        <Activity className="ml-5" size="20" color="#595959" />
      </div>
    </div>
  );
}

export { FilterAdmin, FilterAgen, FilterJamaah };
