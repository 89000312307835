import { Select } from "antd";
import styled from "styled-components";

const SelectWrapper = styled.div`
  position: relative;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && .ant-select .ant-select-selector {
    padding-left: calc(3rem - 8px);
  }
`;

const CustomSelect = ({ prefixIconLeft, children, ...rest }) => {
  return (
    <SelectWrapper>
      {prefixIconLeft && (
        <div className="prefix-icon-wrapper">{prefixIconLeft}</div>
      )}
      <Select {...rest}>{children}</Select>
    </SelectWrapper>
  );
};

export default CustomSelect;
