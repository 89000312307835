
import MoslemeTable from 'components/Table';
import Avatar from 'components/avatar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams }   from 'react-router-dom';

import { handleGetPembimbingDocument }  from 'stores/actions/paket/dokumen';

import CheckIcon from "assets/icons/Checklist-gray.svg";
import CheckIconChecked from "assets/icons/Checklist-Green.svg";

// import DetailDocument from './DetailDocument';
// import Accordions from 'components/accordions';

import { Grid } from "@material-ui/core"
import { Edit, Eye } from 'iconsax-react';
import DocumentForm from './DocumentForm';
import Popup from 'components/popup';

const Pembimbing = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  const [updatingDocument, setUpdatingDocument] = useState(false)
  const [updateDocumentKey, setUpdateDocumentKey] = useState('')
  const [updateDocumentValue, setUpdateDocumentValue] = useState('')
  const [pembimbingPaketID, setPembimbingPaketID] = useState('')

  const { pembimbingDocument, isLoading } = useSelector(state => state.dokumen)

  const renderIconCheck = (document) => {
    if (document.key === "passport") {
      if (document.data[0].value === null || document.data[1].value === null) {
        return <img src={CheckIcon} className="w-[20px] h-[20px]" />;
      } else {
        return <img src={CheckIconChecked} className="w-[20px] h-[20px]" />;
      }
    } else {
      if (document.value === null) {
        return <img src={CheckIcon} className="w-[20px] h-[20px]" />;
      } else {
        return <img src={CheckIconChecked} className="w-[20px] h-[20px]" />;
      }
    }
  };

  const covertNameDocument = (document_key) => {
    switch (document_key) {
      case "passport":
        return "Pasport";
      case "pas_photo_4x6":
        return "Pas Foto 4x6";
      case "ktp":
        return "KTP";
      case "kk":
        return "KK";
      case "akta_kelahiran":
        return "Akta Kelahiran";
      case "surat_nikah":
        return "Surat Nikah";
      case "meningitis_vaccine":
        return "Vaksin Meningitis";
      case "vaccine_covid_1":
        return "Vaksin Covid 1";
      case "vaccine_covid_2":
        return "Vaksin Covid 2";
      case "vaccine_covid_3":
        return "Vaksin Covid 3";
      case "bpjs_kesehatan":
        return "BPJS Kesehatan";
    }
  }

  const handleUpdate = (document, pembimbingPaketID) => {
    setUpdatingDocument(true)
    setUpdateDocumentKey(document.key)
    setPembimbingPaketID(pembimbingPaketID)

    if(document.key === "passport") {
      setUpdateDocumentValue(document.data)
    } else {
      setUpdateDocumentValue(document.value)
    }

  }

  const onUpdate = () => {
    dispatch(handleGetPembimbingDocument(params.id))
  }

  useEffect(() => {
      dispatch(handleGetPembimbingDocument(params.id))
  }, [])

  return (
      <>
        <Popup
          open={updatingDocument}
          handleClose={() => setUpdatingDocument(false)}
          title={`Update ${covertNameDocument(updateDocumentKey)}`}
          width="900px" 
          children={
            <DocumentForm 
              documentKey={updateDocumentKey}
              documentValue={updateDocumentValue}
              pembimbingPaketID={pembimbingPaketID}
              // jamaahData={jamaahData}
              onUpdate={() => {
                onUpdate()
              }}
              />
          }
        />
        <div className="pt-4 mb-4 border-t px-4">
            <Grid container spacing={2}>
            {!!pembimbingDocument.length && pembimbingDocument.map(pembimbing => (
              <>
                <Grid item md={6}>
                  <div className='shadow-soft rounded-2xl'>
                    <div className='p-4 flex justify-between gap-4 items-center border-b font-semibold'>
                      <div className='flex gap-4 items-center'>
                        <Avatar imageTemp={pembimbing.photo} />
                        <span>
                          <div>{pembimbing.name}</div>
                          <span className='font-normal text-xs text-slate-400 mt-1'>
                            Pembimbing
                          </span>
                        </span>
                      </div>
                      <div>
                        {pembimbing.documents.total_document_filled}/{pembimbing.documents.total_document}
                      </div>
                    </div>
                    <div className='p-4 w-full'>
                      <Grid container spacing={2}>
                        {pembimbing.documents.data.map(document => (
                          <Grid item md={6}>
                            <div className='shadow-soft p-4 rounded-2xl border w-full flex justify-between items-center'>
                              <div className='flex gap-4 items-center'>
                                <div>
                                  {renderIconCheck(document)}
                                </div>
                                <div className='font-semibold'>
                                  {covertNameDocument(document.key)}
                                </div>
                              </div>
                              <div>
                              {permissions?.includes('update_manifest') ? (
                                <Edit
                                  size="20"
                                  color="#141414"
                                  className="cursor-pointer "
                                  onClick={() => {
                                    handleUpdate(document, pembimbing.id_pembimbing_paket);
                                  }}
                                />
                              ) : (
                                <Eye
                                  size="20"
                                  color="#141414"
                                  className="cursor-pointer "
                                  onClick={() => {
                                    handleUpdate(document, pembimbing.id_pembimbing_paket);
                                  }}
                                />
                              )}
                            </div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </>
            ))}
            </Grid>
        </div>
      </>
  )
}

export default Pembimbing