import styled from "styled-components";

const ChartStyle = styled.div`
    .custom-chart {
        .apexcharts-tooltip.apexcharts-theme-light {
            border:none!important;

        }
    }
`;

export default ChartStyle;
