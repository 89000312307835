import Buttons from "components/buttons";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import Inputs from "components/form/inputs";
import Textareas from "components/form/textarea";
import React, { useEffect, useState } from "react";

import * as Yup from "yup";

import { storeRatingQuestionsService } from "utils/api/paket/ibadahku";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

const PopupAddQuestion = ({ dataEdit, onSuccess }) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      id_paket: params.id,
      question: ""
    },
    validationSchema: Yup.object({
      question: Yup.string().required("Pertanyaan wajib diisi"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      let payload = {
        question: values.question
      }

      if (dataEdit) {
        payload = {
          ...payload,
          id: dataEdit.id
        }
      } else {
        payload = {
          ...payload,
          id_paket: params.id
        }
      }

      const res = await storeRatingQuestionsService(payload);
      setIsLoading(false);

      if (res.status === 200) {
        // onClick();
        
        if(!dataEdit) {
          dispatch(handleSuccess(null, { message: "Pertanyaan berhasil ditambahkan", code: 200}));
        } else {
          dispatch(handleSuccess(null, { message: "Pertanyaan berhasil diubah", code: 200}));
        };
        onSuccess();
      } else {
        dispatch(handleError(res.data));
      }
      
      formik.resetForm();
      // await storeRatingQuestionsService(values);
      // onClick();
    },
  })

  useEffect(() => {
    if (dataEdit) {
      formik.setFieldValue('question', dataEdit?.question);
    } else {
      formik.resetForm();
    }
  }, [dataEdit]);

  return (
    <div className="flex flex-col gap-[16px] w-full h-fit border rounded-lg">
      <div className="p-4 pb-0">
        <Inputs 
          label="Pertanyaan"
          name="question"
          type="text"
          placeholder="Masukkan pertanyaan"
          // defaultValue={formik.values.question}
          value={formik.values.question}
          onChange={formik.handleChange}
          error={formik.touched.question && formik.errors.question}
          onBlur={formik.handleBlur}
          // onChange={(e) => setValue(e)}
          />
      </div>
      <div className="flex flex-row justify-end items-center p-[16px] gap-[8px] border-t">
        <Buttons 
          loading={isLoading}
          disabled={!(formik.isValid && formik.dirty)}
          text={`${dataEdit ? "Edit" : "Tambah"}`}
          customClass="btn-primary btn-medium"
          onClick={formik.handleSubmit}
          />
      </div>
    </div>
  );
};

export default PopupAddQuestion;
