import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { Table } from 'antd'
import { useDispatch, useSelector } from "react-redux";

import MoslemeTable from "../../../../../../../../components/Table";
import Badge from "../../../../../../../../components/badge";
import Segment from "../../../../../../../../components/segment";

import { getListPayments } from "utils/api/paket/bills";
import { setStatusPayment, generateEInvoice } from "utils/api/payments";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { IDRFormater } from "utils/helpers";
import moment from "moment";
import { DocumentDownload } from "iconsax-react";
import Avatar from "components/avatar";


import IconSistemMaroon from 'assets/icons/icon-sistem-maroon.svg'
import IconAdminMaroon from 'assets/icons/icon-admin-maroon.svg'
import Spinner from "components/spinner";

import AlertPopup from 'components/popup/alert'

const TabPembayaran = ({ orderID, orderNumber, activeTab, handleGetBill }) => {
  const params = useParams()
  const router = useHistory();
  const dispatch = useDispatch()
  const [downloading, setDownloading] = useState(false)
  const [downloadingID, setDownloadingID] = useState(null)


  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  
  
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [isExpand, setIsExpand] = useState(true);
  const [payments, setPayments] = useState([])
  const [isVerif, setIsVerif] = useState(false)
  const [verificationData, setVerificationData] = useState({
    status: 1,
    paymentID: null
  })

  const [isLoadingVerification, setIsLoadingVerification] = useState(false)
  const [isLoadingGet, setIsLoadingGet] = useState(false)

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  const handleIsVerification = (val, paymentID) => {
    setIsVerif(true)
    setVerificationData({
      status: val,
      paymentID
    })
  }

  const setVerificationStatus = (statusPayment) => {
    const status = {
      '1': 0,
      '2': 0,
      '3': 1,
      '4': 0
    }

    return status[statusPayment]
  }

  const handleGetPayments = async () => {
    const paramaters =  {
      package_id: params.id,
      order_id: orderID
    }
    const payload = {
      per_page: size,
      page: currentpage
    }
    setIsLoadingGet(true)
    const response = await getListPayments(paramaters, payload)
    const {status, data} = response
    setIsLoadingGet(false)
    if(status === 200) {
      let newPayment = data.data 
      newPayment = newPayment.map((item, index) => {
        return {
          ...item,
          key: `${index + 1}`
        }
      })

      setPayments(newPayment)
      setTotal(data.total)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleGetPayments()
    }, 600000); // 600000 milidetik = 10 menit

    return () => clearInterval(interval); // Membersihkan interval saat komponen di-unmount
  }, []);

  const onHandleVerification = async () => {
    const newPayments = [...payments]
    const paymentIDSelection = newPayments.findIndex(item => item.id === verificationData?.paymentID);
    setIsLoadingVerification(true)
    const response = await setStatusPayment(verificationData?.paymentID, { status: verificationData?.status })
    const { status, data } = response
    setIsLoadingVerification(false)

    if(status === 200) {
      newPayments[paymentIDSelection]['status_payment'] = verificationData?.status ? 3 : 2
      newPayments[paymentIDSelection]['payment_method'] = 1
      setIsVerif(false)
      dispatch(handleSuccess(response))
      handleGetBill()
    } else {
      dispatch(handleError(data))
    }

    setIsVerif(false)

  }

  const handleDownloadEInvoice = async (paymentID) => {
    setDownloading(true)
    setDownloadingID(paymentID)
    const response = await generateEInvoice(orderNumber, paymentID)
    
    if(response.status === 200) {
      var a = document.createElement("a")
      a.href = `${response.data.data.url}`; //Image Base64 Goes here
      a.download = `${response.data.data.url}`; //File name Here
      a.target = "_blank"
      a.click();
    } else {
      dispatch(handleError(response.data))
    }

    setDownloading(false)

  };

  const columns = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: "ID Transaksi",
      dataIndex: "payment_number",
      render: (record) => (
        <>
          #{record}
        </>
      )
    },
    {
      title: "Tanggal & Waktu",
      dataIndex: "created_at",
      render: (record) => (
        <>
          {moment(record).format("dddd, DD MMMM YYYY H:mm")} WIB
        </>
      )
    },
    {
      title: "Nominal Transaksi",
      dataIndex: "amount",
      render: (record) => (
        <div className="font-semibold">
          { IDRFormater(record) }
        </div>
      ),
    },
    {
      title: "Nominal Bayar",
      dataIndex: "amount_request",
      render: (record, payments) => (
        <div className="font-semibold">
          {payments?.verified_with_unique_code ? IDRFormater(record) : IDRFormater(payments.amount)}
        </div>
      ),
    },
    {
      title: "Status Verifikasi",
      dataIndex: "status_payment",
      render: (record, payment) => (
        <Segment
          value={setVerificationStatus(record)}
          disabled={!permissions?.includes('update_bill')}
          onChange={(e) => {
            // onHandleVerification(e, payment.id)
            handleIsVerification(e, payment.id)
          }}
          options={[
            {
              label: <div className="font-semibold flex gap-2">
                Terverifikasi {!!setVerificationStatus(record) && (
                  <>
                    {payment?.payment_method == 2 ? (
                        <img src={IconSistemMaroon} />
                      ) : (
                        <img src={IconAdminMaroon} />
                    )}
                  </>
                )}
              </div>,
              value: 1,
            },
            {
              label: (
                <div className="segment-label-error font-semibold">Belum Terverifikasi</div>
              ),
              value: 0,
            },
          ]}
        />
      ),
    },
    {
      title: "Status Transaksi",
      dataIndex: "status_payment",
      render: (record) => (
        <>
          {record == '1' && (
            <Badge 
              label="TERTUNDA"
              backgroundColor={'#F7A399'}
              color={'#FE0000'}
              />
          )}
          {record == '2' && (
              <Badge 
                label="TERTUNDA"
                backgroundColor={'#F7A399'}
                color={'#FE0000'}
              />
          )}
          {record == '3' && (
              <Badge 
                label="BERHASIL"
                backgroundColor={'#FCDE9C'}
                color={'#FE9A00'}
              />
          )}
          {record == '4' && (
              <Badge 
                label="BATAL"
                backgroundColor={'#D6D1CD'}
                color={'#C60F7B'}
              />
          )}
        </>
      ),
    },
    {
      title: "Tipe Transaksi",
      dataIndex: "type",
      render: (record, payment) => (
        <>
          {record === 'PAYMENT' && (
            <Badge 
              backgroundColor={'#92E6A7'}
              color={'#1B662E'}
              label="PAYMENT" />
          )}
          {record === 'REFUND' && (
            <Badge 
              backgroundColor={'#D6D1CD'}
              color={'#C60F7B'}
              label="REFUND" />
          )}

          <div className="action !mr-4">
            <div className="action-content">
              {payment.type === 'PAYMENT' && (
                <div className="action-content-item">
                  {downloading ? (
                    <Spinner/>
                  ) : (
                    <DocumentDownload
                      className="cursor-pointer"
                      onClick={() => handleDownloadEInvoice(payment.id)}
                      size="16"
                      color="#141414"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ),
    },
  ];

  const expandable = {
    expandedRowRender: (record) => {
      return (
        <div className="ml-2">
          <ul className="w-full flex py-3 border-l">
            <li className="w-full">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">Akun Penerima</li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">Logo Bank</li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">Rekening Penerima</li>
              </ul>
            </li>
          </ul>
          <ul className="w-full flex pb-3 border-l">
            <li className="w-full">
              <ul>
                <li className="pl-5 pt-2">
                  {record._receipent_account_detail.account_owner}
                </li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="pl-5 pt-2 flex">
                  <Avatar 
                    imageTemp={record._receipent_account_detail.bank_logo}
                    />
                </li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="pl-5 pt-2 flex">
                  {record._receipent_account_detail.account_number}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      );
    },
    rowExpandable: (record) => record.name !== "Not Expandable",
  };

  useEffect(() => {
    handleGetPayments()
  }, [])

  useEffect(() => {
    handleGetPayments()
  }, [currentpage, size, activeTab])
  

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // const expandable = {
  //   expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
  //   rowExpandable: (record) => record.name !== "Not Expandable",
  // };

  // manipulated data with action
  // const sourceData = data.map((v) => {
  //   // v.actions = <ButtonAction value={v} />;
  //   return v;
  // });

  return (
    <>
      <AlertPopup
        open={isVerif}
        handleClose={() => {
          setIsVerif(false);
        }}
        handleContinue={onHandleVerification}
        loading={isLoadingVerification}
        title={verificationData?.status ? "Verifikasi?" : "Batalkan Verifikasi?"}
        subtitle={`Apakah Anda sudah yakin ingin ${verificationData.status? `memverfifikasi` : `membatalkan` } pembayaran ini?`}
        text_accept="Yakin"
      />
      <MoslemeTable
        expandable={isExpand && expandable}
        columns={columns}
        data={payments}
        withSelection={true}
        pagination={pagination}
        customPaginationClass="pr-4"
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
        loading={isLoadingGet}
      />
    </>
  );
};

export default TabPembayaran;
