import React from "react";
import Inputs from "../../../../../components/form/inputs";
import ImageField from "../../../../../components/image_field";
import Grid from "@mui/material/Grid";
import TextAreas from "components/form/textarea";
import RichTextEditor from "components/richTextEditor";

function InfoBandaraComponent({
  valueName,
  valueCode,
  formikOnChange,
  formikValue,
  valueDescription,
  descriptionOnChange,
}) {
  console.log(valueDescription)
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={12} md={6}>
        <ImageField
          label="Logo Bandara"
          name="logo bandara"
          customContainer="!w-[16rem] h-[16rem] !rounded-2xl"
          width="16rem"
          height="16rem"
          setFile={(file) => {
            formikValue("image_logo", file?.path);
          }}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <ImageField
          label="Thumbnail Bandara"
          name="thumbnail bandara"
          customContainer="!w-auto h-[16rem] !rounded-2xl"
          width="100%"
          height="16rem"
          setFile={(file) => {
            formikValue("image_thumbnail", file?.path);
          }}
        />
      </Grid>

      <Grid item sm={12} md={6}>
        <Inputs
          id="name"
          name="name"
          label="Nama Bandara"
          value={valueName}
          placeholder="Masukan Nama Bandara"
          onChange={formikOnChange}
        />
      </Grid>

      <Grid item sm={12} md={6}>
        <Inputs
          id="domestic_code"
          name="domestic_code"
          label="Kode Domestik Bandara"
          placeholder="Masukan Kode Domestik Bandara"
          value={valueCode}
          onChange={formikOnChange}
        />
      </Grid>

      <Grid item sm={12}>
        <RichTextEditor
          data={{ value: valueDescription }}
          onChange={descriptionOnChange}
        />
      </Grid>
    </Grid>
  );
}

export default InfoBandaraComponent;
