import React, { useRef, useState } from "react";
import TaskIcon from "../../../../../assets/icons/task-table.svg";
import DokumenJamaah from "./DokumenJamaah";
import useClickOutside from "utils/helpers/useClickOutside";
import Dropdowns from "components/dropdowns";
import { DocumentDownload } from "iconsax-react";
import Popup from "components/popup";
import DocumentForm from "./DocumentForm";
import DropdownCustom from "components/DropdownCustom";

const PerlengkapanDokumen = ({
  value,
  jamaahOrderID,
  onUpdate,
  orderNumber,
  jamaahName,
  handleUploadDocument,
  isLoadingSave,
  handleUploadDocumentPassport,
}) => {
  const popover = useRef();
  const [openDropdown, setOpenDropdown] = useState(false);
  // useClickOutside(popover, () => setOpenDropdown(false));

  const [isUpdateDocument, setIsUpdateDocument] = useState(false);
  const [updateDocumentKey, setUpdateDocumentKey] = useState("");
  const [documentData, setDocumentData] = useState("");

  const covertNameDocument = (document_key) => {
    switch (document_key) {
      case "passport":
        return "Pasport";
      case "pas_photo_4x6":
        return "Pas Foto 4x6";
      case "ktp":
        return "KTP";
      case "kk":
        return "KK";
      case "akta_kelahiran":
        return "Akta Kelahiran";
      case "surat_nikah":
        return "Surat Nikah";
      case "meningitis_vaccine":
        return "Vaksin Meningitis";
      case "vaccine_covid_1":
        return "Vaksin Covid 1";
      case "vaccine_covid_2":
        return "Vaksin Covid 2";
      case "vaccine_covid_3":
        return "Vaksin Covid 3";
      case "bpjs_kesehatan":
        return "BPJS Kesehatan";
    }
  }

  return (
    <>
    <Popup
      open={isUpdateDocument}
      handleClose={() => setIsUpdateDocument(false)}
      title={`Update ${covertNameDocument(updateDocumentKey)}`}
      width="900px" 
      children={
        <DocumentForm 
          documentKey={updateDocumentKey}
          dataDocument={documentData}
          jamaahOrderID={jamaahOrderID}
          jamaahName={jamaahName}
          orderNumber={orderNumber}
          // jamaahData={jamaahData}
          onUpdate={() => {
            onUpdate()
          }}
          />
      }
      />

      <DropdownCustom
        dropdownRender={() => (
          <>
            <DokumenJamaah
              jamaahOrderID={jamaahOrderID}
              orderNumber={orderNumber}
              jamaahName={jamaahName}
              value={value?.data}
              handleUpdate={(document) => {
                setIsUpdateDocument(true);
                setUpdateDocumentKey(document.document_key);
                
                if(document.document_key === 'passport') {
                  setDocumentData(document.data);
                } else {
                  setDocumentData(document.document_url);
                }

              }}
              />
          </>
        )}
        >
        <>
          <div className={`border cursor-pointer ${value?.total_document_filled !== 0 ? value?.total_document_filled < value?.total_document ? "border-[#FFD700] bg-[#FFFACD]" : "border-[#71BE0E] bg-[#D8E1BE]" : "border-[#E0E0E0]"} px-3 py-[6px] rounded-lg inline-flex items-center gap-1 text-xs`}>
            <div>
              <img
                src={TaskIcon}
                alt="perlengkapan"
                className="w-[16px] h-[16px]"
              />
            </div>
            <div>
              Kelengkapan Data ({value?.total_document_filled}/{value?.total_document})
            </div>
          </div>
        </> 
      </DropdownCustom>

      {/* <Dropdowns 
        labelDropdown={
          <>
            <div className={`border ${value?.total_document_filled !== 0 ? "border-[#71BE0E] bg-[#D8E1BE]" : "border-[#E0E0E0]"} px-3 py-[6px] rounded-lg inline-flex items-center gap-1 text-xs`}>
              <div>
                <img
                  src={TaskIcon}
                  alt="perlengkapan"
                  className="w-[16px] h-[16px]"
                />
              </div>
              <div>
                Kelengkapan Data ({value?.total_document_filled}/{value?.total_document})
              </div>
            </div>
          </> 
        }
        children={
          <DokumenJamaah
              jamaahOrderID={jamaahOrderID}
              orderNumber={orderNumber}
              jamaahName={jamaahName}
              value={value?.data}
              handleUpdate={(document) => {
                setIsUpdateDocument(true);
                setUpdateDocumentKey(document.document_key);
                
                if(document.document_key === 'passport') {
                  setDocumentData(document.data);
                } else {
                  setDocumentData(document.document_url);
                }

              }}
              />
        }
        /> */}
    </>
  );
};

export default PerlengkapanDokumen;
