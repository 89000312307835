import styled from "styled-components";

const InputsStyle = styled.div`
  .ic-left {
    position: absolute;
    left: 24px;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .ic-right {
    position: absolute;
    right: 0;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .custom-input {
    padding: 16px 16px;
    width: 100%;
    background: #f2f2f2;
    border-radius: 12px;
    border: 0;

    &.success {
      border: 1px solid #71BE0E;
    }

    &.error {
      border: 1px solid #E82320;
    }

    &::placeholder {
      color: #828282;
    }

    &:hover,
    &:active {
      background: #ffdcdc;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .input-icon--left {
    padding-left: 42px;
  }

  .input-icon--right {
    padding-right: 48px;
  }

  .input-error {
    border: 1px solid #e82320;
  }

  .input-error-msg {
    color: #e82320;
    margin-left: 8px;
  }

  .input-success {
    border: 1px solid #97e03b;
  }

  .input-success-msg {
    color: #97e03b;
    margin-left: 8px;
  }

  .input-disabled {
    color: #828282;

    &:hover {
      cursor: not-allowed;
      background: #f2f2f2;
    }
  }

  .input-label {
    font-size: 14px;
    font-weight: 600;
    color: #4f4f4f;
    margin-bottom: 8px;
    height: 20px;

    &--disabled {
      color: #828282;
    }
  }

  .input-icon-password {
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    right: 0;
  }
`;

export default InputsStyle;
