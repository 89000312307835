import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Buttons from "../../../../../components/buttons";
import MoslemeTable from "../../../../../components/Table";

import AlertPopup from "components/popup/alert";
import Popup from "../../../../../components/popup";
import AddOrEditAkomodasi from "./components/AddOrEditAkomodasi";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePaketAkomodasi,
  getPaketAkomodasi,
} from "stores/actions/paket/akomodasi";
import {
  detailById,
  globalState,
  set_after_validation,
  setValidation,
} from "stores/actions/general";
import { getAllDataAkomodasi } from "stores/actions/datamaster";
import { columns } from "./columns";
import { IcJamPasir } from "assets/icons/dashboard";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";

const Akomodasi = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { global } = useSelector((state) => state.general);
  const { paketAkomodasi } = useSelector((state) => state?.akomodasi);

  const [selectionType, setSelectionType] = useState("checkbox");
  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [choice, setChoce] = useState(null);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const total = paketAkomodasi?.total_data;

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const allPaketAkomodasi = useCallback(() => {
    dispatch(
      getPaketAkomodasi({
        id_paket: id,
        page_number: global?.currentpage,
        per_page: global?.size,
        keyword: "",
        sort_column: "",
        sort_order: "DESC",
      })
    );
  }, [dispatch, global?.currentpage, global?.size, id]);

  useEffect(() => {
    dispatch(
      getAllDataAkomodasi({
        page_number: 1,
        per_page: 1000,
        keyword: "",
        sort_column: "",
        sort_order: "DESC",
      })
    );

    allPaketAkomodasi();
  }, []);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: global?.currentpage,
    pageSize: global?.size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  const onConfirmDelete = () => {
    setIsLoadingAction(true);

    dispatch(
      deletePaketAkomodasi(
        choice.id,
        (message) => {
          setIsLoadingAction(false);
          setIsDelete(false);
          allPaketAkomodasi();

          dispatch(handleSuccess(null, {message: "Berhasil menghapus akomodasi", code: 200}));
        },
        (error) => {
          setIsLoadingAction(false);
          setIsDelete(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  return (
    <>
      <Popup
        open={isOpen}
        handleClose={() => {
          setIsOpen(false);
          setChoce(null);
        }}
        containerClass="!overflow-y-auto"
        title={` ${choice ? "Edit Akomodasi" : "Tambah Akomodasi"}`}
        width="1080px"
        // height="500px"
        children={
          <AddOrEditAkomodasi
            setIsOpen={setIsOpen}
            allPaketAkomodasi={allPaketAkomodasi}
            choice={choice}
            setChoce={setChoce}
          />
        }
      />
      <AlertPopup
        open={isDelete}
        handleClose={() => {
          setIsDelete(false);
        }}
        handleContinue={onConfirmDelete}
        loading={isLoadingAction}
        title="Hapus Data"
        subtitle="Apakah Anda sudah yakin ingin menghapus paket Akomodasi? "
      />
     
        {auth.user.access_data === 'all_branches' && (
          <>
            {permissions?.includes('update_package') && (
              <>
                <div className="flex flex-row justify-end py-[16px] mr-[16px]">
                  <Buttons
                    text="Tambah Data"
                    customClass="btn-primary btn-small !w-fit"
                    onClick={() => {
                      setIsOpen(true);
                      setChoce(null);
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}

      {paketAkomodasi?.length === 0 ? (
        <main className="flex flex-col items-center gap-y-4 justify-center h-[69vh]">
          <img alt="hourglass" src={IcJamPasir} />
          <div>Belum ada Akomodasi di kategori ini</div>
        </main>
      ) : (
        <MoslemeTable
          columns={columns(
            dispatch,
            paketAkomodasi,
            setIsDelete,
            setChoce,
            setIsOpen,
            auth,
            permissions
          )}
          data={paketAkomodasi}
          withSelection={true}
          selectionType={selectionType}
          pagination={pagination}
          customPaginationClass="pr-4"
          customClass=""
          onPageChange={(page) =>
            dispatch(globalState({ currentpage: page, size: global.size }))
          }
          onSizeChange={(currentpage, pageSize) =>
            dispatch(globalState({ currentpage: currentpage, size: pageSize }))
          }
        />
      )}

      <div className="area-modal"></div>
    </>
  );
};

export default React.memo(Akomodasi);
