
import { handleGetBookingDetail, handleGetDataJamaahAPI, handleGetDataManifestAPI } from "utils/api/booking"
import { handleError, handleErrorBE } from "../errorGeneral"

export const SET_ISLOADING = 'booking/SET_ISLOADING'
export const FETCH_ORDER_DETAIL = 'booking/FETCH_ORDER_DETAIL'
export const FETCH_DATA_JAMAAH = 'booking/FETCH_DATA_JAMAAH'
export const FETCH_DATA_MANIFEST = 'booking/FETCH_DATA_MANIFEST'

export const setIsLoading = (payload) => ({
    type: SET_ISLOADING,
    payload
})

export const fetchOrderDetail = (payload) => ({
    type: FETCH_ORDER_DETAIL,
    payload
})

export const fetchDataJamaah = (payload) => ({
    type: FETCH_DATA_JAMAAH,
    payload
})

export const fetchDataManifest = (payload) => ({
    type: FETCH_DATA_MANIFEST,
    payload
})

export const handleGetDetailOrder = (payload, actionNotFound) => async (dispatch) => {
    try {

        dispatch(setIsLoading(true))
        const res = await handleGetBookingDetail(payload)
        dispatch(setIsLoading(false))
        const { status, data } = res
        if (status == 200) {
            dispatch(fetchOrderDetail(data.data))
        } else {
            dispatch(handleError(res.data))
            if(status === 404) {
                actionNotFound()
            }
        }

    } catch (error) {
        dispatch(handleError(error))
    }
}

export const handleGetDataJamaah = (payload) => async (dispatch) => {
    try {

        dispatch(setIsLoading(true))
        const res = await handleGetDataJamaahAPI(payload)
        dispatch(setIsLoading(false))
        const { status, data } = res
        if (status == 200) {
            dispatch(fetchDataJamaah(data.data))
        } else {
            dispatch(handleError(res.data))
        }

    } catch (error) {
        dispatch(handleError(error))
    }
}

export const handleGetDataManifest = (payload) => async (dispatch) => {
    try {

        dispatch(setIsLoading(true))
        const res = await handleGetDataManifestAPI(payload)
        dispatch(setIsLoading(false))
        const { status, data } = res
        if (status == 200) {
            dispatch(fetchDataManifest(data.data))
        } else {
            dispatch(handleErrorBE(res))
        }

    } catch (error) {
        dispatch(handleError(error))
    }
}
