import React, { useEffect, useState } from "react";
import Card from "../../../../../../components/cards";
import Tab from "../../../../../../components/tab";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { IMAGE } from "constant/constanta";

import "./tabs.css";
import EditInfo from "./info";
import EditGallery from "./galery";
import { editDataTransport } from "stores/actions/datamaster";

function EditTransport({ setOpen }) {
  const { detailData, isLoading } = useSelector((state) => state.datamaster);
  const { dataMultiple } = useSelector((state) => state.uploadFile);
  const dispatch = useDispatch();
  const [dataVideo, setDataVideo] = useState();
  const [options, setOptions] = useState("");

  const [time, setTime] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      category: "",
      image_logo: "",
      image_thumbnail: "",
      description: "",
      image: "",
    },
  });

  useEffect(() => {
    if (detailData) {
      formik.setFormikState({
        values: {
          id: detailData?.data?.id_transportation,
          name: detailData?.data?.name,
          category: detailData?.data?.category,
          image_logo: detailData?.data?.logo,
          thumbnail: detailData?.data?.thumbnail,
          description: detailData?.data?.description,
          image: detailData?.data?.gallery
            ?.filter((x) => x?.type === "image")
            ?.map((x) => x?.link),
        },
      });
      setDataVideo(
        detailData?.data?.gallery
          ?.filter((x) => x?.type === "video")
          ?.map((x) => x?.link)
      );
    }
    setTimeout(() => {
      setTime(true);
    }, 1000);
  }, [detailData]);

  const categories = [
    {
      value: "Bis",
      label: "Bis",
    },
    {
      value: "Kereta",
      label: "Kereta",
    },
  ];

  // submit form
  const handleSave = () => {
    dispatch(
      editDataTransport({
        ...formik.values,
        category: options !== "" ? options : formik.values.category,
        video: dataVideo,
        image: dataMultiple
          .filter((x) => x.type === "image" && x.path.split("/")[2] === IMAGE)
          .map((x) => x.path),
      })
    );
    setOpen(false);
  };
  return (
    <>
      {time === true ? (
        <Card containerClass=" !shadow-none !rounded-2xl !border !border-gray-200">
          <Tab
            defaultActiveKey="1"
            items={[
              {
                label: "Info Dasar",
                key: "1",
                children: (
                  <EditInfo
                    valueName={formik.values.name}
                    valueCategory={formik.values.category}
                    formikOnChange={formik.handleChange}
                    formikValue={formik.setFieldValue}
                    handleSave={handleSave}
                    options={categories}
                    setOptions={setOptions}
                    loading={isLoading}
                  />
                ),
              },
              {
                label: "Galeri & Deskripsi",
                key: "2",
                children: (
                  <EditGallery
                    valueImage={formik.values.image}
                    valueDescription={formik.values.description}
                    descriptionOnChange={(value) => {
                      formik.setFieldValue("description", value);
                    }}
                    formikOnChange={formik.handleChange}
                    formikValue={formik.setFieldValue}
                    handleSave={handleSave}
                    setDataVideo={setDataVideo}
                    dataVideo={dataVideo}
                    loading={isLoading}
                  />
                ),
              },
            ]}
          />
        </Card>
      ) : (
        <div className="w-full h-[534px] flex justify-center items-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
}

export default EditTransport;
