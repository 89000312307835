import PropTypes from "prop-types";

import IconRating from "../../assets/icons/Rating.svg";
import IconRatingActive from "../../assets/icons/rating_active.svg";


function Rating({ value, size }) {
    const makeRating = (number) => {
        const numbers = Array.from({ length: number - 1 + 1 }, (_, index) => 1 + index);
        return numbers
    }
  return (
    <>
        <div className="flex">
            {makeRating(value).map((i) => (
                <img 
                    key={i} 
                    src={IconRatingActive} 
                    alt="Rating"
                    width={size}
                    height={size} />
            ))}
            {makeRating(5 - value).map((i) => (
                <img 
                    key={i} 
                    src={IconRating} 
                    width={size} 
                    height={size}
                    alt="Rating" />
            ))}
        </div>
    </>
  );
}

Rating.propTypes = {
  size: PropTypes.string,
  value: PropTypes.number,
};

Rating.defaultProps = {
    size: "16px",
}

export default Rating;
