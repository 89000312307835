/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { ImAvatar } from "../../../assets/images";
import Badge from "../../../components/badge";

const ListMember = ({ img, name, role, cabang }) => {
  return (
    <section className="flex gap-x-2 items-center">
      <img
        alt="picture"
        src={
          img ||
          "https://i.pinimg.com/564x/7f/5e/3d/7f5e3dd07bbf25185e87e89943e76522.jpg"
        }
        className="w-8 h-8 rounded-full object-cover"
      />
      <div>
        <div className="text-xs mb-1 capitalize">{name}</div>
        <div className="flex gap-x-1">
          <Badge label={role} backgroundColor="#FCDE9C" color="#FE9A00" />
          <Badge label={cabang} backgroundColor="#6FFFE9" color="#3A506B" />
        </div>
      </div>
    </section>
  );
};

export default ListMember;
