import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import SlidersStyle from "./Slider.style";
import ArrowSlider from "../../assets/icons/arrow-square-down.svg";
import "./slick.css";
import "./slick-theme.css";

export const PrevArrow = ({ className, onClick }) => {
  return (
    <div className={`${className} custom-slick-arrow-prev`} onClick={onClick}>
      <img src={ArrowSlider} />
    </div>
  );
};

export const NextArrow = ({ className, onClick }) => {
  return (
    <div className={`${className} custom-slick-arrow-next`} onClick={onClick}>
      <img src={ArrowSlider} />
    </div>
  );
};

const Sliders = ({
  containerClass,
  customClass,
  dots,
  infinite,
  responsive,
  speed,
  slidesToShow,
  slidesToScroll,
  children,
}) => {
  const settings = {
    dots: dots,
    infinite: infinite,
    speed: speed,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    responsive: responsive,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  return (
    <SlidersStyle className={`${containerClass}`}>
      <Slider slidesToShow={2} className={`custom-slick ${customClass}`} {...settings}>
        {children}
      </Slider>
    </SlidersStyle>
  );
};

Sliders.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
};

Sliders.defaultProps = {
  containerClass: "",
  customClass: "",
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

export default React.memo(Sliders);
