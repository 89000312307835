import React from 'react';

import Buttons from '../../../../../components/buttons';
import Inputs from '../../../../../components/form/inputs';

import IconSearch from '../../../../../assets/icons/search-normal.svg';
import { SettingArticleAPI } from '../../../../../utils/api/setting';
import debounce from 'debounce-promise';
import { Spin } from 'antd';
import { convertBase64 } from 'utils/helpers/convertBase64';
import { useDispatch } from 'react-redux';
import SettingAction from 'stores/actions/article';
const parse = require('html-react-parser');
let debounceGetData = debounce(SettingArticleAPI.getArticle, 1000);

const AddArticle = ({ handleClose }) => {
  const dispatch = useDispatch();

  const [keyword, setKeyword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [isLoadingAdd, setIsLoadingAdd] = React.useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    let payload = { keyword };
    try {
      let res = await debounceGetData(payload);
      setData(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [id, setId] = React.useState('');

  const onSelect = (id) => {
    setId(id);
  };

  const callBackPost = () => {
    dispatch(SettingAction.fetchData());
    handleClose();
  };

  React.useEffect(() => {
    fetchData();
  }, [keyword]);

  return (
    <div>
      <div className="border-[1px] border-[#4048521a] rounded-[12px]">
        <div className="p-4 pb-0">
          <Inputs
            id="test"
            placeholder="Cari Artikel"
            customClass="!px-3 !py-4 !pl-[42px]"
            iconPosition="left"
            icon={IconSearch}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />
        </div>
        <div className="p-4 flex flex-col gap-4 overflow-auto costumScrollbar !h-[calc(100vh-350px)]">
          {isLoading ? (
            <div className="flex justify-center items-center py-10">
              <Spin size="large" />
            </div>
          ) : (
            data.map((v) => {
              return (
                <div
                  onClick={() => onSelect(v.id_artikel)}
                  key={v.id_artikel}
                  className={`card-article cursor-pointer border-[1px] rounded-[12px] p-4 ${
                    v.id_artikel === id ? 'border-red-400' : ''
                  } `}
                >
                  <div className="font-semibold">{v.judul}</div>
                  {/* <div className="mt-2 line-clamp-2">{ v.isi }</div> */}
                  <div className="flex flex-row gap-2">
                    <div className="border-[1px] border-[#BDBDBD] rounded-[40px] px-2 py-1 mt-4">
                      {v.nama_kategori}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end p-4">
          <Buttons
            loading={isLoadingAdd}
            disabled={id === ''}
            onClick={async () => {
              setIsLoadingAdd(true);
              const res = await dispatch(SettingAction.createData({ id_artikel: id }, callBackPost));
              setIsLoadingAdd(false);

            }}
            text="Tambah"
            customClass="btn-primary btn-medium !w-fit"
          />
        </div>
      </div>
    </div>
  );
};

export default AddArticle;
