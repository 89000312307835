import React from "react";
import ScanQR from ".";

const DocScanQR = () => {
  return (
    <div>
      <h2 className="mb-[5px]">
        <b>ScanQR Example</b>
      </h2>
      <ScanQR />
    </div>
  );
};

export default DocScanQR;
