import React from "react";
import JPGIcon from "assets/icons/colored/JPG.svg";
import PDFIcon from "assets/icons/colored/PDF.svg";
import DownloadIcon from "assets/icons/Download.svg";
import DeleteIcon from "assets/icons/trash.svg";
import { useSelector } from "react-redux";

const CardFileUpload = ({ fileName, time, fileType, url, handleRemove }) => {
  const auth = useSelector((state) => state.auth);
  const handleDownloadCSV = (url) => {
      var a = document.createElement("a"); //Create <a>
      a.href = `${url}`; //Image Base64 Goes here
      a.download = `${fileName}`; //File name Here
      a.target = "_blank";
      a.click();
  };

  const icon = fileType === "pdf" ? PDFIcon : JPGIcon;
  return (
    <div className="bg-[#F2F2F2] rounded-[12px] p-4 mt-2">
      <div className="flex flex-row justify-between items-center mb-4">
        <div className="flex flex-row items-center">
          <img src={icon} alt="jpg icon" className="w-[27px] h-[32px] mr-4" />
          <div>
            <h1 className="text-base font-semibold text-[#141414]">{fileName}</h1>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="cursor-pointer">
            <img
              src={DownloadIcon}
              alt="delete icon"
              className="w-6 h-6 cursor-pointer"
              onClick={()=> handleDownloadCSV(url)}
            />
          </div>
          {auth.user.access_data === "all_branches" && (
            <div className="cursor-pointer ml-6">
              <img 
                src={DeleteIcon} 
                alt="delete icon" 
                className="w-6 h-6" 
                onClick={() => handleRemove()}
                />
            </div>
          )}
        </div>
      </div>
      <div>
        {fileType === "pdf" ? (
          <>
            <iframe
              className="w-full h-[300px] object-cover rounded-xl"
              src={url}
              >
              </iframe>
          </>
        ) : (
          <>
            <img
              className="w-full max-h-[300px] object-cover rounded-xl"
              src={url}
              alt="jpg icon"
              />
          </>
        )}
      </div>
    </div>
  );
};

export default CardFileUpload;
