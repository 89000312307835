import Avatar from "components/avatar";
import Badge from "components/badge";
import { toCapital } from "utils/helpers";

const { default: ListMember } = require("./components/ListMember");

export const columns = [
  {
    title: "",
    render: (record) => (
      <>
        <div className="flex gap-2 items-center">
          <div>
            <Avatar
              value={record}
              containerClass=
                "items-center justify-center  w-[32px] h-[32px] rounded-[100%]  bg-slate-100"
                customClass= "object-cover w-[32px] h-[32px] rounded-[100%]"
              />
          </div>

          <div>
            <div>
              {record.title ? `${toCapital(record.title)}. ` : ""} {record.name?.toUpperCase()}
            </div>
            <div className="flex gap-1">
              {Object.keys(record.branch).length !== 0 && (
                <Badge
                  label={record.branch.name?.toUpperCase()}
                  color={record.branch.color_text}
                  backgroundColor={record.branch.color_bg}
                  />
              )}
              <Badge
                label={record.role.display_name?.toUpperCase()}
                color={record.role.color_text}
                backgroundColor={record.role.color_bg}
                />
            </div>
          </div>
        </div>
      </>
    ),
  },
];
