import React, { useState } from "react";

import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import ImageField from "components/image_field";
import Rates from "components/form/rate";
import Buttons from "components/buttons";
import Textarea from "components/form/textarea";
import { useDispatch, useSelector } from "react-redux";
import {
    saveDataTestimoni,
    getDataTestimoni,
} from "stores/actions/apps/testimoni";
import UploadArea from "components/Uploadarea";

const AddTestimoniUpload = ({ handleClose }) => {
    const dispatch = useDispatch();
    const { dataUpload } = useSelector((state) => state.uploadFile);
    const { isLoading } = useSelector((state) => state.testimoni);

    const [options, setOptions] = useState([
        {
            value: 1,
            label: "Haji",
        },
        {
            value: 2,
            label: "Umroh",
        },
        {
            value: 3,
            label: "Tabungan",
        },
        {
            value: 4,
            label: "Tour",
        },
        {
            value: 5,
            label: "Haji Badal",
        },
    ]);

    const [isMulti, setIsMulti] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [rating, setRating] = useState(3);
    const [jenisPaket, setJenisPaket] = useState(1);
    const [image, setImage] = useState("");
    const [pesan, setPesan] = useState("");

    const isFormCompleted =
        name && email && rating && jenisPaket && image & pesan;

    const resetForm = () => {
        setName("");
        setEmail("");
        setRating(3);
        setJenisPaket("");
        setImage("");
        setPesan("");
    };

    const save = async () => {
        const payload = {
            nama: name,
            jenis_paket: jenisPaket[0].value,
            email: email,
            image: image,
            rating: rating,
            pesan: pesan,
        };

        const getPayload = {
            keyword: "",
            per_page: 1000,
            page_number: 1,
            sort_column: "rating",
            sort_order: "ASC",
        };
        try {
            console.log(jenisPaket.value);
            const res = await dispatch(saveDataTestimoni(payload));
            resetForm();
            if (res.status == 200) {
                console.log("masuk");
                dispatch(getDataTestimoni(getPayload));
            }
        } catch (error) {
            console.log(error);
        } finally {
            handleClose();
        }
    };

    return (
        <>
            <div className="grid grid-cols-2 gap-4 mt-4">
                <Inputs
                    label="Nama"
                    placeholder="Masukkan Nama"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                />
                <Inputs
                    label="Email"
                    placeholder="Masukkan Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
                <div>
                    <Selects
                        label="Jenis Paket"
                        placeholder="Select items"
                        options={options}
                        searchable
                        onChange={(e) => {
                            setJenisPaket(e);
                            console.log(e);
                        }}
                        itemRenderer={({ state, item, methods }) => {
                            return (
                                <div
                                    className={`react-dropdown-select-item ${
                                        state.values.length !== 0 &&
                                        state.values[0].value === item.value &&
                                        "react-dropdown-select-item-selected"
                                    }`}
                                    onClick={() => methods.addItem(item)}
                                >
                                    {item?.label}
                                </div>
                            );
                        }}
                    />
                    <div className="w-fit mt-4">
                        <div className="font-semibold text-[#4F4F4F]">
                            Rating
                        </div>
                        <div className="border-[0.5px] border-[#E0E0E0] rounded-2xl px-4 py-3 mt-2">
                            <Rates value={rating} setValue={setRating} />
                        </div>
                    </div>
                </div>
                <div>
                    {/* <ImageField
                        customContainer="h-[200px]"
                        error={isError}
                        isMulti={isMulti}
                        setFile={(file) => console.log(file)}
                        name="testimoni_image"
                    /> */}
                    <UploadArea
                        id="testimoni_image"
                        label="Foto"
                        onChange={(e) => setImage(e)}
                        customClass="!h-[250px] !w-[250px]"
                    />
                </div>
            </div>
            <div>
                <Textarea
                    label="Pesan"
                    placeholder="Tulis Pesan disini..."
                    onChange={(e) => setPesan(e.target.value)}
                    value={pesan}
                />
            </div>
            <div className="divider mt-8"></div>
            <div className="flex flex-row justify-end gap-2 p-4">
                <Buttons
                    text="Kembali"
                    customClass="btn-outline btn-medium !w-fit"
                />
                <Buttons
                    text="Tambah"
                    customClass="btn-primary btn-medium !w-fit"
                    onClick={save}
                    loading={isLoading}
                    disabled={isFormCompleted}
                />
            </div>
        </>
    );
};

export default React.memo(AddTestimoniUpload);
