export const actionLayout = {
  SET_VISIBILITY_TRUE: "SET_VISIBILITY_TRUE",
  SET_VISIBILITY_FALSE: "SET_VISIBILITY_FALSE",
  SET_ALLOWED_TRUE: "SET_ALLOWED_TRUE",
  SET_ALLOWED_FALSE: "SET_ALLOWED_FALSE"
};

export const setVisibilityTrue = () => ({
  type: actionLayout.SET_VISIBILITY_TRUE,
});

export const setVisibilityFalse = () => ({
  type: actionLayout.SET_VISIBILITY_FALSE,
});

export const setAllowedTrue = () => ({
  type: actionLayout.SET_ALLOWED_TRUE,
});

export const setAllowedFalse = () => ({
  type: actionLayout.SET_ALLOWED_FALSE,
});
