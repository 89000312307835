import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import StarIcon from "assets/icons/Star.svg";
import Popup from "components/popup";
import PopupRatingQuestion from "./PopupRatingQuestion";

import ILCoomingSoon from "assets/images/cooming-soon.svg";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";

import { getRatingPerQuestionPackageService, removeRatingQuestionsService } from "utils/api/paket/ibadahku";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import Spinner from "components/spinner";
import { Edit, Trash } from "iconsax-react";
import Alert from "components/popup/alert";
import Rating from "components/rating";

const RatingQuestionData = [
  {
    valueTotalStar: "4,8/5",
    pertanyaan: `Apakah pelayanan paket yang ditawarkan kami sebanding dengan
        harga paketnya?`,
    valueStarSatu: "0",
    valueStarDua: "0",
    valueStarTiga: "0",
    valueStarEmpat: "8",
    valueStarLima: "12",
  },
  {
    valueTotalStar: "4,8/5",
    pertanyaan: `Apakah pelayanan paket yang ditawarkan kami sebanding dengan harga paketnya?`,
    valueStarSatu: "0",
    valueStarDua: "0",
    valueStarTiga: "0",
    valueStarEmpat: "8",
    valueStarLima: "12",
  },
  {
    valueTotalStar: "4,8/5",
    pertanyaan: `Apakah pelayanan paket yang ditawarkan kami sebanding dengan harga paketnya?`,
    valueStarSatu: "0",
    valueStarDua: "0",
    valueStarTiga: "0",
    valueStarEmpat: "8",
    valueStarLima: "12",
  },
  {
    valueTotalStar: "4,8/5",
    pertanyaan: `Apakah pelayanan paket yang ditawarkan kami sebanding dengan harga paketnya?`,
    valueStarSatu: "0",
    valueStarDua: "0",
    valueStarTiga: "0",
    valueStarEmpat: "8",
    valueStarLima: "12",
  },
];

const RatingQuestionCard = ({
  valueTotalStar,
  pertanyaan,
  valueStarSatu,
  valueStarDua,
  valueStarTiga,
  valueStarEmpat,
  valueStarLima,
  questionID,
  handleDeleted,
  onEdit
}) => {

  const [openPopupRatingQuestion, setOpenPopupRatingQuestion] = useState(false);
  const [ratingSelected, setRatingSelected] = useState(0);
  const [ratingValueSelected, setRatingValueSelected] = useState(0);

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();

  const handleRemoveQuestion = async () => {
    setIsDeleting(true);
    const res = await removeRatingQuestionsService(questionID);
    setIsDeleting(false);
    if (res.status === 200) {
      setOpenDeleteAlert(false);
      setOpenPopupRatingQuestion(false);
      dispatch(handleSuccess(null, { message: "Pertanyaan berhasil di hapus", code: 200 }));
      handleDeleted()
    } else {
      dispatch(handleError(res.data));
    }
  };

  return (
    <>
      <Popup
        open={openPopupRatingQuestion}
        handleClose={() => {
          setOpenPopupRatingQuestion(false);
        }}
        title={
          <>
            <div className="text-sm mb-2">{pertanyaan}</div>
            <div className="text-xs flex items-center gap-2">
            <Rating value={ratingSelected} /> 
            <span className="font-semibold">({ratingValueSelected})</span>
            </div>
          </>
        }
        width="676px"
        height="536px"
        containerClass=""
        children={<PopupRatingQuestion questionID={questionID} rating={ratingSelected} />}
      />

      <Alert 
        open={openDeleteAlert}
        handleClose={() => setOpenDeleteAlert(false)}
        title="Hapus Pertanyaan"
        subtitle="Apakah anda yakin ingin menghapus pertanyaan ini ?"
        buttonText="Hapus"
        // onClick={() => setOpenDeleteAlert(false)}
        handleContinue={handleRemoveQuestion}
        loading={isDeleting}
        />

      <div className="flex justify-between shadow-soft rounded-xl">
        <div
          className="flex flex-row items-center p-[16px]  gap-[16px] "
          // onClick={() => setOpenPopupRatingQuestion(true)}
        >
          <div className="flex flex-row gap-[4px] border rounded-lg py-[12px] px-[16px]">
            <img src={StarIcon} alt="rating" />
            <h2 className="font-semibold">{valueTotalStar}</h2>
          </div>
          <div className="flex flex-col items-start gap-[8px]">
            <div>
              <h2>{pertanyaan}</h2>
            </div>
            <div className="flex flex-row items-start gap-[16px]">
              <div 
                className="flex flex-row items-center gap-[4px] cursor-pointer"
                onClick={() => {
                  setOpenPopupRatingQuestion(true)
                  setRatingSelected(1)
                  setRatingValueSelected(valueStarSatu)
                }}
                >
                <img src={StarIcon} alt="rating" />
                <p className="font-semibold">({valueStarSatu})</p>
              </div>
              <div 
                className="flex flex-row items-center gap-[4px] cursor-pointer"
                onClick={() => {
                  setOpenPopupRatingQuestion(true)
                  setRatingSelected(2)
                  setRatingValueSelected(valueStarDua)
                }}
                >
                <img src={StarIcon} alt="rating" />
                <img src={StarIcon} alt="rating" />
                <p className="font-semibold">({valueStarDua})</p>
              </div>
              <div 
                className="flex flex-row items-center gap-[4px] cursor-pointer"
                onClick={() => {
                  setOpenPopupRatingQuestion(true)
                  setRatingSelected(3)
                  setRatingValueSelected(valueStarTiga)
                }}
                >
                <img src={StarIcon} alt="rating" />
                <img src={StarIcon} alt="rating" />
                <img src={StarIcon} alt="rating" />
                <p className="font-semibold">({valueStarTiga})</p>
              </div>
              <div 
                className="flex flex-row items-center gap-[4px] cursor-pointer"
                onClick={() => {
                  setOpenPopupRatingQuestion(true)
                  setRatingSelected(4)
                  setRatingValueSelected(valueStarEmpat)
                }}
                >
                <img src={StarIcon} alt="rating" />
                <img src={StarIcon} alt="rating" />
                <img src={StarIcon} alt="rating" />
                <img src={StarIcon} alt="rating" />
                <p className="font-semibold">({valueStarEmpat})</p>
              </div>
              <div 
                className="flex flex-row items-center gap-[4px] cursor-pointer"
                onClick={() => {
                  setOpenPopupRatingQuestion(true)
                  setRatingSelected(5)
                  setRatingValueSelected(valueStarLima)
                }}
                >
                <img src={StarIcon} alt="rating" />
                <img src={StarIcon} alt="rating" />
                <img src={StarIcon} alt="rating" />
                <img src={StarIcon} alt="rating" />
                <img src={StarIcon} alt="rating" />
                <p className="font-semibold">({valueStarLima})</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center gap-4 py-4 mr-4">
          <Edit onClick={() => {
            onEdit();
          }} className="cursor-pointer" />
          <Trash onClick={() => {
            setOpenDeleteAlert(true);
          }} className="cursor-pointer" />
        </div>
      </div>
    </>
  );
};

const RatingQuestion = (
{
  isGettingQuestions,
  setIsGettingQuestions,
  handleUpdate
}
) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [gettingQuestions, setGettingQuestions] = useState(false);
  const [questions, setQuestions] = useState([]);

  const handleGetQuestions = async () => {
    setGettingQuestions(true);

    const payload = {
      "id_paket" : params.id,
      "keyword" : null,
      "per_page" : 1000,
      "page_number" : 1,
      "sort_column" : "id",
      "sort_order" : "asc"
  }

    const res = await getRatingPerQuestionPackageService(payload);

    setGettingQuestions(false);

    if(res.status === 200) {
      setQuestions(res.data.data);

      isGettingQuestions && setIsGettingQuestions(false);

    } else {
      dispatch(handleError(res.data));
    }
    
  };

  useEffect(() => {
    handleGetQuestions();
  }, []);

  useEffect(() => {
    if(isGettingQuestions) {
      handleGetQuestions();
    }

  }, [isGettingQuestions]);

  return (
    <>
      <div className="flex flex-col gap-[16px] mt-4">
        {gettingQuestions ? (
          <>
            <div className="flex h-[400px] justify-center items-center">
              <Spinner width="50px" height="50px"/>
            </div>
          </>
        ) : (
          <>
            {questions.length > 0 ? (
              <>
                <div className="flex gap-[16px] flex-col">
                  {questions.map((item) => (
                    <RatingQuestionCard
                      valueTotalStar={item.rating}
                      pertanyaan={item.question}
                      valueStarSatu={item.ratings[0].count}
                      valueStarDua={item.ratings[1].count}
                      valueStarTiga={item.ratings[2].count}
                      valueStarEmpat={item.ratings[3].count}
                      valueStarLima={item.ratings[4].count}
                      questionID={item.id}
                      onEdit={() => {
                        handleUpdate(item);
                      }}
                      handleDeleted={handleGetQuestions}
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col h-[400px] justify-center items-center bg-white">
                  <img
                    src={IconJamPasir}
                    alt="Cooming Soon"
                    className="mb-2 w-[252px] h-[260px]"
                  />
                  <p className="text-base text-black">
                    Belum ada data pertanyaan
                  </p>  
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RatingQuestion;
