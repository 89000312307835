/* eslint-disable import/no-anonymous-default-export */
// akomodasi Reducers
// --------------------------------------------------------

import {
  SET_LOADING,
  CLEAR_ERROR,
  SET_ERROR,
  INIT_DATA,
  SET_DOUBLE_SUBMIT,
  SET_PAKET_AKOMODASI,
} from "../../../actions/paket/akomodasi";

const initialState = {
  isLoading: false,
  isDoubleSubmit: false,
  isSubmitting: false,
  error: {},
  paketAkomodasi: null,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_DOUBLE_SUBMIT:
      return {
        ...state,
        isDoubleSubmit: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case INIT_DATA:
      return {
        ...initialState,
      };
    case SET_PAKET_AKOMODASI:
      return {
        ...state,
        paketAkomodasi: payload,
      };
    default:
      return state;
  }
};
