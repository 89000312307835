import {
  ERROR_FETCHING_BOOKING_UMROH,
  SET_LOADING,
  START_ADD_BOOKING_UMROH,
  SET_ADD_OR_EDIT_BOOKING_UMROH,
  SET_DELETE_BOOKING_UMROH,
  SET_PAGE_BOOKING_UMROH,
  SET_SIZE_BOOKING_UMROH,
  SET_TOTAL_BOOKING_UMROH,
  START_FETCHING_BOOKING_UMROH,
  SUCCESS_FETCHING_BOOKING_UMROH,
} from "stores/actions/booking/umroh/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  total: 100,
  per_page: 10,
  page_number: 1,
  sort_column: "",
  sort_order: "Desc",
  keyword: "",
  status: statuslist.idle,
  isLoading: false,
  isNewOrder: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {...state, isLoading: action.payload}

    case START_FETCHING_BOOKING_UMROH:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_BOOKING_UMROH:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_BOOKING_UMROH:
      return {
        ...state,
        data: action.payload,
        status: statuslist.success,
      };
    case SET_PAGE_BOOKING_UMROH:
      return { ...state, page_number: action.page };

    case SET_SIZE_BOOKING_UMROH:
      return { ...state, per_page: action.size };

    case SET_TOTAL_BOOKING_UMROH:
      return { ...state, total: action.total };

    case SET_ADD_OR_EDIT_BOOKING_UMROH:
      state["data"].unshift(action.payload.data);
      return { ...state, isNewOrder: true }

    case START_ADD_BOOKING_UMROH:
      return { ...state, isNewOrder: false }

    case SET_DELETE_BOOKING_UMROH:
      state["data"] = state["data"].filter((list) => list.id !== action.id);
      return state;
    default:
      return state;
  }
}
