import React from 'react';
import ImageField from '.';

function ImageFieldDocs() {
  const [isMulti, setIsMulti] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  return (
    <div>
      <div className="flex mb-5">
        <label className="mr-3">Multiple</label>
        <input className="mr-3" type="radio" checked={isMulti} onClick={() => setIsMulti(true)} />
        <label className="mr-3">Single</label>
        <input className="mr-3" type="radio" checked={!isMulti} onClick={() => setIsMulti(false)} />
      </div>
      <div className="flex mb-5">
        <label className="mr-3">Default</label>
        <input className="mr-3" type="radio" checked={!isError} onClick={() => setIsError(false)} />
        <label className="mr-3">Error</label>
        <input className="mr-3" type="radio" checked={isError} onClick={() => setIsError(true)} />
      </div>
      <hr className="mb-5" />
      <ImageField
        error={isError}
        isMulti={isMulti}
        setFile={(file) => console.log('file', file)}
        name="name1"
      />
    </div>
  );
}

export default ImageFieldDocs;
