import styled from "styled-components";

const AppsStyle = styled.div`
  .list-kelola-artikel {
    &:hover {
      .list-kelola-artikel-action {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export default AppsStyle;
