import Buttons from "components/buttons"
import AntdSelect from "components/form/antdSelect"
import { useEffect, useState } from "react"
import Badge from "./Forms/Badge"

const FilterForm = ({
  options,
  data,
  onChange,
  values
}) => {

  const [customOptions, setCustomOptions] = useState([])
  const [optionSelected, setOptionSelected] = useState(null)
  const [dataValues, setDataValues] = useState([])

  const handleSetValues = (newDataValues, key) => {
    let _tempValues = dataValues

    _tempValues[key] = newDataValues

    setDataValues(_tempValues)
  }

  useEffect(() => {
    const _tempOptions = options.map((item) => ({
      ...item,   // Menjaga properti lain dari item
      value: item.key  // Menambahkan atau mengubah nilai 'value'
    }));

    setCustomOptions(_tempOptions)

    setOptionSelected(_tempOptions[0])
  }, [options])

  useEffect(() => {
    if (customOptions.length > 0 && values) {
      setDataValues(values)
    }
  }, [customOptions, values])

  return (
    <div 
      className="bg-white p-4 rounded-lg shadow-hard w-[260px]">
      <AntdSelect
        options={customOptions}
        value={optionSelected?.value}
        theme="light-border"
        size={"small"}
        customClass={"mb-4"}
        />

          {optionSelected?.type === "badge" && (
            <>
              <Badge
                option={optionSelected}
                data={data}
                onChange={(data) => handleSetValues(data, optionSelected.key)}
                values={dataValues[optionSelected.key]}
                />
            </>
          )}
      <div className="mt-4">
        <Buttons
          text="Terapkan"
          customClass="btn-primary btn-small"
          onClick={() => {
            console.log("Halloooo")
            onChange(dataValues)
          }}
        />
      </div>
    </div>
  )
}

export default FilterForm