import styled from "styled-components";

const InputPhoneStyle = styled.div`
  .ic-left,
  .ic-left-arrow,
  .input-phone-code {
    position: absolute;
    left: 24px;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .ic-left-arrow {
    left: 52px;
  }

  .input-phone-code {
    left: 80px;
  }

  .custom-input {
    /* padding-left: 100px; */
    /* padding: 16px;
    position: relative;
    top: 1px;
    padding-left: 100px;
    width: 100%;
    background: #f2f2f2;
    border: 0;
    border-radius: 12px; */

    border-radius: 12px;

    &.error {
      border: 1px solid rgb(232, 35, 32);
    }

    .form-control {
      padding: 16px 16px 16px 48px;
      height: initial;
      background: #f2f2f2;
      border-radius: 12px;
      border: none;
      width: 100%;

      &:hover,
      &:active {
        background: #ffdcdc;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .flag-dropdown {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      border: none;

      .selected-flag {
        padding: 0 0 0 12px;
        width: 44px;
      }

      .selected-flag:hover {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        background: #f2f2f2;
      }

      .search {
        padding: 12px 12px 12px 0;
      }

      .search-emoji {
        display: none;
      }

      .search-box {
        padding: 8px 12px;
        background: #f2f2f2;
        border-radius: 12px;
        border: none;
        width: 100%;

        &:hover,
        &:active {
          background: #ffdcdc;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .country-list .country {
        padding: 8px 12px;
      }

      .country-list .country:hover {
        background: #ffdcdc;
      }

      .country-list .country.highlight {
        background: #ffdcdc;
      }
    }

    .flag-dropdown.open .selected-flag {
      background: #f2f2f2;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    &::placeholder {
      color: #828282;
    }
  }

  .input-error {
    border: 1px solid #e82320;
  }

  .input-error-msg {
    color: #e82320;
    margin-left: 8px;
  }

  .input-success {
    border: 1px solid #97e03b;
  }

  .input-success-msg {
    color: #97e03b;
    margin-left: 8px;
  }

  .input-disabled {
    color: #828282;

    &:hover {
      cursor: not-allowed;
      background: #f2f2f2;
    }
  }
`;

export default InputPhoneStyle;
