import {
  ERROR_FETCHING_REQUEST,
  SET_APPROVE_REQUEST,
  SET_REJECT_REQUEST,
  START_FETCHING_REQUEST,
  SUCCESS_FETCHING_REQUEST,
  SET_LOADING,
} from "stores/actions/calculator/request/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  status: statuslist.idle,
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case START_FETCHING_REQUEST:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_REQUEST:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_REQUEST:
      return {
        ...state,
        data: action.payload.data,
        status: statuslist.success,
      };
    case SET_APPROVE_REQUEST:
      // state["data"] = state["data"].filter(
      //   (list) => list.id_request_paket !== action.payload.data.id_request_paket
      // );
      return state;
    case SET_REJECT_REQUEST:
      // state["data"] = state["data"].filter(
      //   (list) => list.id_request_paket !== action.id
      // );
      return state;
    default:
      return state;
  }
}
