import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const AdminGalleryAPI = {
  getList: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-galeri`, payload),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-galeri`, payload),
  deleteData: (id) => axios.delete(`${BASE_URL_ENDPOINT}/admin/galeri/${id}`),
};

export { AdminGalleryAPI };
