import axios from "axios";
import { BASE_URL_ENDPOINT, BASE_URL_ENDPOINT_V2 } from "constant/constanta";

export const onHandleuploadEvisaService = (packageID, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${packageID}/e-visa/upload`,
    payload
  );

export const onHandleUpdateMessageInfoEvisaService = (packageID, payload) =>
  axios.put(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${packageID}/e-visa/message-info`,
    payload
  );

export const onHandleSendInfoEvisaService = (packageID, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${packageID}/e-visa/message-info/send`,
    payload
  );


export const onHandleRemoveEvisaService = (packageID, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${packageID}/e-visa/delete`,
    payload
  );

