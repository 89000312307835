import React from "react";

const ItemSeat = ({ className, icon, seat, totalSeat }) => {
  return (
    <div className={`items-center flex flex-col ${className}`}>
      <img alt="seat" src={icon} />
      <div className="font-medium text-xs">{seat}</div>
      <div className="text-[28px] font-semibold">{totalSeat}</div>
    </div>
  );
};

export default ItemSeat;
