import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const AppsSlideAPI = {
  getList: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-slide`, payload),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-slide`, payload),
  deleteData: (id) => axios.delete(`${BASE_URL_ENDPOINT}/admin/slide/${id}`),
};

export { AppsSlideAPI };
