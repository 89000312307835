import styled from "styled-components";

const TimePickersStyle = styled.div`
  .ant-picker {
    cursor: pointer;
    width: 100%;

    &:hover,
    &:active {
      border-color: transparent;
      background: #ffdcdc;
    }
  }

  .ant-picker-focused {
    border-color: transparent !important;
    background: #ffdcdc;
  }

  .ant-picker .ant-picker-input {
    width: 100% !important;
  }

  .custom-timepicker-open {
    z-index: 1400;

    .ant-btn.ant-btn-sm {
      background: linear-gradient(180deg, #ea3634 0%, #94110f 100%);

      &:hover {
        background: linear-gradient(180deg, #de1a17 0%, #6f0d0b 100%);
        color: white;
        span {
          color: white;
        }
      }

      &:active {
        color: white !important;
        background: #970c10;
        box-shadow: none;
      }

      &.disabled,
      &.loading {
        background: #ff9c9a !important;
        cursor: not-allowed;
      }
    }

    .ant-picker-dropdown
      .ant-picker-time-panel-column
        &::-webkit-scrollbar:horizontal {
          display: none;
        }
        
        &::-webkit-scrollbar {
          width: 4px;
        }
        
        &::-webkit-scrollbar-track {
          width: 4px;
          border-right: 10px solid #fff;
          border-left: 10px solid #fff;
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: #e0e0e0;
          width: 4px;
          border-radius: 16px;
        }
        
        &::-webkit-scrollbar-track-piece:start {
          background: #fff;
        }
        
        &::-webkit-scrollbar-track-piece:end {
          background: #fff;
        }

      .ant-picker-time-panel-column
      > li.ant-picker-time-panel-cell-selected
      .ant-picker-time-panel-cell-inner {
      background: #ffdcdc;
    }
  }
`;

export default TimePickersStyle;
