import Tab from "components/tab";
import Inputs from "components/form/inputs";

import IconAlbumPerjalananActive from "assets/icons/colored/Album-Perjalanan.svg";
import IconAlbumPerjalanan from "assets/icons/icon_album_selama_beribadah.svg";
import IconDataJamaahActive from "assets/icons/album_data_jamaah_active.svg";
import IconDataJamaah from "assets/icons/Album-Jamaah-2.svg";


import AlbumSelamaBeribadah from "./Tabs/AlbumSelamaBeribadah";
import AlbumDataJamaah from "./Tabs/AlbumDataJamaah";
import Buttons from "components/buttons";
import { useState } from "react";
// import IconAirplane from "../../../../../assets/icons/colored/airplan-3-icon.svg";
// import IconBusInactive from "../../../../../assets/icons/colored/bus-inactive-icon.svg";

const Album = () => {
  const [isActive, setIsActive] = useState("1")

const AlbumTabs = [
  {
    label: (
      <span className="flex gap-2 items-center px-4 py-1">
        <img src={isActive === "1"  ? IconAlbumPerjalananActive : IconAlbumPerjalanan} alt="icon" />
        <span>Album Selama Beribadah</span>
      </span>
    ),
    key: "1",
    children: <AlbumSelamaBeribadah />,
  },
  {
    label: (
      <span className="flex gap-2 items-center px-4 py-1">
        <img src={isActive === "2" ? IconDataJamaahActive : IconDataJamaah} alt="icon" />
        <span>Album Data Jamaah</span>
      </span>
    ),
    key: "2",
    children: <AlbumDataJamaah />,
  },
];
  return (
    <>
      <div className="relative ml-4">
          <Tab defaultActiveKey="1" items={AlbumTabs} onChange={(value) => setIsActive(value)} className="border-b w-auto"/>
      </div>
    </>
  );
};

export default Album;
