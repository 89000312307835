import React, { useCallback, useEffect, useState } from "react";
import MoslemeTable from "components/Table";
import Badge from "components/badge";
import IconSearch from "../../../../../assets/icons/search-normal.svg";
import IconActivity from "../../../../../assets/icons/activity.svg";
import PerlengkapanContent from "./PerlengkapanContent";
import { useDispatch, useSelector } from "react-redux";
import { IcJamPasir } from "assets/icons/dashboard";
import IconSquare from "assets/icons/send-square.svg";
import {
  getListCabangPaketPerlengkapan,
  getListStartingPaketPerlengkapan,
  handleGetAllPerlengkapan,
  handleGetPerlengkapanJamaah,
  handleUpdatedPerlengkapan,
  saveDataPerlengkapan,
  setDataPerlengkapanV2,
} from "stores/actions/paket/perlengkapan";
import Inputs from "components/form/inputs";
import Dropdowns from "components/dropdowns";
import Buttons from "components/buttons";
import Popup from "components/popup";
import PerlengkapanPopup from "./PerlengkapanPopup";
import Filter from "assets/icons/filter.svg";
import { Select } from "antd";
import IconCeklist from "assets/icons/tick-circle-green.svg";
import { getAllDataPerlengkapan } from "stores/actions/datamaster";
import { useParams } from "react-router-dom";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import useDebounce from "utils/helpers/useDebounce";
import Scroll from "components/scroll";
import Avatar from "components/avatar";
import { toCapital } from "utils/helpers";

const Perlengkapan = () => {
  const dispatch = useDispatch();
  const { isLoading, listPerlengkapan } = useSelector(
    (state) => state.perlengkapan
  );
  const { id } = useParams();
  const { dataPerlengkapan: dataPerlengkapanMaster, status } = useSelector(
    (state) => state.datamaster
  );
  const [masterPerlengkapan, setMasterPerlengkapan] = useState([]);
  const [paketPerlengkapan, setPaketPerlengkapan] = useState([]);
  const [loadingAction, setLoadingAction] = useState(false);
  const [listStarting, setListStarting] = useState([]);
  const [listCabang, setListCabang] = useState([]);
  const [choiceFilter, setChoiceFilter] = useState("starting");
  const [input, setInput] = useState("");
  const search = useDebounce(input, 1000);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: listPerlengkapan.total_data,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const [open, setOpen] = useState(false);
  const handleClosePopup = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const cabang = listCabang
      ?.filter((val) => val.checked === true)
      ?.map((nil) => nil.id_cabang);
    const starting = listStarting
      ?.filter((val) => val.checked === true)
      ?.map((nil) => nil.id);
    dispatch(
      handleGetPerlengkapanJamaah(
        id,
        {
          equipment: "equipment",
        },
        {
          keyword: search,
          branch: cabang,
          starting: starting,
          page_number: currentpage,
          per_page: size,
          sort_column: "id",
          sort_order: "DESC",
        },
        true
        ,
        (message) => {
          console.log("TOTAL DATA", message?.data?.total_data);
        }
      )
    );
  }, [search, currentpage, size]);

  useEffect(() => {
    dispatch(
      handleGetAllPerlengkapan(
        id,
        (message) => {
          setPaketPerlengkapan(message?.data?.data);
        },
        (error) => {
          dispatch(handleErrorBE(error));
        }
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      getAllDataPerlengkapan({
        per_page: 10000000000,
        page_number: 1,
        sort_column: "",
        sort_order: "Desc",
        keyword: "",
      })
    );
    dispatch(
      getListStartingPaketPerlengkapan(
        id,
        (message) => {
          let _resultMap = message?.data?.data?.map((val) => ({
            ...val,
            checked: false,
          }));


          setListStarting(_resultMap);
        },
        (error) => {
          dispatch(handleErrorBE(error));
        }
      )
    );
    dispatch(
      getListCabangPaketPerlengkapan(
        {
          per_page: 10,
          page_number: 1,
          sort_column: "",
          sort_order: "Desc",
          keyword: "",
        },
        (message) => {
          let _resultMap = message?.data?.data?.map((val) => ({
            ...val,
            checked: false,
          }));
          setListCabang(_resultMap);
        },
        (error) => {
          dispatch(handleErrorBE(error));
        }
      )
    );
  }, []);

  useEffect(() => {
    let temp = paketPerlengkapan?.map((val) => val.equipment_id);

    const _resultMap = dataPerlengkapanMaster?.data?.map((item) => {
      return {
        ...item,
        checked: temp?.includes(item?.id_equipment),
      };
    }); 

    setMasterPerlengkapan(_resultMap);
  }, [dataPerlengkapanMaster]);

  const handleInserPerlengkapan = () => {
    setLoadingAction(true);
    const payload = {
      paket_id: id,
      perlengkapan: masterPerlengkapan
        ?.filter((item) => item.checked === true)
        ?.map((val) => ({ equipment_id: val?.id_equipment })),
    };

    dispatch(
      saveDataPerlengkapan(
        payload,
        (message) => {
          dispatch(
            getAllDataPerlengkapan({
              per_page: size,
              page_number: 1,
              sort_column: "",
              sort_order: "Desc",
              keyword: "",
            })
          );
          dispatch(
            handleGetAllPerlengkapan(
              id,
              (message) => {
                setPaketPerlengkapan(message?.data?.data);
                setLoadingAction(false);

                setOpen(false);
                dispatch(
                  handleGetPerlengkapanJamaah(
                    id,
                    {
                      equipment: "equipment",
                    },
                    {
                      keyword: search,
                      branch: [],
                      starting: [],
                      page_number: currentpage,
                      per_page: size,
                      sort_column: "id",
                      sort_order: "DESC",
                    },
                    true
                  )
                );

                dispatch(handleSuccess(message));
              },
              (error) => {
                dispatch(handleErrorBE(error));
              }
            )
          );
        },
        (error) => {
          setLoadingAction(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleUpdated = async (data, action = (message) => {}) => {
    setLoadingAction(true);
    dispatch(
      handleUpdatedPerlengkapan(
        id,
        data,
        (message) => {
          setLoadingAction(false);
          action();
          dispatch(
            handleGetPerlengkapanJamaah(
              id,
              {
                equipment: "equipment",
              },
              {
                keyword: search,
                branch: [],
                starting: [],
                page_number: currentpage,
                per_page: size,
                sort_column: "id",
                sort_order: "DESC",
              },
              true,
            )
          );
        },
        (error) => {
          setLoadingAction(false);

          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const columns = [
    {
      title: "Foto",
      dataIndex: "foto",
      render: (image, data) => {
        return (
          <Avatar value={data?.jamaah} />
          // <img
          //   src={data?.jamaah?.profile_photo}
          //   alt="foto"
          //   className="shadow rounded-full w-[32px] h-[32px] max-w-full  align-middle border-none"
          // />
        );
      },
    },
    {
      title: "Nama",
      dataIndex: "name",
      width: "300px",
      render: (text, data) => (
        <span>
          {toCapital(data?.jamaah?.title)}. {data?.jamaah?.name?.toUpperCase()}
        </span>
      ),
    },
    {
      title: "Starting",
      dataIndex: "starting",
      render: (val, data) => (
        <Badge
          label={data?.starting?.name}
          backgroundColor={data?.starting?.bg_color}
          color={data?.starting?.text_color}
        />
      ),
    },
    {
      title: "Cabang",
      dataIndex: "cabang",
      render: (val, data) => (
        <Badge
          label={data?.branch?.name?.toUpperCase()}
          backgroundColor={data?.branch?.bg_color}
          color={data?.branch?.text_color}
        />
      ),
    },
    {
      title: "Perlengkapan",
      dataIndex: "perlengkapan",
      className: "whitespace-nowrap",
      render: (value, data) => (
        <>
          <div className="flex flex-row">
            <PerlengkapanContent
              value={data?.equipment}
              id_kamar_mapping={data?.id_kamar_mapping}
              handleUpdated={handleUpdated}
              loadingAction={loadingAction}
            />
          </div>
        </>
      ),
    },
  ];

  const handleChangeStarting = (index) => {
    let _temp = [...listStarting];
    _temp[index]["checked"] = !_temp[index]["checked"];

    setListStarting(_temp);
  };

  const handleChangeCabang = (index) => {
    let _temp = [...listCabang];
    _temp[index]["checked"] = !_temp[index]["checked"];

    setListCabang(_temp);
  };

  const applyFilter = () => {
    setLoadingAction(true);
    const cabang = listCabang
      ?.filter((val) => val.checked === true)
      ?.map((nil) => nil.id_cabang);
    const starting = listStarting
      ?.filter((val) => val.checked === true)
      ?.map((nil) => nil.id);
    dispatch(
      handleGetPerlengkapanJamaah(
        id,
        {
          equipment: "equipment",
        },
        {
          keyword: search,
          branch: cabang,
          starting: starting,
          page_number: currentpage,
          per_page: size,
          sort_column: "id",
          sort_order: "DESC",
        },
        false,
        (message) => {
          setLoadingAction(false);
          dispatch(setDataPerlengkapanV2(message?.data));
        },
        (error) => {
          setLoadingAction(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  return (
    <>
      <div className="relative">
        <div className="w-full flex flex-row items-center justify-between p-[16px]">
          <div className="flex flex-row gap-[16px] items-center">
            <div>
              <Inputs
                id="test"
                placeholder="Search"
                customClass="!w-[283px]"
                iconPosition="left"
                icon={IconSearch}
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </div>
            {/* <div>
              <img src={IconActivity} alt="activity" width={16} height={16} />
            </div>
            <div>
              <img src={IconSquare} alt="square" width={16} height={16} />
            </div> */}
          </div>
          <div className="flex flex-row gap-[16px] pt-[9px]">
            <div className="border rounded-lg !w-[112px] !h-[36px] flex flex-row items-center gap-[8px] justify-center relative">
              <img src={Filter} alt="filter" className="w-4 h-4 ml-[-21px]" />
              <h1 className="text-sm text-start mr-[2px]">Filter</h1>
              <Dropdowns
                label="Filter"
                placement="bottomRight"
                customClass="border-0 absolute top-1 right-[8px]"
                dropdownArrow={true}
              >
                <div className="dropdown-content !p-[16px] mt-[16px] !w-[224px] !rounded-2xl">
                  <div className="flex flex-col  w-full !rounded-2xl">
                    <div className="mb-[16px]">
                      <Select
                        placeholder="Pilih Filter"
                        className="!w-full"
                        size="large"
                        value={choiceFilter}
                        onChange={(value) => {
                          setChoiceFilter(value);
                        }}
                        options={[
                          { label: "Berdasarkan cabang", value: "cabang" },
                          { label: "Berdasarkan starting", value: "starting" },
                        ]}
                      />
                    </div>
                    <Scroll height="140px">
                      <div className=" flex flex-col gap-[16px] justify-start items-start w-full ">
                        <>
                          {choiceFilter === "starting" ? (
                            <>
                              {listStarting?.map((val, index) => (
                                <div className="flex flex-row justify-start items-center">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => handleChangeStarting(index)}
                                  >
                                    <Badge
                                      label={val?.name}
                                      color={val?.text_color}
                                      backgroundColor={val?.bg_color}
                                    />
                                  </div>
                                  {val.checked && (
                                    <img
                                      src={IconCeklist}
                                      width={20}
                                      height={20}
                                      className="ml-[8px]"
                                    />
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            <>
                              {listCabang?.map((val, i) => (
                                <div className="flex flex-row justify-start items-center">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => handleChangeCabang(i)}
                                  >
                                    <Badge
                                      label={val?.name}
                                      color={val?.color_text}
                                      backgroundColor={val?.color}
                                    />
                                  </div>
                                  {val.checked && (
                                    <img
                                      src={IconCeklist}
                                      width={20}
                                      height={20}
                                      className="ml-[8px]"
                                    />
                                  )}
                                </div>
                              ))}
                            </>
                          )}
                        </>
                      </div>
                    </Scroll>

                    <div className="absolute  bottom-0 left-0  right-0 p-[16px] !bg-[#fff] !rounded-2xl">
                      <Buttons
                        text="Terapkan"
                        customClass="btn-primary btn-sm"
                        onClick={() => applyFilter()}
                        loading={loadingAction}
                      />
                    </div>
                  </div>
                </div>
              </Dropdowns>
            </div>
            <Popup
              open={open}
              handleClose={handleClosePopup}
              title="Tambah Perlengkapan"
              containerClass=""
              width="1103px"
            >
              <PerlengkapanPopup
                setOpen={setOpen}
                masterPerlengkapan={masterPerlengkapan}
                setMasterPerlengkapan={setMasterPerlengkapan}
                handleInserPerlengkapan={handleInserPerlengkapan}
                loadingAction={loadingAction}
              />
            </Popup>

            <div>
              {permissions?.includes('create_equipment') && (
                <>
                  <Buttons
                    customClass="btn-secondary btn-sm px-[12px] py-[8px]"
                    text="Tambah Data"
                    onClick={() => setOpen(true)}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {listPerlengkapan?.data?.length > 0 ? (
          <MoslemeTable
            loading={isLoading}
            columns={columns}
            data={listPerlengkapan?.data ?? []}
            withSelection
            selectionType="checkbox"
            // customClass="overflow-y-scroll h-[450px]"
            pagination={pagination}
            customPaginationClass="z-1 py-4 px-8"
            withPagination={true}
            onPageChange={(page) => setCurrentpage(page)}
            onSizeChange={(currentpage, pageSize) =>
              onSizeChange(currentpage, pageSize)
            }
            rowKey={(record) => record?.id_kamar_mapping}
          />
        ) : (
          <main className="flex flex-col items-center gap-y-4 justify-center h-[69vh]">
            <img alt="hourglass" src={IcJamPasir} />
            <div>Belum ada Perlengkapan di paket ini</div>
          </main>
        )}
      </div>
    </>
  );
};

export default Perlengkapan;
