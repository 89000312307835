// privacy Actions
// --------------------------------------------------------

import { privacyApi } from "utils/api/privacy";
import { handleError, handleErrorBE, handleSuccess } from "../errorGeneral";
import { set_after_validation } from "../general";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "privacy/SET_LOADING";
export const CLEAR_ERROR = "privacy/CLEAR_ERROR";
export const SET_ERROR = "privacy/SET_ERROR";
export const INIT_DATA = "privacy/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "privacy/SET_DOUBLE_SUBMIT";
export const SET_ALL_PRIVACY = "privacy/SET_ALL_PRIVACY";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setAllData = (payload) => ({
  type: SET_ALL_PRIVACY,
  payload,
});

const getAppPrivacy = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await privacyApi.list(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setAllData(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

const createOrUpdateAppPrivacy = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await privacyApi.post(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      set_after_validation(res.data);
      dispatch(handleSuccess(null, { message: "Berhasil mengupdate privacy", code: 200}));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

const privacyAction = {
  data: getAppPrivacy,
  createOrUpdate: createOrUpdateAppPrivacy,
};

export default privacyAction;
