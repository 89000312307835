import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ArticleCategoryAction from "stores/actions/apps/artikel/category";

import Buttons from "components/buttons";

import { Edit, Trash } from "iconsax-react";
import Alert from "components/popup/alert";
import { handleSuccess } from "stores/actions/errorGeneral";

const AppsKelolaKategori = ({
  setOpenPopupKelolaKategori,
  setOpenPopupAddKategori,
  setSuccessEditOrDeleteArtikel,
  listCategory,
}) => {
  const dispatch = useDispatch();
  const [dataListArticle, setDataListArticle] = useState([]);
  const [deleting, setDeleting] = useState(false);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [categorySelectedID, setCategorySelectedID] = useState(null);


  const confirmDeleteCategory = async () => {
    setDeleting(true);
    const status = await dispatch(
      ArticleCategoryAction.deleteData(categorySelectedID)
    );

    setDeleting(false);
    
    if (status === 200) {
      const oldList = [...dataListArticle];

      setDataListArticle(
        oldList.filter(
          (item) =>
            item.id_kategori_artikel !== categorySelectedID
        )
      );

      setOpenDeleteCategory(false);

      dispatch(handleSuccess(null, { message: "Berhasil menghapus kategori", code: 200 }));

      return setSuccessEditOrDeleteArtikel(true);
    }

  }

  useEffect(() => {
    setDataListArticle(listCategory);
  }, [listCategory]);

  const getContent = () => {
    return dataListArticle?.map((val, idx) => {
      return (
        <div
          key={idx}
          className="bg-white soft-shadow rounded-xl p-4 border border-[#ffffff] hover:border hover:border-[#F90601] relative list-kelola-artikel"
        >
          <div className="text-[16px] font-semibold">{val?.nama_kategori}</div>
          <div className="absolute right-6 top-0 bottom-0 hidden gap-8 list-kelola-artikel-action">
           {permissions?.includes('update_apps') && (
               <Edit
                className="cursor-pointer"
                onClick={() => {
                  setOpenPopupKelolaKategori(false);
                  setOpenPopupAddKategori({
                    show: true,
                    edit: {
                      id: val.id_kategori_artikel,
                      nama_kategori: val.nama_kategori,
                    },
                  });
                }}
              />
            )}
            {permissions?.includes('delete_apps') && (
              <>
                <Trash
                  className="cursor-pointer"
                  onClick={() => {
                    setOpenDeleteCategory(true);
                    setCategorySelectedID(val.id_kategori_artikel);
                  }}
                />
              </>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Alert        open={openDeleteCategory}
        subtitle="Apakah anda yakin untuk menghapus kategori ini?"
        handleClose={() => setOpenDeleteCategory(false)}
        handleContinue={confirmDeleteCategory}
        loading={deleting}
      />
      <div className="bg-white border-[1px] border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <div className="flex flex-col gap-4">{getContent()}</div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end gap-2 p-4">
          <Buttons
            text="Kembali"
            customClass="btn-outline btn-medium !w-fit"
            onClick={() => setOpenPopupKelolaKategori(false)}
          />
          {permissions?.includes('create_apps') && (
              <Buttons
              text="Tambah Kategori"
              customClass="btn-primary btn-medium !w-fit"
              onClick={() => {
                setOpenPopupKelolaKategori(false);
                setOpenPopupAddKategori({
                  show: true,
                  edit: null,
                });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

AppsKelolaKategori.propTypes = {
  setOpenPopupKelolaKategori: PropType.func,
  setOpenPopupAddKategori: PropType.func,
  setSuccessEditOrDeleteArtikel: PropType.func,
  listCategory: PropType.array,
};

AppsKelolaKategori.defaultProps = {
  setOpenPopupKelolaKategori: () => {},
  setOpenPopupAddKategori: () => {},
  setSuccessEditOrDeleteArtikel: () => {},
};

export default React.memo(AppsKelolaKategori);
