import VideoPlayer from "./";

const VideoPlayerDocs = () => {
    return (
        <div>
            <h2>Video Player</h2>
            <VideoPlayer videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc" />
        </div>
    );
};

export default VideoPlayerDocs;
