import React, { useState } from "react";

import Drawers from ".";
import Buttons from "../buttons";

import IconCloseDrawer from "../../assets/icons/Close-Drawer.svg";

const DrawersDoc = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div>
      <Buttons
        text="Open"
        containerClass="w-[100px]"
        customClass="btn-primary"
        onClick={() => setOpenDrawer(true)}
      />
      <Drawers open={openDrawer}>
        <div>
          <img
            className="cursor-pointer"
            src={IconCloseDrawer}
            alt="close"
            onClick={() => setOpenDrawer(false)}
          />
        </div>
        <div className="mt-4">
          <div>Test</div>
          <div>Test</div>
          <div>Test</div>
        </div>
      </Drawers>
    </div>
  );
};

export default DrawersDoc;
