import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player/youtube";
import PlayIcon from "../../assets/icons/play-white-icon.svg";
import PauseIcon from "../../assets/icons/pause-white-icon.svg";

import VideoPlayerStyle from "./VideoPlayer.style";

const VideoPlayer = ({ videoUrl, width, height }) => {
  const [videoReady, setVideoReady] = useState(false);
  const [videoDuration, setVideoDuration] = useState();
  const [currentVideoDuration, setCurrentVideoDuration] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);

  const refReactPlayer = useRef(0);

  const formatDuration = (t) => {
    let s = Math.floor(t);
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
  };

  const onProgressHandler = (t) => {
    if (!seeking) {
      setPlayed(t.played);
    }
    setCurrentVideoDuration(t.playedSeconds);
  };

  const handleSeekMouseDown = (e) => {
    setSeeking(true);
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    refReactPlayer.current.seekTo(parseFloat(e.target.value));
  };

  return (
    <VideoPlayerStyle>
      <ReactPlayer
        ref={refReactPlayer}
        url={videoUrl}
        width={width}
        height={height}
        controls={false}
        onReady={() => setVideoReady(true)}
        onDuration={(t) => setVideoDuration(t)}
        onProgress={onProgressHandler}
        playing={playing}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        config={{
          youtube: {
            playerVars: {
              autohide: 1,
              modestbranding: 1,
              showinfo: 0,
            },
          },
        }}
      />
      <div className="navigation-wrapper">
        <button onClick={() => setPlaying(!playing)} className="cursor-pointer">
          <img src={playing ? PauseIcon : PlayIcon} alt="play icon" />
        </button>
        <div className="duration-wrapper">
          {videoReady && formatDuration(currentVideoDuration)}/
          {videoReady && formatDuration(videoDuration)}
        </div>
        <div className="progress-wrapper ml-3">
          <input
            className="w-full"
            type="range"
            min={0}
            max={0.999999}
            step="any"
            value={played}
            onMouseDown={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
          />
        </div>
      </div>
    </VideoPlayerStyle>
  );
};

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

VideoPlayer.defaultProps = {
  videoUrl: "",
  width: "640px",
  height: "360px",
};

export default VideoPlayer;
