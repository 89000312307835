import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handleGetBusById, handleSaveOrEditBus } from "stores/actions/paket/generalInfo";

import { handleRemoveBusAPI } from "utils/api/paket/bus";

import Buttons from "components/buttons";
import AntdSelect from "components/form/antdSelect";

import IconTrash from "assets/icons/trash.svg";
import Inputs from "components/form/inputs";
import { handleError, handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import Spinner from "components/spinner";



const JumlahSeatBus = ({ data, closePopup, setSuccessEdit }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [listBus, setListBus] = useState([]);
  const [isDeletingBus, setIsDeletingBus] = useState(false);
  const [deletingBusIndex, setDeletingBusIndex] = useState(null);

  const [input, setInput] = useState([{}]);
  const [validateInput, setValidateInput] = useState(false);
  const [isLoading, setIsloading] = useState(false)

  const data_state = [
    {
      id_bus: null,
      paket_id: params.id,
      nama_bus: "",
      jumlah_seat: "",
    },
  ];

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues: {
      forms: [],
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values, actions) => {
      setIsloading(true)
      dispatch(
        handleSaveOrEditBus(
          {
            paket_id: parseInt(params.id),
            bus: values.forms,
          },
          (message) => {
            dispatch(handleSuccess(null, {message: "Berhasil memperbarui seat", code: 200}));
            setIsloading(false)
            setSuccessEdit(true);
            // closePopup(false);
            // 
            // change values.froms with message
            // setFieldValue("forms", message);
          },
          (err) => {
            setIsloading(false)
          }
        )
      );
    },
  });

  const handleRemove = async (idx) => {
    setDeletingBusIndex(idx)
    let _temp = [...values.forms];
    
    if(_temp[idx]?.id_bus) {
      setIsDeletingBus(true);
      const res = await handleRemoveBusAPI(params.id, data[idx].id_bus);
      setIsDeletingBus(false);
      if (res.status === 200) {
        _temp.splice(idx, 1);
        setFieldValue("forms", _temp);
        dispatch(handleSuccess(null, { message: "Berhasil menghapus bus" }));
        dispatch(handleGetBusById(params?.id));
      } else {
        dispatch(handleError(res.data));
      }
    } else {
      _temp.splice(idx, 1);
      setFieldValue("forms", _temp);
    }

  }

  useEffect(() => {
    console.log(data)
    if (data.length > 0) {
      setFieldValue("forms", data);
    } else {
      setFieldValue("forms", data_state);
    }
  }, [data, data?.length]);


  return (
    <>
      <div className="flex flex-col items-center gap-[20px] p-4">
        {values.forms.map((val, idx) => (
          <div
            className="flex flex-row items-center justify-between gap-[16px] w-[100%]"
            key={idx}
          >
            <div className="flex-grow">
              <Inputs
                label={`Bus ${idx + 1}`}
                placeholder="Masukkan bus"
                error={touched.forms && errors.forms && errors.forms}
                customClass={`flex-grow ${
                  touched.forms && errors.forms && "input-error"
                }`}
                value={val?.nama_bus}
                onChange={(e) => {
                  let _temp = [...values.forms];
                  _temp[idx]["nama_bus"] = e.target.value;
                  setFieldValue("forms", _temp);
                }}
              />
            </div>
            <div className="flex flex-row items-center justify-between w-[287px]">
              <Inputs
                label="Jumlah Seat"
                placeholder="Masukkan jumlah seat"
                error={touched.forms && errors.forms && errors.forms}
                customClass={`${
                  values.forms.length > 1 && idx > 0
                    ? "!w-[231px]"
                    : "!w-[287px]"
                } ${touched.forms && errors.forms && "input-error"}`}
                value={val?.jumlah_seat}
                onChange={(e) => {
                  let list = [...values.forms];
                  list[idx]["jumlah_seat"] = e.target.value;
                  setFieldValue("forms", list);
                }}
              />
              {values.forms.length > 1 && idx > 0 && (
                <div className="justify-center items-center px-2">
                  {isDeletingBus && deletingBusIndex === idx ? (
                    <>
                      <Spinner 
                        customClass="relative top-4"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={IconTrash}
                        className="cursor-pointer h-[24px] w-[24px] relative top-4"
                        onClick={() => {
                          handleRemove(idx);
                        }}
                        alt="trash"
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
        <div className="w-[100%]">
          <Buttons
            text="Tambah Bus"
            customClass="btn-outline !w-full btn-medium"
            onClick={() => {
              let _temp = [
                ...values.forms,
                {
                  id_bus: null,
                  paket_id: params.id,
                  nama_bus: "",
                  jumlah_seat: "",
                },
              ];
              setFieldValue("forms", _temp);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col justify-end border-t py-[16px] px-[20px] mt-auto">
        <Buttons
          text="Simpan"
          loading={isLoading}
          customClass={`btn-primary btn-medium !w-[186px] float-right ${
            isLoading && "loading"
          }`}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

JumlahSeatBus.propTypes = {
  data: PropType.array,
  closePopup: PropType.func,
  setSuccessEdit: PropType.func,
};

export default React.memo(JumlahSeatBus);
