import Badge from "components/badge";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import uuid from "react-uuid";

import { getMasterId } from "stores/actions/datamaster";
import { setDataUpload } from "stores/actions/uploadFile";

export const columns = (dispatch, dataUpload, permissions) => [
  {
    title: "Logo",
    dataIndex: "bank_logo",
    render: (bank_logo) => (
      <img
        className="rounded-full w-10 h-10 object-cover"
        alt="logo maskapai"
        src={bank_logo}
      />
    ),
  },
  {
    title: "Nama Bank",
    dataIndex: "bank_name",
    render: (bank_name) => <div className="text-sm">{bank_name}</div>,
  },
  {
    title: "Nomor Rekening",
    dataIndex: "account_number",
    render: (account_number) => <div className="text-sm">{account_number}</div>,
  },
  {
    title: "Pemilik rekening",
    dataIndex: "account_owner",
    render: (account_owner) => <div className="text-sm">{account_owner}</div>,
  },
  {
    title: "Mata Uang",
    dataIndex: "currency",
    render: (currency) => <div className="text-sm">{currency}</div>,
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    render: (description) => <div className="text-sm">{description}</div>,
  },

  {
    title: "Jenis Rekening",
    dataIndex: "description",

    render: (s, data) => (
      <div>
        <div className={`flex gap-x-4 items-center`}>
          {data?.is_haji === "1" && (
            <Badge label="Haji" color="#FE9A00" backgroundColor="#ffdeab" />
          )}
          {data?.is_umroh === "1" && (
            <Badge label="Umroh" color="#564592" backgroundColor="#DDDAE9" />
          )}
          {data?.is_tour === "1" && (
            <Badge label="Tour" color="#F20089" backgroundColor="#F9BCC2" />
          )}
          {data?.is_jasa_badal === "1" && (
            <Badge
              label="Jasa Badal"
              color="#333D29"
              backgroundColor="#CDFFCB"
            />
          )}
          {data?.is_tabungan === "1" && (
            <Badge label="Tabungan" color="#C08F01" backgroundColor="#F2EACC" />
          )}
        </div>
        <div className="action">
          <div className="action-content">
            {permissions?.includes("delete_master-data") && (
              <div className="action-content-item">
                <Trash
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(getMasterId({ modal: true, data: data }))
                  }
                  size="16"
                  color="#141414"
                />
              </div>
            )}
            {permissions?.includes("create_master-data") && (
              <div className="action-content-item">
                <DocumentCopy
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(getMasterId({ popup: true, data: data }))
                  }
                  size="16"
                  color="#141414"
                />
              </div>
            )}
            {permissions?.includes("update_master-data") && (
              <div className="action-content-item">
                <Edit
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(getMasterId({ modalEdit: true, data: data }));
                    dispatch(
                      setDataUpload([
                        ...dataUpload,
                        {
                          name: "bank_logo",
                          path: data?.bank_logo,
                          fileName: data?.name,
                          id: uuid(),
                        },
                      ])
                    );
                  }}
                  size="16"
                  color="#141414"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ),
  },
];
