import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppsSlideAction from "stores/actions/apps/slide";

import Popup from "components/popup";
import Buttons from "components/buttons";
import Tab from "components/tab";
import SlideWeb from "./components/Web";
import AddSlide from "./components/AddSlide";

const AppsSlide = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ slide }) => slide);
  const [openPopup, setOpenPopup] = useState({
    show: false,
    edit: null,
  });
  const [slideType, setSlideType] = useState("web");

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const handleGetSlide = () => {
    const payload = {
      "keyword": "",
      "jenis_slide": slideType,
      "per_page": 1000,
      "page_number": 1,
    }
    dispatch(AppsSlideAction.fetchData(payload));
  };

  useEffect(() => {
    handleGetSlide()
  }, [dispatch, store.data.length, store.successEdit]);

  useEffect(() => {
    handleGetSlide()
  }, [slideType]);

  return (
    <>
      <Popup
        open={openPopup.show}
        handleClose={() =>
          setOpenPopup({
            show: false,
            edit: null,
          })
        }
        title={`${openPopup.edit ? "Edit Slide" : "Tambah Slide"}`}
        width="982px"
        children={
          <AddSlide
            dispatch={dispatch}
            setOpenPopup={setOpenPopup}
            handleSuccess={() => handleGetSlide()}
            edit={openPopup?.edit}
          />
        }
      />
      <div className="bg-white soft-shadow rounded-[20px]">
        <div className="flex flex-row justify-between p-4">
          <div className="text-[20px] font-semibold">Slide</div>
          {permissions?.includes('create_apps') && (
            <Buttons
              text="Tambah"
              customClass="btn-primary btn-small"
              onClick={() =>
                setOpenPopup({
                  show: true,
                  edit: null,
                })
              }
            />
          )}
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <Tab
            defaultActiveKey="1"
            containerClass="mb-4"
            onChange={(e) => {
              setSlideType(e)
            }}
            items={[
              {
                label: "Web",
                key: "web",
                children: (
                  <SlideWeb
                    store={store}
                    dispatch={dispatch}
                    setOpenPopup={setOpenPopup}
                  />
                ),
              },
              {
                label: "Mobile",
                key: "mobile",
                children: (
                  <SlideWeb
                    store={store}
                    dispatch={dispatch}
                    setOpenPopup={setOpenPopup}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(AppsSlide);
