import Maskapai from "../assets/icons/Maskapai.svg";
import MaskapaiActive from "../assets/icons/Maskapai1.svg";
import AppWeb from "../assets/icons/app-web.svg";
import Notification from "../assets/icons/notification.svg";

import AdminIC from '../assets/icons/admin.svg';
import AdminActiveIC from '../assets/icons/admin_active.svg';
import AgenIC from '../assets/icons/agen.svg';
import AgenActiveIC from '../assets/icons/agen_active.svg';
import JamaahIC from '../assets/icons/jamaah-2.svg';
import JamaahActiveIC from '../assets/icons/jamaah_active.svg';

export const memberMenuList = [
  {
    title: "Admin",
    key: "admin",
    icon: AdminIC,
    iconActive: AdminActiveIC,
    order: 0,
  },
  {
    title: "Agen",
    key: "agen",
    icon: AgenIC,
    iconActive: AgenActiveIC,
    order: 1,
  },
  {
    title: "Jamaah",
    key: "jamaah",
    icon: JamaahIC,
    iconActive: JamaahActiveIC,
    order: 2,
  },
];
