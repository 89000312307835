import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Buttons from "components/buttons";
import Badge from "components/badge";

import IconCSV from "assets/icons/CSV.svg";
import IconSingle from "assets/icons/Single.svg";
import IconDouble from "assets/icons/Double.svg";
import IconTripple from "assets/icons/Triple.svg";
import IconQuad from "assets/icons/Quad.svg";
import IconCabangPusat from "assets/icons/Cabang-Pusat.svg";

import { onHandleGetRomlistReportAPI, exportLaporanRoomlistService } from "utils/api/paket/laporan";
import { handleError } from "stores/actions/errorGeneral";
import { formattorbase64, hexToRGB } from "utils/helpers";

const PaketLaporanRoomlist = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [report, setReport] = useState({});

  const handleGetEquipmentReport = async () => {
    const payload = {
        "keyword" : null,
        "sort_column" : "is_pusat",
        "sort_order" : "desc",
        "per_page" : 1000,
        "page_number" : 1,
    }
    const response = await onHandleGetRomlistReportAPI(params.id, payload);
    
    if (response.status === 200) {
      setReport(response.data.data);
    } else 
    {
      dispatch(handleError(response.data));
    }

  };

  const handleDownloadCSV = async () => {
    const response = await exportLaporanRoomlistService(params.id);

    if (response.status === 200) {
      const { base64, type, file_name } = response.data.data;
      const insertPrefix = formattorbase64(type, base64);
      var a = document.createElement("a"); //Create <a>
      a.href = `${insertPrefix}`; //Image Base64 Goes here
      a.download = `${file_name}`; //File name Here
      a.click();
    } else {
      dispatch(handleError(response.data));
    }
  };

  useEffect(() => {
    handleGetEquipmentReport();
  }, [])

  return (
    <div className="bg-white border-[1px] border-[#4048521a] rounded-bl-lg rounded-br-lg">
      <div className="p-4">
        <div className="font-semibold text-[20px]">Kebutuhan Roomlist</div>
      </div>
      <div className="divider"></div>
      <div className="p-4">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-col gap-4 bg-white rounded-lg shadow-soft p-4">
            <div className="font-semibold text-[16px]">
              Total Semua Kebutuhan Roomlist
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex flex-row items-center gap-2">
                <img src={IconQuad} alt="single" />
                <div className="font-semibold">Quad</div>
                <div className="txt-green text-[16px] font-semibold">{report?.data_summary?.quad}</div>
              </div>
              <div className="flex flex-row items-center gap-2">
                <img src={IconTripple} alt="single" />
                <div className="font-semibold">Triple</div>
                <div className="txt-green text-[16px] font-semibold">{report?.data_summary?.tripple}</div>
              </div>
              <div className="flex flex-row items-center gap-2">
                <img src={IconDouble} alt="single" />
                <div className="font-semibold">Double</div>
                <div className="txt-green text-[16px] font-semibold">{report?.data_summary?.double}</div>
              </div>
              <div className="flex flex-row items-center gap-2">
                <img src={IconSingle} alt="single" />
                <div className="font-semibold">Single</div>
                <div className="txt-green text-[16px] font-semibold">{report?.data_summary?.single}</div>
              </div>
              
            </div>
          </div>
          <Buttons
            text="Export CSV"
            customClass="btn-outline btn-small"
            icon={IconCSV}
            onClick={handleDownloadCSV}
          />
        </div>

        <div className="mt-5">
          <div className="grid grid-cols-4 gap-4">
            {report?.branches?.map((branch, index) => (
              <>
                <div className="shadow-soft rounded-lg bg-white">
                  <div 
                    style={{ backgroundColor: `rgba(${hexToRGB(branch.branch_bg_color).r},${hexToRGB(branch.branch_bg_color).g}, ${hexToRGB(branch.branch_bg_color).b}, 0.5)` }} className="px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                    {!!branch.main_branch && (
                      <img src={IconCabangPusat} alt="cabang" />
                    )}
                    <Badge 
                      label={branch.branch_name?.toUpperCase()} 
                      color={branch.branch_text_color} 
                      backgroundColor={branch.branch_bg_color} 
                      />
                  </div>
                  <div className="divider"></div>
                  <div className="p-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-row items-center gap-2">
                        <img src={IconQuad} alt="single" />
                        <div className="font-semibold">Quad</div>
                        <div className="txt-green text-[16px] font-semibold">
                          {branch.room_lists?.quad}
                        </div>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <img src={IconTripple} alt="single" />
                        <div className="font-semibold">Triple</div>
                        <div className="txt-green text-[16px] font-semibold">
                          {branch.room_lists?.tripple}
                        </div>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <img src={IconDouble} alt="single" />
                        <div className="font-semibold">Double</div>
                        <div className="txt-green text-[16px] font-semibold">
                          {branch.room_lists?.double}
                        </div>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <img src={IconSingle} alt="single" />
                        <div className="font-semibold">Single</div>
                        <div className="txt-green text-[16px] font-semibold">
                          {branch.room_lists?.single}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PaketLaporanRoomlist);
