/* eslint-disable import/no-anonymous-default-export */
// musim Reducers
// --------------------------------------------------------

import {
  SET_LOADING,
  CLEAR_ERROR,
  SET_ERROR,
  INIT_DATA,
  SET_DOUBLE_SUBMIT,
  SET_DATA_MUSIM,
  SET_FAILED_MUSIM,
  SET_RESULT_MUSIM,
} from "../../../actions/datamaster/musim";

const initialState = {
  isLoading: false,
  isDoubleSubmit: false,
  isSubmitting: false,
  error: {},
  all_musim: {},
  resultMusim: {},
  failedMusim: {},
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_DOUBLE_SUBMIT:
      return {
        ...state,
        isDoubleSubmit: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case INIT_DATA:
      return {
        ...initialState,
      };
    case SET_DATA_MUSIM:
      return {
        ...state,
        all_musim: payload,
      };
    case SET_RESULT_MUSIM:
      return {
        ...state,
        resultMusim: payload,
      };
    case SET_FAILED_MUSIM:
      return {
        ...state,
        failedMusim: payload,
      };
    default:
      return state;
  }
};
