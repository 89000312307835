import {
    FETCH_ORDER_DETAIL,
    SET_ISLOADING,
    FETCH_DATA_JAMAAH,
    FETCH_DATA_MANIFEST
} from "../../actions/booking";

const initialState = {
    isLoading: false,
    bookingDetail: {},
    bookingDataJamaah: [],
    bookingDataManifest: []
};
  
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_ISLOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case FETCH_ORDER_DETAIL:
            return {
                ...state,
                bookingDetail: action.payload
            };
        case FETCH_DATA_JAMAAH:
            return {
                ...state,
                bookingDataJamaah: action.payload
            }
        case FETCH_DATA_MANIFEST:
            return {
                ...state,
                bookingDataManifest: action.payload
            }
        default:
            return state;
    }
}