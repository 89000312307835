export const SET_LOADING = "apps/mitra/SET_LOADING";
export const CLEAR_ERROR = "apps/mitra/CLEAR_ERROR";
export const SET_ERROR = "apps/mitra/SET_ERROR";
export const START_FETCHING_MITRA = "apps/mitra/START_FETCHING_MITRA";
export const ERROR_FETCHING_MITRA = "apps/mitra/ERROR_FETCHING_MITRA";
export const SUCCESS_FETCHING_MITRA = "apps/mitra/SUCCESS_FETCHING_MITRA";
export const SET_PAGE_MITRA = "apps/mitra/SET_PAGE_MITRA";
export const SET_SIZE_MITRA = "apps/mitra/SET_SIZE_MITRA";
export const SET_TOTAL_MITRA = "apps/mitra/SET_TOTAL_MITRA";
export const SET_KEYWORD_MITRA = "apps/mitra/SET_KEYWORD_MITRA";
export const SET_ADD_OR_EDIT_MITRA = "apps/mitra/SET_ADD_OR_EDIT_MITRA";
export const SET_DELETE_MITRA = "apps/mitra/SET_DELETE_MITRA";
