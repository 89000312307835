// pusatBantuanKontak Actions
// --------------------------------------------------------
import {
    deleteKontakService,
    getKontakService,
    saveKontakService,
} from "utils/api/pusatBantuan/kontak";
import {
    handleError,
    handleErrorBE,
    handleSuccess,
} from "stores/actions/errorGeneral";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "pusatBantuanKontak/SET_LOADING";
export const CLEAR_ERROR = "pusatBantuanKontak/CLEAR_ERROR";
export const SET_ERROR = "pusatBantuanKontak/SET_ERROR";
export const INIT_DATA = "pusatBantuanKontak/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "pusatBantuanKontak/SET_DOUBLE_SUBMIT";
export const SET_KONTAK_EMAIL_DATA = "pusatBantuanKontak/SET_KONTAK_EMAIL_DATA";
export const SET_KONTAK_WHATSAPP_DATA =
    "pusatBantuanKontak/SET_KONTAK_WHATSAPP_DATA";

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload,
});

export const setDoubleSubmit = (payload) => ({
    type: SET_DOUBLE_SUBMIT,
    payload,
});

export const setKontakEmailData = (payload) => ({
    type: SET_KONTAK_EMAIL_DATA,
    payload,
});

export const setKontakWhatsappData = (payload) => ({
    type: SET_KONTAK_WHATSAPP_DATA,
    payload,
});

export const getKontakAction = (payload) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await getKontakService(payload);
        dispatch(setLoading(false));
        const { status, data } = res;
        if (status == 200) {
            // handleSuccess(data);
            const data_email = data.data.filter(
                (item) => item.jenis == "email"
            );
            const data_whatsapp = data.data.filter(
                (item) => item.jenis == "whatsapp"
            );
            dispatch(setKontakEmailData(data_email));
            dispatch(setKontakWhatsappData(data_whatsapp));
        } else {
            handleErrorBE(res);
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const saveKontakAction = (payload) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await saveKontakService(payload);
        if (res.status == 200) {
            console.log("success");
            handleSuccess(res.data);
            return res.status;
        } else {
            dispatch(handleErrorBE(res));
        }
    } catch (error) {
        dispatch(handleError(error.status));
    } finally {
        dispatch(setLoading(false));
    }
};

export const deleteKontakAction = (payload) => async (dispatch) => {
    console.log(payload);
    try {
        dispatch(setLoading(true));
        const res = await deleteKontakService(payload);
        if (res.status == 200) {
            console.log("success");
            handleSuccess(res.data);
            return res.status;
        } else {
            dispatch(handleErrorBE(res));
        }
    } catch (error) {
        dispatch(handleError(error.status));
    } finally {
        dispatch(setLoading(false));
    }
};
