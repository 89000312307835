import React from "react";
import PropTypes from "prop-types";

import SidebarChildStyle from "./SidebarChild.style";

const SidebarChild = ({
  containerClass,
  customClass,
  menuList,
  showContent,
  handleClick,
}) => {
  return (
    <SidebarChildStyle className={`${containerClass}`}>
      {menuList.map((val, idx) => {
        return (
          <div
            key={idx}
            className={`flex items-center cursor-pointer ${
              idx !== 0 && "mt-5"
            } ${customClass}`}
            onClick={() => handleClick(val.key)}
          >
            <img 
              className="sidebar-child-image" 
              src={showContent === val.key ? (val.iconActive || val.icon) : val.icon}
              alt="icon" />
            <div
              className={`sidebar-child-title ${
                showContent === val.key && "active"
              }`}
            >
              {val.title}
            </div>
          </div>
        );
      })}
    </SidebarChildStyle>
  );
};

SidebarChild.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  menuList: PropTypes.array,
  showContent: PropTypes.string,
  handleClick: PropTypes.func,
};

SidebarChild.defaultProps = {
  handleClick: () => {},
};

export default React.memo(SidebarChild);
