import React from "react";
import { useSelector } from "react-redux";

import "./Perlengkapan.scss";
import Checkbox from "components/checkbox";
import Buttons from "components/buttons";
import Spinner from "components/spinner";

const PerlengkapanJamaah = ({
  equipment_package,
  handleChange,
  handleInsertPerlengkapan,
  handleChangeVariant,
  loadingAction,
}) => {
  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  return (
    <div className=" @apply shadow-[0px_4px_80px_-4px_#0000000A,0px_10px_16px_-6px_#0000000A] rounded-2xl">
      <div className="flex flex-col items-start w-[330px] h-full @apply shadow-[0px_4px_80px_-4px_#0000000A,0px_10px_16px_-6px_#0000000A] rounded-tl-2xl rounded-tr-2xl">
        <div className="w-[330px] bg-[#FAAB36] rounded-tl-2xl rounded-tr-2xl flex flex-row justify-between items-center p-[16px]">
          <div>
            <p className="text-[16px] text-white font-semibold">
              Perlengkapan Jamaah
            </p>
          </div>
          {permissions?.includes('update_equipment') && (
            <>
              {loadingAction ? (
                <Spinner />
              ) : (
                <Buttons
                  text="Simpan"
                  customClass="btn-primary btn-sm !w-[68px] flex flex-row justify-center items-center"
                  onClick={() => handleInsertPerlengkapan()}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-[8px] bg-white w-full h-fit absolute p-[12px] @apply shadow-[0px_4px_80px_-4px_#0000000A,0px_10px_16px_-6px_#0000000A] rounded-bl-2xl rounded-br-2xl">
        {equipment_package?.map((item, index) => (
          <div className="flex flex-row justify-start items-start">
            <Checkbox
              checked={item?.status === 1 ? true : false}
              disabled={!permissions?.includes('update_equipment')}
              onChange={() => handleChange(index)}
            />
            <div className=" ml-[8px] flex flex-col justify-start items-start content-center gap-[8px]">
              <div className="text-[#141414] text-[14px] font-normal">
                {item?.equipment_name}
              </div>
              {item?.variant?.length > 0 && (
                <div className="flex flex-row justify-start items-center gap-[8px]">
                  {item?.variant?.map((val, i) => (
                    <div
                      className={`py-[2px] px-[6px] rounded-[6px] ${
                        val.status === 1 ? "bg-[#141414]" : "bg-[#F2F2F2]"
                      }  flex flex-row justify-center items-center ${
                        val.status === 1 ? "text-white" : "text-[#828282]"
                      } ${permissions?.includes('update_equipment') ? "cursor-pointer" : ""} `}
                      onClick={() => {
                        if(permissions?.includes('update_equipment')){
                          handleChangeVariant(index, i)
                        }
                      }}
                    >
                      {val.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PerlengkapanJamaah;
