import Umroh from "../assets/icons/Umroh.svg";
import UmrohNonActive from "../assets/icons/umroh_non_active.svg";
import Haji from "../assets/icons/Haji.svg";
import HajiActive from "../assets/icons/haji_active.svg";
import Tour from "../assets/icons/Tour.svg";
import TourActive from "../assets/icons/tour_active.svg";
import JasaBadal from "../assets/icons/Jasa_badal.svg";
import JasaBadalActive from "../assets/icons/jasa_badal_active.svg";
import TabunganActive from "../assets/icons/tabungan_active.svg";
import Tabungan from "../assets/icons/Tabungan.svg";
import RequestPaket from '../assets/icons/request-paket.svg';
import RequestPaketActive from '../assets/icons/request-paket-active.svg';

export const menuStepSidebarList = [
  {
    title: "Umroh",
    key: "umroh",
    icon: UmrohNonActive,
    order: 0,
    iconActive: Umroh,
  },
  {
    title: "Haji",
    key: "haji",
    icon: Haji,
    order: 1,
    iconActive: HajiActive,
  },
  {
    title: "Tour",
    key: "tour",
    icon: Tour,
    order: 2,
    iconActive: TourActive,
  },
  {
    title: "Jasa Badal",
    key: "jasabadal",
    icon: JasaBadal,
    order: 3,
    iconActive: JasaBadalActive,
  },
  {
    title: "Tabungan",
    key: "tabungan",
    icon: Tabungan,
    order: 4,
    iconActive: TabunganActive,
  },
  {
    title: "Cek Paket",
    key: "request_paket",
    icon: RequestPaket,
    order: 5,
    iconActive: RequestPaketActive,
  },
];
