import React, { useEffect, useState, useCallback } from "react";
import MoslemeTable from "../../../../../components/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteDataMaskapai,
  duplicateDataMaskapai,
  getAllDataMaskapai,
} from "../../../../../stores/actions/datamaster";
import { columns } from "./column";
import useDebounce from "../../../../../utils/helpers/useDebounce";
import AlertPopup from "../../../../../components/popup/alert";
import Popup from "../../../../../components/popup";
import Inputs from "../../../../../components/form/inputs";
import CheckboxMaster from "../../checkbox";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Buttons from "../../../../../components/buttons";

function Maskapai({ filter, openModal }) {
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [actionHandle, setActionHandle] = useState();
  const [open, setOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [duplicateCode, setDuplicateCode] = useState("");
  const [duplicateLogo, setDuplicateLogo] = useState("");
  const [duplicateDescription, setDuplicateDescription] = useState("");
  const auth = useSelector(({ auth }) => auth)

  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      logo: "",
      description: "",
    },
  });

  // data table
  const { dataMaskapai, status } = useSelector((state) => state.datamaster);
  let dataTable = dataMaskapai.data;
  const dispatch = useDispatch();

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = dataMaskapai.total_data;

  // Handle action table
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    dispatch(deleteDataMaskapai(actionHandle));
    setOpen(false);
  };

  // filter data table
  const emailDebounce = useDebounce(filter, 1000);

  useEffect(() => {
    dispatch(
      getAllDataMaskapai({
        per_page: size,
        page_number: currentpage,
        sort_column: "name",
        sort_order: "",
        keyword: filter,
      })
    );
  }, [currentpage, dispatch, emailDebounce, filter, size]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // submit form
  const handleSave = () => {
    dispatch(duplicateDataMaskapai(formik.values));
    setIsModal(false);
  };

  return (
    <div>
      <AlertPopup
        open={open}
        handleClose={handleClose}
        handleContinue={handleContinue}
      />

      <Popup
        type="primary"
        title="Duplikat Maskapai"
        open={isModal}
        handleClose={() => setIsModal(false)}
      >
        <Inputs id="test" label="Nama Maskapai" placeholder="Masukan nama maskapai" value={formik.values.name} />
        <div className="my-4">
          <CheckboxMaster
            title="Kode Maskapai"
            checked={formik.values.code !== ""}
            value={formik.values.code}
            onChange={(e) => {
              formik.setFieldValue(
                "code",
                formik.values.code === "" ? duplicateCode : ""
              );
            }}
          />
        </div>
        <div className="my-4">
          <CheckboxMaster
            title="Logo"
            checked={formik.values.logo !== ""}
            value={formik.values.logo}
            onChange={(e) => {
              formik.setFieldValue(
                "logo",
                formik.values.logo === "" ? duplicateLogo : ""
              );
            }}
          />
        </div>
        <div className="my-4">
          <CheckboxMaster
            title="Deskripsi"
            checked={formik.values.description !== ""}
            value={formik.values.description}
            onChange={(e) => {
              formik.setFieldValue(
                "description",
                formik.values.description === "" ? duplicateDescription : ""
              );
            }}
          />
        </div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6}>
            <Buttons
              onClick={() => setIsModal(false)}
              text="Batal"
              customClass="btn-secondary btn-large"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Buttons
              onClick={handleSave}
              text="Simpan"
              customClass="btn-primary btn-large"
            />
          </Grid>
        </Grid>
      </Popup>

      <MoslemeTable
        columns={columns(
          handleOpen,
          setActionHandle,
          setIsModal,
          openModal,
          dispatch,
          formik.setFieldValue,
          setDuplicateCode,
          setDuplicateLogo,
          setDuplicateDescription,
          auth?.user?.permissions
        )}
        loading={status === "process"}
        data={dataTable}
        rowKey={(record) => record.id_maskapai}
        withSelection={true}
        selectionType={selectionType}
        customPaginationClass="pr-4"
        pagination={pagination}
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) => {
          onSizeChange(currentpage, pageSize);
        }}
      />
    </div>
  );
}

export default Maskapai;
