import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Badge from "../../../../../components/badge";
import Popup from "../../../../../components/popup";
import {
  editPenerbanganDomesticPaket,
  handleGetAllPenerbangan,
  handleGetAllPenerbanganInternational,
  storePenerbanganInternationalPaket,
  updatedMaskapaiPenerbangan,
} from "../../../../../stores/actions/paket/penerbangan";
import AddOrEditFlight from "./components/AddOrEditFlight";
import Arrival from "./components/Arrival";
import Garuda from "assets/icons/icon_garuda.svg";

import Departure from "./components/Departure";
import Inputs from "components/form/inputs";
import Timepickers from "components/timepickers";
import Buttons from "components/buttons";
import Radio from "@material-ui/core/Radio";
import Scroll from "components/scroll";
import { useParams } from "react-router-dom";
import moment from "moment";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import { getAllDataMaskapai } from "stores/actions/datamaster";
import DatePicker from "components/datepicker/DatePicker";
import PaketDetailGeneralInfoAction from "stores/actions/paket/generalInfo";

import IconJamPasir from "assets/icons/Jam-Pasir.svg";

import dayjs from "dayjs";
import Selects from "components/form/selects";

const PaketPenerbangan = () => {
  const dispatch = useDispatch();
  const { isLoading, allFlight, penerbanganInternational } = useSelector(
    (state) => state.penerbangan
  );
  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const { id } = useParams();
  const [isDomestik, setIsDomestik] = useState(false);
  const [isRadio, setIsRadio] = useState(null);
  const [isMaskapai, setIsMaskapai] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [masterMaskapai, setMasterMaskapai] = useState([]);
  const [maskapaiOptions, setMaskapaiOptions] = useState([]);
  const { dataMaskapai, status } = useSelector((state) => state.datamaster);
  const [ListDataKeberangkatanDomestic, setListDataKeberangkatanDomestic] =
    useState([]);
  const [listPenerbanganInternational, setListPenerbanganInternational] =
    useState([]);
  const [listDataKedatanganDomestic, setListDataKedatanganDomestic] = useState(
    []
  );
  const store = useSelector(({ generalInfo }) => generalInfo);
  const [formDomestic, setFormDomestic] = useState({
    terminal: "",
    kode: "",
    date_keberangkatan: null,
    time_keberangkatan: null,
    date_kedatangan: null,
    time_kedatangan: null,
    maskapai_id: null,
  });

  const [formInternational, setFormInternational] = useState({
    terbang_dari: null,
    teransit_di: null,
    is_transit: 0,
    terminal_terbang: "",
    kode_penerbangan: "",
    date_keberangkatan: null,
    date_kedatangan: null,
    time_keberangkatan: null,
    time_kedatangan: null,
    mendarat_di: null,
    terminal_transit: "",
    kode_penerbangan_transit: "",
    date_keberangkatan_transit: null,
    date_kedatangan_transit: null,
    time_keberangkatan_transit: null,
    time_kedatangan_transit: null,
  });

  const reloadInternational = () => {
    dispatch(handleGetAllPenerbanganInternational(id));
  };

  useEffect(() => {
    dispatch(handleGetAllPenerbangan(id));
    dispatch(handleGetAllPenerbanganInternational(id));
    dispatch(
      getAllDataMaskapai({
        per_page: 1000,
        page_number: 1,
        sort_column: "name",
        sort_order: "",
        keyword: "",
      })
    );
  }, []);

  useEffect(() => {

    if (dataMaskapai) {
      setMasterMaskapai(dataMaskapai?.data);

      const _temp = dataMaskapai?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id_maskapai,
        }
      })

      setMaskapaiOptions(_temp)

    }
  }, [dataMaskapai]);

  const [openPopupAddOrEditFlight, setOpenPopupAddOrEditFlight] = useState({
    label: "Add",
    show: false,
  });

  useEffect(() => {
    if (allFlight) {
      let resultKeberangkatan = allFlight?.filter(
        (item) => item.flight_type === 1
      );
      let ressultKedatangan = allFlight?.filter(
        (item) => item.flight_type === 2
      );

      setListDataKeberangkatanDomestic(resultKeberangkatan);
      setListDataKedatanganDomestic(ressultKedatangan);
    }
    if (penerbanganInternational) {
      setListPenerbanganInternational(penerbanganInternational?.data);
    }
  }, [allFlight, penerbanganInternational]);

  const handleChangeFormDomestic = (value, name) => {
    setFormDomestic({ ...formDomestic, [name]: value });
  };

  const handleUpdatedMaskapai = () => {
    setIsLoadingAction(true);

    const payload = {
      maskapai_id: isRadio,
    };
    dispatch(
      updatedMaskapaiPenerbangan(
        id,
        payload,
        (message) => {
          setIsLoadingAction(false);
          setIsMaskapai(false);
          dispatch(handleGetAllPenerbangan(id));
          dispatch(handleGetAllPenerbanganInternational(id))
          dispatch(PaketDetailGeneralInfoAction.fetchData(id));
          dispatch(handleSuccess(message));
        },
        (error) => {
          setIsLoadingAction(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleClickUpdateDomestic = () => {
    setIsLoadingAction(true);
    //dummy
    const payload = {
      departure_date: formDomestic?.date_keberangkatan,
      arrival_date: formDomestic?.date_kedatangan,
      departure_time: formDomestic?.time_keberangkatan,
      arrival_time: formDomestic?.time_kedatangan,
      flight_code: formDomestic?.kode,
      terminal: formDomestic?.terminal,
      flight_type: formDomestic?.flight_type,
      id: formDomestic?.id,
      starting_id: formDomestic?.starting?.starting_id,
      maskapai_id: formDomestic?.maskapai_id,
    };

    dispatch(
      editPenerbanganDomesticPaket(
        id,
        payload,
        (message) => {
          setIsLoadingAction(false);
          setFormDomestic({
            terminal: "",
            kode: "",
            date_keberangkatan: null,
            time_keberangkatan: null,
            date_kedatangan: null,
            time_kedatangan: null,
            maskapai_id: null
          });
          setIsDomestik(false);

          dispatch(handleGetAllPenerbangan(id));
          dispatch(handleSuccess(message));
          
        },
        (error) => {
          setIsLoadingAction(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleSaveFlightInternational = () => {
    
    let payload = {
      // "id" : 1, // id di gunakan kalau ingin update
      departure_airport_id: formInternational?.terbang_dari,
      arrival_airport_id: formInternational?.mendarat_di,
      terminal: formInternational?.terminal_terbang,
      flight_code: formInternational?.kode_penerbangan,
      estimate_departure_date: formInternational?.date_keberangkatan,
      estimate_arrival_date: formInternational?.date_kedatangan,
      estimate_departure_time: formInternational?.time_keberangkatan,
      estimate_arrival_time: formInternational?.time_kedatangan,
      is_transit: formInternational?.is_transit,
      transit_airport_id: formInternational?.teransit_di,
      estimate_departure_transit_date:
        formInternational?.date_keberangkatan_transit,
      estimate_arrival_transit_date: formInternational?.date_kedatangan_transit,
      estimate_departure_transit_time:
        formInternational?.time_keberangkatan_transit,
      estimate_arrival_transit_time: formInternational?.time_kedatangan_transit,
      flight_code_transit: formInternational?.kode_penerbangan_transit,
      terminal_transit: formInternational?.terminal_transit,
    };

    if (formInternational?.id) {
      payload.id = formInternational?.id;
    }

    setIsLoadingAction(true);
    dispatch(
      storePenerbanganInternationalPaket(
        id,
        payload,
        (message) => {
          setIsLoadingAction(false);

          setFormInternational({
            terbang_dari: null,
            teransit_di: null,
            is_transit: 0,
            terminal_terbang: "",
            kode_penerbangan: "",
            date_keberangkatan: null,
            date_kedatangan: null,
            time_keberangkatan: null,
            time_kedatangan: null,
            mendarat_di: null,
            terminal_transit: "",
            kode_penerbangan_transit: "",
            date_keberangkatan_transit: null,
            date_kedatangan_transit: null,
            time_keberangkatan_transit: null,
            time_kedatangan_transit: null,
          });

          dispatch(handleGetAllPenerbanganInternational(id));
          dispatch(handleGetAllPenerbangan(id));
          setOpenPopupAddOrEditFlight({
            label: "Edit",
            show: false,
          });

          dispatch(handleSuccess(message));
        },
        (error) => {
          setIsLoadingAction(false);
          // setOpenPopupAddOrEditFlight({
          //   label: "Edit",
          //   show: false,
          // });
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleChangeFormInternational = (val, name) => {
    setFormInternational({
      ...formInternational,
      [name]: val,
    });
  };

  const handleEditKeberangkatan = (data) => {
    setFormDomestic({
      ...data,
      ...formDomestic,
      terminal: data?.terminal,
      kode: data?.flight_code,
      date_keberangkatan: data?.departure_date,
      time_keberangkatan: data?.departure_time,
      date_kedatangan: data?.arrival_date,
      time_kedatangan: data?.arrival_time,
      maskapai_id: data?.airplane.airplane_id,
    });

    setIsDomestik(true);
  };

  const onEditPenerbanganInternational = (data) => {
    setFormInternational({
      ...data,
      ...formInternational,
      terbang_dari: data?.airport_departure_id,
      mendarat_di: data?.airport_arrival_id,
      terminal_terbang: data?.terminal,
      kode_penerbangan: data?.flight_code,
      date_keberangkatan: data?.departure_date,
      date_kedatangan: data?.arrival_date,
      time_keberangkatan: data?.departure_time,
      time_kedatangan: data?.arrival_time,
      is_transit: data?.is_transit,
      teransit_di: data?.transit?.airport_transit_id,
      date_keberangkatan_transit: data?.transit?.transit_departure_date,
      time_keberangkatan_transit: data?.transit?.transit_departure_time,
      time_kedatangan_transit: data?.transit?.transit_arrival_time,
      date_kedatangan_transit: data?.transit?.transit_arrival_date,
      kode_penerbangan_transit: data?.transit?.transit_flight_code,
      terminal_transit: data?.transit?.transit_terminal,
    });

    setOpenPopupAddOrEditFlight({
      label: "Edit",
      show: true,
    });
  };

  useEffect(() => {
    if (isRadio === null) {
      setIsRadio(store.data?.maskapai?.id_maskapai);
    }
  }, [store, isRadio]);

  return (
    <>
      <Popup
        title="Pilh Maskapai"
        width="1020px"
        open={isMaskapai}
        handleClose={() => {
          setIsMaskapai(false);
          setIsRadio(null);
        }}
      >
        <div className="border-[#E0E0E0] border-solid border-[1px] rounded-2xl ">
          <div className="m-[16px]">
            <div className="grid grid-cols-3 gap-4">
              {masterMaskapai?.map((item, index) => (
                <div className="rounded-lg !@apply overflow-hidden !shadow-soft">
                  <div
                    className={`p-[16px] border-b-[#E0E0E0] border-b border-solid flex flex-row justify-between items-center ${
                      isRadio === item.id_maskapai ? "!bg-[#FFDCDC]" : ""
                    }`}
                  >
                    <div>
                      <Badge
                        label={item?.code}
                        backgroundColor={`${
                          isRadio === item.id_maskapai
                            ? "#FE000033"
                            : " #99999833"
                        }`}
                        color={`${
                          isRadio === item.id_maskapai
                            ? "#FE0000"
                            : " #6C757D"
                        }`}
                      />
                    </div>
                    <Radio
                      checked={isRadio === item.id_maskapai ? true : false}
                      onChange={(e) => {
                        setIsRadio(item.id_maskapai);
                      }}
                      color="linear-gradient(214.47deg, #FF3733 15.18%, #9A1212 120.58%)"
                      style={{
                        color:
                          "linear-gradient(214.47deg, #FF3733 15.18%, #9A1212 120.58%)",
                      }}
                    />
                  </div>
                  <div className="flex flex-row p-[16px] justify-start items-center @apply border-[0px,1px,1px,1px] border-solid border-[#FFF4F4]">
                    <img
                      src={item?.logo}
                      width={32}
                      height={32}
                      alt="garuda_icon"
                      className="w-[32px] h-[32px] rounded-full"
                    />
                    <div className="ml-[16px] font-semibold text-[#141414] text-[14px]">
                      {item?.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="p-[16px] flex flex-row justify-end border-t border-[#E0E0E0]">
            <Buttons
              text="Batalkan"
              customClass="btn-outline btn-large w-[163px] "
              onClick={() => {
                setIsMaskapai(false);
                setIsRadio(null);
              }}
            />
            <Buttons
              text="Simpan"
              loading={isLoadingAction}
              customClass="btn-primary btn-large w-[154px] ml-[8px]"
              onClick={handleUpdatedMaskapai}
              disabled={isRadio === null ? true : false}
            />
          </div>
        </div>
      </Popup>

      <Popup
        width="604px"
        open={isDomestik}
        title="Edit Penerbangan Domestik"
        handleClose={() => {
          setIsDomestik(false);
        }}
      >
        <div className=" rounded-2xl border-[1px] border-solid border-[#F2F2F2]">
          <div className="w-full">
            <div className="p-4">
              <Selects
                label="Maskapai "
                placeholder="Pilih Maskapai"
                options={maskapaiOptions}
                values={isDomestik && formDomestic?.maskapai_id ? [maskapaiOptions?.find(
                  (item) => item?.value === formDomestic?.maskapai_id
                )] : [{
                  value: "",
                  label: "Pilih Maskapai"
                }]}
                onChange={(e) => 
                  {
                    handleChangeFormDomestic(e[0].value, "maskapai_id")
                  }
                }
                containerClass="w-full"
              />
            </div>
            <div className="grid grid-cols-2 gap-[16px] m-[16px] mt-0">
              <Inputs
                placeholder="Masukan Terminal"
                label="Terminal"
                value={formDomestic.terminal}
                onChange={(e) =>
                  handleChangeFormDomestic(e.target.value, "terminal")
                }
              />
              <Inputs
                placeholder="Kode penerbangan"
                label="Kode Penerbangan"
                value={formDomestic.kode}
                onChange={(e) =>
                  handleChangeFormDomestic(e.target.value, "kode")
                }
              />
              <div>
                <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
                  Perkiraan Tanggal Keberangkatan
                </div>
                <DatePicker
                  label="Perkiraan Tanggal keberangakatan"
                  containerClass="w-full"
                  customClass="w-full"
                  value={dayjs(moment(formDomestic?.date_keberangkatan, "DD-MM-YYYY").format("YYYY-MM-DD"), "YYYY-MM-DD")}
                  placeholder={"Perkiraan Tanggal keberangakatan"}
                  onChange={(e) => {
                    handleChangeFormDomestic(e, "date_keberangkatan");
                  }}
                />
              </div>
              <Timepickers
                label="Perkiraan Waktu Keberangkatan"
                placeholder={"Pilih Waktu Keberangkatan"}
                value={dayjs(formDomestic?.time_keberangkatan, "H:mm")}
                onSelect={(e) => {
                  let data = moment(e?.$d).format("HH:mm:ss");
                  handleChangeFormDomestic(data, "time_keberangkatan");
                }}
              />
              <div>
                <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
                  Perkiraan Tanggal Kedatangan
                </div>
                <DatePicker
                  label="Perkiraan Tanggal kedatangan"
                  containerClass="w-full"
                  customClass="w-full"
                  value={dayjs(moment(formDomestic?.date_kedatangan, "DD-MM-YYYY").format("YYYY-MM-DD"), "YYYY-MM-DD")}
                  placeholder={"Pilih Tanggal Kedatangan"}
                  onChange={(e) =>
                    handleChangeFormDomestic(e, "date_kedatangan")
                  }
                />
              </div>

              <Timepickers
                label="Perkiraan Waktu Kedatangan"
                placeholder="Pilih waktu kedatangan"
                value={dayjs(formDomestic?.time_kedatangan, "H:mm")}
                onSelect={(e) => {
                  let data = moment(e?.$d).format("HH:mm:ss");
                  handleChangeFormDomestic(data, "time_kedatangan");
                }}
              />
            </div>
            <div className="flex flex-row justify-end items-center p-4 border-t border-[#F2F2F2]">
              <Buttons
                text="Simpan"
                customClass="btn-primary btn-large"
                loading={isLoadingAction}
                onClick={handleClickUpdateDomestic}
              />
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={openPopupAddOrEditFlight.show}
        handleClose={() =>
          setOpenPopupAddOrEditFlight({
            label: "Add",
            show: false,
          })
        }
        title={`${
          openPopupAddOrEditFlight?.label === "Edit"
            ? "Edit Penerbangan Internasional"
            : "Tambah Penerbangan Internasional"
        }`}
        width="1080px"
        children={
          <AddOrEditFlight
            formInternational={formInternational}
            onSaveHandle={handleSaveFlightInternational}
            isLoadingAction={isLoadingAction}
            handleChangeFormInternational={handleChangeFormInternational}
          />
        }
      />

      <div className="overflow-hidden">
        <div className="flex flex-row items-center gap-3 p-4">
          <div className="text-[18px] font-semibold">Keberangkatan</div>
          <div>
            <Badge
              label="Domestik"
              backgroundColor={"#BF900133"}
              color="#C08F01"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 overflow-x-auto scrolling-touch items-start pb-4 pl-[16px] costumScrollbarHorizontal">
          {/* {getContent()} */}
          {ListDataKeberangkatanDomestic.length ? (
            <>
              {ListDataKeberangkatanDomestic?.map((item, idx) => (
                <Departure
                  key={idx}
                  first={idx === 0 ? true : false}
                  last={idx === allFlight.length - 1 ? true : false}
                  data={item}
                  handleEditKeberangkatan={(data) => handleEditKeberangkatan(data)}
                  setIsDomestik={setIsDomestik}
                  setOpenPopupAddOrEditFlight={() => {}}
                  reloadData={() => {
                    reloadInternational()
                  }}
                />
              ))}
            </>
          ) : (
            <>
              <div className="flex w-full flex-row justify-center items-center">
                <div className="flex flex-col justify-center items-center h-[350px]">
                  <div className="flex flex-col h-full justify-center items-center !gap-y-[16px]">
                    <img src={IconJamPasir} alt="jam_pasir" />
                    <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
                      Belum ada starting dipilih, sehingga belum ada penerbangan domestik
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          
        </div>

        <Arrival
          data={listPenerbanganInternational}
          setOpenPopupAddOrEditFlight={setOpenPopupAddOrEditFlight}
          onEditPenerbanganInternational={onEditPenerbanganInternational}
          setFormInternational={setFormInternational}
          setIsMaskapai={setIsMaskapai}
          setIsRadio={setIsRadio}
          reloadData={reloadInternational}
        />

        <div className="flex flex-row items-center gap-3 p-4">
          <div className="text-[18px] font-semibold">Kedatangan</div>
          <div>
            <Badge
              label="Domestik"
              backgroundColor={"#BF900133"}
              color="#C08F01"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 overflow-x-auto costumScrollbarHorizontal scrolling-touch items-start pb-4 pl-[16px]">
          {/* {getContent()} */}
          {listDataKedatanganDomestic.length ? (
            <>
              {listDataKedatanganDomestic.map((item, idx) => (
                <Departure
                  key={idx}
                  first={idx === 0 ? true : false}
                  last={idx === allFlight.length - 1 ? true : false}
                  data={item}
                  setIsDomestik={setIsDomestik}
                  handleEditKeberangkatan={(data) => handleEditKeberangkatan(data)}
                  reloadData={reloadInternational}
                />
              ))}
            </>
          ) : (
            <>
              <div className="flex w-full flex-row justify-center items-center">
                <div className="flex flex-col justify-center items-center h-[350px]">
                  <div className="flex flex-col h-full justify-center items-center !gap-y-[16px]">
                    <img src={IconJamPasir} alt="jam_pasir" />
                    <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
                      Belum ada starting dipilih, sehingga belum ada penerbangan domestik
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

      </div>

      <div className="area-modal"></div>
    </>
  );
};

export default React.memo(PaketPenerbangan);
