import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const ProfileAPI = {
  updateProfile: (payload) => 
    axios.put(`${BASE_URL_ENDPOINT}/admin/profile/update`, payload),
  updateContact: (payload) => 
    axios.put(`${BASE_URL_ENDPOINT}/admin/profile/update-contact`, payload),
  updatePassword: (payload) => 
    axios.put(`${BASE_URL_ENDPOINT}/admin/profile/update-password`, payload)
}

export { ProfileAPI }