import {
    FETCH_INDONESIAN_BANKS
} from "../../actions/indonesianBanks";

const initialState = {
    indonesianBanks: []
};
  
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_INDONESIAN_BANKS:
            return {
                ...state,
                indonesianBanks: action.payload
            };
        default:
            return state;
    }
}