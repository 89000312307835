// paketDetail Actions
// --------------------------------------------------------

import { getAllTagihan, getDetailTagihan } from "utils/api/detailPaket";
import { handleError, handleErrorBE, handleSuccess } from "../errorGeneral";
import { clearData, setDataUpload, setDataUploadMulti } from "../uploadFile";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "paketDetail/SET_LOADING";
export const CLEAR_ERROR = "paketDetail/CLEAR_ERROR";
export const SET_ERROR = "paketDetail/SET_ERROR";
export const INIT_DATA = "paketDetail/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "paketDetail/SET_DOUBLE_SUBMIT";

// type status
export const SET_STATUSDETAILPAKET = "tagihan/SET_STATUSDETAILPAKET";

//  data
export const SET_DATAPAKET = "tagihan/SET_DETAILPAKET";

// detail data
export const SET_DETAILDATA = "tagihan/SET_DETAILDATA";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setStatusData = (payload) => ({
  type: SET_STATUSDETAILPAKET,
  payload,
});

export const setDataPaket = (payload) => ({
  type: SET_DATAPAKET,
  payload,
});

export const setDetailData = (payload) => ({
  type: SET_DETAILDATA,
  payload,
});

// cek status
export const statusChecking = (payload) => async (dispatch) => {
  dispatch(setStatusData(payload));
};

// detail paket tagihan
export const getAllDataTagihan = (packageID, payload, actionSuccess) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));
    dispatch(setLoading(true));
    const response = await getAllTagihan(packageID, payload);
    dispatch(setLoading(false));

    if (response.status === 200) {
      dispatch(setDataPaket(response.data));
      dispatch(statusChecking("idle"));
      actionSuccess(response.data)
    } else {
      dispatch(handleErrorBE(response));
      dispatch(statusChecking("idle"));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDetailDataTagihan = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getDetailTagihan(payload);
    dispatch(setLoading(false));

    if (response.status === 200) {
      dispatch(setDetailData(response.data));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};
