import React, { useEffect } from "react";
import Badge from "../../components/badge";
import MoslemeTable from "../../components/Table";
import MaleAvatar from "../../assets/icons/male.svg";
import AgenAction from "../../stores/actions/member/agen";
import CreateAgen from "./components/CreateAgen";
import AlertPopup from "../../components/popup/alert";
import ICWarn from "../../assets/icons/warn.svg";
import { Edit, Trash, Verify } from "iconsax-react";
import { FilterAgen } from "./components/Filter";
import { useDispatch, useSelector } from "react-redux";
import { getAllCabang } from "stores/actions/cabang";
import Avatar from "components/avatar";

function Agen() {
  const dispatch = useDispatch();
  const [isShowDelete, setIsShowDelete] = React.useState(false);
  const [detail, setDetail] = React.useState(null);
  const auth = useSelector(({ auth }) => auth)
  const callbackDelete = () => {
    dispatch(AgenAction.fetchData());
    setIsShowDelete(false);
  };
  const store = useSelector(({ agen }) => agen);
  const sourceData = store?.data?.map((item, i) => {
    return {
      ...item,
      key: `${i + 1}`,
      status_active: item.is_online ? "Online" : "Offline",
      status_email: item.is_email_confirmed === 1 ? "Verified" : "Unverified",
      foto: MaleAvatar,
      className: "whitespace-nowrap",
    };
  });
  // console.log('store: ', store);

  const [isEditShow, setIsEditShow] = React.useState(false);

  const permissions = auth?.user?.permissions;

  const onShowEdit = (id) => {
    dispatch(AgenAction.setAgenId(id));
    setTimeout(() => {
      setIsEditShow(true);
    }, 500);
  };

  const columns = [
    {
      title: "Foto",
      dataIndex: "foto",
      width: "33px",
      render: (img, a) => <Avatar value={a}/>
    },
    {
      title: "Nama",
      dataIndex: "name",
    },
    {
      title: "Telepon",
      dataIndex: "phone",
    },
    // {
    //   title: 'Email',
    //   dataIndex: 'email',
    // },
    {
      title: "Cabang",
      dataIndex: "cabang_name",
      render: (text, a) => {
        return (
          <Badge
            backgroundColor={a.color_bg_cabang}
            color={a.color_text_cabang}
            label={text || "-"}
          />
        );
      },
    },
    {
      title: "Status aktif",
      dataIndex: "status_active",
      className: "whitespace-nowrap",
      render: (s, a) => {
        return (
          <div>
            <div className="flex items-center">
              <div
                className={`w-3 h-3 ${
                  s === "Online" ? "bg-[#A7C957]" : "bg-slate-400"
                } rounded-full mr-2`}
              />
              <p className="font-bold">{s}</p>
            </div>
            <div className="action">
              <div className="action-content">
                {permissions?.includes("delete_member") && (
                  <div className="action-content-item">
                    <Trash
                      className="cursor-pointer"
                      onClickCapture={() => {
                        setIsShowDelete(true);
                        setDetail(a);
                      }}
                      size="15"
                      color="#141414"
                    />
                  </div>
                )}
                {permissions?.includes("update_member") && (
                  <div className="action-content-item">
                    <Edit
                      className="cursor-pointer"
                      onClick={() => onShowEdit(a.id)}
                      size="15"
                      color="#141414"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const expandable = {
    expandedRowRender: (record) => {
      return (
        <div className="ml-2">
          <ul className="w-full flex py-3 border-l">
            <li className="w-[10rem]">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">Status KTP</li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">Status Email</li>
              </ul>
            </li>
          </ul>
          <ul className="w-full flex pb-3 border-l">
            <li className="w-[10rem]">
              <ul>
                <li className="pl-5 pt-2">
                  <Badge
                    backgroundColor={
                      record.status_ktp === 1
                        ? "rgba(167, 201, 87, 0.3);"
                        : "rgba(141, 8, 1, 0.3)"
                    }
                    color="#141414"
                    label={
                      <div className="flex items-center">
                        {record.status_ktp === 1 ? (
                          <Verify size="16" color="#2DC653" variant="Bold" />
                        ) : (
                          <img src={ICWarn} alt="warn" />
                        )}

                        <p className="ml-1">
                          {record.status_ktp === 1 ? "Verified" : "Unverified"}
                        </p>
                      </div>
                    }
                  />
                </li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="pl-5 pt-2 flex">
                  <Badge
                    backgroundColor="rgba(167, 201, 87, 0.3);"
                    color="#141414"
                    label={
                      <div className="flex items-center">
                        <Verify size="16" color="#2DC653" variant="Bold" />
                        <p className="ml-1">{record.status_email}</p>
                      </div>
                    }
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      );
    },
    rowExpandable: (record) => record.name !== "Not Expandable",
  };

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: store.total,
    current: store.page_number,
    pageSize: store.per_page,
    defaultPageSize: store.per_page,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  useEffect(() => {
    dispatch(AgenAction.fetchData());
  }, [
    dispatch,
    store.keyword,
    store.per_page,
    store.page_number,
    store.id_role,
  ]);

  useEffect(() => {
    console.log("STORE : ", store);
  }, [store]);

  useEffect(() => {
    dispatch(getAllCabang());
  }, [dispatch]);

  return (
    <div>
      <AlertPopup
        subtitle={`Apakah kamu yakin ingin menghapus “${detail?.name}”?`}
        open={isShowDelete}
        handleClose={() => setIsShowDelete(false)}
        handleContinue={() =>
          dispatch(AgenAction.deleteData(detail?.id, callbackDelete))
        }
        loading={store.status === "process"}
      />
      <FilterAgen />

      <CreateAgen
        show={isEditShow}
        close={() => {
          dispatch(AgenAction.setAgenId("0"));
          setIsEditShow(false);
        }}
      />

      <div>
        <MoslemeTable
          loading={store.status === "process"}
          expandable={expandable}
          columns={columns}
          data={sourceData}
          pagination={pagination}
          onPageChange={(page) => dispatch(AgenAction.setPage(page))}
          onSizeChange={(currentpage, pageSize) => {
            dispatch(AgenAction.setPage(currentpage));
            dispatch(AgenAction.setSize(pageSize));
          }}
        />
      </div>
    </div>
  );
}

export default Agen;
