import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import Places from ".";

const style = {
  width: "522px",
  height: "318px",
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    "& .card_container": {
      marginLeft: "20px",
    },
  },
});

const MapDocs = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState({
    lat: -7.7955798,
    lng: 110.3694896,
  });
  const [address, setAdress] = useState("");
  const handleSetAdress = (data) => {
    setAdress(data);
  };

  const handleSelected = (data) => {
    console.log("data:", data);
    setSelected(data);
  };

  console.log("selected:", selected);
  console.log("address:", address);
  
  return (
    <React.Fragment>
      <div className={classes.container}>
        <Places
          style={style}
          selected={selected}
          setSelected={handleSelected}
          address={address}
          setAdress={handleSetAdress}
        />
        <div className="card_container">
          <h2>
            Koordinat Lokasi: <b>{address}</b>
          </h2>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MapDocs;
