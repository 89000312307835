import {
  ERROR_FETCHING_PAKET_COUPON,
  SET_ADD_OR_EDIT_PAKET_COUPON,
  SET_DELETE_PAKET_COUPON,
  SET_KEYWORD_PAKET_COUPON,
  SET_PAGE_PAKET_COUPON,
  SET_SIZE_PAKET_COUPON,
  SET_SIZE_TOTAL_PAKET_COUPON,
  START_FETCHING_PAKET_COUPON,
  SUCCESS_FETCHING_PAKET_COUPON,
} from "stores/actions/paket/coupon/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  total: 100,
  per_page: 10,
  page_number: 1,
  sort_column: "code_coupon",
  sort_order: "",
  keyword: "",
  status: statuslist.idle,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_PAKET_COUPON:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_PAKET_COUPON:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_PAKET_COUPON:
      return {
        ...state,
        data: action.payload,
        status: statuslist.success,
      };
    case SET_KEYWORD_PAKET_COUPON:
      return { ...state, keyword: action.keyword };

    case SET_PAGE_PAKET_COUPON:
      return { ...state, page_number: action.page };

    case SET_SIZE_PAKET_COUPON:
      return { ...state, per_page: action.size };

    case SET_SIZE_TOTAL_PAKET_COUPON:
      return { ...state, total: action.total };

    case SET_ADD_OR_EDIT_PAKET_COUPON:
      console.log("PAYLOAD", action);
      if (action.payload && action.payload.data && !action.edit) {
        state["data"].push(action.payload.data);
      } else {
        state["data"].map((val, idx) => {
          if (val.id_artikel === action.payload.data.id_artikel) {
            state["data"][idx] = action.payload.data;
          }
        });
      }
      return {
        ...state,
      };
    case SET_DELETE_PAKET_COUPON:
      state["data"] = state["data"].filter(
        (list) => list.id_coupon !== action.id
      );
      return state;
    default:
      return state;
  }
}
