import Buttons from "components/buttons";
import ImageField from "components/image_field";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import CardFileUpload from "./CardFileUpload";
import UploadArea from "components/Uploadarea";
import { useFormik } from "formik";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { onHandleuploadEvisaService, onHandleRemoveEvisaService } from "utils/api/paket/evisa";
import { handleDetectFile } from "utils/helpers";
import Alert from "components/popup/alert";

const PopupUploadVisa = ({
  jamaahEvisa,
  onUploaded,
}) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [uploading, setIsUploading] = useState(false);

  const formik = useFormik({
    initialValues: {
      order_kamar_mapping_id: jamaahEvisa?.id,
      file: null
    }
  })

  const handleSave = async () => {
    setIsUploading(true)
    const response = await onHandleuploadEvisaService(params.id, formik.values)
    setIsUploading(false)

    if (response.status === 200) {
      dispatch(handleSuccess(null, {message: "Berhasil upload Evisa", code: 200}))
      onUploaded()
    } else {
      dispatch(handleError(response.data))
    }

  }

  return (
    <>
     
      <div className="flex flex-col gap-[16px] w-full h-fit border rounded-lg">
        <div className="p-[16px]">
          <UploadArea 
            label="Upload Evisa"
            type="chose"
            onChange={(url) => {
              formik.setFieldValue("file", url)
              setTimeout(() => {
                formik.setFieldTouched("file", true)
              })
            }}
            />
        </div>
        <div className="flex flex-row justify-end items-center p-[16px] gap-[8px] border-t">
          <Buttons 
            onClick={handleSave}
            text="Simpan" 
            loading={uploading}
            customClass="btn-primary btn-medium" />
        </div>
      </div>
    </>
  );
};

export default PopupUploadVisa;
