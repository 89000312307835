import Progressbars from "components/progressbars";
import Scroll from "components/scroll";
import { Edit } from "iconsax-react";

import { useSelector } from "react-redux";
import { handleName } from "utils/helpers";

const Manifests = ({ 
  data,
  setIsUpdateManifest,
  setUpdateManifestKey,
  setUpdateManifestTitle,
}) => {
  
  const handleCountPercentManifest = (data) => {
    return data.total_document_uploaded / data.total_document * 100
  }

  return (
    <>
      <div className="shadow-soft rounded-2xl overflow-hidden w-[300px]" key={data?.id_kamar_mapping}>
        <div className="flex p-4 font-semibold gap-4 text-base justify-between bg-orange-1 text-white">
          <div>
              Manifest
          </div>
        </div>
        <div className="bg-white text-sm px-3 py-2 flex flex-col gap-4">
          <Scroll 
            height="350px"
          >
              {/* PAS FOTO 4x6 */}
            <div className="shadow-soft rounded-xl px-3 py-[10px]">
              <div className="w-full relative">
                <div className="absolute top-0 right-0 font-semibold">
                  {data.manifest.pas_photo_4x6?.total_document_uploaded}/{data.manifest.pas_photo_4x6?.total_document}
                </div>
                <Progressbars 
                  label={`Pas Foto 4x6`}
                  isEditIcon={true}
                  percent={handleCountPercentManifest(data.manifest.pas_photo_4x6)}
                  onClick={() => {
                    setIsUpdateManifest(true)
                    setUpdateManifestKey('pas_photo_4x6')
                    setUpdateManifestTitle("Pas Photo 4x6")
                  }}
                  // showInfo={true}
                  />
              </div>
            </div>

            <div className="shadow-soft rounded-xl px-3 py-[10px]">
              <div className="w-full relative">
                <div className="absolute top-0 right-0 font-semibold">
                  {data.manifest.ktp?.total_document_uploaded}/{data.manifest.ktp?.total_document}
                </div>
                <Progressbars 
                  label={`KTP`}
                  isEditIcon={true}
                  percent={handleCountPercentManifest(data.manifest.ktp)}
                  onClick={() => {
                    setIsUpdateManifest(true)
                    setUpdateManifestKey('ktp')
                    setUpdateManifestTitle("KTP")
                  }}
                  // showInfo={true}
                  />
              </div>
            </div>

            <div className="shadow-soft rounded-xl px-3 py-[10px]">
              <div className="w-full relative">
                <div className="absolute top-0 right-0 font-semibold">
                  {data.manifest.passport?.total_document_uploaded}/{data.manifest.passport?.total_document}
                </div>
                <Progressbars 
                  label={`Passport`}
                  isEditIcon={true}
                  percent={handleCountPercentManifest(data.manifest.passport)}
                  onClick={() => {
                    setIsUpdateManifest(true)
                    setUpdateManifestKey('passport')
                    setUpdateManifestTitle("Passport")
                  }}
                  // showInfo={true}
                  />
              </div>
            </div>

            <div className="shadow-soft rounded-xl px-3 py-[10px]">
              <div className="w-full relative">
                <div className="absolute top-0 right-0 font-semibold">
                  {data.manifest.family?.total_document_uploaded}/{data.manifest.family?.total_document}
                </div>
                <Progressbars 
                  label={`Keluarga`}
                  isEditIcon={true}
                  percent={handleCountPercentManifest(data.manifest.family)}
                  onClick={() => {
                    setIsUpdateManifest(true)
                    setUpdateManifestKey('family')
                    setUpdateManifestTitle("Keluarga")
                  }}
                  // showInfo={true}
                  />
              </div>
            </div>
{/* 
            <div className="shadow-soft rounded-xl px-3 py-[10px]">
              <div className="w-full relative">
                <div className="absolute top-0 right-0 font-semibold">
                  {data.manifest.vaccine_covid?.total_document_uploaded}/{data.manifest.vaccine_covid?.total_document}
                </div>
                <Progressbars 
                  label={`Vaksin Covid`}
                  isEditIcon={true}
                  percent={handleCountPercentManifest(data.manifest.vaccine_covid)}
                  onClick={() => {
                    setIsUpdateManifest(true)
                    setUpdateManifestKey('vaccine_covid')
                    setUpdateManifestTitle("Vaksin Covid")
                  }}
                  // showInfo={true}
                  />
              </div>
            </div> */}

            <div className="shadow-soft rounded-xl px-3 py-[10px]">
              <div className="w-full relative">
                <div className="absolute top-0 right-0 font-semibold">
                  {data.manifest.meningitis_vaccine?.total_document_uploaded}/{data.manifest.meningitis_vaccine?.total_document}
                </div>
                <Progressbars 
                  label={`Kartu Meningitis`}
                  isEditIcon={true}
                  percent={handleCountPercentManifest(data.manifest.meningitis_vaccine)}
                  onClick={() => {
                    setIsUpdateManifest(true)
                    setUpdateManifestKey('meningitis_vaccine')
                    setUpdateManifestTitle("Vaksin Meningitis")
                  }}
                  // showInfo={true}
                  />
              </div>
            </div>

            <div className="shadow-soft rounded-xl px-3 py-[10px]">
              <div className="w-full relative">
                <div className="absolute top-0 right-0 font-semibold">
                  {data.manifest.akta_kelahiran?.total_document_uploaded}/{data.manifest.akta_kelahiran?.total_document}
                </div>
                <Progressbars 
                  label={`Akta Lahir`}
                  isEditIcon={true}
                  percent={handleCountPercentManifest(data.manifest.akta_kelahiran)}
                  onClick={() => {
                    setIsUpdateManifest(true)
                    setUpdateManifestKey('akta_kelahiran')
                    setUpdateManifestTitle("Akta Lahir")
                  }}
                  // showInfo={true}
                  />
              </div>
            </div>

            {/* <div className="shadow-soft rounded-xl px-3 py-[10px]">
              <div className="w-full relative">
                <div className="absolute top-0 right-0 font-semibold">
                  {data.manifest.bpjs?.total_document_uploaded}/{data.manifest.bpjs?.total_document}
                </div>
                <Progressbars 
                  label={`BPJS Kesehatan`}
                  isEditIcon={true}
                  percent={handleCountPercentManifest(data.manifest.bpjs)}
                  onClick={() => {
                    setIsUpdateManifest(true)
                    setUpdateManifestKey('bpjs')
                    setUpdateManifestTitle("BPJS Kesehatan")
                  }}
                  />
              </div>
            </div> */}
            
          </Scroll>
        </div>
      </div>
    </>
  );
};

export default Manifests