import React, { useState } from "react";
import AlbumFoto from "./AlbumFoto";
import AlbumVideo from "./AlbumVideo";
import ILCoomingSoon from "assets/images/cooming-soon.svg";

const AlbumSelamaBeribadah = () => {
  return (
    // <div className="flex flex-col items-start gap-[16px]">
    //   <AlbumFoto />
    //   <AlbumVideo />
    // </div>

    <div className="flex flex-col h-screen justify-center items-center bg-white">
      <img
        src={ILCoomingSoon}
        alt="Cooming Soon"
        className="mb-2 w-[252px] h-[260px]"
      />
      <p className="text-base text-black">
        Maaf, fitur ini sedang dalam tahap pengembangan
      </p>
    </div>
  );
};

export default AlbumSelamaBeribadah;
