import React, { useState } from "react";
import InputOTP from ".";

const InputOTPDocs = () => {
  const [otp, setOtp] = useState("");

  return (
    <>
      <div>
        <InputOTP
          containerClass="coba"
          value={otp}
          onChange={(e) => setOtp(e)}
        />
      </div>
      <div>
        <InputOTP
          containerClass="mt-4"
          value={otp}
          disabled
          onChange={(e) => setOtp(e)}
        />
      </div>
      <div>
        <InputOTP
          containerClass="mt-4"
          value={otp}
          error
          onChange={(e) => setOtp(e)}
        />
      </div>
    </>
  );
};

export default InputOTPDocs;
