export const SET_LOADING = "paket/fee/SET_LOADING";
export const CLEAR_ERROR = "paket/fee/CLEAR_ERROR";
export const SET_ERROR = "paket/fee/SET_ERROR";
export const SET_PAGE_PAKET_FEE = "apps/artikel/SET_PAGE_PAKET_FEE";
export const SET_SIZE_PAKET_FEE = "apps/artikel/SET_SIZE_PAKET_FEE";
export const SET_SIZE_TOTAL_PAKET_FEE = "apps/artikel/SET_SIZE_TOTAL_PAKET_FEE";
export const SET_KEYWORD_PAKET_FEE = "apps/artikel/SET_KEYWORD_PAKET_FEE";
export const START_FETCHING_PAKET_FEE = "paket/fee/START_FETCHING_PAKET_FEE";
export const ERROR_FETCHING_PAKET_FEE = "paket/fee/ERROR_FETCHING_PAKET_FEE";
export const SUCCESS_FETCHING_PAKET_FEE =
  "paket/fee/SUCCESS_FETCHING_PAKET_FEE";
export const SET_PAKET_FEE_BESIC = "paket/FEE/SET_PAKET_FEE_BESIC";
export const SET_PAKET_FEE_LIST_AGENT = "paket/FEE/SET_PAKET_FEE_LIST_AGENT";
export const SET_PAKET_FEE_LIST_CABANG = "paket/FEE/SET_PAKET_FEE_LIST_CABANG";
export const SET_PAKET_FEE_DATA_AGENT = "paket/FEE/SET_PAKET_FEE_DATA_AGENT";
export const SET_PAKET_FEE_DATA_CABANG = "paket/FEE/SET_PAKET_FEE_DATA_CABANG";
