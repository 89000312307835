import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import InputPhone from "../../../components/form/inputPhone";
import Inputs from "../../../components/form/inputs";
import Selects from "../../../components/form/selects";
import Popup from "../../../components/popup";
import FlagIndonesia from "../../../assets/icons/flag-indonesia.svg";
import ImageField from "../../../components/image_field";
import Buttons from "../../../components/buttons";
import JamaahAction from "../../../stores/actions/member/jamaah";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import uuid from "react-uuid";
import { setDataUpload } from "stores/actions/uploadFile";
import { JamaahMemberAPI } from "utils/api/member";
import { handleSuccess } from "stores/actions/errorGeneral";

function CreateJamaah({ show, close }) {
  const dispatch = useDispatch();
  const jamaah = useSelector(({ jamaah }) => jamaah);
  const id_jamaah = jamaah.id_jamaah;
  const [isLoadingAdd, setIsLoading] = useState(false);
  const modalTitle =
    id_jamaah === "0" ? "Tambah Data Jamaah" : "Edit Data Jamaah";

  const validation = useFormik({
    initialValues: {
      name: "",
      title: "",
      nickname: "",
      email: null,
      phone: "",
      password: null,
      profile_photo: "",
      is_email_confirmed: "1",
      cabang_id: "",
    },
    validationSchema: Yup.object().shape(
      id_jamaah === "0"
        ? {
            name: Yup.string().required("Nama lengkap wajib diisi"),
            nickname: Yup.string().required("Nama panggilan wajib diisi"),
            email: Yup.string().required("Email wajib diisi"),
            password: Yup.string().required("Kata sandi wajib diisi"),
            phone: Yup.string().required("Telepon wajib diisi"),
          }
        : {
            name: Yup.string().required("Nama lengkap wajib diisi"),
            phone: Yup.string().required("Telepon wajib diisi"),
            email: Yup.string().required("Email wajib diisi"),
          }
    ),
    onSubmit: (val) => {
      setIsLoading(true);
      let payloadEdit = {
        id: id_jamaah,
        title: val.title,
        nickname: val.nickname,
        name: val.name,
        email: val.email,
        phone: val.phone,
        password: val.password || null,
        profile_photo: val.profile_photo,
        is_email_confirmed: val.is_email_confirmed,
        cabang_id: val.cabang_id,
      };
      dispatch(
        JamaahAction.createData(
          id_jamaah === "0" ? val : payloadEdit,
          callbackPost,
          (message) => {
            setIsLoading(false);
          }
        )
      );
    },
  });

  const callbackPost = (message) => {
    setIsLoading(false);
    validation.resetForm();
    dispatch(JamaahAction.fetchData());
    dispatch(JamaahAction.setJamaahId("0"));
    close();
    dispatch(handleSuccess(message));
  };

  useEffect(() => {
    if (id_jamaah !== "0") {
      JamaahMemberAPI.getDetail(id_jamaah).then((res) => {
        if (res.status === 200) {
          let results = res.data.data;
          validation.setFieldValue("name", results?.name);
          validation.setFieldValue("title", results?.title);
          validation.setFieldValue("nickname", results?.nickname);
          validation.setFieldValue("phone", results?.phone);
          validation.setFieldValue("profile_photo", results?.profile_photo);
          validation.setFieldValue(
            "is_email_confirmed",
            results?.is_email_confirmed
          );
          validation.setFieldValue("cabang_id", results?.cabang_id);
          validation.setFieldValue("email", results?.email);

          let image = [
            {
              id: uuid(),
              fileName: "Profil photo",
              path: results.profile_photo,
              name: "profile_photo",
            },
          ];

          dispatch(setDataUpload(image));
        }
      });
    }
  }, [id_jamaah]);

  return (
    <Popup width="1184px" open={show} handleClose={close} title={modalTitle}>
      <div className="w-full grid grid-cols-2 gap-4">
        <div>
          <Inputs
            name="name"
            customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name}
            label="Nama Lengkap"
            placeholder="Masukan"
            error={
              validation.touched.name &&
              validation.errors.name &&
              validation.errors.name
            }
          />
          <div className="grid grid-cols-3 gap-3 mt-3">
            <Selects
              label="Title"
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              placeholder="Select item(s)"
              value={[
                {
                  label: validation.values.title,
                  value: validation.values.title,
                },
              ]}
              onChange={(e) => validation.setFieldValue("title", e[0].value)}
              options={[
                {
                  value: "Tn",
                  label: "Tuan",
                },
                {
                  value: "Ny",
                  label: "Nyonya",
                },
                {
                  value: "Nn",
                  label: "Nona",
                },
              ]}
              error={
                validation.touched.title &&
                validation.errors.title &&
                validation.errors.title
              }
            />

            <div className="col-span-2">
              <Inputs
                customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                label="Nama Panggilan"
                placeholder="Masukan"
                name="nickname"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.nickname}
                error={
                  validation.touched.nickname &&
                  validation.errors.nickname &&
                  validation.errors.nickname
                }
              />
            </div>
          </div>

          <div className="mt-3">
            <Inputs
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              label="Email"
              name="email"
              placeholder="Masukan"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email}
              error={
                validation.touched.email &&
                validation.errors.email &&
                validation.errors.email
              }
            />
          </div>

          <div className="mt-3">
            <InputPhone
              label="Nomor Telepon"
              placeholder="Masukan"
              name="phone_number"
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              value={validation.values.phone}
              error={
                validation.touched.phone &&
                validation.errors.phone &&
                validation.errors.phone
              }
              onChange={(value) => validation.setFieldValue("phone", value)}
              onBlur={validation.handleBlur}
              phoneRegion="'+62'"
              containerClass="mt-2"
              icon={FlagIndonesia}
              enableSearch
            />
          </div>
        </div>

        <div>
          <div className="mt-3">
            <Inputs
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              type="password"
              label="Kata Sandi"
              placeholder="Masukan"
              name="password"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.password}
              error={
                validation.touched.password &&
                validation.errors.password &&
                validation.errors.password
              }
            />
          </div>

          <div className="mt-3">
            <Selects
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              label="Status Email"
              placeholder="Select item(s)"
              value={[
                {
                  label:
                    validation.values.is_email_confirmed === 1
                      ? "Verified"
                      : "Unverified",
                  value: validation.values.is_email_confirmed,
                },
              ]}
              onChange={(e) =>
                validation.setFieldValue("is_email_confirmed", e[0].value)
              }
              options={[
                {
                  value: 1,
                  label: "Verified",
                },
                {
                  value: 0,
                  label: "Unverified",
                },
              ]}
            />
          </div>

          <div className="mt-3">
            <ImageField
              customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
              customContainer="h-[248px]"
              setFile={(file) =>
                validation.setFieldValue("profile_photo", file?.path)
              }
              label="Foto Profil"
              name="profile_photo"
            />
          </div>

          <Buttons
            loading={isLoadingAdd}
            disabled={!(validation.isValid && validation.dirty)}
            onClick={() => validation.handleSubmit()}
            text="Simpan"
            customClass="btn-primary btn-medium mt-3"
          />
        </div>
      </div>
    </Popup>
  );
}

export default CreateJamaah;
