import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getBelumDp, getBelumLunas, getKelengkapanData } from "stores/actions/dashboard";
import { detailById } from "stores/actions/general";
import {
  IcBatasPerlunasan,
  IcDp3,
  IcKelengkapanData,
  IcPerlengkapanMaroon,
  IcSeatTerisi,
  IcSeatTersisa,
  IcTotalSeat,
} from "../../../assets/icons/dashboard";
import Buttons from "../../../components/buttons";
import CardMain from "./CardMain";
import CardProgress from "./CardProgress";
import ItemSeat from "./ItemSeat";
import UserDesc from "./UserDesc";

const PaketAktif = ({
  judul_paket,
  image,
  tanggal_keberangkatan,
  tipe_paket,
  total_kuota_jamaah,
  total_jamaah,
  total_seat,
  seat_terisi,
  sisa_seat,
  data,
  handleClickBelumDP,
  handleClickBelumLunas,
  pakcageID
}) => {
  const dispatch = useDispatch();
  const navigate = useHistory();

  const onBelum = (type) => {
    if(type === 'belumDp') {
      handleClickBelumDP({
        type: "belum_dp",
        status: true,
        paket_id: data?.paketid
      })
      // return console.log("belum dp", type);
      dispatch(
        getBelumDp({
          paket_id: data?.paketid,
          // paket_id: 1,
          keyword: "",
          per_page: 1000,
          page_number: 1,
          sort_column: "ordered_at",
          sort_order: "ASC",
        })
      ).then((item) => {
        // console.log("ini adalah", item);
        if (item.data.length !== 0 && type !== "") {
          dispatch(
            detailById({
              status: type,
              data: item,
            })
          );
        }
      });
    }

    if(type === 'belumLunas') {
      handleClickBelumLunas({
        type: "belum_lunas",
        status: true,
        paket_id: data?.paketid
      })
      // return console.log("belum dp", type);
      dispatch(
        getBelumLunas({
          paket_id: data?.paketid,
          // paket_id: 1,
          keyword: "",
          per_page: 1000,
          page_number: 1,
          sort_column: "ordered_at",
          sort_order: "ASC",
        })
      )
    }
    
  };

  const onKelengkapanData = (type) => {
    dispatch(
      getKelengkapanData({
        // paket_id: 1,
        paket_id: data?.paketid,
        keyword: "",
        per_page: 10,
        page_number: 1,
        sort_column: "ordered_at",
        sort_order: "ASC",
      })
    ).then((item) => {
      // console.log("ini adalah", item);
      if (item.data.length !== 0 && type !== "") {
        dispatch(
          detailById({
            status: type,
            data: item,
          })
        );
      }
    });
  };

  return (
    <main className="bg-white rounded-[20px] shadow-soft border border-[#f2f2f2] p-4 flex flex-col gap-y-4">
      <UserDesc
        name={judul_paket}
        linkImg={image}
        desc={tanggal_keberangkatan}
        status={tipe_paket}
        className="object-cover"
      />
      <section className="bg-white shadow-soft rounded-xl p-4 flex flex-col gap-y-4">
        <CardMain className="gap-[155px] !text-red-3">
          <div className="text-sm">Total Jamaah</div>
          <div className="text-[28px] font-semibold">
            {total_jamaah}
          </div>
        </CardMain>
        <CardMain className="!grid grid-cols-3 items-center">
          <ItemSeat
            icon={IcTotalSeat}
            seat="Total Seat"
            totalSeat={total_seat}
          />
          <ItemSeat
            className="text-blue-3"
            icon={IcSeatTerisi}
            seat="Seat Terisi"
            totalSeat={seat_terisi}
          />
          <ItemSeat
            className="text-[#5C9D0A]"
            icon={IcSeatTersisa}
            seat={"Seat Tersisa"}
            totalSeat={sisa_seat}
          />
          <div className="col-span-3 mt-4">
            <Buttons
              text="Lihat Seat Terisi"
              customClass="btn-secondary btn-small"
            />
          </div>
        </CardMain>
      </section>
      <section className="flex flex-col gap-y-2">
        <CardProgress
          icon={IcDp3}
          title="Belum DP"
          percent={data?.belum_dp_persen}
          info={`${data?.belum_dp} Tagihan`}
          totalData={data?.belum_dp}
          // onPress={belumDp}
          onPress={() => {
            {data?.belum_dp_persen && onBelum("belumDp")}
          }}
        />
        <CardProgress
          icon={IcBatasPerlunasan}
          title="Belum Lunas (Sudah DP)"
          percent={data?.belum_lunas_persen}
          info={`${data?.belum_lunas} Tagihan`}
          totalData={data?.belum_lunas}
          onPress={() => onBelum("belumLunas")}
        />
        <CardProgress
          icon={IcPerlengkapanMaroon}
          title="Perlengkapan"
          percent={data?.pax_perlengkapan_lengkap_persen}
          info={`(${data?.pax_perlengkapan_lengkap} / ${data?.total_jamaah}) Pax`}
          totalData={data?.total_jamaah}
          onPress={() => navigate.push(`/paket/detail/${pakcageID}`)}
          // onPress={() =>
          //   dispatch(
          //     detailById({
          //       perlengkapan: true,
          //     })
          //   )
          // }
        />
        <CardProgress
          icon={IcKelengkapanData}
          title="Kelengkapan Data"
          percent={data?.pax_kelengkapan_data_lengkap_persen}
          info={`(${data?.pax_kelengkapan_data_lengkap} / ${data?.total_jamaah}) Pax`}
          onPress={() => navigate.push(`/paket/detail/${pakcageID}`)}
          totalData={data?.total_jamaah}
        />
      </section>
    </main>
  );
};

export default PaketAktif;
