// transportasi Actions
// --------------------------------------------------------

import {
  deleteIdTransportasiApi,
  getListPaketTransportasiApi,
  getListTransportasiApi,
  storeTransportasiApi,
} from "../../../../utils/api/paket";
import { setDeleteIdCabang } from "../../cabang";
import { handleError, handleErrorBE, handleSuccess } from "../../errorGeneral";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "transportasi/SET_LOADING";
export const CLEAR_ERROR = "transportasi/CLEAR_ERROR";
export const SET_ERROR = "transportasi/SET_ERROR";
export const INIT_DATA = "transportasi/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "transportasi/SET_DOUBLE_SUBMIT";
export const SET_LIST_PAKET_TRANSPORTASI =
  "transportasi/SET_LIST_PAKET_TRANSPORTASI";
export const SET_LIST_TRANSPORTASI = "transportasi/SET_LIST_TRANSPORTASI";
export const SET_DELETE_PAKET_TRANSPORTASI =
  "transportasi/SET_DELETE_PAKET_TRANSPORTASI";
export const SET_STORE_TRANSAKSI = "transportasi/SET_STORE_TRANSAKSI";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setListPaketTransportasi = (payload) => ({
  type: SET_LIST_PAKET_TRANSPORTASI,
  payload,
});

export const setListTransportasi = (payload) => ({
  type: SET_LIST_TRANSPORTASI,
  payload,
});

export const setDeleteTransportasi = (id, payload) => ({
  type: SET_DELETE_PAKET_TRANSPORTASI,
  id: id,
  payload,
});

export const setStoreTransaksi = (payload) => ({
  type: SET_STORE_TRANSAKSI,
  payload,
});

export const getListPaketTransportasi = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await getListPaketTransportasiApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setListPaketTransportasi(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deletePaketTransportation =
  (id, payload, actionSuccess, actionError) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await deleteIdTransportasiApi(id, payload);
      dispatch(setLoading(false));

      if (res.status === 200) {
        actionSuccess(res.data);
      } else {
        actionError(res.data);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getListTransportasi = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await getListTransportasiApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setListTransportasi(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const addTransportasiPaket =
  (payload, actionSuccess = (message) => {}, actionErrror = (message) => {}) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await storeTransportasiApi(payload);
      dispatch(setLoading(false));
      if (res.status === 200) {
        actionSuccess(res.data);
      } else {
        actionErrror(res.data);
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
