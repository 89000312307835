import axios from "axios";
import { BASE_URL_ENDPOINT } from "../../../constant/constanta";

export const getAllUmroh = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/order/tagihan`, payload);

export const getDetailUmroh = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/order/tagihan/${payload}`);

// pembayaran
export const getAllPembayaran = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/tagihan`, payload);

export const addPembayaran = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-tagihan`, payload);

export const getRekening = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-rekening`, payload);
