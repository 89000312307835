import React from "react";
import PropTypes from "prop-types";

import Badge from './index'

const BadgeTypePaket = ({typePaket}) => {

    return (
        <>
          {typePaket === 'umroh' && (
            <Badge backgroundColor="rgba(86, 69, 146, 0.2)" color="#564592" label="Umroh" />
          )}
          {typePaket === 'haji' && (
            <Badge backgroundColor="rgba(254, 153, 1, 0.2)" color="#FE9A00" label="Haji" />
          )}
          {typePaket === 'tour' && (
            <Badge backgroundColor="rgba(248, 81, 163, 0.2)" color="#F20089" label="Tour" />
          )}
          {typePaket === 'jasa badal' && (
            <Badge backgroundColor="rgba(0, 255, 1, 0.2)" color="#333D29" label="Jasa Badal" />
          )}
          {typePaket === 'tabungan' && (
            <Badge backgroundColor="rgba(191, 144, 1, 0.2)" color="#C08F01" label="Tabungan" />
          )}
        </>
    )
}

Badge.propTypes = {
    typePaket: PropTypes.string.isRequired,
};

Badge.defaultProps = {
    typePaket: 'umroh',
};

export default React.memo(BadgeTypePaket);