import React, { useState } from "react";

import Tab from "components/tab";
import PenarikanAgen from "./components/PenarikanAgen";
import PenarikanCabang from "./components/PenarikanCabang";

import IconAgen from "assets/icons/icon-agen-grey.svg";
import IconAgenActive from "assets/icons/icon-agen-maroon.svg";
import IconCabang from "assets/icons/icon-nama-cabang-grey.svg";
import IconCabangActive from "assets/icons/icon-nama-cabang-maroon.svg";

const FeePenarikan = () => {
  const [activeTab, setActiveTab] = useState("1");

  const [totalWithdrawRequestAgent, setTotalWithdrawRequestAgent] = useState(0)
  const [totalWithdrawRequestBranch, setTotalWithdrawRequestBranch] = useState(0)

  return (
    <>
      <div className="p-4">
        <Tab
          defaultActiveKey="1"
          onChange={(e) => setActiveTab(e)}
          customClass='!mx-0'
          items={[
            {
              label: (
                <div className="flex flex-row items-center gap-2">
                  {activeTab === "1" ? (
                    <img src={IconAgenActive} alt="agen" />
                  ) : (
                    <img src={IconAgen} alt="agen" />
                  )}
                  <div>Agen</div>
                  {totalWithdrawRequestAgent > 0 ? (
                    <div className="flex justify-center items-center w-[16px] h-[16px] rounded-full gold-dark-bg">
                      <div className="text-[6px] text-[#141414] font-semibold">
                        {totalWithdrawRequestAgent > 99 ? "99+" : totalWithdrawRequestAgent}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ),
              key: "1",
              children: <PenarikanAgen setTotalWithdraw={setTotalWithdrawRequestAgent} />,
            },
            {
              label: (
                <div className="flex flex-row items-center gap-2">
                  {activeTab === "2" ? (
                    <img src={IconCabangActive} alt="cabang" />
                  ) : (
                    <img src={IconCabang} alt="cabang" />
                  )}

                  <div>Cabang</div>
                  {totalWithdrawRequestBranch > 0 ? (
                    <div className="flex justify-center items-center w-[16px] h-[16px] rounded-full gold-dark-bg">
                      <div className="text-[6px] text-[#141414] font-semibold">
                        {totalWithdrawRequestBranch > 99 ? "99+" : totalWithdrawRequestBranch}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ),
              key: "2",
              children: <PenarikanCabang setTotalWithdraw={setTotalWithdrawRequestBranch}  />,
            },
          ]}
        />
      </div>
    </>
  );
};

export default React.memo(FeePenarikan);
