import Buttons from "components/buttons";
import Spinner from "components/spinner";

import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import IconRadioButtonGrey from "assets/icons/RadioButtonGrey.svg";
import IconRadioButtonMaroon from "assets/icons/RadioButtonMaroon.svg";
import IconEmailMaroon from "assets/icons/icon-email-maroon.svg";
import IconWhatsapp from "assets/icons/icon-whatsapp.svg";

import { handleGetAccountJamaahAPI, handleUpdateJamaahOrderAPI, handleRegisterAccountJamaahAPI, linkingJamaahAccountService } from "utils/api/booking";

import Avatar from "components/avatar";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import { useFormik } from "formik";

import * as Yup from 'yup';
import { handleGetDataJamaah } from "stores/actions/booking";
import Inputs from "components/form/inputs";
import InputPhone from "components/form/inputPhone";

const TautkanAccount = ({
    jamaahData,
    tertaut
}) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [gettingAccount, setGettingAcount] = useState(false)

  const [jamaahAccounts, setJamaahAccounts] = useState([])
  const [jamaahAccounIDSelected, setJamaahAccountsIDSelected] = useState(null)
  const [submitingSelectedAccount, setSubmitingSelectedAccount] = useState(false)

  const [isRegisterLoading, setIsRegisterLoading] = useState(false)

  const formikSelectAccount = useFormik({
    initialValues: {
      // jamaah_title: 'tn',
      // jamaah_phone: '',
      // jamaah_email: '',
      // jamaah_identity_number: '',
      user_jamaah_id: null,
      // room_type: {
      //   id: null,
      //   name: null,
      //   description: null
      // }
    },
    validationSchema: Yup.object({
      // "jamaah_title": Yup.string().required("Harus pilih salah satu title"),
      // "jamaah_name": Yup.string().required("Nama Jamaah harus diisi"),
      // "jamaah_identity_number": Yup.string().required("NIK harus diisi")
      //                                       .matches(/^[0-9]+$/, "NIK Harus berupa angka")
      //                                       .min(16, "NIK harus 16 digit")
      //                                       .max(16, "NIK harus 16 digit"),
      "user_jamaah_id": Yup.number().required("Harus ditautkan keakun jamaah terlebih dahulu"),
      // "room_type": Yup.object({
      //     "id": Yup.number().required("Harus pilih starting & Tipekamar"),
      //     "name": Yup.string().required("Harus pilih starting & Tipekamar"),
      // })
    }),
  })

  const formikRegisterAccount = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      title: ''
    },
    validationSchema: Yup.object({
      "name": Yup.string().required("Nama harus diisi"),
      "phone": Yup.string().required("Nomor telepon harus diisi"),
      "title": Yup.string().required("Email wajib diisi")
    })
  })

  const handleGetJamaahAccount = async (phoneNumber) => {
    setGettingAcount(true)
    const response = await handleGetAccountJamaahAPI(phoneNumber)
    setGettingAcount(false)

    if(response.status === 200) {
      setJamaahAccounts(response.data.data)
    } else {
      dispatch(handleError(response.data))
    }
  }

  const onHandleSelectJamaahAccount = (id) => {
    setJamaahAccountsIDSelected(id)

    formikSelectAccount.setFieldValue("user_jamaah_id", id)

    setTimeout(() => {
      formikSelectAccount.setFieldTouched("user_jamaah_id", 1)
    })

  }

  const onHandleSubmitSelectAccount = async () => {
    setSubmitingSelectedAccount(true)
    const response = await linkingJamaahAccountService(params.orderNumber, jamaahData.id, formikSelectAccount.values)
    setSubmitingSelectedAccount(false)

    if(response.status === 200) {
      dispatch(handleSuccess(null, { message: "Berhasil menautkan akun jamaah", code: 200 }))
      dispatch(handleGetDataJamaah(params.orderNumber))
      tertaut()
    } else {
      dispatch(handleError(response.data))
    }

  }

  const onHandleSubmitRegister = async () => {

    setIsRegisterLoading(true)
    const response = await handleRegisterAccountJamaahAPI(formikRegisterAccount.values)
    setIsRegisterLoading(false)

    if(response.status === 201) {
      formikSelectAccount.setFieldValue('user_jamaah_id', response.data.data.id)
      handleGetJamaahAccount(formikRegisterAccount.values.phone)
    } else {
      dispatch(handleError(response.data))
    }

  }

  useEffect(() => {
    handleGetJamaahAccount(jamaahData.jamaah_phone)

    if(jamaahData) {
      formikSelectAccount.setFieldValue("jamaah_title", jamaahData.jamaah_title)
      formikSelectAccount.setFieldValue("jamaah_name", jamaahData.jamaah_name)
      formikSelectAccount.setFieldValue("jamaah_identity_number", jamaahData.jamaah_identity_number)
      formikSelectAccount.setFieldValue("jamaah_phone", jamaahData.jamaah_phone)
      formikSelectAccount.setFieldValue("jamaah_email", jamaahData.jamaah_email)

      formikSelectAccount.setFieldValue("room_type.id", jamaahData.paket_kamar_id)
      formikSelectAccount.setFieldValue("room_type.name", jamaahData.room_type)
      formikSelectAccount.setFieldValue("room_type.description", jamaahData.kamar_description)

      setTimeout(() => {
        formikSelectAccount.setFieldTouched("room_type.id", 1)
        formikSelectAccount.setFieldTouched("room_type.name", 1)
        formikSelectAccount.setFieldTouched("room_type.description", 1)
        formikSelectAccount.setFieldTouched("jamaah_title", 1)
        formikSelectAccount.setFieldTouched("jamaah_name", 1)
        formikSelectAccount.setFieldTouched("jamaah_identity_number", 1)
        formikSelectAccount.setFieldTouched("jamaah_phone", 1)
        formikSelectAccount.setFieldTouched("jamaah_email", 1)
      })

      formikRegisterAccount.setFieldValue("name", jamaahData.jamaah_name)
      formikRegisterAccount.setFieldValue("phone", jamaahData.jamaah_phone)
      formikRegisterAccount.setFieldValue("title", jamaahData.jamaah_title)
      formikRegisterAccount.setFieldValue("email", jamaahData.email)

      setTimeout(() => {
        formikRegisterAccount.setFieldTouched("name", 1)
        formikRegisterAccount.setFieldTouched("phone", 1)
        formikSelectAccount.setFieldTouched("title", 1)
        formikSelectAccount.setFieldTouched("email", 1)
      })

    }

  }, [jamaahData])

  return (
    <>
      <div className="border p-4 rounded-tr-2xl rounded-tl-2xl">
        {gettingAccount && <div className="w-full flex justify-center items-center h-[200px]">
          <Spinner 
            // customClass=" w-12 h-12"
            width="50px"
            height="50px"
            />
        </div>}
        {!gettingAccount && (
          <> 
            {jamaahAccounts.length > 0 ? jamaahAccounts.map((jamaah, index) => (
              <>
                <div>
                  <div 
                    onClick={() => onHandleSelectJamaahAccount(jamaah.id)}
                    className={`
                      border-gray-5 hover:border-red-3 focus:border-red-3 
                        border p-4 rounded-xl flex gap-4 items-center cursor-pointer
                        ${jamaahAccounIDSelected === jamaah.id && "border-red-3"}
                      `}>
                    <div className="cursor-pointer">
                      <img
                        src={jamaahAccounIDSelected === jamaah.id ? IconRadioButtonMaroon : IconRadioButtonGrey}
                        />
                    </div>
                    <div>
                      <Avatar 
                        value={jamaah}
                        customClass="object-cover w-[40px] h-[40px] rounded-[100%]"
                        containerClass="items-center justify-center  w-[40px] h-[40px] rounded-[100%]  bg-slate-100"
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="font-semibold text-[14px] text-[#141414]">
                        {jamaah.name.toUpperCase()}
                      </div>
                      <div className="flex gap-10">
                        <div className="flex gap-2 items-center">
                          <img 
                            src={IconEmailMaroon}
                            />
                          <div>
                          {jamaah.email}
                          </div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <img 
                            src={IconWhatsapp}
                            />
                          <div>
                            {jamaah.phone}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
          )) : (
            <>
              <InputPhone
                name="phone_number"
                id="phone_number"
                label="Daftarkan Akun"
                placeholder="masukan Nomor WA"
                value={formikRegisterAccount.values.phone}
                onChange={(val) => {
                  formikRegisterAccount.setFieldValue("phone", val)

                  setTimeout(() => {
                    formikRegisterAccount.setFieldTouched('phone', 1)
                  })
                  
                }}
                />
            </>
          )}
          </>
        )}
      </div>
      <div className="border p-4 rounded-br-2xl rounded-bl-2xl border-t-0 flex justify-end">
        {jamaahAccounts.length || gettingAccount > 0 ? (
          <>
            <Buttons 
              text="Tautkan"
              customClass="btn btn-primary btn-large"
              onClick={() => onHandleSubmitSelectAccount()}
              loading={submitingSelectedAccount}
              disabled={!(formikSelectAccount.isValid && formikSelectAccount.dirty)}
              // onClick={() => setGettingAcount(true)}
              />
          </>
        ) : (
          <>
            <Buttons 
              text="Daftar"
              customClass="btn btn-primary btn-large"
              onClick={() => onHandleSubmitRegister()}
              loading={isRegisterLoading}
              disabled={!(formikRegisterAccount.isValid && formikRegisterAccount.dirty)}
              // onClick={() => setGettingAcount(true)}
              />
          </>
        )}
      </div>
    </>
  )
}

export default TautkanAccount