// marketingKit Actions
// --------------------------------------------------------

import {
  getAllMarketingService,
  saveInfoMarketingService,
} from "utils/api/marketingKit";
import {
  clearError,
  handleError,
  handleErrorBE,
  handleSuccess,
} from "../errorGeneral";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "marketingKit/SET_LOADING";
export const CLEAR_ERROR = "marketingKit/CLEAR_ERROR";
export const SET_ERROR = "marketingKit/SET_ERROR";
export const INIT_DATA = "marketingKit/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "marketingKit/SET_DOUBLE_SUBMIT";
export const SET_DATA = "marketingKit/SET_DATA";
export const SET_DATA_MARKETING = "marketingKit/SET_DATA_MARKETING";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setDataMarketing = (payload) => ({
  type: SET_DATA_MARKETING,
  payload,
});

export const saveDataMarketing = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(clearError());
    const res = await saveInfoMarketingService(payload);
    dispatch(setLoading(false));
    if (res.status == 200) {
      dispatch(handleSuccess(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};
