import Textareas from ".";
import { Col, Row } from "antd";

const TextAreasDocs = () => {
  return (
    <>
      <Row>
        <Col span={12} className="px-3 mt-[32px]">
          <Textareas id="test" placeholder="placeholder" />
        </Col>
        <Col span={12} className="px-3">
          <Textareas id="test" placeholder="placeholder" label="Label" />
        </Col>
        <Col span={12} className="px-3 mt-4">
          <Textareas
            placeholder="placeholder"
            label="Label"
            customClass="textarea-disabled"
            disabled
          />
        </Col>
        <Col span={12} className="px-3 mt-4">
          <Textareas
            placeholder="placeholder"
            label="Label"
            customClass="textarea-error"
            error="Error Message"
          />
        </Col>
        <Col span={12} className="px-3 mt-4">
          <Textareas
            placeholder="placeholder"
            label="Label"
            customClass="textarea-success"
            success="Field validation is successfull"
          />
        </Col>
      </Row>
    </>
  );
};

export default TextAreasDocs;
