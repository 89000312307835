import { handleError } from "stores/actions/errorGeneral";
import {
  getAllRelationV2,
  getFileRelationV2,
  getJamaahByRoomlistIdV2,
  getRoomlistByPackageV2,
  getSetRoomlistV2,
  updatedRelationV2,
  updatedSetRoomlistV2,
} from "utils/api/paket/roomlist";

export const handleGetRoomlistByPackage =
  (id, payload, actionSuccess = (message) => {}, actionError = (error) => {}) =>
  async (dispatch) => {
    try {
      const response = await getRoomlistByPackageV2(id, payload);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleGetJamaahByRoomList =
  (
    id,
    payload = {
      search: "",
    },
    actionSuccess = (message) => {},
    actionError = (error) => {}
  ) =>
  async (dispatch) => {
    try {
      const response = await getJamaahByRoomlistIdV2(id, payload);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleGetListRelasi =
  (
    id,

    actionSuccess = (message) => {},
    actionError = (error) => {}
  ) =>
  async (dispatch) => {
    try {
      const response = await getAllRelationV2(id);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleUpdatedRelasi =
  (
    id,
    payload,

    actionSuccess = (message) => {},
    actionError = (error) => {}
  ) =>
  async (dispatch) => {
    try {
      const response = await updatedRelationV2(id, payload);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleGetSetRoomList =
  (
    id,
    payload,

    actionSuccess = (message) => {},
    actionError = (error) => {}
  ) =>
  async (dispatch) => {
    try {
      const response = await getSetRoomlistV2(id, payload);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleUpdatedRoomList =
  (
    id,
    payload,

    actionSuccess = (message) => {},
    actionError = (error) => {}
  ) =>
  async (dispatch) => {
    try {
      const response = await updatedSetRoomlistV2(id, payload);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleGetFileRelasi =
  (
    id,
    id_relasi,

    actionSuccess = (message) => {},
    actionError = (error) => {}
  ) =>
  async (dispatch) => {
    try {
      const response = await getFileRelationV2(id, id_relasi);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
