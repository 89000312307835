import React, { useEffect, useState } from "react";

import EditorToolbar, {
  formats,
  modules,
} from "components/richTextEditor/EditorToolbar";
import ReactQuill from "react-quill";
import RichTextEditor from "components/richTextEditor";
import FormDPOrderer from "./forms/FormDPOrderer";
import Tab from "components/tab";

import { handleGetFollowupTemplate } from "utils/api/booking";
import { useFormik } from "formik";
import MoslemeTable from "components/Table";

const FollowUpTwo = ({
  formik
}) => {
  const [input, setInput] = useState();

  const handleGetTemplate = async () => {
    const res = await handleGetFollowupTemplate('manifest');
    const templateDP = res.data.data[0]

    formik.setFieldValue('message_to_main_admin', templateDP.message_to_main_admin)
    formik.setFieldValue('message_to_orderer', templateDP.message_to_orderer)
    formik.setFieldValue('message_to_jamaah', templateDP.message_to_jamaah)
  }

  const shortcodes = [
    {
      shortcode: "[nama]",
      description: "Nama yang ditagihkan"
    },
    {
      shortcode: "[nama_pemesan]",
      description: "Nama Pemesan"
    },
    {
      shortcode: "[no_whatsapp_pemesan]",
      description: "Nomor Whatsapp Pemesan"
    },
    {
      shortcode: "[email_pemesan]",
      description: "Email Pemesan"
    },
    {
      shortcode: "[role_pemesan]",
      description: "Role Pemesan"
    },
    {
      shortcode: "[nama_travel]",
      description: "Nama Travel"
    },
    {
      shortcode: "[jumlah_pax]",
      description: "Menampilkan Jumlah Pax"
    },
    {
      shortcode: "[judul_paket]",
      description: "Menampilkan Judul Paket"
    },
    {
      shortcode: "[booking_id]",
      description: "Menampilkan Booking ID"
    },
    {
      shortcode: "[jamaah] ... [/jamaah]",
      description: "Menampilkan daftar jamaah diorder"
    }
  ]

  const shortcodeJamaah = [
    {
      shortcode: "[nama_jamaah]",
      description: "Menampilkan nama jamaah"
    },
    {
      shortcode: "[manifest] ... [/manifest]",
      description: "Menampilkan daftar manifest setiap jamaah"
    }
  ]

  const shortcodeManifest = [
    {
      shortcode: "[nama_data]",
      description: "Menampilkan nama data manifest"
    },
    {
      shortcode: "[total_data]",
      description: "Menampilkan total data manifest"
    },
    {
      shortcode: "[total_data_terisi]",
      description: "Menampilkan total data manifest yang terisi"
    },
    {
      shortcode: "[total_data_belum_terisi]",
      description: "Menampilkan total data manifest yang belum terisi"
    }
  ]


  const columns = [
    {
        title: "Shortcode",
        dataIndex: "shortcode",
        width: "250px",
    },
    {
        title: "Deskripsi",
        dataIndex: "description",
        
    }
]

  const shortCodeContentOrderer = 
    <>
      <div className="mb-2 font-semibold text-sm">Shortcode Dasar</div>
      <MoslemeTable
        columns={columns}
        data={shortcodes}
        withSelection={false}
        selectionType={false}
        withPagination={false}
        customClass="w-full shadow-soft !rounded-2xl overflow-hidden"
        />
      <div className="mb-2 mt-4 font-semibold text-sm">Shortcode [jamaah] ... [/jamaah]</div>
      <MoslemeTable
        columns={columns}
        data={shortcodeJamaah}
        withSelection={false}
        selectionType={false}
        withPagination={false}
        customClass="w-full shadow-soft !rounded-2xl overflow-hidden"
        />
      <div className="mb-2 mt-4 font-semibold text-sm">Shortcode [manifest] ... [/manifest]</div>
      <MoslemeTable
        columns={columns}
        data={shortcodeManifest}
        withSelection={false}
        selectionType={false}
        withPagination={false}
        customClass="w-full shadow-soft !rounded-2xl overflow-hidden"
        />
    </>

  const shortCodeContentJamaah = 
  <>
    <div className="mb-2 font-semibold text-sm">Shortcode Dasar</div>
    <MoslemeTable
      columns={columns}
      data={shortcodes}
      withSelection={false}
      selectionType={false}
      withPagination={false}
      customClass="w-full shadow-soft !rounded-2xl overflow-hidden"
      />
    <div className="mb-2 mt-4 font-semibold text-sm">Shortcode [manifest] ... [/manifest]</div>
    <MoslemeTable
      columns={columns}
      data={shortcodeManifest}
      withSelection={false}
      selectionType={false}
      withPagination={false}
      customClass="w-full shadow-soft !rounded-2xl overflow-hidden"
      />
  </>

  useEffect(() => {
    handleGetTemplate();
  }, [])

  return (
    <>
    <div>
      <Tab
        defaultActiveKey="1"
        destroyInactiveTabPane={true}
        items={[
          {
            label: "Pesan ke Admin",
            key: "1",
            children: (
              <>
                <div className="mt-4">
                    <RichTextEditor 
                      placeholder="Masukan pesan template untuk admin"
                      label="" 
                      shortcode={true}
                      renderShortcode={shortCodeContentOrderer}
                      data={{value: formik.values.message_to_main_admin}}
                      onChange={(value) => formik.setFieldValue('message_to_main_admin', value)}
                      />
                </div>
              </>
            ),
          },
          {
            label: "Pesan ke Pemesan",
            key: "2",
            children: (
              <>
                <div className="mt-4">
                    <RichTextEditor 
                      placeholder="Masukan pesan template untuk pemesan"
                      label="" 
                      shortcode={true}
                      renderShortcode={shortCodeContentOrderer}
                      data={{value: formik.values.message_to_orderer}}
                      onChange={(value) => formik.setFieldValue('message_to_orderer', value)}
                      />
                </div>
              </>
            ),
          },
          {
            label: "Pesan ke Jamaah",
            key: "3",
            children: (
              <>
                <div className="mt-4">
                    <RichTextEditor 
                      label="" 
                      placeholder="Masukan pesan template untuk jamaah" 
                      shortcode={true}
                      renderShortcode={shortCodeContentJamaah}
                      data={{value: formik.values.message_to_jamaah}}
                      onChange={(value) => formik.setFieldValue('message_to_jamaah', value)}
                      />
                </div>
              </>
            ),
          },
        ]}
      />
      </div>
    </>
  );
};

export default React.memo(FollowUpTwo);
