import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import UploadArea from "components/Uploadarea"
import Buttons from "components/buttons"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

import { handleUpdateManifestAPI } from 'utils/api/booking'
import { handleError, handleSuccess } from 'stores/actions/errorGeneral'

import { handleGetDataJamaah } from 'stores/actions/booking'
import { toPermalink } from 'utils/helpers'

const PasPhoto4x6 = ({
    data,
    jamaahOrderID,
    onSuccess,
    permissions,
    jamaahName,
    orderNumber
}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const formik = useFormik({
        initialValues: {
            order_kamar_mapping_id: jamaahOrderID,
            data
        }
    })
    const [isLoading, setIsLoading] = useState(false)

    const handleSave = async () => {
        setIsLoading(true)
        const response = await handleUpdateManifestAPI(orderNumber, formik.values)
        setIsLoading(false)

        if(response.status === 200) {
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate data manifest", code: 200 }))
            onSuccess()
        } else {
            dispatch(handleError(response.data))
        }

    }

    useEffect(() => {
        formik.setValues({
            order_kamar_mapping_id: jamaahOrderID,
            data
        })
    }, [data])
    return (
        <>
            <div className="border p-4 rounded-t-2xl">
                <UploadArea
                    viewOnly={!permissions.includes('update_manifest')}
                    label="Pas Photo 4x6"
                    customClass="!w-[245px] !h-[330px]"
                    defaultUrl={formik.values?.data?.pas_photo_4x6}
                    onChange={(url) => formik.setFieldValue('data.pas_photo_4x6', url)}
                    handleRemove={() => formik.setFieldValue('data.pas_photo_4x6', null)}
                    customName={`pas_photo_4x6-${orderNumber}-${toPermalink(jamaahName)}`}
                    />
            </div>
            {permissions.includes('update_manifest') && (
                <div className="border rounded-b-2xl flex justify-between border-t-0 p-4">
                    <div>
                    </div>
                    <div>
                        <Buttons
                            customClass="btn-primary btn-large"
                            text="Simpan"
                            loading={isLoading}
                            onClick={handleSave}
                            />
                    </div>
                </div>
            )}
        </>
    )
}

export default PasPhoto4x6