import axios from "axios";
import {
  BASE_URL_ENDPOINT,
  BASE_URL_ENDPOINT_V2,
} from "../../../../constant/constanta";

export const getAlbumDataJamaahService = (id) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/${id}/album-data-jamaah`);

export const updateHidePhotoAlbumJamaahService = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/album-data-jamaah/update`,
    payload
  );

export const saveDataQuestionService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-rating-question`, payload);

export const getEvisaDataJamaahService = (id, payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/package/${id}/e-visa`, payload);

export const getRatingPackageService = (id, payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/${id}/rating`, payload);


export const getRatingPerQuestionPackageService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/get-rating-question`, payload);

export const storeRatingQuestionsService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-rating-question`, payload)

export const removeRatingQuestionsService = (questionID) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/paket/rating-question/${questionID}`)

export const getRatingQuestionSurveys = (questionID, rating) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/paket/rating-question/${questionID}/rating/${rating}`)