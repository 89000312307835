import axios from "axios";
import { BASE_URL_ENDPOINT } from "../../../constant/constanta";

export const getAllCabangService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-cabang`, payload);

export const getDetailCabangService = (id) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/cabang/${id}`);

export const deleteIdCabangService = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/cabang/${id}`);

export const createCabangService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-cabang`, payload);

export const createWaEmailApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/cabang/update-wa-email`, payload);

export const getRegionApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/region`, payload);

export const activeNoWACabangService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/cabang/store-license`, payload);

export const checkLisensiWAService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/cabang/check-license`, payload);

export const generateQRWAService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/cabang/generate-qrcode`, payload);

export const getInfoWAService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/cabang/check-info-wa`, payload);

export const getHistoryWAWAService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/cabang/history-wa`, payload);

export const logoutWAService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/cabang/logout`, payload);

export const checkQRWAStatus = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/cabang/qr-status-wa`, payload);

export const updateWebsiteService = (payload) =>
  axios.put(`${BASE_URL_ENDPOINT}/admin/websites/update`, payload)