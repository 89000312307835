import axios from "axios";
import { BASE_URL_ENDPOINT, BASE_URL_ENDPOINT_V2 } from "../../../../constant/constanta";

export const getLaporanManasikLokasiService = (id) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/${id}/report-manasik/location`, {
    sort_column: "name",
    sort_order: "asc",
    per_page: 25,
    page_number: 1,
  });

export const exportLaporanManasikLokasiService = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-manasik/export-location`
  );

export const getLaporanManasikAbsensiService = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-manasik/absence`,
    payload
  );

export const exportLaporanManasikAbsensiService = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-manasik/export-absence`
  );

export const onHandleInputAbsensiAPI = (id, payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/${id}/manasik-absence`, payload)

// PERLENGKAPAN
export const onHandleGetEquipmentReportAPI = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-equipment`, payload
  );

export const exportLaporanEquipmentService = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-equipment/export`
  );

// ROOMLIST
export const onHandleGetRomlistReportAPI = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-room-list`, payload
  );

export const exportLaporanRoomlistService = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-room-list/export`
  );

export const onHandleGetOrderReportAPI = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-order`, payload
  );

export const onHandleGetOrderPaymentsReportAPI = (id, orderID, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-order/${orderID}/payments`, payload
  );


export const exportLaporanOrderReportService = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-order/export`
  );

export const onHandleGetSeatReportAPI = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-seat`, payload
  );


export const exportLaporanSeatReportService = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-seat/export`
  );

export const getListReportStartingService = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/jamaah-orders`,
    payload
  );

export const exportReportStartingService = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/report-starting/export`
  );