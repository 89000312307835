import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const ScrollCustom = ({
  height,
  children,
  style,
  scrollHorizontal,
  width,
  scroll,
  customClass,
  maxHeight,
  scollRef
}) => {
  const useStyles = makeStyles({
    scrollCustom: {
      height,
      ...(scrollHorizontal ? { width } : { width }),
      overflowY: "auto",
      overflowX: scrollHorizontal ? "auto" : "hidden",
      maxHeight,
      
      "&::-webkit-scrollbar": {
        width: "4px",
        height: scrollHorizontal ? "4px" : "100px",
      },
      "&::-webkit-scrollbar-track": {
        width: "4px",
        borderRight: "10px solid #fff",
        borderLeft: "10px solid #fff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#e0e0e0",
        // height: "30px",
        width: "4px",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-track-piece:start": {
        background: "#F4F7FB",
      },
      "&::-webkit-scrollbar-track-piece:end": {
        background: "#F4F7FB",
      },
    },
  });

  const classes = useStyles();
 
  if (!scroll) {
    return <div>{children}</div>;
  }

  return (
    <div 
      ref={scollRef}
      className={`${classes.scrollCustom} ${customClass}`} 
      style={style}>
      {children}
    </div>
  );
};

ScrollCustom.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  scrollHorizontal: PropTypes.bool,
  scroll: PropTypes.bool,
  customClass: PropTypes.string,
};

ScrollCustom.defaultProps = {
  height: "auto",
  width: "auto",
  children: null,
  style: {},
  scrollHorizontal: false,
  scroll: true,
  customClass: null,
  maxHeight: "100%",
};

export default React.memo(ScrollCustom);
