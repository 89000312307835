import { useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { saveDataPaket } from "../../../../../../stores/actions/paket/infoDasar";

import Popup from "../../../../../../components/popup";
import Steppers from "../../../../../../components/steps/Steppers";
import Buttons from "../../../../../../components/buttons";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { getAllPaket } from "stores/actions/paket/landing";
import { handleSuccess } from "stores/actions/errorGeneral";
import Scroll from "components/scroll";

const useStyle = makeStyles({
  custom_stepper: {
    paddingTop: "14px",
    width: "120px",
    "& .ant-steps-item": {
      height: "330px !important",
      "&:last-child": {
        height: "20px !important",
      },
      "& .ant-steps-item-icon": {
        marginInlineEnd: "0 !important",
      },
      "& .ant-steps-item-title": {
        lineHeight: "20px !important",
        paddingInlineEnd: "0 !important",
        textAlign: "center",
      },
      "& .ant-steps-item-tail": {
        left: "49% !important",
      },
    },
  },
  modal_body: {
    display: "flex",
    gap: "20px",
    "& .scroll_area": {
      height: "422px",
      overflow: "auto",
      padding: "14px",
      width: "100%",
    },
    "& .action_area": {
      borderTop: "1px solid #e5e7eb",
      display: "flex",
      justifyContent: "flex-end",
      padding: "8px 14px",
    },
  },
});

const AddPaketPopup = ({ open, handleClose }) => {
  const classes = useStyle();

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.infoDasar);

  const [currentStep, setCurrentStep] = useState(0);

  const stepItems = [
    {
      title: "Info Dasar",
    },
    {
      title: "Jumlah Seat Bus",
    },
  ];

  const [payload, setPayload] = useState({
    judul_paket: "",
    jadwal_keberangkatan: null,
    musim_id: "",
    jumlah_hari: "",
    kategori: "",
    tag: "",
    deskripsi: "",
    image_thumbnail: "",
    tipe_paket: "3",
    maskapai_id: "",
    slug: ""
  });

  const [busItems, setBusItems] = useState([
    {
      nama_bus: "",
      jumlah_seat: "",
    },
  ]);

  const handleFormChange = (index, event) => {
    let data = [...busItems];
    data[index][event.target.name] = event.target.value;
    setBusItems(data);
  };

  const saveData = () => {
    const data = {
      info_dasar: payload,
      bus: busItems,
    };
    dispatch(
      saveDataPaket(data, (message) => {
        dispatch(handleSuccess(null, {message: "Berhasil menambahkan paket", code: 200}));

        const payloadGet = {
          tipe_paket: 3,
          keyword: "",
          per_page: 10,
          page_number: 1,
          sort_column: "id_paket",
          sort_order: "DESC",
          status: [],
          status_keberangkatan: [],
          slug: ","
        };
        dispatch(
          getAllPaket(payloadGet, (meesage) => {
            setCurrentStep(0);
            setBusItems([
              {
                nama_bus: "",
                jumlah_seat: "",
              },
            ]);
            setPayload({
              judul_paket: "",
              jadwal_keberangkatan: "",
              musim_id: "",
              jumlah_hari: "",
              kategori: "",
              tag: "umroh",
              deskripsi: "",
              image_thumbnail: "",
              tipe_paket: "2",
              maskapai_id: "",
            });
            handleClose();
          })
        );
      })
    );
  };

  const isAbleToNextStep1 =
    payload.judul_paket &&
    payload.jadwal_keberangkatan &&
    payload.musim_id &&
    payload.jumlah_hari &&
    payload.kategori &&
    payload.tag &&
    payload.deskripsi !== "<p><br></p>" &&
    payload.image_thumbnail &&
    payload.tipe_paket &&
    payload.maskapai_id &&
    payload.slug

  const hasEmptyBusValue = busItems.some(
    (item) => item.nama_bus === "" || item.jumlah_seat === ""
  );

  const handleDeletBus = (index) => {
    let temp = [...busItems];
    temp.splice(index, 1);

    setBusItems(temp);
  };

  return (
    <Popup
      open={open}
      handleClose={handleClose}
      title="Tambah Paket Umroh"
      height="fit-content"
      width="1172px"
    >
      <div className={classes.modal_body}>
        <div className=" @apply shadow-[0px_4px_80px_-4px_#0000000A] rounded-lg p-3">
          <Steppers
            containerClass={`${classes.custom_stepper} custom-stepper-vertical m-auto`}
            items={stepItems}
            current={currentStep}
            labelPlacement="vertical"
            direction="vertical"
          />
        </div>
        <div className="@apply shadow-[0px_4px_80px_-4px_#0000000A] rounded-lg w-full p-[16px]">
          <div className="scroll_area costumScrollbar">
            {currentStep === 0 && (
              <Step1 payload={payload} setPayload={setPayload} />
            )}
            {currentStep === 1 && (
              <Step2
                items={busItems}
                handleFormChange={handleFormChange}
                setItems={setBusItems}
                handleDeletBus={handleDeletBus}
              />
            )}
          </div>
          <div className="action_area">
            {currentStep !== 1 ? (
              <Buttons
                text="Selanjutnya"
                customClass={`btn-primary ${
                  !isAbleToNextStep1 && "disabled"
                } btn-medium w-fit`}
                onClick={() => setCurrentStep(currentStep + 1)}
                disabled={!isAbleToNextStep1}
              />
            ) : (
              <div className="flex gap-5">
                <Buttons
                  text="Kembali"
                  customClass="btn-outline btn-medium w-fit"
                  onClick={() => setCurrentStep(currentStep - 1)}
                />
                <Buttons
                  text="Tambah"
                  customClass={`btn-primary ${
                    hasEmptyBusValue && "disabled"
                  } btn-medium w-fit`}
                  onClick={saveData}
                  loading={isLoading}
                  disabled={hasEmptyBusValue}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default AddPaketPopup;
