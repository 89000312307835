import DateRangePicker from "./DateRangePicker";
import DatePicker from "./DatePicker";

const DatePickerDocs = () => {
    const onDatePickerChange = (val) => {
        console.log(val);
    };

    return (
        <div>
            <h2 className="font-semibold mt-5 mb-2">Single Date Picker</h2>
            <DatePicker
                placeholder="Pilih Tanggal"
                onChange={onDatePickerChange}
                calendar
            />
            <h2 className="font-semibold mt-5 mb-2">Date Range Picker</h2>
            <DateRangePicker
                placeholder="Periode"
                onCalendarChange={onDatePickerChange}
            />
            <h2 className="font-semibold mt-5 mb-2">
                Date Range Picker with Range Presets
            </h2>
            <DateRangePicker
                placeholder="Periode"
                onCalendarChange={onDatePickerChange}
                showRangePresets={true}
            />
        </div>
    );
};

export default DatePickerDocs;
