import React from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";

import './style.css'

const InputRadio = ({
    value,
    checked,
    onChange
}) => {
    return (
        <>
            <Radio 
                value={value}
                checked={checked}
                onChange={onChange}
            />
        </>
    )
}

InputRadio.propTypes = {
    value: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func
}

InputRadio.defaultProps = {
    value: "",
    checked: false,
    onChange: () => {},
}

export default InputRadio