// general Actions
// --------------------------------------------------------

import { handleError } from "../errorGeneral";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "general/SET_LOADING";
export const CLEAR_ERROR = "general/CLEAR_ERROR";
export const SET_ERROR = "general/SET_ERROR";
export const INIT_DATA = "general/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "general/SET_DOUBLE_SUBMIT";
export const SET_DETAIL_BY_ID = "general/SET_DETAIL_BY_ID";
export const SET_GLOBAL_STATE = "general/SET_GLOBAL_STATE";
export const SET_AFTER_VALIDATION = "general/SET_AFTER_VALIDATION";
export const SET_VALIDATION = "general/SET_VALIDATION";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const set_after_validation = (payload) => ({
  type: SET_AFTER_VALIDATION,
  payload,
});

export const setValidation = (payload) => ({
  type: SET_VALIDATION,
  payload,
});

export const setDetailById = (payload) => ({
  type: SET_DETAIL_BY_ID,
  payload,
});

export const setGlobalState = (payload) => ({
  type: SET_GLOBAL_STATE,
  payload,
});

export const detailById = (payload) => async (dispatch) => {
  try {
    dispatch(setDetailById(payload));
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const globalState = (payload) => async (dispatch) => {
  try {
    dispatch(setGlobalState(payload));
  } catch (error) {
    dispatch(handleError(error));
  }
};
