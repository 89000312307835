import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AccordionsStyle from "./Accordions.Style";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "components/avatar";

const Accordions = ({
  containerClass,
  customClass,
  customSummaryClass,
  customTitleClass,
  customContentClass,
  avatar,
  title,
  content,
  disabled,
  arrowIcon,
  AvatarJamaah,
  onChange,
  defaultExpanded
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const handleChange = () => {
    setExpanded(!expanded);
    onChange(!expanded)
  }

  useEffect(() => {
    setExpanded(defaultExpanded)
  }, [defaultExpanded])

  return (
    <AccordionsStyle className={`${containerClass}`}>
      <Accordion
        className={`custom-accordion ${customClass}`}
        disabled={disabled}
        onChange={handleChange}
        expanded={expanded}
      >
        <AccordionSummary
          className={customSummaryClass}
          expandIcon={
            !arrowIcon 
              ? <ExpandMoreIcon sx={{ color: "#141414" }} />
              : <img src={arrowIcon} className="w-8 h-8" />
          }
          //   aria-controls="panel1a-content"
          //   id="panel1a-header"
        >
          <div className="flex flex-row items-center gap-2">
            {avatar !== "" && <Avatar imageTemp={avatar} />}
            {AvatarJamaah && (
              <>
                <Avatar 
                 value={AvatarJamaah}
                 />
              </>
            )}
            <div className={`text-[16px] ${customTitleClass}`}>{title}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={customContentClass}>{content}</AccordionDetails>
      </Accordion>
    </AccordionsStyle>
  );
};

Accordions.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  customSummaryClass: PropTypes.string,
  customTitleClass: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  arrowIcon: PropTypes.string,
  customContentClass: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  onChange: PropTypes.func
};

Accordions.defaultProps = {
  containerClass: "",
  customClass: "",
  customSummaryClass: "",
  customTitleClass: "",
  customContentClass: "",
  avatar: "",
  title: "Accordion",
  disabled: false,
  arrowIcon: null,
  defaultExpanded: false,
  onChange: () => {},
};

export default React.memo(Accordions);
