import styled from "styled-components";

const DrawersStyle = styled.div`
  .ant-drawer .ant-drawer-body {
    padding: 16px;
  }
  .ant-drawer-content-wrapper {
    width: ${(props) =>
      props.isItenary ? "540px !important" : "400px !important"};
  }
`;

export default DrawersStyle;
