import Tab from "components/tab";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RatingPerjamaah from "./Tabs/RatingPerjamaah";
import RatingQuestion from "./Tabs/RatingQuestion";
import Buttons from "components/buttons";
import Popup from "components/popup";
import PopupAddQuestion from "./Tabs/PopupAddQuestion";
import { saveRatingQuestion } from "stores/actions/paket/ibadahku";


const Rating = () => {
  const [isSelected, setIsSelected] = useState("1");
  const [isVisibled, setIsVisibled] = useState(false);

  const [dataEdit, setDataEdit] = useState(null);

  const [isGettingQuestions, setIsGettingQuestions] = useState(false);

  const dispatch = useDispatch();

  const handleSelectTab = (value) => {
    setIsSelected(value);
  };

  const handleSubmit = (value) => {
    const payload = {};
    dispatch(saveRatingQuestion(payload));
  };


  const RatingTabs = [
    {
      label: <span className="flex gap-1">Rating Perjamaah</span>,
      key: "1",
      children: <RatingPerjamaah />,
    },
    {
      label: <span className="flex gap-1">Rating Question</span>,
      key: "2",
      children: <RatingQuestion 
                  isGettingQuestions={isGettingQuestions} 
                  setIsGettingQuestions={setIsGettingQuestions} 
                  handleUpdate={(editQuestionData) => {
                    console.log("Hi")
                    setIsVisibled(true);
                    setDataEdit(editQuestionData);
                  }}
                  />,
    },
  ];

  return (
    <>
      <Popup
        open={isVisibled}
        handleClose={() => {
          setDataEdit(null);
          setIsVisibled(false);
        }}
        title={`${dataEdit ? "Edit" : "Tambah"} Pertanyaan Rating`}
        containerClass=""
        children={
        <PopupAddQuestion 
          dataEdit={dataEdit}
          onSuccess={() => {
            setIsVisibled(false);
            setIsGettingQuestions(true);
          }} 
        onClick={(value) => handleSubmit(value)} />}
      />
      <div className="border-b relative w-full p-4">
        <div>
          <Tab
            customClass="w-full"
            containerClass="w-full"
            defaultActiveKey={isSelected}
            items={RatingTabs}
            onChange={(value) => handleSelectTab(value)}
            tabBarExtraContent={
              isSelected === "2" && (
                <div className="ml-auto mr-4">
                  <Buttons
                    text="Tambah Question"
                    customClass="btn-primary btn-small"
                    onClick={() => setIsVisibled(true)}
                  />
                </div>
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default Rating;
