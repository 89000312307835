import React from "react";
import Inputs from "../../../../../components/form/inputs";
import ImageField from "../../../../../components/image_field";
import Grid from "@mui/material/Grid";
import Selects from "components/form/selects";

function InfoTransport({
  valueCategory,
  valueName,
  formikOnChange,
  formikValue,
  options,
  setOptions,
}) {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={12} md={6}>
        <ImageField
          name="image_logo"
          label="Logo Transportasi"
          customContainer="!rounded-2xl"
          width="16rem"
          height="16rem"
          setFile={(file) => {
            formikValue("image_logo", file?.path);
          }}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <ImageField
          name="image_thumbnail"
          label="Thumbnail Transportasi"
          customContainer="!rounded-2xl"
          width="100%"
          height="16rem"
          setFile={(file) => {
            formikValue("image_thumbnail", file?.path);
          }}
        />
      </Grid>

      <Grid item sm={12} md={6}>
        <Inputs
          id="name"
          name="name"
          label="Nama Transportasi"
          placeholder="Masukan Nama Kendaraan"
          onChange={formikOnChange}
          value={valueName}
        />
      </Grid>

      <Grid item sm={12} md={6}>
        <Selects
          label="Kategori Transportasi"
          placeholder="Text Input"
          options={options}
          onChange={(values) => setOptions(values.map((x) => x.value)[0])}
        />
      </Grid>
    </Grid>
  );
}

export default InfoTransport;
