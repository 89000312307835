/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import Cards from "../../../../components/cards";
import Buttons from "../../../../components/buttons";
import Steppers from "../../../../components/steps/Steppers";
import Places from "../../../../components/map";
import Inputs from "../../../../components/form/inputs";
import Textareas from "../../../../components/form/textarea";
import useDebounce from "../../../../utils/helpers/useDebounce";

import IconLocation from "../../../../assets/icons/location-black.svg";
import { useDispatch, useSelector } from "react-redux";
import { getRegion } from "../../../../stores/actions/cabang";
import { Select } from "antd";

const defaultLatLeng = {
  lat: -7.7955798,
  lng: 110.369488,
};

const Alamat = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setCurrentStep,
  formik,
}) => {
  const dispatch = useDispatch();
  const { byDetail } = useSelector((state) => state.general);
  const { region, isLoading } = useSelector((state) => state.cabang);
  const [stepAddress, setStepAddress] = useState(0);
  const [selected, setSelected] = useState({
    lat: Number(values?.latitude) || defaultLatLeng.lat,
    lng: Number(values?.longitude) || defaultLatLeng.lng,
  });
  const [fullLocation, setFullLocation] = useState(
    values?.address_lat_long || ""
  );

  const handleSetAdress = (data) => {
    setFieldValue("address_lat_long", data);
    setFullLocation(data);
  };

  const handleSelected = (data) => {
    setFieldValue("latitude", data.lat);
    setFieldValue("longitude", data.lng);
    setSelected(data);
  };
  let alamat = `${byDetail?.data?.subdistrict_name}, ${byDetail?.data?.city_name}, ${byDetail?.data?.province_name}`;

  const onChange = (data) => {
    let splitIdLocation = data.split(",");
    setFieldValue("province_id", splitIdLocation[splitIdLocation.length - 1]);
    setFieldValue("city_id", splitIdLocation[splitIdLocation.length - 2]);
    setFieldValue(
      "subdistrict_id",
      splitIdLocation[splitIdLocation.length - 3]
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  const debounceLocation = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debounceLocation !== "") {
      dispatch(
        getRegion({
          keyword: debounceLocation,
        })
      );
    }
  }, [debounceLocation, dispatch]);

  const location =
    region?.total_data !== 0 &&
    region?.data?.map((item) => {
      return {
        ...item,
        value: `${item.subdistrict_id}, ${item.city_id}, ${item.province_id}`,
        label: `${item.subdistrict_name}, ${item.city_name}, ${item.province_name}`,
      };
    });

  const items = [
    {
      title: "Pilih Koordinat Lokasi",
    },
    {
      title: "Alamat Lengkap",
    },
  ];

  const getContent = () => {
    if (stepAddress === 0) {
      return (
        <>
          <div className="p-[20px] flex flex-row">
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Places
                  containerClass="!w-full h-[318px]"
                  // selected={location?.lat !== "" ? location : selected}
                  selected={selected}
                  setSelected={handleSelected}
                  address={fullLocation}
                  setAdress={handleSetAdress}
                />
              </Grid>
              <Grid item md={6}>
                <div className="card_container">
                  <h2>
                    Koordinat Lokasi: <b>{fullLocation}</b>
                  </h2>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="divider"></div>
          <div className="px-[20px] py-[6px] flex flex-row justify-end gap-2">
            <Buttons
              text="Kembali"
              customClass="btn-outline btn-large"
              onClick={() => setCurrentStep(0)}
            />
            <Buttons
              text="Selanjutnya"
              customClass="btn-primary btn-large"
              onClick={() => setStepAddress(1)}
            disabled={selected.lng === defaultLatLeng.lng && selected.lat === defaultLatLeng.lat}
          />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="p-[20px]">
            <Inputs
              placeholder="Lokasi"
              value={fullLocation}
              customClass="!py-[10px] input-disabled"
              icon={IconLocation}
              disabled
            />
            <Grid container spacing={2} className="!mt-4">
              <div className="font-semibold pl-2">
                Kecamatan, Kota, Provinsi
              </div>
              <Grid item md={12}>
                <Select
                  showSearch
                  placeholder="Pilih lokasi"
                  optionFilterProp="children"
                  onChange={onChange}
                  className="custom-select w-full"
                  size="large"
                  onSearch={(value) => setSearchTerm(value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  defaultValue={
                    byDetail?.data !== undefined ? alamat : "Pilih Lokasi"
                  }
                  options={location}
                />
              </Grid>
              <Grid item md={12}>
                <Textareas
                  id="address_details"
                  label="Alamat Lengkap"
                  value={values.address_details}
                  name="address_details"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Masukan alamat lengkap"
                />
              </Grid>
            </Grid>
          </div>
          <div className="divider"></div>
          <div className="px-[20px] py-[6px] flex flex-row justify-end gap-2">
            <Buttons
              text="Kembali"
              customClass="btn-outline btn-large"
              onClick={() => setStepAddress(0)}
            />
            <Buttons
              text="Selanjutnya"
              customClass="btn-primary btn-large"
              onClick={() => setCurrentStep(2)}
              loading={isLoading ? true : false}
              disabled={
                !(
                  formik.dirty &&
                  formik.isValid 
                )
              }
            />
          </div>
        </>
      );
    }
  };

  return (
    <Cards containerClass="shadow-soft !p-0">
      <div className="p-[20px]">
        <Steppers
          containerClass="w-[80%] m-auto"
          labelPlacement="vertical"
          items={items}
          current={stepAddress}
        />
      </div>
      <div className="divider"></div>
      {getContent()}
    </Cards>
  );
};

export default React.memo(Alamat);
