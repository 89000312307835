import React, { useEffect, useState } from "react";
import Card from "../../../../../components/cards";
import StepsComponentDataMaster from "../../steps";
import InfoAkomodasiComponent from "./info";
import AlamatAkomodasiComponent from "./address";
import GaleriAkomodasiComponent from "./galery";
import Steppers from "../../../../../components/steps/Steppers";
import Buttons from "../../../../../components/buttons";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { addDataAkomodasi } from "stores/actions/datamaster";
import * as Yup from "yup";

function AkomodasiComponentModal({ setOpen }) {
  const [current, setCurrent] = useState(0);
  // const [city, setCity] = useState("");
  const [dataVideo, setDataVideo] = useState([]);
  const [multipleUpload, setMultipleUpload] = useState([]);
  const [addressDetails, setAddressDetail] = useState("");
  const AkomodasiSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      image_logo: "",
      image_thumbnail: "",
      kategori: "hotel",
      bintang: "",
      deskripsi: "",
      latitude: "",
      longitude: "",
      address_lat_long: "",
      name: "",
      city: "",
      distance_alharam: null,
      distance_nabawi: null
    },
    validationSchema: AkomodasiSchema,
  });

  const dispatch = useDispatch();

  const items = [
    {
      title: "Info Dasar",
    },
    {
      title: "Alamat & Koordinat",
    },
    {
      title: "Galeri",
    },
  ];

  const getContent = () => {
    switch (current) {
      case 0:
        return (
          <InfoAkomodasiComponent
            formik={formik}
            errors={formik.errors}
            formikValue={formik.setFieldValue}
            bintang={formik.values.bintang}
            valueDescription={formik.values.deskripsi}
            descriptionOnChange={(value) => {
              formik.setFieldValue("deskripsi", value);
            }}
            formikOnChange={formik.handleChange}
            touched={formik.touched}
          />
        );
      case 1:
        return (
          <AlamatAkomodasiComponent
            formikValue={formik.setFieldValue}
            formikAddressDetails={formik.values.address_details}
            formikOnChange={formik.handleChange}
            setAddressDetail={setAddressDetail}
            formikDataValues={formik.values}
          />
        );
      case 2:
        return (
          <GaleriAkomodasiComponent
            formikOnChange={formik.handleChange}
            setDataVideo={setDataVideo}
            dataVideo={dataVideo}
            formikValue={formik.setFieldValue}
            setMultipleUpload={setMultipleUpload}
          />
        );

      default:
        return null;
    }
  };

  // submit form
  const handleSave = () => {
    // return console.log(formik.values);
    dispatch(
      addDataAkomodasi({
        ...formik.values,
        alamat_lengkap: `${addressDetails}`,
        url_video: dataVideo,
        images: multipleUpload,
        latitude: `${formik.values.latitude}`,
        longitude: `${formik.values.longitude}`,
        name: `${formik.values.name}`,
      })
    );
    setOpen(false);
  };

  return (
    <>
      <Card containerClass="!px-0 !pt-6 !pb-0 !shadow-none !rounded-2xl !border !border-gray-200">
        <div className="w-full flex justify-center  border-b pb-5 border-gray-300   ">
          <div className="w-[80%]">
            <Steppers
              items={items}
              current={current}
              labelPlacement="vertical"
            />
          </div>
        </div>

        <div className="overflow-y-auto px-4 my-2.5 h-[24.5rem]">
          {getContent()}
        </div>

        <div className="border-t py-6 border-gray-300 items-center flex justify-end px-4 flex-row gap-3">
          <Buttons
            text="Kembali"
            disabled={current === 0 ? true : false}
            customClass={`btn-outline btn-large ${
              current === 0 ? "disabled" : ""
            }`}
            onClick={() => setCurrent(current - 1)}
          />

          {current === 0 && (
            <Buttons
              text="Selanjutnya"
              disabled={
                formik.values.image_logo !== undefined &&
                formik.values.image_thumbnail !== undefined &&
                formik.values.kategori !== "" &&
                formik.values.rating !== "" &&
                formik.values.deskripsi !== "<p><br></p>" &&
                formik.values.name !== ""
                  ? false
                  : true
              }
              customClass={`btn-primary btn-large ${
                formik.values.image_logo !== undefined &&
                formik.values.image_thumbnail !== undefined &&
                formik.values.kategori !== "" &&
                formik.values.rating !== "" &&
                formik.values.deskripsi !== "<p><br></p>" &&
                formik.values.name !== ""
                  ? ""
                  : "disabled"
              }`}
              onClick={() => setCurrent(current + 1)}
            />
          )}

          {current === 1 && (
            <Buttons
              text="Selanjutnya"
              disabled={
                formik.values.latitude !== "" &&
                formik.values.address_details !== "" &&
                formik.values.longitude !== "" 
                  ? false
                  : true
              }
              customClass={`btn-primary btn-large ${
                formik.values.latitude !== "" &&
                formik.values.address_details !== "" &&
                formik.values.longitude !== ""
                  ? ""
                  : "disabled"
              }`}
              onClick={() => setCurrent(current + 1)}
            />
          )}

          {current === items.length - 1 && (
            <Buttons
              text="Simpan"
              disabled={
                dataVideo.length !== 0 && multipleUpload.length !== 0
                  ? false
                  : true
              }
              customClass={`btn-primary btn-large ${
                dataVideo.length !== 0 && multipleUpload.length !== 0
                  ? ""
                  : "disabled"
              }`}
              onClick={handleSave}
            />
          )}
        </div>
      </Card>
    </>
  );
}

export default AkomodasiComponentModal;
