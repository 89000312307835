import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteDataTestimoni,
  getDataTestimoni,
} from "stores/actions/apps/testimoni";

import Popup from "components/popup";
import Buttons from "components/buttons";
import Selects from "components/form/selects";
import Rates from "components/form/rate";
import Badge from "components/badge";

import AvatarRed from "assets/images/Avatar-ikhwan-red.svg";
import IconDelete from "assets/icons/trash.svg";
import AddTestimoni from "./components/AddTestimoni";
import { useEffect } from "react";
import Avatar from "components/avatar";

const CardTestimoni = ({ item }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const showJenisPaket = (jenis) => {
    switch (jenis) {
      case 1:
        return "Haji";
      case 2:
        return "Umroh";
      case 3:
        return "Tabungan";
      case 4:
        return "Tour";
      case 5:
        return "Haji Badal";
      default:
        return "Haji";
    }
  };

  const deleteData = async (id) => {
    try {
      setIsLoading(true);
      const res = await dispatch(deleteDataTestimoni(id));
      console.log(res.status);
      if (res.status == 200) {
        dispatch(getDataTestimoni());
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white soft-shadow rounded-2xl">
      <div className="flex flex-row justify-between p-4">
        <div className="flex flex-row items-center gap-2 relative">
          <Avatar imageTemp={item.image} />
          <div>
            <div className="flex gap-2 items-center">
              <div className="font-semibold">{item.nama}</div>
              <div>
                {item.jenis_paket == 1 && <Badge backgroundColor={"#BBDEFB"} color={"#0000FE"} label="Haji" />}
                {item.jenis_paket == 2 && <Badge backgroundColor="#92E6A7" color="#1B662E" label="Umroh" />}
                {item.jenis_paket == 3 && <Badge backgroundColor={"#F7A399"} color="#FE0000" label="Tour" />}
                {item.jenis_paket == 4 && <Badge label="Tabungan" />}
                {item.jenis_paket == 5 && <Badge label="Jasa Badal" />}
              </div>
            </div>
            <Rates containerClass="mt-1" value={item.rating} />
          </div>
        </div>
        {permissions?.includes('delete_apps') && (
          <>
            {isLoading ? (
              <Buttons
                customClass="bg-white hard-shadow rounded-lg cursor-pointer p-2 h-fit !w-fit"
                onClick={() => deleteData(item.id)}
                text="."
                loading={isLoading}
              />
            ) : (
              <button
                className="bg-white hard-shadow rounded-lg cursor-pointer p-2 h-fit"
                onClick={() => deleteData(item.id)}
              >
                <img src={IconDelete} alt="delete" />
              </button>
            )}
          </>
        )}
      </div>
      <div className="divider"></div>
      <div className="p-4 text-[#828282]">{item.pesan}</div>
    </div>
  );
};
const AppTestimoni = () => {
  const { dataTestimoni, isLoading } = useSelector((state) => state.testimoni);
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const [packageTypeSelected, setPackageTypeSelected] = useState(null)

  const [options, setOptions] = useState([
    {
      value: "",
      label: "Semua",
    },
    {
      value: 1,
      label: "Haji",
    },
    {
      value: 2,
      label: "Umroh",
    },
    {
      value: 3,
      label: "Tabungan",
    },
    {
      value: 4,
      label: "Tour",
    },
    {
      value: 5,
      label: "Haji Badal",
    },
  ]);

  const handleGetTestimoni = () => {
    const payload = {
      keyword: "",
      per_page: 10,
      page_number: 1,
      sort_column: "rating",
      sort_order: "ASC",
      jenis_paket: packageTypeSelected
    };

    dispatch(getDataTestimoni(payload));
    
  }
  
  useEffect(() => {
    handleGetTestimoni()
  }, []);

  useEffect(() => {
    handleGetTestimoni()
  }, [packageTypeSelected]);

  return (
    <>
      <Popup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        title="Tambah Testimoni"
        width="982px"
        children={<AddTestimoni handleClose={() => setOpenPopup(false)} />}
      />
      <div className="bg-white soft-shadow rounded-[20px]">
        <div className="flex flex-row justify-between p-4">
          <div className="text-[20px] font-semibold">Testimonial</div>
          {permissions?.includes('create_apps') && (
            <Buttons
              text="Tambah"
              customClass="btn-primary btn-small"
              onClick={() => setOpenPopup(true)}
            />
          )}
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <Selects
            label="Jenis Paket"
            placeholder="Semua Jenis"
            customClass="!w-[200px]"
            options={options}
            searchable
            itemRenderer={({ state, item, methods }) => {
              return (
                <div
                  className={`react-dropdown-select-item ${
                    state.values.length !== 0 &&
                    state.values[0].value === item.value &&
                    "react-dropdown-select-item-selected"
                  }`}
                  onClick={() => 
                    {
                      methods.addItem(item)
                      setPackageTypeSelected(item.value)
                    }
                  }
                >
                  {item.label}
                </div>
              );
            }}
          />
          <div className="grid grid-cols-2 gap-4 mt-4">
            {dataTestimoni.length > 0 &&
              dataTestimoni.map((item) => (
                <CardTestimoni item={item} key={item.id} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AppTestimoni);
