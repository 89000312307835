import React from "react";
import PropTypes from "prop-types";
import TextArea from "antd/es/input/TextArea";

import TextareasStyle from "./Textareas.style";
import Close from "../../../assets/icons/Close.svg";
import Check from "../../../assets/icons/Check.svg";

const Textareas = ({
  containerClass,
  customClass,
  id,
  label,
  rows,
  placeholder,
  maxLength,
  value,
  disabled,
  success,
  error,
  onChange,
  onBlur,
  defaultValue,
}) => {
  return (
    <TextareasStyle className={`${containerClass}`}>
      {label !== "" && (
        <div
          className={`textarea-label ${disabled && "textarea-label--disabled"}`}
        >
          {label}
        </div>
      )}
      <TextArea
        id={id}
        rows={rows}
        className={`custom-textarea ${customClass}`}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        maxLength={maxLength}
        onChange={onChange}
        onBlur={onBlur}
      />
      {success !== "" && (
        <div className="flex flex-row items-center">
          <img src={Check} alt="check" />
          <div className="textarea-success-msg">{success}</div>
        </div>
      )}
      {error !== "" && (
        <div className="flex flex-row items-center">
          <img src={Close} alt="close" />
          <div className="textarea-error-msg">{error}</div>
        </div>
      )}
    </TextareasStyle>
  );
};

Textareas.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  success: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Textareas.defaultProps = {
  containerClass: "",
  customClass: "",
  id: "",
  label: "",
  rows: 5,
  placeholder: "",
  maxLength: 255,
  disabled: false,
  success: "",
  error: "",
  onChange: () => {},
  onBlur: () => {},
};

export default React.memo(Textareas);
