import {
  START_FETCHING_ADDITIONAL,
  SUCCESS_FETCHING_ADDITIONAL,
  ERROR_FETCHING_ADDITIONAL,
  SET_PAGE_ADDITIONAL,
  SET_SIZE_ADDITIONAL,
  SET_KEYWORD_ADDITIONAL,
  SET_TOTAL_ADDITIONAL,
  SET_ID_ROLE,
} from "./actionTypes";
import {
  deleteAdditional,
  getAdditionalByPaket,
  saveAdditional,
} from "utils/api/paket/additional";
import debounce from "debounce-promise";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
let debounceGetAdditional = debounce(getAdditionalByPaket, 500);

const startFetchingAdditional = () => ({
  type: START_FETCHING_ADDITIONAL,
});

const errorFetchingAdditional = () => ({
  type: ERROR_FETCHING_ADDITIONAL,
});

const successFetchingAdditional = (data) => ({
  type: SUCCESS_FETCHING_ADDITIONAL,
  data,
});

const setPage = (page) => ({
  type: SET_PAGE_ADDITIONAL,
  page,
});

const setSize = (size) => ({
  type: SET_SIZE_ADDITIONAL,
  size,
});

const setTotal = (total) => ({
  type: SET_TOTAL_ADDITIONAL,
  total,
});

const setKeyword = (keyword) => ({
  type: SET_KEYWORD_ADDITIONAL,
  keyword,
});

const setRoleId = (id_role) => ({
  type: SET_ID_ROLE,
  id_role,
});

const fetchDataAdditional = (payload, successAction) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingAdditional());
    try {

      let res = await debounceGetAdditional(payload);
      if (res.data.code === 401) {
        window.location.href = "/example";
      }
      let data = res.data.data === "" ? [] : res.data.data;
      dispatch(successFetchingAdditional(data));
      successAction(res.data)
      // dispatch(setTotal(res.data.total_data));
      // dispatch(setPage(res.data.page));
    } catch (error) {

      dispatch(errorFetchingAdditional());
    }
  };
};

const createAdditional = async (payload, action) => {
  try {
    let res = await saveAdditional(payload);
    if (res.status === 200) action();
  } catch (error) {
    console.log("error: ", error.response);
  }
};

export const saveAdditionalPaket = (payload, actionSuccess, actionError) => {
  return async (dispatch) => {
    try {
      const res = await saveAdditional(payload);

      if (res.status === 200) {
        actionSuccess(res.data);
        dispatch(handleSuccess(null, {message: `Berhasil ${payload.id ? 'memperbarui' : 'menambahkan'} additional paket`, code: 200}))
      } else {
        if(res.status == 400 || res.status == 422) {
          actionError(res.data?.data);
        } else {
          dispatch(handleError(res.data));
        }
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const deleteAdditionals = (id, actionSuccess, actionError) => {
  return async (dispatch) => {
    dispatch(startFetchingAdditional());
    // try {
      let res = await deleteAdditional(id);
      if (res.status === 200) {
        dispatch(handleSuccess(null, { message: "Berhasil menghapus additional", code: 200 }));
        actionSuccess(res.data);
      } else {
        actionError(res);
        dispatch(handleError(res.data));
      }
    // } catch (error) {
    //   dispatch(handleError(error));
    // }
  };
};

const AdditionalAction = {
  fetchData: fetchDataAdditional,
  createData: createAdditional,
  deleteData: deleteAdditionals,
  setPage: setPage,
  setSize: setSize,
  setKeyword: setKeyword,
  setRoleId: setRoleId,
};

export default AdditionalAction;
