import {
    SET_FETCH_SALDO
} from "../../actions/fee";

const initialState = {
    isLoading: false,
    saldoFee: 0,
};
  
export default (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_FETCH_SALDO:
            return {
                ...state,
                saldoFee: payload
            };
        default:
            return state;
    }
}