import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Selects from "components/form/selects";
import Rates from "components/form/rate";
import Badge from "components/badge";

import IconStar from "assets/icons/Star.svg";
import AvatarRed from "assets/images/Avatar-ikhwan-red.svg";
import IconDelete from "assets/icons/trash.svg";
import Buttons from "components/buttons";

import { getAllPaketService } from "utils/api/paket";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import IconJamPasir from "assets/icons/Jam-Pasir.svg";

import { getRatingPackageService } from "utils/api/paket/ibadahku";
import TestimoniAPI from "utils/api/apps/testimoni";

import {
  getDataTestimoni,
} from "stores/actions/apps/testimoni";

import Spinner from "components/spinner";
// import { Avatar } from "antd";
import { toCapital } from "utils/helpers";
import Avatar from "components/avatar";
import Rating from "components/rating";

const AddTestimoniIbadahku = ({
  handleClose
}) => {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState([]);
  const [value, setValue] = useState(3);

  const [packageTypeSelected, setPackageTypeSelected] = useState(0);
  const [packageSelected, setPackageSelected] = useState(null);

  const [reviews, setReviews] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);

  const [getting, setGetting] = useState(false);
  const [adding, setAdding] = useState(false);
  const [addingID, setAddingID] = useState(null);

  const packageTypes = [
    {
      value: 0,
      label: "Semua",
    },
    {
      value: 1,
      label: "Haji",
    },
    {
      value: 2,
      label: "Umroh",
    },
    {
      value: 3,
      label: "Tour",
    },
    {
      value: 4,
      label: "Tabungan",
    },
    {
      value: 5,
      label: "Jasa Badal",
    },
  ]

  const listRating = [
    {
      key: null,
      description: "Semua"
    },
    {
      key: 1,
      description: "Sangat Buruk",
    },
    {
      key: 2,
      description: "Buruk",
    },
    {
      key: 3,
      description: "Cukup",
    },
    {
      key: 4,
      description: "Baik",
    },
    {
      key: 5,
      description: "Sangat Baik",
    },
  ]
  

  const handleGetPackages = async () => {
    let payload = {
      page_number: 1,
      per_page: 1000,
      status: [1],
      status_keberangkatan: [2]
    }

    if(packageTypeSelected !== 0) {
      payload.tipe_paket = packageTypeSelected
    }

    const res = await getAllPaketService(payload)
    if (res.status === 200) {
      let _tempPackages = res.data.data

      _tempPackages = _tempPackages.map((item) => {
        return {
          value: item.id_paket,
          label: item.judul_paket,
          type: item.tipe_paket
        }
      })

      setPackages(_tempPackages)

    } else {
      dispatch(handleError(res.data))
    }

  }

  const handleGetRatingReviews = async () => {
    setGetting(true);
    try {

      let payload = { // isi null jika pilih semua
          "per_page" : 1000,
          "page_number" : 1,
          "sort_column" : "jamaah_name",
          "sort_order" : "asc",
          "tipe_paket": packageTypeSelected
      }

      if(activeFilter) {
        payload.rating = activeFilter
      }

      if(packageSelected) {
        payload.paket_id = packageSelected
      }

      const res = await getRatingPackageService(packageSelected, payload);
      setGetting(false);
      if(res.status === 200) {
        setReviews(res.data.data.reviews);
      } else {
        dispatch(handleError(res.data));
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleSaveTestimoniByIbadahku = async (jamaahOrderID) => {
    setAdding(true);
    setAddingID(jamaahOrderID);

    const payload = {
      id_order_kamar_mapping: jamaahOrderID,
    }

    const res = await TestimoniAPI.saveDataByIbadahku(payload);
    setAdding(false);

    if (res.status === 200) {
      dispatch(handleSuccess(null, { message:"Berhasil menambahkan testimoni", code:200 }));

      const getPayload = {
        keyword: "",
        per_page: 1000,
        page_number: 1,
        sort_column: "rating",
        sort_order: "ASC",
      };
      dispatch(getDataTestimoni(getPayload));
      handleClose();

    } else {
      dispatch(handleError(res.data));
    }
  }

  useEffect(() => {
    handleGetPackages()
  }, [dispatch, packageTypeSelected])

  useEffect(() => {
    handleGetRatingReviews()
  }, [dispatch, packageSelected, activeFilter, packageTypeSelected])

  return (
    <>
      <div className="border border-dashed border-[#E0E0E0] rounded-2xl p-4 flex flex-row justify-between mt-4">
        <div className="flex flex-col gap-2">
          <div className="font-semibold">Filter Rating</div>
          <div className="flex flex-row flex-wrap w-[400px] gap-2">
            <div 
              onClick={() => setActiveFilter(null)}
              className={`flex flex-row items-center gap-2 border-[0.5px] border-[#BDBDBD] rounded-lg p-2 cursor-pointer ${activeFilter === null && "!bg-[#F3E9DC] !border-[#F2994A]"}`}>
              <div>
                <img src={IconStar} alt="star" />
              </div>
              <div>
                Semua Rating
              </div>
            </div>
            {listRating.filter(item => item.key != null).map((ratingItem, idx) => {
              return (
                <div 
                  key={idx}
                  onClick={() => setActiveFilter(ratingItem.key)}
                  className={`flex flex-row items-center gap-2 border-[0.5px] border-[#BDBDBD] rounded-lg p-2 cursor-pointer ${activeFilter === ratingItem.key && "!bg-[#F3E9DC] !border-[#F2994A]"}`}>
                  <img src={IconStar} alt="star" />
                  <div>
                    <span className="font-semibold">{ratingItem.key}</span> ({ratingItem.description})
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <Selects
            label="Jenis Paket"
            placeholder="Semua Jenis"
            customClass="!w-[200px]"
            options={packageTypes}
            searchable
            itemRenderer={({ state, item, methods }) => {
              return (
                <div
                  className={`react-dropdown-select-item ${
                    state.values.length !== 0 &&
                    state.values[0].value === item.value &&
                    "react-dropdown-select-item-selected"
                  }`}
                  onClick={() => {
                    setPackageTypeSelected(item.value)
                    methods.addItem(item)
                  }}
                >
                  {item.label}
                </div>
              );
            }}
          />
          <Selects
            label="Paket"
            placeholder="Semua Paket"
            customClass="!w-[200px]"
            options={packages}
            searchable
            itemRenderer={({ state, item, methods }) => {
              return (
                <div
                  className={`react-dropdown-select-item ${
                    state.values.length !== 0 &&
                    state.values[0].value === item.value &&
                    "react-dropdown-select-item-selected"
                  }`}
                  onClick={() => {
                    setPackageSelected(item.value)
                    methods.addItem(item)
                  }}
                >
                  <div className="mb-2">{item.label}</div>
                  <div>
                    {item.type == 1 && <Badge backgroundColor={"#BBDEFB"} color={"#0000FE"} label="Haji" />}
                    {item.type == 2 && <Badge backgroundColor="#92E6A7" color="#1B662E" label="Umroh" />}
                    {item.type == 3 && <Badge backgroundColor={"#F7A399"} color="#FE0000" label="Tour" />}
                    {item.type == 4 && <Badge label="Tabungan" />}
                    {item.type == 5 && <Badge label="Jasa Badal" />}
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
      {getting ? (
        <div className="flex flex-col items-center justify-center h-[250px]">
          <Spinner width="32px" height="32px" />
        </div>
      ) : (
        <>
          {reviews.length > 0 ? (
            <>
              <div className="grid grid-cols-2 gap-4 mt-4">
                {reviews.map((review, index) => (
                  <div className="bg-white shadow-soft rounded-2xl" key={index}>
                    <div className="flex flex-row justify-between p-4">
                      <div className="flex flex-row items-center gap-4 relative">
                        <Avatar value={review} />
                        <div>
                          <div className="flex gap-4 items-center">
                            <div className="font-semibold">
                              {`${toCapital(review.title)}. ${review.name}`}
                            </div>
                            {review.package_type == 1 && <Badge backgroundColor={"#BBDEFB"} color={"#0000FE"} label="Haji" />}
                            {review.package_type == 2 && <Badge backgroundColor="#92E6A7" color="#1B662E" label="Umroh" />}
                            {review.package_type == 3 && <Badge backgroundColor={"#F7A399"} color="#FE0000" label="Tour" />}
                            {review.package_type == 4 && <Badge label="Tabungan" />}
                            {review.package_type == 5 && <Badge label="Jasa Badal" />}
                          </div>
                          <Rating value={review.rating} size="20px" />
                        </div>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="p-4 text-[#828282]">{review.comment}</div>
                    <div className="divider"></div>
                    <div className="flex flex-row justify-end p-4">
                      <Buttons 
                        loading={adding && review.id == addingID}
                        text="Tambahkan" 
                        onClick={() => handleSaveTestimoniByIbadahku(review.id)}
                        customClass="btn-primary btn-small" />
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col h-[400px] justify-center items-center bg-white">
                <img
                  src={IconJamPasir}
                  alt="Cooming Soon"
                  className="mb-2 w-[252px] h-[260px]"
                />
                <p className="text-base text-black">
                  Belum ada data pertanyaan
                </p>  
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(AddTestimoniIbadahku);
