// dashboard Reducers
// --------------------------------------------------------

import {
  SET_LOADING,
  CLEAR_ERROR,
  SET_ERROR,
  INIT_DATA,
  SET_DOUBLE_SUBMIT,
  SET_TOTAL_JAMAAH,
  SET_TOTAL_PAKET,
  SET_TOTAL_PAKET_AKTIF,
  SET_TOTAL_MEMBER_ONLINE,
  SET_STATISTIK,
  SET_BELUM_DP,
  SET_KELENGKAPAN_DATA,
  SET_KELENGKAPAN_DATA_JAMAAH,
  SET_CLEAR_DATA,
  SET_BELUM_LUNAS,
} from "../../actions/dashboard";

const initialState = {
  isLoading: false,
  isDoubleSubmit: false,
  isSubmitting: false,
  error: {},
  totalJamaah: {},
  totalPaket: {},
  totalPaketAktif: {},
  totalMemberOnline: {},
  statistics: {},
  belumDp: null,
  belumLunas: null,
  kelengkapanData: null,
  kelengkapanDataJamaah: null,
};

const Dashboard = (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_DOUBLE_SUBMIT:
      return {
        ...state,
        isDoubleSubmit: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case INIT_DATA:
      return {
        ...initialState,
      };
    case SET_TOTAL_JAMAAH:
      return {
        ...state,
        totalJamaah: payload,
      };
    case SET_TOTAL_PAKET:
      return {
        ...state,
        totalPaket: payload,
      };
    case SET_TOTAL_PAKET_AKTIF:
      return {
        ...state,
        totalPaketAktif: payload,
      };
    case SET_TOTAL_MEMBER_ONLINE:
      return {
        ...state,
        totalMemberOnline: payload,
      };
    case SET_STATISTIK:
      return {
        ...state,
        statistics: payload,
      };
    case SET_BELUM_DP:
      return {
        ...state,
        belumDp: payload,
      };
    case SET_BELUM_LUNAS:
      return {
        ...state,
        belumLunas: payload,
      };
    case SET_KELENGKAPAN_DATA:
      return {
        ...state,
        kelengkapanData: payload,
      };
    case SET_KELENGKAPAN_DATA_JAMAAH:
      return {
        ...state,
        kelengkapanDataJamaah: payload,
      };
    case SET_CLEAR_DATA: {
      return { ...state, kelengkapanDataJamaah: null };
    }
    default:
      return state;
  }
};

export default Dashboard;
