import React from "react";
import Badge from "../../../components/badge";

const ListJamaah = ({ id, name, kamar_name,starting, departure, arrival }) => {
  return (
    <main className="rounded-2xl gap-y-4 bg-white my-6 text-sm px-4 py-5">
      <section className="flex justify-between items-center mb-3">
        <div className="font-semibold">
          {id}. {name}
        </div>
        <div>
          <Badge label="YIA - CGK" backgroundColor="#FFDCDC" color="#E82320" />
        </div>
      </section>
      <section className="flex justify-between items-center">
        <div className="font-semibold uppercase">{kamar_name}</div>
        <div>{starting}</div>
      </section>
    </main>
  );
};

export default ListJamaah;
