import React, { useEffect, useState, useCallback } from "react";
import MoslemeTable from "../../../../../components/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteDataPembimbing,
  duplicateDataPembimbing,
  getAllDataPembimbing,
} from "../../../../../stores/actions/datamaster";
import { columns } from "./column";
import useDebounce from "../../../../../utils/helpers/useDebounce";
import AlertPopup from "../../../../../components/popup/alert";
import Popup from "../../../../../components/popup";
import Inputs from "../../../../../components/form/inputs";
import CheckboxMaster from "../../checkbox";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Buttons from "../../../../../components/buttons";

function Pembimbing({ filter, openModal }) {
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [actionHandle, setActionHandle] = useState();
  const [open, setOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [checkName, setCheckName] = useState("");
  const auth = useSelector(({ auth }) => auth)

  const formik = useFormik({
    initialValues: {
      id: "",
      lname: "",
      type: true,
      image_photo: true,
      description: true,
    },
  });

  // data table
  const { dataPembimbing, status } = useSelector((state) => state.datamaster);
  let dataTable = dataPembimbing.data;
  const dispatch = useDispatch();

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = dataPembimbing.total_data;

  // Handle action table
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    dispatch(deleteDataPembimbing(actionHandle));
    setOpen(false);
  };

  // filter data table
  const emailDebounce = useDebounce(filter, 1000);

  useEffect(() => {
    dispatch(
      getAllDataPembimbing({
        per_page: size,
        page_number: currentpage,
        sort_column: "",
        sort_order: "Desc",
        keyword: "",
      })
    );
  }, [currentpage, dispatch, emailDebounce, size]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // submit form
  const handleSave = () => {
    dispatch(
      duplicateDataPembimbing({
        ...formik.values,
        type: formik.values.type === true ? 1 : 0,
        image_photo: formik.values.image_photo === true ? 1 : 0,
        description: formik.values.description === true ? 1 : 0,
      })
    );
    setIsModal(false);
  };

  return (
    <div>
      <AlertPopup
        open={open}
        handleClose={handleClose}
        handleContinue={handleContinue}
      />

      <Popup
        type="primary"
        title="Duplikat Pembimbing"
        open={isModal}
        handleClose={() => setIsModal(false)}
      >
        <Inputs
          id="name"
          name="name"
          placeholder="Masukan nama pembimbing"
          label="Nama Pembimbing"
          defaultValue={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.values.name === checkName && (
          <p className="my-1 text-red-500"> Masukan nama yang berbeda</p>
        )}

        <div className="my-4">
          <CheckboxMaster
            title="Type"
            checked={formik.values.type === true}
            onChange={(e) => {
              formik.setFieldValue("type", !formik.values.type);
            }}
          />
        </div>

        <div className="my-4">
          <CheckboxMaster
            title="Category"
            checked={formik.values.image_photo === true}
            onChange={(e) => {
              formik.setFieldValue("image_photo", !formik.values.image_photo);
            }}
          />
        </div>
        <div className="my-4">
          <CheckboxMaster
            title="Deskripsi"
            checked={formik.values.description === true}
            onChange={(e) => {
              formik.setFieldValue("description", !formik.values.description);
            }}
          />
        </div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6}>
            <Buttons
              onClick={() => setIsModal(false)}
              text="Batal"
              customClass="btn-secondary btn-large"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Buttons
              onClick={handleSave}
              disabled={formik.values.name === checkName ? true : false}
              text="Simpan"
              customClass={
                formik.values.name === checkName
                  ? "btn-primary disabled btn-large"
                  : "btn-primary  btn-large"
              }
            />
          </Grid>
        </Grid>
      </Popup>

      <MoslemeTable
        columns={columns(
          handleOpen,
          setActionHandle,
          setIsModal,
          openModal,
          dispatch,
          formik.setFieldValue,
          setCheckName,
          auth?.user?.permissions
        )}
        loading={status === "process"}
        data={dataTable}
        rowKey={(record) => record.id_mentor}
        withSelection={true}
        selectionType={selectionType}
        customPaginationClass="pr-4"
        pagination={pagination}
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) => {
          onSizeChange(currentpage, pageSize);
        }}
      />
    </div>
  );
}

export default Pembimbing;
