import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import UploadArea from "components/Uploadarea"
import Buttons from "components/buttons"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

import { handleUpdateManifestAPI } from 'utils/api/booking'
import { handleGetDataJamaah } from 'stores/actions/booking'
import { handleUpdatePembimbingManfiest } from 'utils/api/paket/manifest'

import { handleError, handleSuccess } from 'stores/actions/errorGeneral'
import Inputs from 'components/form/inputs'
import Selects from 'components/form/selects'
import DatePicker from 'components/datepicker/DatePicker'

import * as Yup from 'yup'

import moment from 'moment'
import dayjs from "dayjs";
import Textarea from 'components/form/textarea'

const PasPhoto4x6 = ({
    data,
    pembimbingPaketID,
    onSuccess,
    auth,
    permissions
}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const formik = useFormik({
        initialValues: {
            data: data
        }
    })
    const [isLoading, setIsLoading] = useState(false)

    const handleSave = async () => {
        // console.log(formik.values);
        setIsLoading(true)
        const response = await handleUpdatePembimbingManfiest(params.id, pembimbingPaketID,formik.values)
        setIsLoading(false)
        if(response.status === 200) {
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate data Dokumen", code: 200 }))
            // dispatch(handleGetDataJamaah(params.orderNumber))
            onSuccess()
        } else {
            dispatch(handleError(response.data))
        }

    }

    useEffect(() => {
        formik.setValues({
            data
        })
        
    }, [])

    return (
        <>
            <div className="border p-4 rounded-t-2xl">
                <UploadArea
                    viewOnly={!permissions.includes('update_manifest') && auth.user.access_data !== 'all_branches'}
                    type='image'
                    label="Pas Foto 4x6"
                    customClass="!w-[245px] !h-[330px]"
                    defaultUrl={formik.values?.data?.pas_photo_4x6}
                    onChange={(url) => formik.setFieldValue('data.pas_photo_4x6', url)}
                    handleRemove={() => formik.setFieldValue('data.pas_photo_4x6', null)}
                    />
            </div>
            {(permissions.includes('update_manifest') && auth.user.access_data === 'all_branches') && (
                <div className="border rounded-b-2xl flex justify-between border-t-0 p-4">
                    <div>
                    </div>
                    <div>
                        <Buttons
                            customClass="btn-primary btn-large"
                            text="Simpan"
                            loading={isLoading}
                            onClick={handleSave}
                            />
                    </div>
                </div>
            )}
        </>
    )
}

export default PasPhoto4x6