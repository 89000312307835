import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import useDebounce from "utils/helpers/useDebounce";
import { URL_REGEX, toBase64 } from "utils/helpers";
import ArticleAction from "stores/actions/apps/artikel";

import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import EditorToolbar, {
  modules,
  formats,
} from "components/richTextEditor/EditorToolbar";

import Close from "assets/icons/Close.svg";
import Switch from "components/form/switch";
import UploadArea from "components/Uploadarea";
import { handleError, handleErrorCustom, handleSuccess } from "stores/actions/errorGeneral";

const AppsAddOrdEditArtikel = ({ category, edit, setOpenPopupAddArtikel }) => {
  const dispatch = useDispatch();
  const [selectedCatagory, setSelectedCategory] = useState(
    edit && {
      id: edit.kategori_id,
      label: edit.nama_kategori,
      value: edit.nama_kategori,
    }
  );

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const formikAddOrEditArtikel = useFormik({
    initialValues: {
      title: `${edit ? edit.judul : ""}`,
      kategori: `${edit ? edit.nama_kategori : ""}`,
      permalink: `${edit ? edit.permalink : ""}`,
      content: `${edit ? edit.isi : ""}`,
      thumbnail: `${edit ? edit.thumbnail : ""}`,
      status: edit ? edit.status ? true : false : false,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Judul Artikel Wajib diisi."),
      kategori: Yup.string().required("Kategori Artikel Wajib diisi."),
      permalink: Yup.string()
        // .matches(URL_REGEX, "Masukkan URL yang benar.")
        .required("Permalink Wajib diisi."),
      content: Yup.string().required("Isi Artikel Wajib diisi."),
    }),
    onSubmit: async (val, actions) => {
      if (edit) {
        const status = await dispatch(
          ArticleAction.postData({
            id: edit.id_artikel,
            judul: val.title,
            kategori_id: selectedCatagory.id,
            thumbnail: val.thumbnail,
            permalink: val.permalink,
            status: val.status ? 1 : 0,
            isi: val.content,
            edit: true,
          })
        );

        if (status === 200) {
          dispatch(handleSuccess(null, { message: "Artikel Berhasil diupdate", code:200 }));
          return setOpenPopupAddArtikel({
            show: false,
            edit: null,
          });
        } else {
          dispatch(handleErrorCustom("Terjadi kesalahan", 400));
        }
      } else {
        const status = await dispatch(
          ArticleAction.postData({
            judul: val.title,
            kategori_id: selectedCatagory.id,
            thumbnail: val.thumbnail,
            permalink: val.permalink,
            status: val.status ? 1 : 0,
            // isi: toBase64("encode", val.content),
            isi: val.content,
          })
        );

        if (status === 200) {
          dispatch(handleSuccess(null, { message: "Artikel Berhasil ditambahkan", code:200 }));
          return setOpenPopupAddArtikel({
            show: false,
            edit: null,
          });
        } else {
          dispatch(handleErrorCustom("Terjadi kesalahan", 400));
        }
      }
    },
  });

  // const titleDebounce = useDebounce(formikAddOrEditArtikel.values.title, 1000);

  useEffect(() => {
    // console.log()
    if(!edit) {
      let split = formikAddOrEditArtikel.values.title.replace(/ /g, "-").toLowerCase();
      formikAddOrEditArtikel.setFieldValue(
        "permalink",
        `${split}`
      );
    }
  }, [formikAddOrEditArtikel.values.title]);

  return (
    <div className="bg-white border-[1px] border-[#E0E0E0] rounded-2xl">
      <div className="p-4">
        <div className="flex gap-2 items-center mb-4">
          <Switch
            id="status"
            // label="Publish"
            defaultChecked={formikAddOrEditArtikel.values.status}
            onChange={(val) => {
              formikAddOrEditArtikel.setFieldValue('status', val)

            }}
            />
          <span>Publish</span>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Inputs
            id="title"
            name="title"
            label="Judul Artikel"
            placeholder="Masukkan Judul Artikel"
            value={formikAddOrEditArtikel.values.title}
            error={formikAddOrEditArtikel.errors.title}
            customClass={
              formikAddOrEditArtikel.errors.title ? "input-error" : ""
            }
            onChange={formikAddOrEditArtikel.handleChange}
          />
          <Selects
            id="kategori"
            label="Pilih Kategori"
            placeholder="Pilih Kategori"
            options={category}
            values={
              edit && edit.nama_kategori
                ? [
                    {
                      label: edit.nama_kategori,
                      value: edit.nama_kategori,
                    },
                  ]
                : []
            }
            error={formikAddOrEditArtikel.errors.kategori}
            customClass={
              formikAddOrEditArtikel.errors.kategori ? "select-error" : ""
            }
            onChange={(e) => {
              if (e.length !== 0) {
                formikAddOrEditArtikel.setFieldValue("kategori", e[0].value);
                setSelectedCategory(e[0]);
              }
            }}
            itemRenderer={({ state, item, methods }) => {
              return (
                <>
                  <div
                    className={`react-dropdown-select-item ${
                      state.values.length !== 0 &&
                      state.values[0].value === item.value &&
                      "react-dropdown-select-item-selected"
                    }`}
                    onClick={() => methods.addItem(item)}
                  >
                    {item.label}
                  </div>
                </>
              );
            }}
          />
        </div>
        <Inputs
          id="permalink"
          name="permalink"
          containerClass="mt-4"
          label="Permalink Artikel"
          placeholder="Masukkan Permalink Artikel"
          value={formikAddOrEditArtikel.values.permalink}
          error={formikAddOrEditArtikel.errors.permalink}
          customClass={
            formikAddOrEditArtikel.errors.permalink ? "input-error" : ""
          }
          onChange={formikAddOrEditArtikel.handleChange}
        />
        <div className="my-4">
          <UploadArea
            label="Thumbnail"
            defaultUrl={formikAddOrEditArtikel.values.thumbnail}
            onChange={(url) => {
              formikAddOrEditArtikel.setFieldValue('thumbnail', url)
            }}
            customClass="!h-[350px]"
            />
        </div>
        <div className="text-editor mt-4">
          <EditorToolbar />
          <ReactQuill
            id="content"
            name="content"
            theme="snow"
            value={formikAddOrEditArtikel.values.content}
            onChange={(e) => {
              if (e === "<p><br></p>") {
                formikAddOrEditArtikel.setFieldValue("content", "");
              } else {
                formikAddOrEditArtikel.setFieldValue("content", e);
              }
            }}
            modules={modules}
            formats={formats}
            className="bg-[#EDF2F4] w-full h-[204px]"
          />
          {formikAddOrEditArtikel.errors.content && (
            <div className="flex flex-row items-center gap-2">
              <img src={Close} alt="close" />
              <div className="text-[#E82320]">
                {formikAddOrEditArtikel.errors.content}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="divider"></div>
      <div className="flex flex-row justify-end gap-2 p-4">
        <Buttons
          text="Kembali"
          customClass="btn-outline btn-medium !w-fit"
          onClick={() =>
            setOpenPopupAddArtikel({
              show: false,
              edit: null,
            })
          }
        />
        <Buttons
          text={`${edit ? "Edit" : "Tambah"}`}
          loading={formikAddOrEditArtikel.isSubmitting}
          customClass={`btn-primary btn-medium !w-fit ${
            formikAddOrEditArtikel.isSubmitting && "loading"
          }`}
          onClick={formikAddOrEditArtikel.handleSubmit}
        />
      </div>
    </div>
  );
};

export default React.memo(AppsAddOrdEditArtikel);
