import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";

import SidebarChild from "components/template_layout/Siderbar/SidebarChild";
import Manasik from "./components/Manasik";
import PaketLaporanPerlengkapan from "./components/Perlengkapan";
import PaketLaporanRoomlist from "./components/Roomlist";
import PaketLaporanSeat from "./components/Seat";
import PaketLaporanPembayaran from "./components/Pembayaran";
import PaketLaporanStarting from "./components/Starting";

import IconManasik from 'assets/icons/ManasikGrey.svg';
import IconPerlengkapan from "../../../../../assets/icons/perlengkapan.svg";
import IconRoomlist from "../../../../../assets/icons/roomlist-grey.svg";
import IconPembayaran from "../../../../../assets/icons/Pembayaran.svg";
import IconSeat from "../../../../../assets/icons/Seat.svg";
import IconStarting from "../../../../../assets/icons/Starting.svg";

import IconManasikActive from '../../../../../assets/icons/Manasik.svg'
import IconPerlengkapanActive from 'assets/icons/perlengkapan-maroon.svg'
import IconRoomlistActive from 'assets/icons/Roomlist.svg'
import IconPembayaranActive from 'assets/icons/icon-pembayaran-maroon.svg'
import IconSeatActive from 'assets/icons/icon-seat-maroon.svg'
import IconStartingActive from '../../../../../assets/icons/Starting1.svg'

export const menuSidebar = [
  {
    title: "Manasik",
    key: "manasik",
    icon: IconManasik,
    iconActive: IconManasikActive,
    order: 0,
  },
  {
    title: "Perlengkapan",
    key: "perlengkapan",
    icon: IconPerlengkapan,
    iconActive: IconPerlengkapanActive,
    order: 1,
  },
  {
    title: "Roomlist",
    key: "roomlist",
    icon: IconRoomlist,
    iconActive: IconRoomlistActive,
    order: 2,
  },
  {
    title: "Pembayaran",
    key: "pembayaran",
    icon: IconPembayaran,
    iconActive: IconPembayaranActive,
    order: 3,
  },
  {
    title: "Seat",
    key: "seat",
    icon: IconSeat,
    iconActive: IconSeatActive,
    order: 4,
  },
  {
    title: "Starting",
    key: "starting",
    icon: IconStarting,
    iconActive: IconStartingActive,
    order: 4,
  },
];

const PaketLaporan = () => {
  const [showContent, setShowContent] = useState("manasik");

  const getContent = useCallback(() => {
    switch (showContent) {
      case "manasik":
        return <Manasik />;
      case "perlengkapan":
        return <PaketLaporanPerlengkapan />;
      case "roomlist":
        return <PaketLaporanRoomlist />;
      case "pembayaran":
        return <PaketLaporanPembayaran />;
      case "seat":
        return <PaketLaporanSeat />;
      case "starting":
        return <PaketLaporanStarting />;
      default:
        return <Manasik />;
    }
  }, [showContent]);

  return (
    <div>
      <div className="flex w-full">
        <div className="w-2/12">
          <SidebarChild
            containerClass="!rounded-none border-[1px] border-[#4048521a] !relative !w-full !left-0 !h-full"
            menuList={menuSidebar}
            handleClick={setShowContent}
            path="/paket"
            showContent={showContent}
          />
        </div>
        <div className="w-10/12">
          {getContent()}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PaketLaporan);
