import React from "react";
import Inputs from "../../../../../components/form/inputs";
import ImageField from "../../../../../components/image_field";
import Grid from "@mui/material/Grid";

function InfoItem({ valueCode, valueName, formikOnChange, formikValue }) {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={12} md={6}>
        <div className="align-center">
          <ImageField
            name="logo"
            label="Logo Maskapai"
            width="16rem"
            height="16rem"
            setFile={(file) => {
              formikValue("logo", file?.path);
            }}
          />
        </div>
      </Grid>
      <Grid item sm={12} md={6}>
        <ImageField
          label="Thumbnail Maskapai"
          width="100%"
          height="16rem"
          setFile={(file) => formikValue("thumbnail", file?.path)}
          name="thumbnail"
        />
      </Grid>

      <Grid item sm={12} md={6}>
        <Inputs
          id="name"
          name="name"
          value={valueName}
          label="Nama Maskapai"
          placeholder="Masukan Nama Maskapai"
          onChange={formikOnChange}
        />
      </Grid>

      <Grid item sm={12} md={6}>
        <Inputs
          id="code"
          name="code"
          value={valueCode}
          label="Kode"
          placeholder="Masukan Kode"
          onChange={formikOnChange}
        />
      </Grid>
    </Grid>
  );
}

export default InfoItem;
