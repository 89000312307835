import {
    SET_LOADING,
    PARSE_MANIFEST_PEMBIMBING
} from "../../../actions/paket/manifest";
  

const initialState = {
    setIsLoading: false,
    pembimbingManifests: [],
};
  

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                setIsLoading: payload,
            };
        case PARSE_MANIFEST_PEMBIMBING:
            return {
                ...state,
                pembimbingManifests: payload,
            };
        default:
            return state;
    }
}