import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Buttons from "components/buttons";
import MoslemeTable from "components/Table";
import Badge from "components/badge";
import PendapatanAgenHistory from "./components/History";

import { getFeeTotalListService } from "utils/api/fee";

import { handleError } from "stores/actions/errorGeneral";
import Avatar from "components/avatar";
import { IDRFormater } from "utils/helpers";
// import { Avatar } from "antd";

const PendapatanAgen = () => {
  const dispatch = useDispatch();
  const [history, setHistory] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(true);

  const [feeTotalList, setFeeTotalList] = useState([]);
  const [agentSelected, setAgentSelected] = useState({});

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  const handleGetFeeTotalList = async () => {
    setIsLoading(true);
    const payload = {
      page: currentpage,
      limit: size,
      recipient: "agent",
    };
    const response = await getFeeTotalListService(payload);
    if (response?.data?.data) {
      setTotal(response?.data?.total);
      setFeeTotalList(response?.data?.data);
    } else {
      dispatch(handleError(response?.data?.message));
    }
    setIsLoading(false);
  };

  const columns = [
    {
      title: "Foto",
      width: "40px",
      render: (record) => <Avatar value={record} />,
    },
    {
      title: "Nama",
      dataIndex: "name",
    },
    {
      title: "Role",
      render: (record) => <Badge label={record.role_name} color={record.color_text_role} backgroundColor={record.color_bg_role} />,
    },
    {
      title: "Cabang",
      render: (record) => <Badge label={record.cabang_name} color={record.color_text_cabang} backgroundColor={record.color_bg_cabang} />,
    },
    {
      title: "Total Saldo Fee",
      dataIndex: "saldo_fee",
      render: (saldo) => (
        <>
          <div className="font-semibold">{IDRFormater(saldo)}</div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "history",
      render: (val, item) => (
        <Buttons
          text="History"
          containerClass="w-fit"
          customClass="btn-outline btn-small"
          onClick={() => {
            setHistory(true)
            setAgentSelected(item)
          }}
        />
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Dimas Andreyan Pradana Putra",
      role: "Role",
      total_fee: "10.500.000",
      history: "",
    },
    {
      key: "2",
      name: "Dimas Andreyan Pradana Putra",
      role: "Role",
      total_fee: "10.500.000",
      history: "",
    },
    {
      key: "2",
      name: "Dimas Andreyan Pradana Putra",
      role: "Role",
      total_fee: "10.500.000",
      history: "",
    },
  ];

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };
  // manipulated data with action
  const sourceData = data;

  // useEffect
  useEffect(() => {
    handleGetFeeTotalList();
  }, [dispatch, currentpage, size]);

  return (
    <>
      {history ? (
        <PendapatanAgenHistory setHistory={setHistory} agent={agentSelected} />
      ) : (
        <>
          <div className="flex flex-row justify-between items-center py-4">
            <div className="text-xl font-semibold">Daftar Pendapatan Agen</div>
          </div>
          <div className="mx-[-1rem] border-t border-[##E0E0E0]">
            <MoslemeTable
              loading={isLoading}
              columns={columns}
              data={feeTotalList}
              withSelection={withSelection}
              selectionType={selectionType}
              pagination={pagination}
              onPageChange={(page) => setCurrentpage(page)}
              onSizeChange={(currentpage, pageSize) =>
                onSizeChange(currentpage, pageSize)
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(PendapatanAgen);
