import styled from "styled-components";

const InputOTPStyle = styled.div`
  .custom-otp {
    gap: 16px;
    input {
      width: 78px !important;
      height: 64px !important;
      font-weight: 600;
      font-size: 40px;
      border-bottom: 2px solid #e7e7e7;

      &:focus {
        outline: none;
      }
    }

    &--disabled {
      cursor: not-allowed;
    }

    &--error {
      border-bottom: 2px solid #e82320 !important;
    }
  }
`;

export default InputOTPStyle;
