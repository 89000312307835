import React, { useCallback, useEffect, useRef, useState } from "react";
import PerlengkapanJamaah from "./PerlengkapanJamaah";
import useClickOutside from "utils/helpers/useClickOutside";
import { Task } from "iconsax-react";
const PerlengkapanContent = ({
  value,
  selectedRole,
  handleUpdated,
  id_kamar_mapping,
  loadingAction,
}) => {
  const popover = useRef();
  const [openDropdown, setOpenDropdown] = useState(false);
  const close = useCallback(() => setOpenDropdown(false), []);
  useClickOutside(popover, () => setOpenDropdown(false));

  const [listEquipment_package, setListEquipment_package] = useState([]);

  useEffect(() => {
    setListEquipment_package(value?.equipment_package);
  }, [value]);

  const handleChange = (index) => {
    let _temp = [...listEquipment_package];
    _temp[index]["status"] = _temp[index]["status"] === 0 ? 1 : 0;

    let _tempVariant = _temp[index]["variant"];
    let _resultUsed = [..._tempVariant];

    if (_tempVariant.length > 0) {
      _resultUsed[0]["status"] = 1;
    }

    _temp[index]["variant"] =
      _temp[index]["status"] === 0
        ? _temp[index]["variant"]?.map((item) => ({
            ...item,
            status: 0,
          }))
        : _tempVariant?.length > 0
        ? _resultUsed
        : [];

    setListEquipment_package(_temp);
  };

  const handleChangeVariant = (idParent, idChild) => {
    let _temp = [...listEquipment_package];
    _temp[idParent]["status"] = 1;
    _temp[idParent]["variant"] = _temp[idParent]["variant"]?.map((item) => ({
      ...item,
      status: 0,
    }));
    _temp[idParent]["variant"][idChild]["status"] =
      _temp[idParent]["variant"][idChild]["status"] === 0 ? 1 : 0;
    setListEquipment_package(_temp);
  };

  const handleInsertPerlengkapan = async () => {
    const payload = {
      kamar_mapping_id: id_kamar_mapping,
      equipment: listEquipment_package?.map((item) => {
        return {
          id: item?.id,
          status: item?.status,
          variant_id: item?.variant?.find((val) => val.status === 1)
            ?.id_equipment_variant,
        };
      }),
    };

    await handleUpdated(payload, (message) => {
      setOpenDropdown(!openDropdown);
    });
  };

  return (
    <div>
      <div className="relative">
        <button
          className={`border ${
            value?.total_equipment_package_jamaah > 0 ? "!bg-[#D8E1BE]" : ""
          } flex flex-row justify-center gap-1 rounded-xl py-2 px-4  duration-200 items-center`}
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
        >
          <Task size="18" color="#141414" className="mt-1" />
          Perlengkapan ({value?.total_equipment_package_jamaah}/
          {value?.total_equipment_package})
        </button>
        {openDropdown && value?.total_equipment_package > 0 && (
          <div className="absolute z-50 top-[60px] bottom-0" ref={popover}>
            <PerlengkapanJamaah
              equipment_package={listEquipment_package}
              handleChange={handleChange}
              handleInsertPerlengkapan={handleInsertPerlengkapan}
              handleChangeVariant={handleChangeVariant}
              loadingAction={loadingAction}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PerlengkapanContent;
