// musim Actions
// --------------------------------------------------------

import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import {
  createOrUpdateMusimApi,
  deleteIdMusimApi,
  duplicateMusimApi,
  getMusimApi,
} from "utils/api/datamaster/musim";
import { getAllDataMaster, getMasterId } from "..";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "musim/SET_LOADING";
export const CLEAR_ERROR = "musim/CLEAR_ERROR";
export const SET_ERROR = "musim/SET_ERROR";
export const INIT_DATA = "musim/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "musim/SET_DOUBLE_SUBMIT";
export const SET_DATA_MUSIM = "musim/SET_DATA_MUSIM";
export const SET_DELETE_ID_MUSIM = "musim/SET_DELETE_ID_MUSIM";
export const SET_FAILED_MUSIM = "musim/SET_FAILED_MUSIM";
export const SET_CLEAR = "musim/SET_CLEAR";
export const SET_RESULT_MUSIM = "musim/SET_RESULT_MUSIM";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setDataMusim = (payload) => ({
  type: SET_DATA_MUSIM,
  payload,
});

export const setResultMusim = (payload) => ({
  type: SET_RESULT_MUSIM,
  payload,
});

export const setFailedMusim = (payload) => ({
  type: SET_FAILED_MUSIM,
  payload,
});

export const setDeleteIdMusim = (payload) => ({
  type: SET_DELETE_ID_MUSIM,
  payload,
});

export const clearData = () => ({
  type: SET_CLEAR,
});

export const resetDataMusim = () => {
  return async (dispatch) => {
    dispatch(setFailedMusim());
  };
};

export const getMasterMusim = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await getMusimApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setDataMusim(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createOrUpdateMusim = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await createOrUpdateMusimApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setResultMusim(res.data));
      dispatch(clearData());
      dispatch(handleSuccess(res));
      dispatch(getAllDataMaster());
      setTimeout(() => {
        dispatch(setResultMusim());
        dispatch(setFailedMusim());
      }, 1000);
    } else if (res.status === 400) {
      dispatch(setFailedMusim(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicateMusim = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await duplicateMusimApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      // dispatch(setResultMusim(res.data));
      dispatch(handleSuccess(res));
    } else if (res.status === 400) {
      dispatch(setFailedMusim(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteIdMasterMusim = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await deleteIdMusimApi(id);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setDeleteIdMusim(res.data));
      dispatch(handleSuccess(res));
      dispatch(getAllDataMaster());
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};
