import ProgressBars from ".";

const ProgressBarsDocs = () => {
  return (
    <div className="flex flex-col">
      <ProgressBars percent={40} info="1/3" />
      <ProgressBars percent={70} status="active" label="Label" info="2/3" />
      <ProgressBars percent={90} status="exception" label="Label" info="90%" />
      <ProgressBars percent={100} label="Label" info="100%" />
    </div>
  );
};

export default ProgressBarsDocs;
