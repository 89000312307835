
import image404 from "assets/images/404.svg"

const Page404 = () => {

    return (
        <div 
            className="flex justify-center items-center bg-white rounded-[20px] w-full h-[calc(100vh-135px)]">
            <img 
                src={image404}
                className="h-[50%]"
                alt="404"
                />
        </div>
    )
}

export default Page404