import { handleError, handleErrorBE } from "stores/actions/errorGeneral";
import {
  getManifestJamaahServiceV2,
  postUploadManifestServiceV2,
  handleGetPembimbingManifestsAPI
} from "utils/api/paket/manifest";



export const SET_LOADING = "manifest/SET_LOADING";
export const PARSE_MANIFEST_PEMBIMBING = "manifest/PARSE_MANIFEST_PEMBIMBING";

const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
})

const parseManifestPembimbing = (payload) => ({
  type: PARSE_MANIFEST_PEMBIMBING,
  payload
})

export const handleGetAllManifest =
  (
    id,
    params,
    payload,
    actionSuccess = (message) => {},
    actionError = (error) => {}
  ) =>
  async (dispatch) => {
    try {
      const response = await getManifestJamaahServiceV2(id, params, payload);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleUploadAllManifest =
  (id, payload, actionSuccess = (message) => {}, actionError = (error) => {}) =>
  async (dispatch) => {
    try {
      const response = await postUploadManifestServiceV2(id, payload);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleGetPembimbingManifests = (package_id) => async (dispatch) => {
  try {
    
    dispatch(setLoading(true));
    const response = await handleGetPembimbingManifestsAPI(package_id);
    dispatch(setLoading(false));

    if (response.status === 200) {
      dispatch(parseManifestPembimbing(response.data.data));
    } else {
      dispatch(handleErrorBE(response));
    }

  } catch (error) {
    dispatch(handleError(error));
  }
}