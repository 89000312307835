// settingTravel Actions
// --------------------------------------------------------

import {
    handleError,
    handleErrorBE,
    handleSuccess,
} from "stores/actions/errorGeneral";
import {
    getSettingTravelService,
    saveSettingTravelService,
} from "utils/api/setting/travel";

import {
    getSettingService
} from "utils/api/setting";


/* eslint-disable space-before-function-paren */
export const SET_LOADING = "settingTravel/SET_LOADING";
export const CLEAR_ERROR = "settingTravel/CLEAR_ERROR";
export const SET_ERROR = "settingTravel/SET_ERROR";
export const INIT_DATA = "settingTravel/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "settingTravel/SET_DOUBLE_SUBMIT";
export const SET_TRAVEL_DATA = "settingTravel/SET_TRAVEL_DATA";

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload,
});

export const setDoubleSubmit = (payload) => ({
    type: SET_DOUBLE_SUBMIT,
    payload,
});

export const setTravelData = (payload) => ({
    type: SET_TRAVEL_DATA,
    payload,
});

export const getTravelAction = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await getSettingService({ category: "travel" });
        dispatch(setLoading(false));
        const { status, data } = res;
        if (status == 200) {
            dispatch(setTravelData(data.data));
        } else {
            handleErrorBE(res);
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const saveTravelAction = (payload) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await saveSettingTravelService(payload);
        if (res.status == 200) {
            dispatch(getTravelAction());
            // dispatch(setTravelData(res.data.data));
            dispatch(handleSuccess(null, { message: "Berhasil menyimpan data setting", code: 200 }));
            return res.status;
        } else {
            dispatch(handleErrorBE(res));
        }
    } catch (error) {
        dispatch(handleError(error.status));
    } finally {
        dispatch(setLoading(false));
    }
};
