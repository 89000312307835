import { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Radio, Space, Switch } from "antd";
import useClickOutside from "../../../utils/helpers/useClickOutside";
import ScrollCustom from "components/scroll/index";
import PermissionIcon from "../../../assets/icons/colored/permission-icon.svg";
import {
  getDataDetailRoleV2,
  updatePermissionRoleAction,
  updatePermissionV2,
} from "stores/actions/role";
import { Task } from "iconsax-react";
import "./card_role_table.css";
import { handleSuccess } from "stores/actions/errorGeneral";
import { getLocalStorage } from "utils/helpers";

const PermissionDropdownOnSelect = ({ selectedRole, data }) => {
  const dispatch = useDispatch();
  const { dataDetail } = useSelector((state) => state.role);
  const popover = useRef();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [roleDetail, setRoleDetail] = useState([]);
  const close = useCallback(() => setOpenDropdown(false), []);
  useClickOutside(popover, close);

  const userLogin =
    getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"));
  const accessData = data.access_data;

  const handleCheckboxPermission = (parent, child) => {
    let data_detail = [...roleDetail];

    data_detail[parent]["data_permissions"][child].status =
      data_detail[parent]["data_permissions"][child].status === 0 ? 1 : 0;
    setRoleDetail(data_detail);

    let temp = [];
    data_detail.length > 0 &&
      data_detail.forEach((val) => {
        val.data_permissions.forEach((item) => {
          if (item.status === 1) {
            temp.push(item.name);
          }
        });
      });

    let payload = {
      permissions: temp,
    };

    dispatch(
      updatePermissionV2(payload, data?.name, (message) => {
        setOpenDropdown(true);
      })
    );
  };

  // sync
  useEffect(() => {
    if (Object.keys(dataDetail).length > 0) {
      if (selectedRole?.length > 0) {
        const result_map = selectedRole?.map((val) => {
          return {
            ...val,
            data_permissions: val?.data_permissions?.map((item) => {
              return {
                ...item,
                status: dataDetail?.data?.permissions?.includes(item.name)
                  ? 1
                  : 0,
              };
            }),
          };
        });

        setRoleDetail(result_map);
      }
    } else {
      if (selectedRole?.length > 0) {
        const result_map = selectedRole?.map((val) => {
          return {
            ...val,
            data_permissions: val?.data_permissions?.map((item) => {
              return {
                ...item,
                status: 0,
              };
            }),
          };
        });

        setRoleDetail(result_map);
      }
    }
  }, [selectedRole, dataDetail]);

  const branchExclude = [
    'role', 'branch', 'master-data', 'fee-management', 'apps', 'marketing', 'islamic-services',
    'calculator-package', 'setting', 
  ]

  const mainExclude = [
    'withdraw-fee'
  ]

  return (
    <div>
      <div className="relative ">
        <button
          className={`border border-[#E0E0E0] flex flex-row justify-center items-center
           ${
             true
               ? "bg-transparent  hover:from-orange-100 hover:to-red-100"
               : "hover:bg-gray-100"
           } flex gap-1 rounded-xl py-[6px] px-[12px] duration-200`}
          onClick={() => {
            dispatch(getDataDetailRoleV2({ name: data.name }));
            setTimeout(() => {}, 2000);

            setOpenDropdown(!openDropdown);
          }}
        >
          <Task size="18" color="#141414" className="mt-1" />
          Permission
        </button>
        {openDropdown && roleDetail && (
          <div
            className="absolute mt-[32px]  left-[-100px] right-0 w-[544px] rounded-xl overflow-auto z-50  shadow-xl "
            ref={popover}
          >
            <div className="bg-gradient-to-r from-[#FAAB36] to-[#fdcf89] text-white font-bold py-3 px-4 w-full">
              Select Permission
            </div>

            <div className="bg-white p-4 h-[456px] overflow-y-auto">
              <div className="grid grid-cols-2">
                {roleDetail?.map((access, index) => {
                  return (
                    <>
                      {accessData === 'self_branch' ? (
                        <>
                          {!branchExclude.includes(access?.group) && (
                            <div className="flex flex-col gap-2" key={access}>
                              <h4 className="text-sm font-bold mt-4 mb-2 capitalize">
                                {access?.display_group_name}
                              </h4>
                              <div className="font-semibold  text-[12px] text-[#141414]">
                                Permission Access
                              </div>
                              {access.data_permissions?.map((acc, i) => (
                                <div className="flex gap-[8px] text-[#141414] text-[12px]">
                                  <Switch
                                    style={{
                                      background:
                                        acc?.status === 1
                                          ? "linear-gradient(200.57deg, #F9F218 3.37%, #E2A246 86.36%)"
                                          : "#E0E0E0",
                                    }}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    checked={acc?.status === 1 ? true : false}
                                    onChange={(val) => {
                                      handleCheckboxPermission(index, i);
                                    }}
                                  />

                                  {acc?.display_name}
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {!mainExclude.includes(access?.group) && (
                            <div className="flex flex-col gap-2" key={access}>
                              <h4 className="text-sm font-bold mt-4 mb-2 capitalize">
                                {access?.display_group_name}
                              </h4>
                              <div className="font-semibold  text-[12px] text-[#141414]">
                                Permission Access
                              </div>
                              {access.data_permissions?.map((acc, i) => (
                                <div className="flex gap-[8px] text-[#141414] text-[12px]">
                                  <Switch
                                    style={{
                                      background:
                                        acc?.status === 1
                                          ? "linear-gradient(200.57deg, #F9F218 3.37%, #E2A246 86.36%)"
                                          : "#E0E0E0",
                                    }}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    checked={acc?.status === 1 ? true : false}
                                    onChange={(val) => {
                                      handleCheckboxPermission(index, i);
                                    }}
                                  />

                                  {acc?.display_name}
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PermissionDropdownOnSelect;
