import { useEffect, useState } from "react";
import { IcCamera, IcDeleteCircle } from "assets/icons/dashboard";
import Buttons from "components/buttons";
import ImageField from "components/image_field";
import { Input } from "antd";
import DatePicker from "components/datepicker/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { setDataUpload, setDataUploadMulti } from "stores/actions/uploadFile";
import uuid from "react-uuid";
import RichTextEditor from "components/richTextEditor";
import Scroll from "components/scroll";

import dayjs from 'dayjs'

const { TextArea } = Input;

const EditItinenary = ({ inserItenary, isLoadingAction, itenary }) => {
  const dispatch = useDispatch();
  const { dataUpload, dataMultiple } = useSelector((state) => state.uploadFile);
  const [placeholderDate, setPlaceHolderDate] = useState(null);

  const [form, setForm] = useState({
    date: null,
    deskripsi: { value: null },
    rute: [],
  });

  useEffect(() => {
    if (itenary) {
      setForm({
        ...form,
        date: itenary?.date,
        deskripsi: { value: itenary?.description },
        rute: itenary?.rute,
      });

      setPlaceHolderDate(itenary.date);

      let _temp = [];

      for (let data of itenary.gallery) {
        _temp.push({
          id: data?.id,
          fileName: data.itinerarie_id,
          path: data.link,
          name: "galery_foto",
          type: data.type,
        });
      }

      dispatch(setDataUploadMulti(_temp));
      dispatch(
        setDataUpload([
          ...dataUpload,
          {
            name: "thumbnail",
            path: itenary?.thumbnail,
            fileName: "thumniail",
            id: uuid(),
          },
        ])
      );
    }
  }, [itenary]);

  const [input, setInput] = useState("");

  const handleChange = (val, name) => {
    if (name === "deskripsi") {
      setForm({
        ...form,
        deskripsi: { value: val },
      });
    } else {
      setForm({
        ...form,
        [name]: val,
      });
    }
  };

  const disbledSave = () => {
    if (
      form.date === null ||
      form.deskripsi.value === null ||
      form.rute.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const resultMap = () => {
    if (itenary) {
      return {
        id: itenary?.id_itinerarie,
        date: form.date !== null && form.date,
        rute: form.rute,
        description: form.deskripsi?.value,
        gallery: dataMultiple?.map((item) => ({
          link: item.path,
          type: item.type,
          id: item?.id,
        })),
        thumbnail: dataUpload[0].path,
      };
    } else {
      return {
        date: form.date !== null && form.date,
        rute: form.rute,
        description: form.deskripsi?.value,
        gallery: dataMultiple?.map((item) => ({
          link: item.path,
          type: item.type,
        })),
        thumbnail: dataUpload[0].path,
      };
    }
  };

  return (
    <main className="rounded-2xl bg-white border-solid border-[#E0E0E0] border-[1px]">
      <Scroll height="450px">
      <div className="m-[16px]  flex flex-col justify-start gap-y-[16px]">
          <div className="w-full ">
            <div className="mb-2 font-semibold">Tanggal Perjalanan</div>
            <div className="w-full flex flex-row ">
              <DatePicker
                placeholder={
                  placeholderDate === null ? " Pilih Tanggal" : placeholderDate
                }
                onChange={(val) => {
                  setPlaceHolderDate(null);
                  handleChange(val, "date");
                }}
                containerClass="!w-full"
                customClass="!w-full"
                value={form?.date ? dayjs(form?.date, "YYYY-MM-DD", "YYYY-MM-DD") : null}
              />
            </div>
          </div>
          <div>
            <section className="grid-cols-2 grid gap-x-5 ">
              <div>
                <ImageField
                  label="Thumbnail"
                  customWrapper=""
                  customContainer="!w-full  "
                  width="451px"
                  height="300px"
                  src={IcCamera}
                  isMulti={false}
                  status={false}
                  setFile={(file) => {
                    //   setFieldValue("images", file[0]?.path);
                  }}
                  name="thumbnail"
                />
              </div>
              <div>
                <RichTextEditor
                  data={form.deskripsi}
                  onChange={(e) => {
                    handleChange(e, "deskripsi");
                  }}
                  label="Deskripsi"
                />
              </div>
            </section>
          </div>
          <div>
            <section className=" bg-white p-[16px] border-[#E0E0E0] border-dashed border-[1px] rounded-2xl">
              <div className="font-semibold mb-2">Rute</div>
              <div className="flex items-center justify-between relative overflow-hidden rounded-[12px] bg-[#F2F2F2] ">
                <input
                  placeholder="Masukan nama kota"
                  name="input"
                  className="flex-1 !ml-0 outline-none !py-[16px] !px-[16px] bg-[#F2F2F2] overflow-hidden"
                  value={input}
                  onChange={(e) => {
                    setInput(e.target.value);
                  }}
                />
                <Buttons
                  text="Tambahkan"
                  customClass="btn-primary !w-fit btn-small outline-none absolute top-[10px] right-[16px] "
                  onClick={() => {
                    let _temp = { ...form };
                    _temp.rute.push(input);
                    setInput("");
                    setForm(_temp);
                  }}
                />
              </div>

              <div className="flex justify-start items-start mt-5">
                {form.rute?.map((item, idx) => (
                  <div className="flex items-center" key={idx}>
                    <div className="flex gap-x-2 items-center justify-start bg-gray-1 rounded-lg py-1 px-2">
                      <div>{item}</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          let _temp = { ...form };
                          _temp.rute.splice(idx, 1);
                          setForm(_temp);
                        }}
                      >
                        <img alt="delete-icon" src={IcDeleteCircle} />
                      </div>
                    </div>
                    {idx !== form.rute.length - 1 && (
                      <span className="ln-blue w-5 h-1 mx-2"></span>
                    )}
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div>
            <ImageField
              label="Tambahkan Foto"
              isMulti={true}
              customContainer="!w-auto !rounded-2xl !h-[452px] "
              setFile={(a) => console.log(a)}
              name="galery_foto"
              customImageContainer="!w-[250px] h-full "
            />
          </div>
      </div>
      <div className="divider"></div>

      </Scroll>
      
      <div className="flex justify-end items-end my-4 mr-4">
        <Buttons
          text="Simpan"
          loading={isLoadingAction}
          disabled={disbledSave()}
          customClass="btn-primary btn-medium"
          onClick={() => {
            inserItenary(resultMap());
          }}
        />
      </div>
    </main>
  );
};

export default EditItinenary;
