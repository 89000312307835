import React, { useEffect, useState } from "react";
import Inputs from "../../../../../../components/form/inputs";
import ImageField from "../../../../../../components/image_field";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../../../../../components/buttons";
import { editDataMaskapai } from "../../../../../../stores/actions/datamaster";
import Selects from "components/form/selects";

function EditInfo({
  valueName,
  valueCategory,
  formikOnChange,
  formikValue,
  handleSave,
  options,
  setOptions,
  loading
}) {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={12} md={6}>
        <ImageField
          name="logo"
          label="Logo Transportasi"
          customContainer="!rounded-2xl"
          width="16rem"
          height="16rem"
          setFile={(file) => {
            formikValue("image_logo", file?.path);
          }}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <ImageField
          label="Thumbnail Transportasi"
          customContainer="!rounded-2xl"
          width="100%"
          height="16rem"
          setFile={(file) => {
            formikValue("image_thumbnail", file?.path);
          }}
          name="thumbnail"
        />
      </Grid>

      <Grid item sm={12} md={6}>
        <Inputs
          id="name"
          name="name"
          defaultValue={valueName}
          label="Nama Maskapai"
          placeholder="Masukan Nama Maskapai"
          onChange={formikOnChange}
        />
      </Grid>

      <Grid item sm={12} md={6}>
        <Selects
          label="Kategori Transportasi"
          placeholder="Text Input"
          value={options.filter((x) => x.value === valueCategory)}
          options={options}
          onChange={(values) => setOptions(values.map((x) => x.value)[0])}
        />
      </Grid>

      <div className="w-full flex justify-end pt-4 mt-4 items-center border-t border-gray-300">
        <div className="w-64">
          <Buttons
            onClick={handleSave}
            text="Simpan"
            customClass="btn-primary btn-large"
            loading={loading}
            />
        </div>
      </div>
    </Grid>
  );
}

export default EditInfo;
