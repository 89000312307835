import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import Popup from "components/popup";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMasterId } from "stores/actions/datamaster";
import {
  deleteIdMasterMusim,
  duplicateMusim,
  getMasterMusim,
} from "stores/actions/datamaster/musim";
import MoslemeTable from "components/Table";
import AlertPopup from "components/popup/alert";
import useDebounce from "utils/helpers/useDebounce";
import CheckboxMaster from "../../checkbox";
import { columns } from "./columns";

const Musim = ({ filter }) => {
  const dispatch = useDispatch();
  const { masterId } = useSelector((state) => state.datamaster);
  const { all_musim, isLoading } = useSelector((state) => state.musim);
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = all_musim?.total_data;
  const auth = useSelector(({ auth }) => auth)

  const [input, setInput] = useState({
    thn_hijriyah: true,
    thn_masehi: true,
    description: true,
    musim: true,
  });

  // data table
  let dataTable = all_musim?.data;

  // filter data table
  const filterMusim = useDebounce(filter, 1000);

  const onHandleDelete = () => {
    dispatch(deleteIdMasterMusim(masterId?.data?.id_musim)).then((item) => {
      dispatch(getMasterId({}));
      masterMusim(currentpage, size, filterMusim);
    });
  };

  const masterMusim = useCallback(
    (currentpage, size, filterMusim) => {
      dispatch(
        getMasterMusim({
          page_number: currentpage,
          per_page: size,
          keyword: filterMusim,
          sort_column: "",
          sort_order: "DESC",
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    masterMusim(currentpage, size, filterMusim);
  }, [currentpage, filterMusim, masterMusim, size]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const onHandleDuplicate = () => {
    input.id = masterId?.data?.id_musim;
    delete input.thn_hijriyah;
    delete input.thn_masehi;

    // console.log("input aku", input);
    dispatch(duplicateMusim(input)).then((item) => {
      masterMusim(currentpage, size, filterMusim);
      dispatch(getMasterId({}));
    });
  };

  return (
    <div>
      <AlertPopup
        open={masterId?.modal}
        handleClose={() => dispatch(getMasterId({}))}
        handleContinue={onHandleDelete}
        loading={isLoading ? true : false}
      />

      <Popup
        open={masterId?.popup}
        handleClose={() => dispatch(getMasterId({}))}
        title="Duplicate Musim"
      >
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-disabled"
          value={`${masterId?.data?.thn_masehi} M/${masterId?.data?.thn_hijriyah} H`}
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Musim"
          value={input.musim}
          checked={input.musim}
          onChange={(e) =>
            setInput({
              ...input,
              musim: e.target.checked,
            })
          }
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Description"
          value={input.description}
          checked={input.description}
          onChange={(e) =>
            setInput({
              ...input,
              description: e.target.checked,
            })
          }
        />
        <div className="grid grid-cols-2 mt-4 items-center gap-x-4">
          <Buttons
            onClick={() => dispatch(getMasterId({}))}
            text="Batal"
            customClass="btn-secondary btn-large"
          />

          <Buttons
            onClick={onHandleDuplicate}
            loading={isLoading ? true : false}
            text="Simpan"
            customClass="btn-primary btn-large"
          />
        </div>
      </Popup>
      <MoslemeTable
        columns={columns(dispatch, auth?.user?.permissions)}
        loading={isLoading}
        data={dataTable}
        rowId={(record) => record.id_musim}
        withSelection={true}
        selectionType="checkbox"
        rowKey={(record) => record.id_musim}
        customPaginationClass="pr-4"
        pagination={pagination}
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
      />
    </div>
  );
};

export default Musim;
