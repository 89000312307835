import styled from "styled-components";

const TextareasStyle = styled.div`
  .custom-textarea {
    background: #f2f2f2;
    border-radius: 12px;
    padding: 16px;
    width: 100%;
    border: 0;
    resize: none;

    &::placeholder {
      color: #828282;
    }

    &:hover,
    &:active {
      background: #ffdcdc;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .textarea-error {
    border: 1px solid #e82320;
  }

  .textarea-error-msg {
    color: #e82320;
    margin-left: 8px;
  }

  .textarea-success {
    border: 1px solid #97e03b;
  }

  .textarea-success-msg {
    color: #97e03b;
    margin-left: 8px;
  }

  .textarea-disabled {
    color: #828282;

    &:hover {
      cursor: not-allowed;
      background: #f2f2f2;
    }
  }

  .textarea-label {
    font-size: 14px;
    font-weight: 600;
    color: #4f4f4f;
    margin-bottom: 8px;

    &--disabled {
      color: #828282;
    }
  }
`;

export default TextareasStyle;
