import React, { useEffect } from "react";
import Badge from "../../components/badge";
import MoslemeTable from "../../components/Table";
import AlertPopup from "../../components/popup/alert";
import MaleAvatar from "../../assets/icons/male.svg";
import MemberAction from "../../stores/actions/member/admin";
import { useDispatch, useSelector } from "react-redux";
import { Edit, Trash, Verify } from "iconsax-react";
import { FilterAdmin } from "./components/Filter";
import {
  getDataRole,
  getDataRoleV2,
  getDataRoleV2Utama,
} from "../../stores/actions/role";
import { getAllCabang } from "stores/actions/cabang";
import CreateAdmin from "./components/CreateAdmin";
import Avatar from "components/avatar";

function Admin() {
  const [isShowDelete, setIsShowDelete] = React.useState(false);
  const [isEditShow, setIsEditShow] = React.useState(false);
  const auth = useSelector(({ auth }) => auth)

  const permissions = auth?.user?.permissions;

  const onShowEdit = (id) => {
    dispatch(MemberAction.setAdminId(id));
    setTimeout(() => {
      setIsEditShow(true);
    }, 500);
  };
  const [detail, setDetail] = React.useState(null);
  const columns = [
    {
      title: "Foto",
      dataIndex: "foto",
      width: "33px",
      render: (img, a) => <Avatar value={a} />,
    },
    {
      title: "Nama",
      width: "15%",
      dataIndex: "name",
    },
    {
      title: "Telepon",
      width: "10%",
      dataIndex: "phone_number",
    },
    {
      title: "Email",
      width: "15%",
      dataIndex: "email",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Role",
      dataIndex: "nama_role",
      width: "10%",
      render: (text, a) => (
        <Badge
          backgroundColor={a.color_bg_role}
          color={a.color_text_role}
          label={text || "-"}
        />
      ),
    },
    {
      title: "Cabang",
      dataIndex: "nama_cabang",
      width: "10%",
      render: (text, a) => (
        <Badge
          backgroundColor={a.color_bg_cabang}
          color={a.color_text_cabang}
          label={text || "-"}
        />
      ),
    },
    {
      title: "Status aktif",
      width: "10%",
      dataIndex: "status_active",
      render: (s) => (
        <div>
          <div className="flex items-center">
            <div
              className={`w-3 h-3 rounded-full mr-2 ${
                s === "Online" ? "bg-[#A7C957]" : "bg-slate-400"
              }`}
            />
            <p className="font-bold">{s}</p>
          </div>
        </div>
      ),
    },

    {
      title: "Status email",
      dataIndex: "status_email",
      className: "whitespace-nowrap",
      render: (s, a) => {
        return (
          <div>
            <Badge
              backgroundColor="rgba(167, 201, 87, 0.3);"
              color="#141414"
              label={
                <div className="flex items-center">
                  <Verify size="16" color="#2DC653" variant="Bold" />
                  <p className="ml-1">{s}</p>
                </div>
              }
            />
            <div className="action">
              <div className="action-content">
                {permissions?.includes("delete_member") && (
                  <div className="action-content-item">
                    <Trash
                      className="cursor-pointer"
                      size="15"
                      color="#141414"
                      onClick={() => {
                        setIsShowDelete(true);
                        setDetail(a);
                      }}
                    />
                  </div>
                )}
                {permissions?.includes("update_member") && (
                  <div className="action-content-item">
                    <Edit
                      className="cursor-pointer"
                      onClick={() => onShowEdit(a.id)}
                      size="15"
                      color="#141414"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const callbackDelete = () => {
    dispatch(MemberAction.fetchData());
    setIsShowDelete(false);
  };
  const store = useSelector(({ admin }) => admin);
  const sourceData = store.data.map((item, i) => {
    return {
      ...item,
      key: `${i + 1}`,
      status_active: item.is_online ? "Online" : "Offline",
      status_email: item.is_email_confirmed === 1 ? "Verified" : "Unverified",
      foto: MaleAvatar,
      className: "whitespace-nowrap",
    };
  });

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: store.total,
    current: store.page_number,
    pageSize: store.per_page,
    defaultPageSize: store.per_page,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  useEffect(() => {
    const payloadV2 = {
      main: null,
      page: 1,
      offset: 1000,
      pagination: false,
      keyword: "",
    };

    dispatch(getDataRoleV2(payloadV2));
    dispatch(getDataRole());
    dispatch(getAllCabang());
  }, []);

  useEffect(() => {
    dispatch(MemberAction.fetchData());
  }, [
    dispatch,
    store.keyword,
    store.per_page,
    store.page_number,
    store.id_role,
  ]);

  return (
    <div className="mb-5">
      <AlertPopup
        subtitle={`Apakah kamu yakin ingin menghapus “${detail?.name}”?`}
        open={isShowDelete}
        handleClose={() => {
          setIsShowDelete(false);
        }}
        handleContinue={() =>
          dispatch(MemberAction.deleteData(detail?.id, callbackDelete))
        }
        loading={store.status === "process"}
      />
      <FilterAdmin setIdAdmin={(data) => MemberAction.setAdminId("0")} />

      <CreateAdmin
        show={isEditShow}
        close={() => {
          dispatch(MemberAction.setAdminId("0"));
          setIsEditShow(false);
        }}
      />

      <div>
        <MoslemeTable
          loading={store.status === "process"}
          columns={columns}
          data={sourceData}
          withSelection={true}
          pagination={pagination}
          onPageChange={(page) => dispatch(MemberAction.setPage(page))}
          onSizeChange={(currentpage, pageSize) => {
            dispatch(MemberAction.setPage(currentpage));
            dispatch(MemberAction.setSize(pageSize));
          }}
        />
      </div>
    </div>
  );
}

export default Admin;
