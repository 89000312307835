import React from "react";

const CardMain = ({ className, children }) => {
  return (
    <div
      className={`border border-gray-2 flex justify-between items-center px-4 py-5 rounded-lg ${className}`}
    >
      {children}
    </div>
  );
};

export default CardMain;
