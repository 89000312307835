import React from "react";
import { IcBandara } from "../../../assets/icons/dashboard";
import ItemText from "./ItemText";

const CardJamaah = ({
  bg = "bg-total_jamaah",
  styleItem = "grid-cols-5",
  styleTitlePaket,
  styleTotalPaket,
  styleTitle,
  styleTotal,
  img,
  title,
  paket,
  data,
  customClass = '',
  customStyle = {}
}) => {
  return (
    <main className={`rounded-2xl p-[18px] ${bg} ${customClass}`} style={customStyle}>
      <section className="flex items-center justify-start gap-x-3 mb-4">
        <div className="shadow-highlight bg-white w-14 h-14 rounded-2xl">
          <img alt="flight" src={img} />
        </div>
        <div className="text-white">
          <div className={styleTitlePaket ? styleTitlePaket : styleTitle}>{title}</div>
          {paket && (
            <div className={`text-xl font-semibold ${styleTotalPaket ? styleTotalPaket : styleTotal}`}>{paket}</div>
          )}
        </div>
      </section>
      <section className={`grid gap-4 ${styleItem}`}>
        {/* {data?.map((item, idx) => (
          <ItemText
            key={idx}
            styleTitle={styleTitle}
            styleTotal={styleTotal}
            title={item.title}
            total={item.total}
          />
        ))} */}
        <ItemText
          styleTitle={styleTitle}
          styleTotal={styleTotal}
          title="Umroh"
          total={data?.umroh ?? 0}
        />
        <ItemText
          styleTitle={styleTitle}
          styleTotal={styleTotal}
          title="Haji"
          total={data?.haji ?? 0}
        />
        <ItemText
          styleTitle={styleTitle}
          styleTotal={styleTotal}
          title="Tour"
          total={data?.tour ?? 0}
        />
        <ItemText
          styleTitle={styleTitle}
          styleTotal={styleTotal}
          title="Tabungan"
          total={data?.tabungan ?? 0}
        />
        <ItemText
          styleTitle={styleTitle}
          styleTotal={styleTotal}
          title="Jasa Badal"
          total={data?.jasa_badal ?? 0}
        />
      </section>
    </main>
  );
};

export default CardJamaah;
