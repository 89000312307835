import React, { useEffect, useState } from "react";
import moment from "moment";

import { useDispatch } from "react-redux";
import useDebounce from "utils/helpers/useDebounce";
import { useParams } from "react-router-dom";

import { handlePackagePublishList } from "stores/actions/paket/landing";
import { upadateChosePackageService } from "utils/api/booking"

import { handleGetDetailOrder } from "stores/actions/booking";


import IconSearch from "assets/icons/search-normal.svg";
import IconTanggal from "assets/icons/Tanggal.svg";
import IconTotalSeat from "assets/icons/total-seat.svg";
import IconSeatTerisi from "assets/icons/seat-terisi.svg";
import IconSeatTersisa from "assets/icons/seat-tersisa.svg";
import IconSeatTersisaMaroon from "assets/icons/seat-tersisa-maroon.svg";
import ClockIcon from "assets/icons/colored/clock-icon.svg";
import AirplaneIcon from "assets/icons/colored/airplane-icon.svg";

import Dropdowns, { closeDropdown } from "components/dropdowns";
import Inputs from "components/form/inputs";
import Badge from "components/badge";
import Tab from "components/tab";
import Buttons from "components/buttons";

import Scroll from "components/scroll";
import Avatar from "components/avatar";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { handleError, handleSuccess } from "stores/actions/errorGeneral";


const UpdateChosePackage = ({
    packageSelected,
    onSubmited
}) => {
    const dispatch = useDispatch();
    const params = useParams();

    const [isLoadingList, setIsLoadingList] = useState(false)
    const [isLoadingMore, setIsloadingMore] = useState(false)

    const [packageSelectorLabel, setPackageSelectorLabel] = useState("Pilih Paket")
    const [packageOptionsList, setPackageOptionsList] = useState([])
    const [nextPackageCursorList, setNextPackageCursorList] = useState(null)
    const [keywordPackage, setKeywordPackage] = useState("")
    const searchDebouncePackage = useDebounce(keywordPackage, 1000)

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

    const formData = useFormik({
        initialValues: {
            package_id: null
        },
        validationSchema: Yup.object({
            package_id: Yup.string().required("Harus memilih paket")
        }),
    })

    const handlePackageTypeID = () => {
        return 2
    }

    const handleGetPackageOptionsList = async (first = true) => {
        if(first) {
            setNextPackageCursorList(null)
            setIsLoadingList(true)
        }

        await dispatch(handlePackagePublishList({
            keyword: keywordPackage,
            paginate: 'cursor',
            per_page: 10,
            mode: 'options',
            package_type: packageSelected?.tipe_paket,
            cursor: nextPackageCursorList
        }, (successData) => {
            setIsLoadingList(false)
        if (first) {
            setPackageOptionsList(successData.data)
        } else {
            let packageList = packageOptionsList;

            packageList.push.apply(packageList, successData.data)
            setPackageOptionsList(packageList)
        }
            setNextPackageCursorList(successData.next_cursor)
        }))
    }

    const handleSubmit = async () => {
        setIsLoadingSubmit(true)
        const res = await upadateChosePackageService(params.orderNumber, formData.values)
        setIsLoadingSubmit(false)

        if(res.status === 200 || res.status === 201) {
            dispatch(handleSuccess(res))
            dispatch(handleGetDetailOrder(params.orderNumber));
            onSubmited()
        } else {
            dispatch(handleError(res.data))
        }

    }

    const handleSelectPackage = (packageData) => {
        formData.setFieldValue("package_id", packageData.id_paket)
        formData.setFieldValue("paket.judul", packageData.judul_paket)

        setTimeout(() => {
            formData.setFieldTouched("package_id", 1)
            formData.setFieldTouched("paket.judul", 1)
        })

        setPackageSelectorLabel(packageData.judul_paket.toUpperCase())
    }

    const handleLoadMorePackage = async () => {
        setIsloadingMore(true)
        await handleGetPackageOptionsList(false)
        setIsloadingMore(false)
    }

    useEffect(() => {
        handleGetPackageOptionsList(true)
    
        setPackageSelectorLabel(packageSelected?.judul_paket?.toUpperCase())
        formData.setFieldValue("package_id", packageSelected?.id_paket)

      }, [])
    
      useEffect(() => {
        handleGetPackageOptionsList(true)
      }, [searchDebouncePackage])

    return (
        <div className="border rounded-2xl">
            <div className="p-4">
                <Dropdowns
                    customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
                    placement="bottom"
                    labelDropdown={packageSelectorLabel}
                    dropdownArrow={true}
                    error={formData.touched.packageID ? true : false}
                    errorMsg={formData.touched.packageID && formData.errors.packageID}
                    >
                    
                    <div className="bg-white w-full p-4 shadow-highlight rounded-2xl">
                        <div className="pb-4">
                        <Inputs
                            id="searchPAckage"
                            placeholder="Pencarian..."
                            customClass="!px-3 !py-3.5 !pl-[42px] text-sm"
                            iconPosition="left"
                            onChange={(e) => setKeywordPackage(e.target.value)}
                            icon={IconSearch}
                        />
                        </div>
                        <Scroll 
                        height="350px" 
                        customClass="pr-2">
                            <>
                            {packageOptionsList ? (
                                packageOptionsList.map((val, idx) => {
                                return (
                                    <>
                                        {val.id_paket !== formData.values.package_id && (
                                            <>
                                                <div
                                                    key={idx}
                                                    className="flex flex-row bg-white rounded-2xl border-[0.5px] border-[#e0e0e0] py-2 px-4 mb-4 cursor-pointer"
                                                    onClick={() => {
                                                        handleSelectPackage(val);
                                                        closeDropdown();
                                                        setNextPackageCursorList(null)
                                                    }}
                                                    >
                                                    <div
                                                        className="card-detail-booking-image w-[104px] h-[104px] rounded-xl overflow-hidden"

                                                    >
                                                        <img src={val.image_thumbnail} className="object-cover h-full w-full" />
                                                    </div>
                                                    <div className="ml-4 flex flex-col gap-2 justify-center">
                                                        <div className="font-bold text-xl">
                                                        {val.judul_paket}
                                                        </div>
                                                        <div className="flex flex-row gap-4 items-center">
                                                        <div className="flex flex-row items-center gap-2">
                                                            <img src={IconTanggal} className="w-5 h-5" alt="tanggal" />
                                                            <div className="text-sm">
                                                            {moment(val.jadwal_keberangkatan).format(
                                                                "DD MMMM YYYY"
                                                            )}
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row items-center gap-2">
                                                            <img src={ClockIcon} className="w-5 h-5" alt="tanggal" />
                                                            <div className="text-sm">
                                                            {val.jumlah_hari} Hari
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row items-center gap-2">
                                                            <img src={AirplaneIcon} className="w-5 h-5" alt="tanggal" />
                                                            <div className="text-sm">
                                                            {val.maskapai?.name}
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="flex flex-row gap-4 w-fit">
                                                        <div className="flex flex-row items-center gap-2">
                                                            <img className="w-6 h-6" src={IconTotalSeat} alt="" />
                                                            <div className="black-linear-text !font-medium">{val.kuota}</div>
                                                        </div>
                                                        <div className="flex flex-row items-center gap-2">
                                                            <img className="w-6 h-6" src={IconSeatTerisi} alt="" />
                                                            <div className="blue-linear-text !font-medium">{val.jumlah_jamaah}</div>
                                                        </div>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {val.kuota_tersedia > 5 ? (
                                                            <>
                                                                <img className="w-6 h-6" src={IconSeatTersisa} alt="" />
                                                            </>
                                                            ) : (
                                                            <>
                                                                <img className="w-6 h-6" src={IconSeatTersisaMaroon} alt="" />
                                                            </>
                                                            )}
                                                            <div className={`${val.kuota_tersedia > 5 ? `green-linear-text` : 'red-linear-text'} !font-medium`}>{val.kuota_tersedia}</div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    
                                    </>
                                );
                                })
                            )
                            : (
                                <div className="mt-4">Paket Tidak ditemukan</div>
                            )}
                            {nextPackageCursorList && (
                                <>
                                <div className="flex justify-center items-center w-full m-4">
                                    <Buttons
                                    onClick={handleLoadMorePackage}
                                    loading={isLoadingMore}
                                    text="Lihat selengkapnya"
                                    customClass="btn-outline btn-secondary btn-sm"
                                    />
                                </div>
                                </>
                            )}
                            </>
                        </Scroll>
                    </div>

                </Dropdowns>
            </div>
            <div className="border-t">
                <div className="p-4 flex justify-end">
                    <Buttons
                        disabled={!(formData.isValid && formData.dirty)}
                        customClass="btn-primary btn-large"
                        onClick={handleSubmit}
                        loading={isLoadingSubmit}
                        text="Simpan"
                        />
                </div>
            </div>
        </div>
    )
}

export default UpdateChosePackage