import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropType from "prop-types";
import moment from "moment";
import AppsSlideAction from "stores/actions/apps/slide";

import Buttons from "components/buttons";
import AlertPopup from "components/popup/alert";
import Checkbox from "components/checkbox";

import IconSwap from "assets/icons/Swap.svg";
import { Edit, Trash } from "iconsax-react";

const SlideWeb = ({ store, dispatch, setOpenPopup }) => {
  const [checked, setChecked] = useState(false);
  const [openDeleteSlide, setOpenDeleteSlide] = useState(false);
  const [slideIdDelete, setSlideIdDelete] = useState();
  const [loading, setLoading] = useState(false);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const getContent = useCallback(() => {
    return store.data.map((val, idx) => {
      return (
        <div key={idx} className="flex flex-row soft-shadow">
          <div className="bg-[#F2F2F2] flex flex-col justify-center px-2 rounded-tl-2xl rounded-bl-2xl">
            <img src={IconSwap} alt="drag" />
          </div>
          <div
            className="w-full rounded-tr-2xl rounded-br-2xl relative"
            style={{
              backgroundImage: `url(${val.image})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <div className="foreshadow absolute left-0 top-0 bottom-0 w-[700px] z-[100]"></div>
            <div className="flex flex-row justify-between relative z-[101] p-6">
              <div className="flex flex-col w-min">
                <div className="font-semibold text-[28px] text-white">
                  {val.judul}
                </div>
                <div className="mt-4 text-white text-[12px]">
                  {val.deskripsi}
                </div>
                <Buttons
                  text="Daftar Sekarang"
                  containerClass="mt-4"
                  customClass="btn-primary btn-medium"
                />
              </div>
              <div className="flex flex-col justify-end">
                <Checkbox
                  containerClass="absolute right-4 top-4"
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                  }}
                ></Checkbox>
                <div className="flex flex-row gap-4">
                  <div className="backdrop rounded-lg px-4 py-2 flex items-center">
                    <div className="font-semibold text-[16px] text-white">
                      Periode : {moment(val.start_date).format("MMM Do")} -{" "}
                      {moment(val.end_date).format("MMM Do")}
                    </div>
                  </div>
                  <div className="backdrop rounded-lg px-4 py-2">
                    <div className="flex flex-row gap-2">
                      {permissions?.includes('update_apps') && (
                        <div className="bg-white rounded-lg p-1 cursor-pointer">
                          <Edit
                            className="cursor-pointer"
                            onClick={() => {
                              setOpenPopup({
                                show: true,
                                edit: val,
                              });
                            }}
                          />
                        </div>
                      )}
                      {permissions?.includes('delete_apps') && (
                        <div className="bg-white rounded-lg p-1 cursor-pointer">
                          <Trash
                            className="cursor-pointer"
                            onClick={() => {
                              setSlideIdDelete(val.id);
                              setOpenDeleteSlide(true);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }, [store.data.length, store.successEdit]);

  const confirmDeleteSlide = async () => {
    setLoading(true);
    const status = await dispatch(AppsSlideAction.deleteData(slideIdDelete));

    if (status === 200) {
      setOpenDeleteSlide(false);
      setLoading(false);
    }
  };

  return (
    <>
      <AlertPopup
        open={openDeleteSlide}
        subtitle="Apakah anda yakin untuk menghapus slide?"
        handleClose={() => setOpenDeleteSlide(false)}
        handleContinue={confirmDeleteSlide}
        loading={loading}
      />
      <div className="flex flex-col gap-4 mt-4">{getContent()}</div>
    </>
  );
};

SlideWeb.propTypes = {
  store: PropType.object,
  dispatch: PropType.func,
  setOpenPopup: PropType.func,
};

export default React.memo(SlideWeb);
