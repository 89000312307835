import React, { useEffect, useRef, useState } from "react";
import useClickOutside from "utils/helpers/useClickOutside";
import TaskIcon from "../../../../../assets/icons/task-table.svg";
import DocumentManifest from "./DocumentManifest";
import Manifest from "./Manifests";
import { Dropdown } from "antd";
import DropdownCustom from "components/DropdownCustom";


const items = [
  {
    key: '1',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        1st menu item
      </a>
    ),
  },
  {
    key: '2',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        2nd menu item (disabled)
      </a>
    ),
    disabled: true,
  },
  {
    key: '3',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        3rd menu item (disabled)
      </a>
    ),
    disabled: true,
  },
];

const PerlengkapanManifest = ({
  data,
  setIsUpdateManifest,
  setUpdateManifestKey,
  setUpdateManifestTitle,
  setDataManifestSelected
}) => {
  return (
    <>
      {
        <DropdownCustom
          placement="centerLeft"
          onClick={() => setDataManifestSelected(data)}
          dropdownRender={() => (
            <>
              <Manifest 
                data={data}
                setIsUpdateManifest={setIsUpdateManifest}
                setUpdateManifestKey={setUpdateManifestKey}
                setUpdateManifestTitle={setUpdateManifestTitle}
              />
            </>
          )}
          >
          <>
            <div 
              className={`border cursor-pointer ${data?.manifest?.total_document_uploaded !== 0 ? data?.manifest?.total_document_uploaded < data?.manifest?.total_document ? "border-[#FFD700] bg-[#FFFACD]" : "border-[#71BE0E] bg-[#D8E1BE]" : "border-[#E0E0E0]"} px-3 py-[6px] rounded-lg inline-flex items-center gap-1 text-xs`}
              >
              <div>
                <img
                  src={TaskIcon}
                  alt="perlengkapan"
                  className="w-[16px] h-[16px]"
                />
              </div>
              <div>
                Manifest ({data?.manifest?.total_document_uploaded}/{data?.manifest?.total_document})
              </div>
            </div>
          </>
        </DropdownCustom>
      }
      {/* <Dropdown 
        key={data?.id_kamar_mapping}
        trigger={["click"]}
        // getPopupContainer={(trigger) => trigger.parentElement}
        dropdownAl
        dropdownRender={() => (
          <Manifest 
            data={data}
            setIsUpdateManifest={setIsUpdateManifest}
            setUpdateManifestKey={setUpdateManifestKey}
            setUpdateManifestTitle={setUpdateManifestTitle}
          />
        )}
      >
        <div 
          className={`border cursor-pointer ${data?.manifest?.total_document_uploaded !== 0 ? "border-[#71BE0E] bg-[#D8E1BE]" : "border-[#E0E0E0]"} px-3 py-[6px] rounded-lg inline-flex items-center gap-1 text-xs`}
        >
          <div>
            <img
              src={TaskIcon}
              alt="perlengkapan"
              className="w-[16px] h-[16px]"
            />
          </div>
          <div>
            Manifest ({data?.manifest?.total_document_uploaded}/{data?.manifest?.total_document})
          </div>
        </div>
      </Dropdown> */}
      </>
    // <>
    //   <Dropdowns 
    //     labelDropdown={
    //       <>
    //         <div className={`border ${data?.manifest?.total_document_uploaded !== 0 ? "border-[#71BE0E] bg-[#D8E1BE]" : "border-[#E0E0E0]"} px-3 py-[6px] rounded-lg inline-flex items-center gap-1 text-xs`}>
    //           <div>
    //             <img
    //               src={TaskIcon}
    //               alt="perlengkapan"
    //               className="w-[16px] h-[16px]"
    //             />
    //           </div>
    //           <div>
    //             Manifest ({data?.manifest?.total_document_uploaded}/{data?.manifest?.total_document})
    //           </div>
    //         </div>
    //       </> 
    //     }
    //     onClick={() => {
    //       setDataManifestSelected(data)
    //     }}
    //     children={
    //       <Manifest 
    //         data={data}
    //         setIsUpdateManifest={setIsUpdateManifest}
    //         setUpdateManifestKey={setUpdateManifestKey}
    //         setUpdateManifestTitle={setUpdateManifestTitle}
    //         />
    //     }
    //   />
    // </>
  );
};

export default PerlengkapanManifest;
