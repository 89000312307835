import {
  ERROR_FETCHING_SLIDE,
  SET_ADD_OR_EDIT_SLIDE,
  SET_DELETE_SLIDE,
  START_FETCHING_SLIDE,
  SUCCESS_FETCHING_SLIDE,
} from "stores/actions/apps/slide/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  status: statuslist.idle,
  successEdit: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_SLIDE:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_SLIDE:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_SLIDE:
      return {
        ...state,
        data: action.payload.data,
        status: statuslist.success,
        successEdit: false,
      };
    case SET_ADD_OR_EDIT_SLIDE:
      if (action.payload && action.payload.data && !action.edit) {
        state["data"].push(action.payload.data);
      } else {
        state["successEdit"] = true;
        state["data"].map((val, idx) => {
          if (val.id === action.payload.data.id) {
            state["data"][idx] = action.payload.data;
          }
        });
      }
      return state;
    case SET_DELETE_SLIDE:
      state["data"] = state["data"].filter((list) => list.id !== action.id);
      return state;
    default:
      return state;
  }
}
