import { Tooltip } from "antd";
import { IcArrowBottom } from "assets/icons/dashboard";
import Badge from "components/badge";
import Spinner from "components/spinner";

const { DocumentDownload, Eye } = require("iconsax-react");

export const text = (item, handleGenerateItinerary) => (
  <div className="text-white items-center text-center justify-between p-3 text-sm flex-col">
    <div className="text-center font-semibold capitalize">
      {item?.airport_departure_city}
    </div>
    <div class="flex z-50 items-center">
      <div class="border-b border-white flex-grow"></div>
      <div class="px-2">
        <img alt="arrow" src={IcArrowBottom} />
      </div>
      <div class="border-b border-white flex-grow"></div>
    </div>
    <div className="text-center font-semibold capitalize">
      {item?.airport_arrival_city}
    </div>
  </div>
);

export const columns = (dispatch, setIsDrawer, getDetailItenaryService, handleGenerateItinerary, generating, generateIndex) => [
  {
    title: "Starting",
    dataIndex: "nama_starting",
    // className: "w-[20%]",
    render: (val, data, index) => (
      <Tooltip placement="right" title={text(data)}>
        <Badge
          label={data?.starting?.name}
          backgroundColor={data?.starting?.bg_color}
          color={data?.starting?.text_color}
          customClass="px-3 py-1 text-xs font-semibold  rounded-lg"
        />
      </Tooltip>
    ),
  },
  {
    title: "Durasi",
    dataIndex: "jumlah_pax",
    width: "100px",
    render: (val, data, index) => (
      <div className="flex flex-row">
        <div className="ml-2">{data?.day_duration} hari</div>
      </div>
    ),
  },
  {
    title: "Itinerary",
    dataIndex: "rute",
    // className: "w-[65%]",
    render: (rute, data, index) => {
      // console.log("penasawarn", data);
      return (
        <>
          <div className="w-[calc(100vw-460px)] overflow-x-auto costumScrollbarHorizontal py-2">
            <div className="flex">
              {data?.itineraries?.map((item, idx) => (
                <section
                  key={idx}
                  className={`rounded rounded-tl-2xl rounded-br-2xl border-white border-2 bg-gray-200 py-[6px] px-3 $ ${
                    (idx === 0 || idx === data.itineraries?.length - 1) &&
                    "!bg-[#EDBABA] text-red-3"
                  }`}
                  style={{ position: "relative", left: idx * -8 }}
                >
                  {item?.rute.join(",")}
                </section>
              ))}
            </div>
          </div>
          <div className="action">
            <div className="action-content">
              <div className="action-content-item">
                {generating && generateIndex === index ? (
                  <>
                    <Spinner />
                  </>
                ) : (
                  <>
                    <DocumentDownload 
                      onClick={() => {
                        handleGenerateItinerary(data?.id, index)
                      }}
                      size={16}
                      color="#000000"
                      />
                  </>
                )}
              </div>
              <div 
                onClick={() => {
                  getDetailItenaryService(data);
                }}
                className="action-content-item">
                <Eye
                  size="16"
                  color="#141414"
                />
              </div>
            </div>
          </div>
        </>
      );
    },
  },
  // {
  //   title: "Navigasi",
  //   dataIndex: "color",
  //   // className: "w-[20px]",
  //   render: (color, item, idx) => {
  //     return (
  //       <>
  //        <div className="flex flex-row justify-end pr-4 gap-x-8">
  //         <DocumentDownload 
  //           // onClick={handleDownloadAllDocuments}
  //           size={16}
  //           color="#000000"
  //           />
  //         <Eye
  //           className="cursor-pointer"
  //           onClick={() => {
  //             getDetailItenaryService(item);
  //           }}
  //           size="16"
  //           color="#141414"
  //         />
  //         </div>
  //       </>
  //     );
  //   },
  // },
];
