import React, { useEffect, useState } from "react";

import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import Tab from "components/tab";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import AddJamaah from "./components/AddJamaah";
import Popup from "components/popup";
import BookingDetailDataJamaah from "../BookingDetailDataJamaah";
import BookingDetailRoomlist from "../BookingDetailRoomlist";
import BookingDetailBlockseat from "../BookingDetailBlockseat";

import IconSearch from "assets/icons/search-normal.svg";
import IconJamaah from "assets/icons/Jamaah.svg";
import { handleGetDataJamaah } from "stores/actions/booking";
import AddAdditional from "./components/AddAdditional";
// import Blockseat from "../\";

import Blockseat from "pages/paket/detail/pages/Blockseat";
import PaketRoomlist from "pages/paket/detail/pages/Roomlist";

const BookingDetailContent = ({ 
  order
 }) => {
  const dispatch = useDispatch();
  const params = useParams()
  const { bookingDataJamaah, bookingDetail } = useSelector((state) => state.booking);
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  const [openPopupAddJamaah, setOpenPopupAddJamaah] = useState(false);
  const [openPopupAddAdditional, setOpenPopupAddAdditonal] = useState(false);

  const renderTab = () => {
    let tabs = [
      {
        label: "Data Jamaah",
        key: "1",
        children: <BookingDetailDataJamaah
          order={order}
          bookingDataJamaah={bookingDataJamaah}
          />,
      }
    ]

    return tabs

  }

  useEffect(() => {
    dispatch(handleGetDataJamaah(params.orderNumber))
  }, [])

  return (
    <>
      <Popup
        open={openPopupAddJamaah}
        handleClose={() => setOpenPopupAddJamaah(false)}
        title="Tambah Jamaah"
        width="752px"
        children={
          <AddJamaah 
            packageID={order?.paket?.id_paket}
            setOpenPopupAddJamaah={setOpenPopupAddJamaah} 
            handleAddSuccess={() => setOpenPopupAddJamaah(false)}
            />
        }
      />

      <Popup
        open={openPopupAddAdditional}
        handleClose={() => setOpenPopupAddAdditonal(false)}
        title="Tambah Additional"
        width="752px"
        children={
          <AddAdditional
            packageID={order?.paket?.id_paket}
            order={order}
            handleAddSuccess={() => setOpenPopupAddAdditonal(false)}
            />
        }
      />

      <div className="card-detail-booking-content px-4 py-6">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-2 w-full items-center">
            <img src={IconJamaah} alt="jamaah" />
            <div className="text-[20px] font-semibold">Jumlah Jamaah :</div>
            <div className="card-detail-booking-content-total-jamaah">{bookingDataJamaah?.length}</div>
          </div>
          <div className="flex flex-row gap-2">

            {order?.payment_status != 4 && (
              <>
                {permissions?.includes('update_booking-data') && bookingDetail?.payment_status !== 4 && (
                  <>
                    <Buttons
                      text="Tambah Additional"
                      customClass="btn-primary btn-medium"
                      onClick={() => setOpenPopupAddAdditonal(true)}
                    />
                    {(order?.payments_count == 0 && order?.payment_status == 0)  && (
                      <Buttons
                        text="Tambah Jamaah"
                        customClass="btn-primary btn-medium"
                        onClick={() => setOpenPopupAddJamaah(true)}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div>
          <Tab
            defaultActiveKey="1"
            customClass="!my-4"
            items={[
              {
                label: "Data Jamaah",
                key: "1",
                children: <BookingDetailDataJamaah
                  order={order}
                  bookingDataJamaah={bookingDataJamaah}
                  />,
              },
              permissions?.includes('view_roomlist') && {
                label: "Roomlist",
                key: "2",
                children: 
                <PaketRoomlist 
                  packageID={order?.paket?.id_paket}
                />,
              },
              permissions?.includes('view_blockseat') && {
                label: "Blockseat",
                key: "3",
                children: <Blockseat 
                  packageID={order?.paket?.id_paket}
                  />,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(BookingDetailContent);
