import React, { useCallback } from "react";
import { Grid } from "@material-ui/core";

import Badge from "../../../../../../../components/badge";
import Buttons from "../../../../../../../components/buttons";

import ChecklistGreen from "../../../../../../../assets/icons/Checklist-Green.svg";

const ReminderContent = ({ activeDate }) => {
  const getContent = useCallback(() => {
    switch (activeDate) {
      case "1":
        return (
          <div className="mt-4">
            <Grid container spacing={2}>
              <Grid item md={4}>
                <div className="bg-white rounded-2xl soft-shadow p-4">
                  <div className="text-base font-semibold">
                    Umroh Bersama Keluarga
                  </div>
                  <div className="divider mt-4"></div>
                  <div className="flex flex-col gap-4 mt-4 break-normal">
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Event</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="font-semibold w-full">
                        1 min after lead page closed with sub
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Email</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Whatsapp</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Telegram</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Penerima</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <Badge label="Admin" customClass="!px-2 !py-1" />
                        <Badge label="Agen" customClass="!px-2 !py-1" />
                        <Badge label="Jamaah" customClass="!px-2 !py-1" />
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Caption</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                    </div>
                    <div className="line-clamp-3">
                      Lorem ipsum dolor sit amet consectetur. Non congue
                      pellentesque neque nisl faucibus montes massa maecenas.
                      Sit vel ac tristique nibh id.
                    </div>
                    <Buttons
                      text="Edit Template"
                      customClass="btn-secondary btn-medium"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="bg-white rounded-2xl soft-shadow p-4">
                  <div className="text-base font-semibold">
                    Umroh Bersama Keluarga
                  </div>
                  <div className="divider mt-4"></div>
                  <div className="flex flex-col gap-4 mt-4 break-normal">
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Event</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="font-semibold w-full">
                        1 min after lead page closed with sub
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Email</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Whatsapp</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Telegram</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Penerima</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <Badge label="Admin" customClass="!px-2 !py-1" />
                        <Badge label="Agen" customClass="!px-2 !py-1" />
                        <Badge label="Jamaah" customClass="!px-2 !py-1" />
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Caption</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                    </div>
                    <div className="line-clamp-3">
                      Lorem ipsum dolor sit amet consectetur. Non congue
                      pellentesque neque nisl faucibus montes massa maecenas.
                      Sit vel ac tristique nibh id.
                    </div>
                    <Buttons
                      text="Edit Template"
                      customClass="btn-secondary btn-medium"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="bg-white rounded-2xl soft-shadow p-4">
                  <div className="text-base font-semibold">
                    Umroh Bersama Keluarga
                  </div>
                  <div className="divider mt-4"></div>
                  <div className="flex flex-col gap-4 mt-4 break-normal">
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Event</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="font-semibold w-full">
                        1 min after lead page closed with sub
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Email</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Whatsapp</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Telegram</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Penerima</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <Badge label="Admin" customClass="!px-2 !py-1" />
                        <Badge label="Agen" customClass="!px-2 !py-1" />
                        <Badge label="Jamaah" customClass="!px-2 !py-1" />
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Caption</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                    </div>
                    <div className="line-clamp-3">
                      Lorem ipsum dolor sit amet consectetur. Non congue
                      pellentesque neque nisl faucibus montes massa maecenas.
                      Sit vel ac tristique nibh id.
                    </div>
                    <Buttons
                      text="Edit Template"
                      customClass="btn-secondary btn-medium"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="bg-white rounded-2xl soft-shadow p-4">
                  <div className="text-base font-semibold">
                    Umroh Bersama Keluarga
                  </div>
                  <div className="divider mt-4"></div>
                  <div className="flex flex-col gap-4 mt-4 break-normal">
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Event</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="font-semibold w-full">
                        1 min after lead page closed with sub
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Email</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Whatsapp</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Telegram</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Penerima</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <Badge label="Admin" customClass="!px-2 !py-1" />
                        <Badge label="Agen" customClass="!px-2 !py-1" />
                        <Badge label="Jamaah" customClass="!px-2 !py-1" />
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Caption</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                    </div>
                    <div className="line-clamp-3">
                      Lorem ipsum dolor sit amet consectetur. Non congue
                      pellentesque neque nisl faucibus montes massa maecenas.
                      Sit vel ac tristique nibh id.
                    </div>
                    <Buttons
                      text="Edit Template"
                      customClass="btn-secondary btn-medium"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="bg-white rounded-2xl soft-shadow p-4">
                  <div className="text-base font-semibold">
                    Umroh Bersama Keluarga
                  </div>
                  <div className="divider mt-4"></div>
                  <div className="flex flex-col gap-4 mt-4 break-normal">
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Event</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="font-semibold w-full">
                        1 min after lead page closed with sub
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Email</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Whatsapp</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Telegram</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Penerima</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <Badge label="Admin" customClass="!px-2 !py-1" />
                        <Badge label="Agen" customClass="!px-2 !py-1" />
                        <Badge label="Jamaah" customClass="!px-2 !py-1" />
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Caption</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                    </div>
                    <div className="line-clamp-3">
                      Lorem ipsum dolor sit amet consectetur. Non congue
                      pellentesque neque nisl faucibus montes massa maecenas.
                      Sit vel ac tristique nibh id.
                    </div>
                    <Buttons
                      text="Edit Template"
                      customClass="btn-secondary btn-medium"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="bg-white rounded-2xl soft-shadow p-4">
                  <div className="text-base font-semibold">
                    Umroh Bersama Keluarga
                  </div>
                  <div className="divider mt-4"></div>
                  <div className="flex flex-col gap-4 mt-4 break-normal">
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Event</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="font-semibold w-full">
                        1 min after lead page closed with sub
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Email</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Whatsapp</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Telegram</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <img src={ChecklistGreen} alt="checklist" />
                        <div className="font-semibold">Ready</div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Penerima</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                      <div className="flex flex-row gap-2 w-full">
                        <Badge label="Admin" customClass="!px-2 !py-1" />
                        <Badge label="Agen" customClass="!px-2 !py-1" />
                        <Badge label="Jamaah" customClass="!px-2 !py-1" />
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-[#828282] min-w-[26%]">Caption</div>
                      <div className="text-[#828282] min-w-[1%]">:</div>
                    </div>
                    <div className="line-clamp-3">
                      Lorem ipsum dolor sit amet consectetur. Non congue
                      pellentesque neque nisl faucibus montes massa maecenas.
                      Sit vel ac tristique nibh id.
                    </div>
                    <Buttons
                      text="Edit Template"
                      customClass="btn-secondary btn-medium"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        );
      case "2":
        return (
          <div>
            <div>Test 14</div>
          </div>
        );
      case "3":
        return (
          <div>
            <div>Test 7</div>
          </div>
        );
      case "4":
        return (
          <div>
            <div>Test 3</div>
          </div>
        );
      default:
        return (
          <div>
            <div>Test 40</div>
          </div>
        );
    }
  }, [activeDate]);

  return getContent();
};

export default React.memo(ReminderContent);
