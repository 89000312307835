import React, { useEffect, useState } from "react"
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux'
import { uploadFileAPI } from 'utils/api/upload'
import Spinner from "components/spinner";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import { DocumentDownload, Trash } from "iconsax-react";

import IconUploadImage from 'assets/icons/upload-image.svg'
import IconDokumen from 'assets/icons/Dokumen.svg'

const UploadArea = ({
  type,
  customClass,
  onChange,
  label,
  defaultUrl,
  handleRemove,
  name,
  choseType,
  viewOnly,
  customName
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [fileUrl, setFileUrl] = useState('')
  const [typeFile, setTypeFile] = useState(type)
  const [nameInput, setNameInput] = useState("")

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    setIsLoading(true)
    setNameInput(event.target.name)

    if(file) {
      try {
        const formData = new FormData();
        
        formData.append("files[]", file);
        formData.append('directory', 'data-manifest')
        formData.append('type', typeFile)
        
        if(customName) {
          formData.append('file_name', customName)
        }

        const response = await uploadFileAPI(formData)
        const {status, data} = response
        setIsLoading(false)

        if(status === 200) {
          setFileUrl(data.data.link[0])
          onChange(data.data.link[0])
        } else {
          dispatch(handleSuccess(response))
        }

      } catch(error) {
        setIsLoading(false)
        dispatch(handleError(error))
      }
    }

  }

  const handleRemoveFile = () => {
    setFileUrl('')
    if(type === 'chose') {
      setTypeFile('chose')
    }
    handleRemove()
  }
  
  const getFileExtension = (url) => {
    // Mendapatkan bagian akhir dari URL yang berisi ekstensi file
    const parts = url?.split('.');
    return parts[parts?.length - 1];
  }

  const handleDetectFile = () => {
    const extension = getFileExtension(defaultUrl)

    // Membuat pemetaan ekstensi file dengan jenis file yang sesuai
    // Membuat pemetaan ekstensi file dengan jenis file yang sesuai
    const fileTypes = {
      'jpg': 'image',
      'jpeg': 'image',
      'png': 'image',
      'gif': 'image',
      'bmp': 'image',
      'tiff': 'image',
      'jfif': 'image',
      'webp': 'image',
      'svg': 'image',
      'ico': 'image',
      'heif': 'image',
      'avif': 'image',
      'pdf': 'pdf',
      'psd': 'image', // Adobe Photoshop
      'raw': 'image', // Raw image format
      'arw': 'image', // Sony Alpha Raw
      'cr2': 'image', // Canon Raw 2
      'nef': 'image', // Nikon Electronic Format
      'orf': 'image', // Olympus Raw
      'sr2': 'image', // Sony Raw
      'tga': 'image', // Targa
      'dds': 'image', // DirectDraw Surface
      'dng': 'image', // Digital Negative
      'ai': 'image',  // Adobe Illustrator
      'eps': 'image', // Encapsulated PostScript
      'cpt': 'image', // Corel Photo-Paint
      'jpx': 'image', // JPEG 2000 Extended
      'jp2': 'image', // JPEG 2000 Core
      'emf': 'image', // Enhanced Metafile
      'wmf': 'image', // Windows Metafile
      'jxr': 'image', // JPEG XR
      'hdp': 'image', // HD Photo
      'wdp': 'image', // Windows Media Photo
      'dib': 'image', // Device Independent Bitmap
    };
    

    setTypeFile(fileTypes[extension])

  }

  const handleDownloadImage = (url) => {
    var a = document.createElement("a"); //Create <a>
    a.href = `${url}`; //Image Base64 Goes here
    a.download = `${url}`; //File name Here
    a.target = '_blank';
    a.click();
  };

  useEffect(() => {

    setTypeFile(type)
    
  }, [])

  useEffect(() => {
    if(defaultUrl) {
      handleDetectFile()
      setFileUrl(defaultUrl)
    }
  }, [defaultUrl])

  return (
    <>
      {label && (
        <div className="text-sm font-semibold mb-2">
          {label}
        </div>
      )}
      <div class={`flex w-full items-center h-64 justify-center ${customClass}`}>
        {typeFile === 'chose' && (
          <div class={`dark:hover:bg-bray-800 w-full h-full flex flex-row items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-[#f2f2f2] hover:bg-gray-100 overflow-hidden relative gap-4`}>
            {!viewOnly ? (
              <>
                {(choseType === 'image' || choseType === 'all') && (
                  <div 
                    onClick={() => setTypeFile('image')}
                    className="bg-white rounded-xl py-10 px-11 cursor-pointer shadow-custom-3 flex flex-col gap-3 items-center">
                      <div>
                        <img 
                          className="w-10 h-10"
                          src={IconUploadImage}
                          />
                      </div>
                      <div className="font-semibold">
                        Upload Image
                      </div>
                  </div>
                )}
                {(choseType === 'pdf' || choseType === 'all') && (
                  <div 
                    onClick={() => setTypeFile('pdf')}
                    className="bg-white rounded-xl py-10 px-11 cursor-pointer shadow-custom-3 flex flex-col gap-3 items-center">
                      <div>
                        <img 
                          className="w-10 h-10"
                          src={IconDokumen}
                          />
                      </div>
                      <div className="font-semibold">
                        Upload PDF
                      </div>
                  </div>
                )}
                
              </>
            ) : (
              <>
                <div className="flex flex-col justify-center items-center">
                  <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">TIDAK ADA DOKUMEN</span></p>
                  <p class="text-xs text-gray-500 dark:text-gray-400">Dokumen belum diupload</p>
                </div>
              </>
            )}
          </div>
        )}
        
        {typeFile !== "chose" && (
          <>
            {!fileUrl && (
              <label for="dropzone-file" class={`dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-[#f2f2f2] hover:bg-gray-100 ${customClass}`}>
                <div class="flex flex-col items-center justify-center pb-6 pt-5">
                  {!viewOnly ? (
                    <>
                      {!isLoading ? (
                        <>
                          <svg class="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                          </svg>
                          {typeFile === 'image' && (
                            <>
                              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                              <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG or JPEG (MAX. 2MB)</p>
                            </>
                          )}
                          {typeFile === 'pdf' && (
                            <>
                              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                              <p class="text-xs text-gray-500 dark:text-gray-400">ONLY PDF (MAX. 2MB)</p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Spinner 
                            width="50px"
                            height="50px"
                            />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">TIDAK ADA DOKUMEN</span></p>
                      <p class="text-xs text-gray-500 dark:text-gray-400">Dokumen belum diupload</p>
                    </>
                  )}
                </div>
    
                {!viewOnly && (
                  <>
                    {typeFile === 'image' && (
                      <input 
                        id="dropzone-file" 
                        type="file" 
                        class="hidden" 
                        name={name}
                        accept="image/png, image/jpeg, image/jpg" 
                        onChange={handleUpload}
                        />
                    )}
                    {typeFile === 'pdf' && (
                      <input 
                        name={name}
                        id="dropzone-file" 
                        type="file" 
                        class="hidden" 
                        accept="application/pdf" 
                        onChange={handleUpload}
                        />
                    )}
                  </>
                )}
              </label>
            )}
            {fileUrl && (
              <>
                <div 
                  class={`dark:hover:bg-bray-800 flex h-64 w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-[#f2f2f2] hover:bg-gray-100 ${customClass} overflow-hidden relative`}>
                  {typeFile == 'image' && (
                    <>
                      <img 
                        className="w-full object-cover h-full"
                        src={fileUrl} />
                        <div className="absolute top-4 right-4 z-20 flex items-center gap-4">
                          <div>
                            <DocumentDownload 
                              className="cursor-pointer bg-white p-1 rounded-full"
                              size={25}
                              onClick={() => handleDownloadImage(fileUrl)}
                              />
                          </div>
                          {!viewOnly && (
                              <div 
                                onClick={() => handleRemoveFile()}
                                className="cursor-pointer bg-white p-1 rounded-full">
                                <Trash
                                  size={16}
                                  />
                              </div>
                          )}
                        </div>
                    </>
                  )}
                  {typeFile == 'pdf' && (
                    <>
                      <iframe
                      width="100%"
                      height="100%"
                      src={fileUrl}
                      >
                      </iframe>
                      {!viewOnly && (
                        <div 
                          onClick={() => handleRemoveFile()}
                          className="absolute top-4 cursor-pointer right-4 z-20 bg-white p-1 rounded-full">
                          <Trash
                            size={16}
                            />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}

      </div>
    </>
  )
}

UploadArea.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  customClass: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  handleRemove: PropTypes.func,
  viewOnly: PropTypes.bool,
  choseType: PropTypes.string,
  customName: PropTypes.string
  // type: PropTypes.string,
  // autoClose: PropTypes.number,
  // message: PropTypes.string,
  // handleClose: PropTypes.func,
  // hideProgressBar: PropTypes.bool,
  // position: PropTypes.string
}

UploadArea.defaultProps = {
  type: "image",
  customClass: '',
  label: null,
  onChange: () => {},
  handleRemove: () => {},
  viewOnly: false,
  name: null,
  choseType: 'all',
  customName: null
  // type: 'success',
  // autoClose: 5000,
  // message: null,
  // hideProgressBar: true,
  // position: "bottom-right"
}


export default UploadArea