import IconPenarikan from "assets/icons/withdraw.svg";
import IconPenarikanActive from "assets/icons/Penarikan-Fee.svg";
import IconPendapatan from "assets/icons/Pendapatan.svg";
import IconPendapatanActive from "assets/icons/fee-2-active.svg";

export const feeMenuList = [
  {
    title: "Request Penarikan",
    key: "penarikan",
    icon: IconPenarikan,
    iconActive: IconPenarikanActive,
    order: 0,
  },
  {
    title: "Pendapatan",
    key: "pendapatan",
    icon: IconPendapatan,
    iconActive: IconPendapatanActive,
    order: 1,
  },
];
