import React, { useState, useCallback } from "react"

import "./index.scss";
import CheckIcon from "../../../../../assets/icons/Checklist-gray.svg";
import CheckIconDisbaled from "../../../../../assets/icons/Checklist-Green.svg";
import { DocumentDownload, Edit, Eye } from "iconsax-react";
import DocumentUpload from "./DocumentUpload";
import DokumenUploadPassport from "./DokumenUploadPassport";
import IconDowloadZIP from "assets/icons/icon-download-zip.svg";
import { resetDataUpload, setDataUpload } from "stores/actions/uploadFile";
import uuid from "react-uuid";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorage, toCapital } from "utils/helpers";
import UploadFoto from "./UploadFoto";
import { DOCUMENT_URL } from "constant/constanta";
import { replace } from "formik";

const DokumenJamaah = ({
  value,
  isLoadingSave,
  handleUpdate,
  jamaahOrderID,
  orderNumber
}) => {

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const accessToken = getLocalStorage("accessToken");

  
  const covertNameDocument = (document_key) => {
    switch (document_key) {
      case "passport":
        return "Pasport";
      case "pas_photo_4x6":
        return "Pas Foto 4x6";
      case "ktp":
        return "KTP";
      case "kk":
        return "KK";
      case "akta_kelahiran":
        return "Akta Kelahiran";
      case "surat_nikah":
        return "Surat Nikah";
      case "meningitis_vaccine":
        return "Vaksin Meningitis";
      case "vaccine_covid_1":
        return "Vaksin Covid 1";
      case "vaccine_covid_2":
        return "Vaksin Covid 2";
      case "vaccine_covid_3":
        return "Vaksin Covid 3";
      case "bpjs_kesehatan":
        return "BPJS Kesehatan";
    }
  }

  const rennderIcon = (val) => {
    if (val.document_key === "passport") {
      if (val.data[0].document_url === null && val.data[1].document_url === null) {
        return <img src={CheckIcon} className="w-[16px] h-[16px]" />;
      } else {
        return <img src={CheckIconDisbaled} className="w-[16px] h-[16px]" />;
      }
    } else {
      if (val.document_url === null) {
        return <img src={CheckIcon} className="w-[16px] h-[16px]" />;
      } else {
        return <img src={CheckIconDisbaled} className="w-[16px] h-[16px]" />;
      }
    }
  };

  const handleDownloadAllDocuments = () => {
    let token = accessToken.replace(/"/g, "");
    let url = `${DOCUMENT_URL}/api/v2/admin/order/${orderNumber}/jamaah/${jamaahOrderID}/manifests/download?token=${token}`;
    window.open(url, '_blank');
  }

  return (
    <>
      <div className="shadow-soft rounded-2xl overflow-hidden max-w-[276px]">
        <div className="flex p-4 font-semibold gap-4 text-base justify-between bg-orange-1 text-white">
          <div>
            Dokumen Jamaah
          </div>
          <div>
            <div className="bg-white p-1 rounded-lg shadow-highlight cursor-pointer">
              <DocumentDownload 
                onClick={handleDownloadAllDocuments}
                size={16}
                color="#000000"
                />
            </div>
          </div>
        </div>
        <div className="bg-white text-sm">
          {value.map((item) => (
            <>
              <div className="px-3 py-[10px] flexc hover:bg-red-5 flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <div>
                    {rennderIcon(item)}
                  </div>
                  <div>
                    {covertNameDocument(item.document_key)}
                  </div>
                </div>
                <div>
                  {permissions?.includes("update_manifest") ? (
                    <Edit
                      size="16"
                      color="#141414"
                      className="cursor-pointer "
                      onClick={() => {
                        handleUpdate(item);
                      }}
                    />
                  ) : (
                    <Eye
                      size="16"
                      color="#141414"
                      className="cursor-pointer "
                      onClick={() => {
                        handleUpdate(item);
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </> 
  );
};

export default DokumenJamaah;
