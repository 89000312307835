import React, { useCallback, useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import TagihanDetailStyle from "./TagihanDetail.style";
import Tab from "../../../components/tab";
import Popup from "../../../components/popup";
import CardDetailTagihanHeader from "./components/CardDetailTagihanHeader";
import Inputs from "../../../components/form/inputs";
import AddAdditional from "./components/AddAdditional";
import TabTagihan from "./components/TabTagihan";
import TabTransaction from "./components/TabTransaction";

import IconSearch from "../../../assets/icons/search-normal.svg";
import Photo from "../../../assets/images/Photo.png";
import IconEdit from "../../../assets/icons/edit.svg";
import IconDelete from "../../../assets/icons/trash.svg";
import Buttons from "../../../components/buttons";
import AddTransaction from "./components/AddTransaction";
import EditAdditionalForm from "./components/EditAdditionalForm";

import { handleDeleteAdditionalByJamaahOrderAPI } from "utils/api/booking";
import { handleGetDetailOrder, handleGetDataJamaah } from "stores/actions/booking";

import { handleSuccess, handleError } from "stores/actions/errorGeneral"; 

import Alert from "components/popup/alert";
import EditTransaction from "./components/EditTransaction";
import EditDiscount from "./components/EditDiscount";
import Page404 from "components/404";
import AddCoupon from "./components/AddCoupon";
import UpdateDiscountAgent from "./components/UpdateDiscountAgent";

const TagihanDetailPage = () => {
  const params = useParams()
  const dispatch = useDispatch();
  
  const { bookingDataJamaah, bookingDetail } = useSelector((state) => state.booking);
  
  const [openPopupAdditional, setOpenPopupAdditional] = useState(false);
  const [editPopupAdditional, setEditPopupAdditional] = useState(false);

  const [openPopupAddDiscount, setOpenPopupAddDiscount]  = useState(false)
  const [openPopupDiscountAgent, setOpenPopupDiscountAgent] = useState(false)

  const [deleteAlertAdditional, setDeleteAlertAdditional ] = useState(false);
  const [isDeletingAdditional, setIsDeletingAdditional] = useState(false)

  const [isNotFound, setIsNotFound] = useState(false)

  const [selectedAdditional, setSelectedAdditional] = useState({});
  const [isUpdatedPayments, setIsUpdatedPayments] = useState(false);

  const [openPopupPayment, setOpenPopupPayment] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  
  const [openUpdatePayment, setOpenUpdatePayment] = useState(false)
  const [selectdPayment, setSelectedPayment] = useState(null)

  const [openUpdateDiscount, setOpenUpdateDiscount] = useState(false)
  
  const handleDeleteAdditional = (data) => {
    setDeleteAlertAdditional(true)
    setSelectedAdditional(data)
  }

  const handleUpdatePayment = (paymentData) => {
   setOpenUpdatePayment(true) 
   setSelectedPayment(paymentData)
  }

  const handleConfirmDeleteAdditional = async () => {
    // console.log("DELETIING", selectedAdditional);
    setIsDeletingAdditional(true)
    const dataParams = {
      orderNumber: params.orderNumber,
      jamaahOrderID: selectedAdditional.order_kamar_mapping_id,
      additionalID: selectedAdditional.paket_additional_id,
    }

    const response = await handleDeleteAdditionalByJamaahOrderAPI(dataParams)
    setIsDeletingAdditional(false)
    
    if(response.status === 200) {
      dispatch(handleSuccess(response))
      // handleGetDataAdditionals()
      dispatch(handleGetDataJamaah(params.orderNumber))
      dispatch(handleGetDetailOrder(params.orderNumber, () => setIsNotFound(true)))
      setDeleteAlertAdditional(false)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleClosePopup = useCallback(() => {
    if (openPopupAdditional) {
      setOpenPopupAdditional(false);
    }
    if (openPopupPayment) {
      setOpenPopupPayment(false);
    }
  }, [openPopupAdditional, openPopupPayment]);

  useEffect(() => {
    
    if(typeof params?.page !== "undefined") {
      if(params?.page == "payments") {
        setActiveTab("2")
      } else {
        setActiveTab("1")
      }
    }

  }, [params])


  return (
    <TagihanDetailStyle>
      {!isNotFound && (
        <>
          <Popup
            open={openPopupAdditional}
            handleClose={handleClosePopup}
            title="Tambah Additional"
            containerClass="custom-tab-jamaah"
            children={
              <AddAdditional
                packageID={bookingDetail?.paket?.id_paket}
                order={bookingDetail}
                handleAddSuccess={() => setOpenPopupAdditional(false)}
                />
            }
          />
          <Popup
            open={openPopupAddDiscount}
            handleClose={() => setOpenPopupAddDiscount(false)}
            title="Tambah Diskon"
            containerClass="custom-tab-jamaah"
            children={
              <AddCoupon
                packageID={bookingDetail?.paket?.id_paket}
                onSuccess={(data) => {
                  setOpenPopupAddDiscount(false)
                  dispatch(handleGetDataJamaah(params.orderNumber))
                  dispatch(handleGetDetailOrder(params.orderNumber))
                }}
                />
            }
          />
          <Popup
            open={openPopupDiscountAgent}
            handleClose={() => setOpenPopupDiscountAgent(false)}
            title="Update Diskon Agen"
            containerClass="custom-tab-jamaah"
            children={
              <UpdateDiscountAgent 
                onSuccess={() => {
                  setOpenPopupDiscountAgent(false)
                  dispatch(handleGetDetailOrder(params.orderNumber))
                  dispatch(handleGetDataJamaah(params.orderNumber))
                }}
                />
            }
          />
          <Popup
            open={editPopupAdditional}
            handleClose={() => setEditPopupAdditional(false)}
            title="Edit Additional"
            containerClass="custom-tab-jamaah"
            children={
              <EditAdditionalForm
                packageID={bookingDetail?.paket?.id_paket}
                order={bookingDetail}
                additionalData={selectedAdditional}
                onChange={() => setEditPopupAdditional(false)}
                // handleAddSuccess={() => setOpenPopupAdditional(false)}
                />
            }
          />

            <Alert 
              open={deleteAlertAdditional}
              title="Yakin akan menghapus additonal ini?"
              subtitle="Additonal yang akan terhapus dari jamaah order ini"
              handleClose={() => setDeleteAlertAdditional(false)}
              handleContinue={() => handleConfirmDeleteAdditional()}
              text_accept="Yakin"
              text_cancel="Tidak"
              loading={isDeletingAdditional}
              />

          
          <Popup
            open={openPopupPayment}
            handleClose={handleClosePopup}
            title="Tambah Transaksi"
            customClass=""
            children={<AddTransaction 
              onChange={() => {
                handleClosePopup()
                setIsUpdatedPayments(true)
              }
              } />}
          />

          <Popup
            open={openUpdatePayment}
            handleClose={() => setOpenUpdatePayment(false)}
            title="Edit Transaksi"
            customClass=""
            children={
              <>
                <EditTransaction
                  paymentData={selectdPayment}
                  onChange={() => setIsUpdatedPayments(true)}
                  />
              </>  
            }
          />


          <Popup
            open={openUpdateDiscount}
            handleClose={() => setOpenUpdateDiscount(false)}
            title="Edit Diskon"
            customClass=""
            children={
              <>
                <EditDiscount
                  // paymentData={selectdPayment}
                  // onChange={() => setIsUpdatedPayments(true)}
                  />
              </>  
            }
          />
          
          <CardDetailTagihanHeader 
            handleUpdateDiscount={() => setOpenUpdateDiscount(true)}
            handleNotFound={() => setIsNotFound(true)}
            />
            <div className="relative">
              <div className="flex flex-row absolute items-center right-0">
                <Inputs
                  id="test"
                  placeholder="Search"
                  customClass="!px-3  !pl-[42px] !w-[230px]"
                  iconPosition="left"
                  icon={IconSearch}
                />
                {activeTab == 2 ? (
                  <>
                    {(bookingDetail.name_payment_status != "Belum DP" || (bookingDetail.payments_count !== 1 || bookingDetail.payment_dp_status == 4)) && (
                      <>
                        <div className="flex flex-row gap-2 ml-2">
                          <Buttons
                            text="Tambah Transaksi"
                            customClass="btn-primary btn-medium !py-4"
                            onClick={() => setOpenPopupPayment(true)}
                          />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {bookingDetail?.payment_status !== 4 && (
                      <>
                        <div className="flex flex-row gap-2 ml-2">
                          <Buttons
                            text="Tambah Diskon"
                            customClass="btn-primary btn-medium !py-4"
                            onClick={() => setOpenPopupAddDiscount(true)}
                          />
                        </div>
                        {bookingDetail?.guard_type === 'agent' && (
                          <div className="flex flex-row gap-2 ml-2">
                            <Buttons
                              text="Update Diskon Agen"
                              customClass="btn-primary btn-medium !py-4"
                              onClick={() => setOpenPopupDiscountAgent(true)}
                            />
                          </div>
                        )}
                        <div className="flex flex-row gap-2 ml-2">
                          <Buttons
                            text="Tambah Additonal"
                            customClass="btn-primary btn-medium !py-4"
                            onClick={() => setOpenPopupAdditional(true)}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <Tab
                customClass="mt-10 custom-tab-jamaah"
                defaultActiveKey="1"
                onChange={(e) => setActiveTab(e)}
                items={[
                  {
                    label: `Tagihan (${bookingDataJamaah.length} Jamaah)`,
                    key: "1",
                    children: (
                      <TabTagihan 
                        handleUpdateAdditional={(additional) => {
                          setSelectedAdditional(additional);
                          setEditPopupAdditional(true)
                        }}
                        handleDeleteAdditional={handleDeleteAdditional}
                        />
                    ),
                  },
                  { label: "Transaksi", 
                    key: "2", 
                    children: (
                      <>
                        <TabTransaction activeTab={activeTab} 
                          isUpdatedPayments={isUpdatedPayments} 
                          handleSuccessGetPayment={() => setIsUpdatedPayments(false)} 
                          handleUpdate={handleUpdatePayment}
                          /> 
                      </>
                    )
                  }
                ]}
              />
            </div>
            <div id="area-modal"></div>
        </>
      )}
      {isNotFound && <Page404 />}
    </TagihanDetailStyle>
  );
};

export default TagihanDetailPage;
