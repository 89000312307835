import { handleError, handleErrorBE } from "../errorGeneral"
import { handleGetIndonesianBanksAPI } from "utils/api/indonesianBanks" 

export const FETCH_INDONESIAN_BANKS = 'booking/FETCH_INDONESIAN_BANKS'

export const fethIndonesianBanks = (payload) => ({
    type: FETCH_INDONESIAN_BANKS,
    payload
})

export const handleGetIndonesianBanks = () => async (dispatch) => {
    try {
        const res = await handleGetIndonesianBanksAPI()

        const { status, data } = res
        if (status == 200) {
            dispatch(fethIndonesianBanks(data.data))
        } else {
            dispatch(handleErrorBE(res))
        }

    } catch (error) {
        dispatch(handleError(error))
    }
}

