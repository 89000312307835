export const SET_LOADING = "apps/artikel/category/SET_LOADING";
export const SET_SUCCESS = "apps/artikel/category/SET_SUCCESS";
export const CLEAR_ERROR = "apps/artikel/category/CLEAR_ERROR";
export const SET_ERROR = "apps/artikel/category/SET_ERROR";
export const INIT_DATA = "apps/artikel/category/INIT_DATA";
export const SET_CATEGORY_ARTICLE =
  "apps/artikel/category/SET_CATEGORY_ARTICLE";
export const SET_ADD_CATEGORY_ARTICLE =
  "apps/artikel/category/SET_ADD_CATEGORY_ARTICLE";
export const SET_DELETE_CATEGORY_ARTICLE =
  "apps/artikel/category/SET_DELETE_CATEGORY_ARTICLE";
