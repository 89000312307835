import React, { useEffect, useState } from "react";

import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import Buttons from "components/buttons";
import InputPhone from "components/form/inputPhone";

import Dropdowns, { closeDropdown } from "components/dropdowns";

import { useDispatch } from "react-redux";

import IconSearch from "assets/icons/search-normal.svg";
import Triple from "assets/icons/Triple.svg";

import Scroll from "components/scroll";
import Badge from "components/badge";

import { getRoomPrices } from "utils/api/paket";

import './style.css'
import { IDRFormater, toCapital } from "utils/helpers";
import { handleError } from "stores/actions/errorGeneral";
import starting from "stores/reducers/datamaster/starting";
import Radio from "components/form/radio";
import Switch from "components/form/switch";
import { useFormik } from "formik";

import * as Yup from 'yup';
import { logDOM } from "@testing-library/react";

const JamaahForm = ({ index, dataForm, packageID, handleSaveOrderJamaah }) => {
  const dispatch = useDispatch()

  const [startingRoomPrices, setStartingRoomPrices] = useState([])
  const [startingSelected, setStartingSelected] = useState({})

  const [startingLabel, setStartingLabel] = useState('Pilih Starting')
  const [roomPriceLabel, setRoomPriceLabel] = useState('Pilih Harga Kamar')

  const [isBusinessClass, setIsBusinessClass] = useState(false)
  const [isOldJamaah, setIsOldJamaah] = useState(false)

  const [oldJamaahLabel, setOldJamaahLabel] = useState("Pilih Jamaah")

  const [allowAddJamaah, setAllowAddJamaah] = useState(true)

  const titleOptions = [
    {
      label: "Tuan",
      value: 'tn'
    },
    {
      label: "Nyonya",
      value: 'ny'
    },
    {
      label: "Nona",
      value: 'nn'
    }
  ]  

  const handleFillOptions = (value) => {
    const title = titleOptions.find((item) => item.value === value)

    return [title]
  }

  useEffect(() => {
    if(packageID){
      handleRoomPrices()
    }
  }, [packageID])

  useEffect(() => {
    // console.log(dataForm.errors.order_kamars[index])
  }, [dataForm.values])

  const handleRoomPrices = async () => {
    const response = await getRoomPrices(packageID)
    const { status, data } = response

    if(status == 200) {
      setStartingRoomPrices(data.data)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleSetStarting = (starting) => {
    setStartingSelected(starting)
    setStartingLabel(starting.starting.name)
    closeDropdown();
  }

  const handleSetRoomPrice = (roomID, roomName, roomDescription, price) => {
    jamaahFormik.setFieldValue(`paket_kamar.id`, roomID)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_name`, `${roomName}${isBusinessClass ? '_bussiness' : ""}`)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_description`, roomDescription)
    jamaahFormik.setFieldValue(`paket_kamar.price`, price)

    jamaahFormik.setFieldValue(`starting`, {
      name: startingSelected.starting.name,
      bg_color: startingSelected.starting.bg_color,
      text_color: startingSelected.starting.text_color,
      departure: startingSelected.starting.departure,
      arrival: startingSelected.starting.return
    })

    const label = `${toCapital(roomName)} - ${IDRFormater(price)}`
    setRoomPriceLabel(label)
  }

  const handleChangeBusinnessClass = () => {
    setIsBusinessClass(!isBusinessClass);

    jamaahFormik.setFieldValue(`paket_kamar.id`, null)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_name`, null)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_description`, null)

    setRoomPriceLabel('Pilih Harga Kamar')
  }

  const handleSaveJamaah = () => {

    handleSaveOrderJamaah(jamaahFormik.values)
    
  }

  const jamaahFormik = useFormik({
    initialValues: {
      "is_available": false,
      "jamaah_title": "tn",
      "jamaah_name": null,
      "jamaah_passport_number": null,
      "jamaah_identity_number": null,
      "jamaah_phone": null,
      "jamaah_email": null,
      "user_jamaah_id": null,
      "paket_kamar": {
          "id": null,
          "kamar_name": null,
          "kamar_description": null,
          "price": null
      },
      "starting": {
        "name": null,
        "bg_color": null,
        "text_color": null,
        "departure": null,
        "return": null
      }
    },
    validationSchema: Yup.object({
      "jamaah_title": Yup.string().required("Harus pilih salah satu title"),
      "jamaah_name": Yup.string().required("Nama Jamaah harus diisi"),
      "jamaah_identity_number": Yup.string().required("NIK harus diisi")
                                            .matches(/^[0-9]+$/, "NIK Harus berupa angka")
                                            .min(16, "NIK harus 16 digit")
                                            .max(16, "NIK harus 16 digit"),
      "paket_kamar": Yup.object({
          "id": Yup.number().required("Harus pilih starting & Tipekamar"),
          "kamar_name": Yup.string().required("Harus pilih starting & Tipekamar"),
      })
    })
  })

  return (
      <>
        <div className="bg-white p-4 mt-4 rounded-xl">
          
          <div className="mb-4">
            
            <div className="flex flex-row items-center mb-4">
              <Switch
                checked={isOldJamaah}
                onChange={() => {
                  setIsOldJamaah(!isOldJamaah);
                }}
              />
              <div className="text-sm font-semibold text-[#000] ml-2 w-full">
                Jamaah lama
              </div>
            </div>
            
            {isOldJamaah && (
              <>
                <Dropdowns
                  customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
                  placement="bottom"
                  labelDropdown={oldJamaahLabel}
                  dropdownArrow={true}
                  disabled={!packageID}
                ></Dropdowns>
              </>
            )}

          </div>

          <div className="flex gap-[10px] mb-4">
            <div className="w-[25%]">
              <Selects 
                label="Title"
                placeholder="Title"
                options={titleOptions}
                onChange={(e) => {
                  jamaahFormik.setFieldValue(`jamaah_title`, e[0].value)
                }}
                onBlur={jamaahFormik.handleBlur}
                values={handleFillOptions(jamaahFormik?.values.jamaah_title)}
                error={jamaahFormik?.touched?.jamaah_title && jamaahFormik?.errors?.jamaah_title}
                />
            </div>
            <div className="w-[75%]">
              <Inputs 
                label="Nama"
                placeholder="Masukan nama"
                name={`jamaah_name`}
                onChange={(e) => {
                  jamaahFormik.setFieldValue(`jamaah_name`, e.target.value.toUpperCase())
                }}
                onBlur={jamaahFormik.handleBlur}
                value={jamaahFormik?.values?.jamaah_name?.toUpperCase()}
                error={jamaahFormik?.touched?.jamaah_name && jamaahFormik?.errors?.jamaah_name}
                />
            </div>
          </div>
          <div className="flex gap-[10px] mb-4">
            <div className="w-[25%]">
              <Inputs 
                value={"KTP"}
                label="Identitas"
                disabled
                />
            </div>
            <div className="w-[75%]">
              <Inputs 
                label="No. KTP"
                placeholder="Masukan No. KTP"
                name={`jamaah_identity_number`}
                maxLength={16}
                onChange={jamaahFormik.handleChange}
                onBlur={jamaahFormik.handleBlur}
                values={jamaahFormik?.values.jamaah_identity_number}
                error={jamaahFormik?.touched?.jamaah_identity_number && jamaahFormik?.errors?.jamaah_identity_number}
                />
            </div>
          </div>
          <div className="mb-4 mt-2"> 
          <div className="flex flex-row items-center">
            <Switch
              checked={isBusinessClass}
              onChange={(val) => {
                handleChangeBusinnessClass()
                // handleWACabang(isChecked);
              }}
            />
            <div className="text-sm font-semibold text-[#000] ml-2 w-full">
              Business Class
            </div>
          </div>
          </div>
          <div>
            <div className="font-semibold text-sm mb-2">Starting</div>
            <Dropdowns
              customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
              placement="bottom"
              labelDropdown={startingLabel}
              dropdownArrow={true}
              disabled={!packageID}
            >
              <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
                <div>
                  <Inputs 
                    name="searchStarting"
                    placeholder="Cari Tipe Kamar"
                    customClass="!px-3 !py-3.5 !pl-[42px] text-sm"
                    iconPosition="left"
                    icon={IconSearch}
                    />
                </div>
                <Scroll height="350px" customClass="pr-2">
                  {startingRoomPrices && startingRoomPrices.map((room, idx) => {
                    return (
                      <>
                        <div 
                          onClick={() => handleSetStarting(room)}
                          className="p-4 cursor-pointer active:bg-[#FF9C9A] hover:bg-red-5 hover:border-red-5 rounded-2xl flex justify-between items-start border border-gray-4 mt-[18px]">
                          <div className="flex gap-11.5 justify-between w-[40%]">
                            <div>
                              <div className="text-xs text-think mb-1">
                                Berangkat
                              </div>
                              <div className="font-semibold">
                                {room?.starting?.departure}
                              </div>
                            </div>
                            <div>
                              <div className="text-xs text-think mb-1">
                                Pulang
                              </div>
                              <div className="font-semibold">
                                {room?.starting?.return}
                              </div>
                            </div>
                          </div>
                          <Badge 
                            label={room?.starting?.name}
                            backgroundColor={room?.starting?.bg_color}
                            color={room?.starting?.text_color}
                            />
                        </div>
                      </>
                    )
                  })}
                </Scroll>
              </div>
            </Dropdowns>
          </div>
          <div className="mt-4">
            <div className="font-semibold text-sm mb-2 mt-4">Tipe Kamar</div>
            <Dropdowns
              customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
              placement="bottom"
              labelDropdown={roomPriceLabel}
              dropdownArrow={true}
              disabled={Object.keys(startingSelected).length === 0}
              error={jamaahFormik?.touched?.paket_kamar?.id && jamaahFormik?.errors?.paket_kamar?.id}
            >
              <div className="bg-white w-full soft-shadow rounded-2xl overflow-hidden">
                <Scroll height="350px">
                  
                  {startingSelected.is_active_price_quad && (
                    <div 
                      onClick={() => {
                        handleSetRoomPrice(
                            startingSelected.id_kamar, 
                            `quad`, 
                            `4 pax/room`,
                            isBusinessClass ? startingSelected.price_quad_business : startingSelected.price_quad
                        )
                        closeDropdown()
                      }}
                      className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                      <div className="flex items-center gap-2 mb-2">
                        <img 
                          src={Triple}
                          />
                        <div className="font-base font-semibold">
                          Quad
                        </div>
                      </div>
                      <div className="green-money font-semibold text-xl">
                        {isBusinessClass ? IDRFormater(startingSelected.price_quad_business) : IDRFormater(startingSelected.price_quad)}
                      </div>
                    </div>
                  )}
                  
                  {startingSelected.is_active_price_triple && (
                    <>
                      <div 
                        onClick={() => {
                          handleSetRoomPrice(
                              startingSelected.id_kamar, 
                              `tripple`, 
                              `3 pax/room`,
                              isBusinessClass ? startingSelected.price_triple_business : startingSelected.price_triple
                          )
                          closeDropdown()
                        }}
                        className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                        <div className="flex items-center gap-2 mb-2">
                          <img 
                            src={Triple}
                            />
                          <div className="font-base font-semibold">
                            Triple
                          </div>
                        </div>
                        <div className="green-money font-semibold text-xl">
                          {isBusinessClass ? IDRFormater(startingSelected.price_triple_business) : IDRFormater(startingSelected.price_triple)}
                        </div>
                      </div>
                    </>
                  )}

                  {startingSelected.is_active_price_double && (
                    <div 
                      onClick={() => {
                        handleSetRoomPrice(
                          startingSelected.id_kamar, 
                          `double`, 
                          `2 pax/room`,
                          isBusinessClass ? startingSelected.price_double_business : startingSelected.price_double
                        )
                        closeDropdown()
                      }}
                      className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                      <div className="flex items-center gap-2 mb-2">
                        <img 
                          src={Triple}
                          />
                        <div className="font-base font-semibold">
                          Double
                        </div>
                      </div>
                      <div className="green-money font-semibold text-xl">
                        {isBusinessClass ? IDRFormater(startingSelected.price_double_business) : IDRFormater(startingSelected.price_double)}
                      </div>
                    </div>
                  )}

                  {startingSelected.is_active_price_single && (
                    <div 
                      onClick={() => {
                        handleSetRoomPrice(
                          startingSelected.id_kamar, 
                          `single`, 
                          `1 pax/room`,
                          isBusinessClass ? startingSelected.price_single_business : startingSelected.price_single
                        )
                        closeDropdown()
                      }}
                      className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                      <div className="flex items-center gap-2 mb-2">
                        <img 
                          src={Triple}
                          />
                        <div className="font-base font-semibold">
                          Single
                        </div>
                      </div>
                      <div className="green-money font-semibold text-xl">
                        {isBusinessClass ? IDRFormater(startingSelected.price_single_business) : IDRFormater(startingSelected.price_single)}
                      </div>
                    </div>
                  )}
                </Scroll>
              </div>
            </Dropdowns>
          </div>
          <div className="mt-4">
            <InputPhone 
              label="Nomor Telepon"
              placeholder="Masukan Nomor Telepon"
              name={`jamaah_phone`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.jamaah_phone}
              onChange={(val) => {
                jamaahFormik.setFieldValue(`jamaah_phone`, val)
                jamaahFormik.setFieldTouched(`jamaah_phone`, true, true)
              }}
              error={jamaahFormik?.touched?.jamaah_phone && jamaahFormik?.errors?.jamaah_phone}
              />
          </div>
          <div className="mt-4">
            <Inputs 
              label="Email"
              placeholder="Masukan Email"
              name={`jamaah_email`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.jamaah_email}
              onChange={jamaahFormik.handleChange}
              error={jamaahFormik?.touched?.jamaah_email && jamaahFormik?.errors?.jamaah_email}
              />
          </div>
          <div className="mt-4">
            <Buttons 
              text="Simpan"
              customClass="w-full btn-primary btn-medium"
              onClick={handleSaveJamaah}
              disabled={!jamaahFormik.isValid || !jamaahFormik.dirty}
              />
          </div>
        </div>
      </>
  )
}

export default React.memo(JamaahForm);