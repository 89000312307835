import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import Buttons from "../../../../components/buttons";
import Cards from "../../../../components/cards";
import Inputs from "../../../../components/form/inputs";

const EmailSettings = ({
  values,
  handleChange,
  handleBlur,
  setCurrentStep,
  onHandleSubmit,
  touched,
  errors,
  formik,
}) => {
  const { isLoading } = useSelector((state) => state.cabang);

  return (
    <Cards containerClass="card-border !p-0">
      <div className="p-[20px]">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Inputs
              label="Email Mailer"
              placeholder="Mailer"
              id="email_mailer"
              name="email_mailer"
              value={values?.email_mailer}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.email_mailer && errors.email_mailer
                  ? errors.email_mailer
                  : ""
              }
            />
          </Grid>
          <Grid item md={6}>
            <Inputs
              label="Email Host"
              placeholder="Host"
              id="email_host"
              name="email_host"
              value={values?.email_host}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.email_host && errors.email_host ? errors.email_host : ""
              }
            />
          </Grid>
        </Grid>
        <div className="mt-4">
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Inputs
                label="Email Port"
                placeholder="Port"
                id="email_port"
                name="email_port"
                value={values?.email_port}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.email_port && errors.email_port
                    ? errors.email_port
                    : ""
                }
              />
            </Grid>
          </Grid>
        </div>
        <div className="mt-4">
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Inputs
                label="Email Username"
                placeholder="Username"
                id="email_username"
                name="email_username"
                value={values?.email_username}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.email_username && errors.email_username
                    ? errors.email_username
                    : ""
                }
              />
            </Grid>
            <Grid item md={6}>
              <Inputs
                label="Email Password"
                placeholder="Password"
                id="email_password"
                name="email_password"
                value={values?.email_password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.email_password && errors.email_password
                    ? errors.email_password
                    : ""
                }
              />
            </Grid>
          </Grid>
        </div>
        <div className="mt-4">
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Inputs
                label="Email Encryption"
                placeholder="Email Encryption"
                id="email_encryption"
                name="email_encryption"
                value={values?.email_encryption}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.email_encryption && errors.email_encryption
                    ? errors.email_encryption
                    : ""
                }
              />
            </Grid>
            <Grid item md={6}>
              <Inputs
                label="Email From Address"
                placeholder="Address"
                id="email_from_address"
                name="email_from_address"
                value={values?.email_from_address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.email_from_address && errors.email_from_address
                    ? errors.email_from_address
                    : ""
                }
              />
            </Grid>
            <Grid item md={12}>
              <Inputs
                label="Email From Name"
                placeholder="Email From Name"
                id="email_from_name"
                name="email_from_name"
                value={values?.email_from_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.email_from_name && errors.email_from_name
                    ? errors.email_from_name
                    : ""
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="divider"></div>
      <div className="px-[20px] py-[6px] flex flex-row justify-end gap-2">
        <Buttons
          text="Sebelumnya"
          customClass="btn-primary btn-large"
          onClick={() => setCurrentStep(1)}
        />
        <Buttons
          text="Submit"
          customClass="btn-primary btn-large"
          loading={isLoading ? true : false}
          onClick={onHandleSubmit}
        />
      </div>
    </Cards>
  );
};

export default React.memo(EmailSettings);
