import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import Popup from "components/popup";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteIdMasterAdditional,
  duplicateAdditional,
  getMasterAdditional,
} from "stores/actions/datamaster/adminAdditional";
import MoslemeTable from "../../../../../components/Table";
import AlertPopup from "../../../../../components/popup/alert";
import { getMasterId } from "../../../../../stores/actions/datamaster";
import useDebounce from "../../../../../utils/helpers/useDebounce";
import CheckboxMaster from "../../checkbox";
import { columns } from "./columns";
import {
  globalState,
  setValidation,
  set_after_validation,
} from "stores/actions/general";

const Additional = ({ filter }) => {
  // Selection & type state
  const auth = useSelector(({ auth }) => auth)
  const { global, validation, afterValidation } = useSelector(
    (state) => state.general
  );
  const { allAdditional, isLoading } = useSelector(
    (state) => state.adminAdditional
  );
  const { masterId } = useSelector((state) => state.datamaster);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [input, setInput] = useState({
    name: "",
    default_price: true,
    description: true,
  });
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    masterId?.data &&
      setInput({
        // name: `clone - ${masterId?.data?.name}`,
        name: `${masterId?.data?.name} - CLONE`,
        default_price: true,
        description: true,
      });
  }, [masterId?.data, masterId?.data?.name]);

  // Pagination state
  const total = allAdditional?.total_data;

  // data table
  let dataTable = allAdditional?.data;
  const dispatch = useDispatch();

  // filter data table
  const fitlerAdditional = useDebounce(filter, 1000);

  const masterAdditional = useCallback(() => {
    dispatch(
      getMasterAdditional({
        page_number: currentpage,
        per_page: size,
        keyword: fitlerAdditional,
        sort_column: "id_additional",
        sort_order: "DESC",
      })
    );
  }, [dispatch, fitlerAdditional, currentpage, size]);

  useEffect(() => {
    masterAdditional();
  }, [masterAdditional]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const onHandleDelete = () => {
    dispatch(deleteIdMasterAdditional(masterId?.data?.id_additional)).then(
      (item) => {
        dispatch(getMasterId({}));
        masterAdditional();
      }
    );
  };

  const onHandleDuplicate = () => {
    input.id = masterId?.data?.id_additional;
    input.default_price = input.default_price ? 1 : 0;
    input.description = input.description ? 1 : 0;

    dispatch(duplicateAdditional(input));
  };

  useEffect(() => {
    if (afterValidation?.code === 200) {
      dispatch(getMasterId({}));
      masterAdditional();
      dispatch(setValidation({}));
      dispatch(set_after_validation({}));
    }
  }, [afterValidation?.code, dispatch, masterAdditional]);

  return (
    <div>
      <AlertPopup
        open={masterId?.modal}
        handleClose={() => dispatch(getMasterId({}))}
        handleContinue={onHandleDelete}
        loading={isLoading ? true : false}
      />

      <Popup
        open={masterId?.popup}
        handleClose={() => {
          dispatch(getMasterId({}));
          dispatch(setValidation({}));
          dispatch(set_after_validation({}));
        }}
        title="Duplikat Additional"
      >
        <Inputs
          id="additional_name"
          placeholder="Masukan nama additional"
          // customClass="input-disabled"
          label="Nama Additional"
          value={input.name}
          onChange={(e) => setInput({ ...input, name: e.target.value })}
          error={validation?.data?.name && validation?.data?.name[0]}
        />
        {/* <CheckboxMaster
          customClass="mt-4"
          title="Nama"
          value={input.name}
          checked={input.name}
          onChange={(e) =>
            setInput({
              ...input,
              name: e.target.checked,
            })
          }
        /> */}
        <CheckboxMaster
          customClass="mt-4"
          title="Harga Default"
          value={input.default_price}
          checked={input.default_price}
          onChange={(e) =>
            setInput({
              ...input,
              default_price: e.target.checked,
            })
          }
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Description"
          value={input.description}
          checked={input.description}
          onChange={(e) =>
            setInput({
              ...input,
              description: e.target.checked,
            })
          }
        />
        <div className="grid grid-cols-2 mt-4 items-center gap-x-4">
          <Buttons
            onClick={() => dispatch(getMasterId({}))}
            text="Batal"
            customClass="btn-secondary btn-large"
          />

          <Buttons
            onClick={onHandleDuplicate}
            text="Simpan"
            loading={isLoading ? true : false}
            disabled={isLoading ? true : false}
            customClass="btn-primary btn-large"
          />
        </div>
      </Popup>

      <MoslemeTable
        columns={columns(dispatch, auth?.user?.permissions)}
        loading={isLoading}
        data={dataTable}
        rowId={(record) => record.id_additional}
        withSelection={true}
        selectionType={selectionType}
        rowKey={(record) => record.id_additional}
        pagination={pagination}
        customPaginationClass="pr-4"
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) => {
          onSizeChange(currentpage, pageSize);
        }}
      />
    </div>
  );
};

export default Additional;
