import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

export const totalJamaahApi = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/dashboard/get-total-jamaah`, payload);

export const totalPaketApi = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/dashboard/get-total-paket`, payload);

export const totalPaketAktifApi = (payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/dashboard/get-total-paket-aktif`,
    payload
  );

export const totalMemberOnlineApi = (payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/dashboard/get-total-member-online`,
    payload
  );

export const statistikApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/dashboard/get-statistic`, payload);

export const belumDPApi = (payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/dashboard/get-order-belum-dp`,
    payload
  );

export const belumLunasApi = (payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/dashboard/get-order-belum-lunas`,
    payload
  );

export const kelengkapanDataApi = (payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/dashboard/get-kelengkapan-data-jamaah`,
    payload
  );

export const dataKelengkapanJamaahApi = (payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/dashboard/get-data-kelengkapan-jamaah`,
    payload
  );
