import React, { useState } from "react";
import ImageField from "../../../../../components/image_field";
import Inputs from "../../../../../components/form/inputs";
import Add from "../../../../../assets/icons/add-circle.svg";
import TextAreas from "../../../../../components/form/textarea";
import Grid from "@mui/material/Grid";
import ListUrl from "../../listurl";
import { useSelector } from "react-redux";
import RichTextEditor from "components/richTextEditor";

function GaleriTransport({
  formikOnChange,
  valueDescription,
  setDataVideo,
  dataVideo,
  setMultipleUpload,
  descriptionOnChange,
}) {
  const [data, setData] = useState("");

  const { dataMultiple } = useSelector((state) => state.uploadFile);
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={12} md={6}>
        <ImageField
          label="Galeri Transportasi"
          customContainer="!w-auto h-[20rem] !rounded-2xl"
          isMulti={true}
          name="gallery transportasi"
          setFile={() => setMultipleUpload(dataMultiple.map((x) => x.path))}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <Inputs
          id="link"
          label="Link Video Galeri"
          placeholder="Masukan Link"
          iconPosition="right"
          icon={Add}
          onChange={(e) => setData(e.target.value)}
          onClick={(e) => {
            setDataVideo([...dataVideo, data]);
          }}
        />
        <div className="my-4 ">
          <ListUrl data={dataVideo} setDataVideo={setDataVideo} />
        </div>
      </Grid>
      <Grid item sm={12}>
        <RichTextEditor
          placeholder="Masukan Deskripsi Transportasi"
          data={{ value: valueDescription }}
          onChange={descriptionOnChange}
        />
      </Grid>
    </Grid>
  );
}

export default GaleriTransport;
