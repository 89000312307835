import {
    SET_USER,
    SET_IS_AUTH
} from '../../actions/auth'

const initialState = {
    isAuth: false,
    user: {}
}

export default (state = initialState, { payload, type }) => {
    switch(type) {
      case SET_USER:
        return {
          ...state,
          user: payload
        }
      case SET_IS_AUTH:
        return {
            ...state,
            isAuth: payload
        }
      default:
        return state;
    }
}