const { IcCabangPusat } = require("assets/icons/dashboard");
const { Trash, Eye } = require("iconsax-react");
const { detailById } = require("stores/actions/general");

export const columns = (dispatch) => [
    {
        title: "Nama",
        dataIndex: "name",
        width: "10%",
        render: (name, data) => {
            return (
                <div className="flex flex-row">
                    <div className="ml-2">{name}</div>
                </div>
            );
        },
    },
    {
        title: "Alamat",
        width: "45%",
        dataIndex: "address_details",
    },
    {
        title: "Warna",
        dataIndex: "color",
        width: "15%",
        render: (color, item, idx) => {
            return (
                <>
                    <div
                        className={`px-2 py-1.5 rounded-lg text-center inline-block uppercase`}
                        style={{ backgroundColor: color, fontSize: "12px" }}
                    >
                        {color}
                    </div>
                </>
            );
        },
    },
];
