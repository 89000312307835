import styled from "styled-components";

const SettingStyle = styled.div`
  margin-left: -16px;

  .ant-tabs-nav {
    width: fit-content;
  }

  .card-article {
    cursor: pointer;
    position: relative;

    &:hover,
    &:active {
      border: 1px solid #f90601;
    }

    .card-article-actions {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 144px;
      background: white;
      z-index: 10;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
    }

    &:hover {
      .card-article-actions {
        display: flex;
      }
    }
  }

  .switch-slide {
    &.ant-switch.ant-switch-checked {
      background: linear-gradient(200.57deg, #f9f218 3.37%, #e2a246 86.36%);
    }
  }

  .ant-tabs-nav {
    width: max-content;
  }

  .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
    border: 0.5px solid #bdbdbd;
    border-radius: 8px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    background: #ffffff;
  }

  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border: none;
  }

  .ant-table-tbody .ant-table-row:last-child .ant-table-cell:first-child {
    border-bottom-left-radius: 7px;
  }

  .ant-table-tbody .ant-table-row:last-child .ant-table-cell:last-child {
    border-bottom-right-radius: 7px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 8px;
  }
`;

export default SettingStyle;
