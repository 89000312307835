import axios from "axios";
import {
  BASE_URL_ENDPOINT,
  BASE_URL_ENDPOINT_V2,
} from "../../../../constant/constanta";

const getAllTipeKamarDasar = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/type-room/basic-prices`
  );
const setTipeKamarDasar = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/set-starting-basic`,
    payload
  );

const setTipeKamarStarting = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/set-starting`,
    payload
  );

const setUpdatedTipeKamarDasar = (id, payload) =>
  axios.put(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/type-room/update-basic-price`,
    payload
  );

const getAllTipeKamarPenambahan = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/type-room/starting-price-additions`
  );

const setUpdatedTipeKamarPenambahan = (id, payload) =>
  axios.put(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/type-room/update-starting-price`,
    payload
  );

const getListJamaahStarting = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/type-room/get-jamaah`,
    payload
  );

const updateHarga = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-tipe-kamar`, payload);

const updateHargaBatch = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-tipe-kamar-batch`, payload);

const deleteById = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/paket/tipe-kamar/${id}`);

const detailById = (payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/order/get-jamaah-tipe-kamar-starting`,
    payload
  );

// synce
export const tipeKamarApi = {
  allTipeKamarDasar: getAllTipeKamarDasar,
  setTipedasarKamar: setTipeKamarDasar,
  setTipeKamarStarting: setTipeKamarStarting,
  setUpdatedTipeKamarDasar: setUpdatedTipeKamarDasar,
  allTipeKamarPenambahan: getAllTipeKamarPenambahan,
  setUpdatedTipeKamarPenambahan: setUpdatedTipeKamarPenambahan,
  getListJamaahStarting: getListJamaahStarting,

  updateHarga: updateHarga,
  updateHargaBatch: updateHargaBatch,
  detail: detailById,
  delete: deleteById,
};
