import { ArrowDown, ArrowDown2 } from "iconsax-react";
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";


const DropdownsKu = ({
    costumLabel,
    children,
    open,
    classLabel,
    arrow
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        } else {
            setIsOpen(true)
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    });

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    return (
        <>
            <div 
                // onClick={() => handlDropdownOpen(!isOpen)}
                ref={dropdownRef}
                className="cursor-pointer relative">
                <div className={`text-sm ${classLabel}`}>
                    {costumLabel}
                    {arrow && (
                        <div className={`transition-all duration-500 ${isOpen ? "rotate-180" : ""}`}>
                            <ArrowDown2
                                size={16} 
                                />
                        </div>
                    )}
                </div>
                {isOpen && (
                    <>
                        <div className="absolute left-0 top-[100%] z-50">
                            {children}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

DropdownsKu.prototype = {
    costumLabel: PropTypes.string,
    children: PropTypes.node,
    open: PropTypes.bool,
    classLabel: PropTypes.string,
    arrow: PropTypes.bool
};

DropdownsKu.defaultProps = {
    costumLabel: "Dropdown",
    children: null,
    open: false,
    classLabel: "",
    arrow: true
};

export default React.memo(DropdownsKu);