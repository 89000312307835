import React, { useEffect, useState } from "react";
import Card from "../../../../../components/cards";
import Steppers from "../../../../../components/steps/Steppers";
import Buttons from "../../../../../components/buttons";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import InfoBandaraComponent from "./info";
import AlamatBandaraComponent from "./address";
import GaleriBandaraComponent from "./galery";
import { addDataBandara } from "stores/actions/datamaster";

function BandaraComponentModal({ setOpen }) {
  const [current, setCurrent] = useState(0);
  const [city, setCity] = useState("Sleman");
  const [dataVideo, setDataVideo] = useState([]);
  const [multipleUpload, setMultipleUpload] = useState([]);
  const [addressDetails, setAddressDetail] = useState("");
  const formik = useFormik({
    initialValues: {
      name: "",
      image_logo: "",
      domestic_code: "",
      description: "",
      image_thumbnail: "",
      latitude: -7.7955798,
      longitude: 110.3694896,
      address_lat_long: "Sleman",
      city: null
    },
  });

  const dispatch = useDispatch();

  const items = [
    {
      title: "Info Dasar",
    },
    {
      title: "Alamat & Koordinat",
    },
    {
      title: "Galeri",
    },
  ];

  // submit form
  const handleSave = () => {
    dispatch(
      addDataBandara({
        ...formik.values,
        address_details: `${city} , ${addressDetails}`,
        url_video: dataVideo,
        images: multipleUpload,
      })
    );
    setOpen(false);
  };

  const getContent = () => {
    switch (current) {
      case 0:
        return (
          <InfoBandaraComponent
            valueName={formik.values.name}
            valueCode={formik.values.domestic_code}
            valueDescription={formik.values.description}
            descriptionOnChange={(value) => {
              formik.setFieldValue("description", value);
            }}
            formikOnChange={formik.handleChange}
            formikValue={formik.setFieldValue}
          />
        );
      case 1:
        return (
          <AlamatBandaraComponent
            formikValue={formik.setFieldValue}
            formikAddressDetails={formik.values.address_details}
            formikOnChange={formik.handleChange}
            setCity={setCity}
            setAddressDetail={setAddressDetail}
          />
        );
      case 2:
        return (
          <GaleriBandaraComponent
            formikOnChange={formik.handleChange}
            setDataVideo={setDataVideo}
            dataVideo={dataVideo}
            formikValue={formik.setFieldValue}
            setMultipleUpload={setMultipleUpload}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Card containerClass="!px-0 !pt-6 !pb-0 !shadow-none !rounded-2xl !border !border-gray-200">
        <div className="w-full flex justify-center  border-b pb-5 border-gray-300   ">
          <div className="w-[80%]">
            <Steppers
              items={items}
              current={current}
              labelPlacement="vertical"
            />
          </div>
        </div>

        <div className="overflow-y-auto px-4 my-2.5 h-[24.5rem]">
          {getContent()}
        </div>

        <div className="border-t py-4 border-gray-300 items-center flex justify-end px-4 flex-row gap-3">
          <Buttons
            text="Kembali"
            disabled={current === 0 ? true : false}
            customClass={`btn-outline btn-large ${
              current === 0 ? "disabled" : ""
            }`}
            onClick={() => setCurrent(current - 1)}
          />

          {current === 0 && (
            <Buttons
              text="Selanjutnya"
              disabled={
                formik.values.name !== "" &&
                formik.values.image_logo !== undefined &&
                formik.values.image_thumbnail !== undefined &&
                formik.values.domestic_code !== "" &&
                formik.values.description !== "<p><br></p>"
                  ? false
                  : true
              }
              customClass={`btn-primary btn-large ${
                formik.values.name !== "" &&
                formik.values.image_logo !== undefined &&
                formik.values.image_thumbnail !== undefined &&
                formik.values.domestic_code !== "" &&
                formik.values.description !== ""
                  ? ""
                  : "disabled"
              }`}
              onClick={() => setCurrent(current + 1)}
            />
          )}

          {current === 1 && (
            <Buttons
              text="Selanjutnya"
              customClass={`btn-primary btn-large ${
                formik.values.latitude !== "" &&
                formik.values.address_lat_long !== "" &&
                formik.values.longitude !== "" &&
                city !== ""
                  ? ""
                  : "disabled"
              }`}
              onClick={() => setCurrent(current + 1)}
            />
          )}

          {current === items.length - 1 && (
            <Buttons
              text="Simpan"
              disabled={
                dataVideo.length !== 0 && multipleUpload.length !== 0
                  ? false
                  : true
              }
              customClass={`btn-primary btn-large ${
                dataVideo.length !== 0 && multipleUpload.length !== 0
                  ? ""
                  : "disabled"
              }`}
              onClick={handleSave}
            />
          )}
        </div>
      </Card>
    </>
  );
}

export default BandaraComponentModal;
