import React, { useCallback, useState } from "react";

import Badge from "components/badge";
import Buttons from "components/buttons";

import TickCircle from "assets/icons/tick-circle.svg";

const listLocation = [
  "Yogyakarta",
  "Solo",
  "Jakarta",
  "Surabaya",
  "Balikpapan",
  "Banjarmasin",
  "Aceh",
  "Tarakan",
  "Jambi",
  "Makassar",
  "Gorontalo",
  "Pekanbaru",
  "Palembang",
  "Lubuklinggau",
  "Medan",
];

const LocationExportCSV = () => {
  const [selectedLocation, setSelectedLocation] = useState([]);

  const getContent = useCallback(() => {
    return listLocation.map((val, idx) => {
      return (
        <div
          key={idx}
          className={`border-[1px] border-[#4048521a] rounded-lg p-4 relative ${
            selectedLocation.includes(val) && "border-[#F90601]"
          }`}
          onClick={() => {
            if (selectedLocation.includes(val)) {
              setSelectedLocation((prevState) =>
                prevState.filter((el) => el !== val)
              );
            } else {
              setSelectedLocation((prevState) => [...prevState, val]);
            }
          }}
        >
          {selectedLocation.includes(val) && (
            <img
              className="absolute top-[-12px] right-[-12px]"
              src={TickCircle}
              alt="check"
            />
          )}
          <Badge label={val} />
        </div>
      );
    });
  }, [selectedLocation.length]);

  return (
    <>
      <div className="border-[1px] border-[#4048521a] rounded-lg">
        <div className="p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col gap-2">
              <div className="font-semibold text-[16px]">Lokasi Manasik</div>
              <div className="text-[#4F4F4F]">
                Pilih Cabang yang akan di-export sebagai csv
              </div>
            </div>
            <div
              className="txt-maroon font-semibold text-[16px] cursor-pointer"
              onClick={() => {
                if (listLocation.length === selectedLocation.length) {
                  setSelectedLocation([]);
                } else {
                  setSelectedLocation([]);
                  setSelectedLocation((prevState) => [
                    ...prevState,
                    ...listLocation,
                  ]);
                }
              }}
            >
              {listLocation.length === selectedLocation.length
                ? "Hapus Semua"
                : " Pilih semua"}
            </div>
          </div>
          <div className="grid gap-4 grid-cols-5 mt-6">{getContent()}</div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end p-4">
          <Buttons text="Export" customClass="btn-primary btn-medium" />
        </div>
      </div>
    </>
  );
};

export default React.memo(LocationExportCSV);
