import React from 'react';
// import { Steps } from 'antd';
import StepsComponent from './index';

const StepsDoc = () => {
	return (
		<StepsComponent
			currentSteps={0}
			itemContent={[
				{
					title: 'Info Dasar',
					content: 'halo',
				},
				{
					title: 'Alamat & Koordinasi',
					content: <div>yuhu</div>,
				},
				{
					title: 'Whatsapp Settings',
					content: <div>huuuaa</div>,
				},
			]}
			labelPosition='vertical'
			direction='horizontal'
		/>
	);
};

export default StepsDoc;
