import { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Radio, Space, Switch } from "antd";
import useClickOutside from "../../../utils/helpers/useClickOutside";

import PermissionIcon from "../../../assets/icons/colored/permission-icon.svg";
import { updatePermissionRoleAction } from "stores/actions/role";

const PermissionDropdownOnPopup = ({
  selectedRole,
  setDataPermisionSelect,
  dataListPermision,
}) => {
  const dispatch = useDispatch();
  const { dataRoleV2Utama, dataDetail } = useSelector((state) => state.role);

  const popover = useRef();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [roleDetail, setRoleDetail] = useState([]);

  const close = useCallback(() => setOpenDropdown(false), []);
  useClickOutside(popover, close);

  const handleCheckboxPermission = (parent, child) => {
    let data_detail = [...roleDetail];

    data_detail[parent]["data_permissions"][child].status =
      data_detail[parent]["data_permissions"][child].status === 0 ? 1 : 0;
    setRoleDetail(data_detail);

    setDataPermisionSelect(data_detail);
  };

  useEffect(() => {
    if (Object.keys(dataDetail).length > 0) {
      if (selectedRole?.length > 0) {
        const result_map = selectedRole?.map((val) => {
          return {
            ...val,
            data_permissions: val?.data_permissions?.map((item) => {
              if (item !== undefined) {
                return {
                  ...item,
                  status: dataDetail?.data?.permissions?.includes(item.name)
                    ? 1
                    : 0,
                };
              }
            }),
          };
        });

        setRoleDetail(result_map);
      }
    } else {
      if (selectedRole?.length > 0) {
        const result_map = selectedRole?.map((val) => {
          return {
            ...val,
            data_permissions: val?.data_permissions?.map((item) => {
              if (item !== undefined) {
                return {
                  ...item,
                  status: dataListPermision?.includes(item.name) ? 1 : 0,
                };
              }
            }),
          };
        });

        setRoleDetail(result_map);
      }
    }
  }, [selectedRole, dataDetail, dataListPermision]);

  useEffect(() => {
    if (roleDetail.length > 0) {
      setDataPermisionSelect(roleDetail);
    }
  }, [roleDetail]);

  return (
    <div>
      <div className="text-[#4f4f4f] font-semibold text-[14px] mb-2">
        Perizinan
      </div>
      <div className="relative">
        <div
          className="rounded-xl bg-[#f2f2f2] text-[14px] p-4 w-full cursor-pointer relative"
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          Pilih izin
          <img
            src={PermissionIcon}
            alt="icon"
            width={24}
            height={24}
            className="absolute right-4 top-4"
          />
        </div>
        {openDropdown && roleDetail && (
          <div
            className="absolute mt-[16px]  left-0 right-0 w-[544px] border-[0.5px] border-solid border-[#E0E0E0] box-border rounded-xl overflow-auto z-50  shadow-xl"
            ref={popover}
          >
            <div className="bg-gradient-to-r from-[#FAAB36] to-[#fdcf89] text-white text-center font-bold py-3 px-4 w-full flex flex-row justify-center items-center">
              Select Permission
            </div>
            <div className="bg-white p-4">
              <div className="grid grid-cols-2">
                {roleDetail.map((access, index) => {
                  return (
                    <div className="flex flex-col gap-2 " key={access}>
                      <h4 className="text-sm font-bold mt-4 mb-2 capitalize">
                        {access?.display_group_name}
                      </h4>
                      <div className="font-semibold  text-[12px] text-[#141414]">
                        Permission Access
                      </div>
                      {access.data_permissions?.map((acc, i) => {
                        if (acc !== undefined) {
                          return (
                            <div className="flex gap-[8px] text-[12px] text-[#141414]">
                              <Switch
                                style={{
                                  background:
                                    acc?.status === 1
                                      ? "linear-gradient(200.57deg, #F9F218 3.37%, #E2A246 86.36%)"
                                      : "#E0E0E0",
                                }}
                                checkedChildren="ON"
                                unCheckedChildren="OFF"
                                checked={acc?.status === 1 ? true : false}
                                onChange={(val) => {
                                  handleCheckboxPermission(index, i);
                                }}
                              />

                              {acc?.display_name}
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PermissionDropdownOnPopup;
