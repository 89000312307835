import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState  } from "react";
import { useParams } from "react-router-dom";

import MoslemeTable from "components/Table"
import Buttons from "components/buttons"
import RichTextEditor from "components/richTextEditor"
import { useFormik } from "formik";

import { detailById } from "stores/actions/general";

import PaketDetailGeneralInfoAction from "stores/actions/paket/generalInfo";

import { onHandleUpdateMessageInfoEvisaService } from "utils/api/paket/evisa";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";


const templateMessage = [
  {
    'code': '[nama_jamaah]',
    'description': 'Untuk memanggil nama jamaah'
  }
]

const columns = [
  {
    title: "Kode Pesan",
    dataIndex: "code",
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
  }
]

const PesanInfoEvisa = () => {
  const packageDetail = useSelector(({ generalInfo }) => generalInfo);
  const dispatch = useDispatch();
  const params = useParams();

  const [sending, setSending] = useState(false);
  
  const formik = useFormik({
    initialValues: {
      message: packageDetail.data.evisa_message_info
    }
  })
  
  const handleSave = async () => {
    setSending(true)
    const response = await onHandleUpdateMessageInfoEvisaService(params.id, formik.values)
    setSending(false)

    if (response.status === 200) {
      dispatch(handleSuccess(null, {message: "Berhasil update Evisa", code: 200}))
      dispatch(PaketDetailGeneralInfoAction.fetchData(params.id));
    } else {
      dispatch(handleError(response.data))
    }
  }

  return (
    <>
      <div className="border rounded-tl-2xl rounded-tr-2xl p-4">
        <MoslemeTable
          columns={columns}
          data={templateMessage}
          withPagination={false}
          customClass="rounded-2xl border overflow-hidden"
        />
        
        <div className="mt-4">
          <RichTextEditor 
            placeholder="Masukan Pesan Info Manasik"
            label="Pesan Info Manasik"
            data={{value: formik.values.message}}
            onChange={(value) => formik.setFieldValue('message', value)}
            />
        </div>

      </div>
      <div className="border rounded-bl-2xl rounded-br-2xl p-4 !border-t-0 flex justify-end">
        <Buttons
          text="Simpan"
          customClass="btn-primary btn-large"
          onClick={handleSave}
          loading={sending}
          />
      </div>
    </>
  )
}

export default PesanInfoEvisa