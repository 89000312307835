export const SET_LOADING = "apps/artikel/SET_LOADING";
export const CLEAR_ERROR = "apps/artikel/CLEAR_ERROR";
export const SET_ERROR = "apps/artikel/SET_ERROR";
export const START_FETCHING_ARTICLE = "apps/artikel/START_FETCHING_ARTICLE";
export const ERROR_FETCHING_ARTICLE = "apps/artikel/ERROR_FETCHING_ARTICLE";
export const SUCCESS_FETCHING_ARTICLE = "apps/artikel/SUCCESS_FETCHING_ARTICLE";
export const SET_PAGE_ARTICLE = "apps/artikel/SET_PAGE_ARTICLE";
export const SET_SIZE_ARTICLE = "apps/artikel/SET_SIZE_ARTICLE";
export const SET_TOTAL_ARTICLE = "apps/artikel/SET_TOTAL_ARTICLE";
export const SET_KEYWORD_ARTICLE = "apps/artikel/SET_KEYWORD_ARTICLE";
export const SET_ID_ROLE_ARTICLE = "apps/artikel/SET_ID_ROLE_ARTICLE";
export const SET_ADD_OR_EDIT_ARTICLE = "apps/artikel/SET_ADD_OR_EDIT_ARTICLE";
export const SET_DELETE_ARTICLE = "apps/artikel/SET_DELETE_ARTICLE";
