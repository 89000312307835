import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

import ButtonStyle from "./Button.style";
import Spinner from "../spinner";

const Buttons = ({
  customClass,
  containerClass,
  id,
  disabled,
  icon,
  iconPosition,
  loading,
  text,
  onClick,
  style,
  onMouseEnter,
  onMouseLeave,
  loadingIcon
}) => {
  return (
    <ButtonStyle className={`${containerClass}`}>
      <Button
        id={id}
        disabled={disabled}
        className={`flex items-center justify-center custom-btn ${loading && 'loading'} ${customClass}`}
        onClick={onClick}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="flex gap-2 items-center">
          {icon && iconPosition === "left" && (
            <>
              {!loading && <img src={icon} alt="left" />}
              {loading && loadingIcon && <Spinner />}
            </>
          )}
          {loading && !loadingIcon && <Spinner />}
          {text != "" && <span>{text}</span>}
          {icon && iconPosition === "right" && (
            <>
              {!loading && <img src={icon} alt="right" />}
              {loading && loadingIcon && <Spinner />}
            </>
          )}
        </div>
      </Button>
    </ButtonStyle>
  );
};

Buttons.propTypes = {
  customClass: PropTypes.string,
  containerClass: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  iconPosition: PropTypes.string,
  loading: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  loadingIcon: PropTypes.bool
};

Buttons.defaultProps = {
  customClass: "",
  containerClass: "",
  id: "",
  disabled: false,
  icon: "",
  iconPosition: "left",
  loading: false,
  text: "Submit",
  onClick: () => {},
  loadingIcon: false
};

export default React.memo(Buttons);
