import {
  ERROR_FETCHING_ARTICLE,
  SET_ADD_OR_EDIT_ARTICLE,
  SET_DELETE_ARTICLE,
  SET_ID_ROLE_ARTICLE,
  SET_KEYWORD_ARTICLE,
  SET_PAGE_ARTICLE,
  SET_SIZE_ARTICLE,
  SET_TOTAL_ARTICLE,
  START_FETCHING_ARTICLE,
  SUCCESS_FETCHING_ARTICLE,
} from "stores/actions/apps/artikel/actionTypes";
import {
  SET_ADD_CATEGORY_ARTICLE,
  SET_CATEGORY_ARTICLE,
  SET_DELETE_CATEGORY_ARTICLE,
} from "stores/actions/apps/artikel/category/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  total: 100,
  per_page: 10,
  page_number: 1,
  sort_column: "",
  sort_order: "Desc",
  keyword: "",
  id_role: "0",
  status: statuslist.idle,
  listCategory: [],
  pageCategory: 1,
  totalListCategory: 10,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_ARTICLE:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_ARTICLE:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_ARTICLE:
      return {
        ...state,
        data: action.data,
        status: statuslist.success,
      };
    case SET_KEYWORD_ARTICLE:
      return { ...state, keyword: action.keyword };

    case SET_PAGE_ARTICLE:
      return { ...state, page_number: action.page };

    case SET_SIZE_ARTICLE:
      return { ...state, per_page: action.size };

    case SET_TOTAL_ARTICLE:
      return { ...state, total: action.total };

    case SET_ID_ROLE_ARTICLE:
      return { ...state, id_role: action.id_role };

    case SET_CATEGORY_ARTICLE:
      return {
        ...state,
        listCategory: [...action.payload.data],
        pageCategory: action.payload.page,
        totalListCategory: action.payload.total_data,
      };
    case SET_ADD_CATEGORY_ARTICLE:
      if (action.payload && action.payload.data && !action.edit) {
        state["listCategory"].push(action.payload.data);
        state["totalListCategory"]++;
      } else {
        state["listCategory"].map((val, idx) => {
          if (
            val.id_kategori_artikel === action.payload.data.id_kategori_artikel
          ) {
            state["listCategory"][idx].nama_kategori =
              action.payload.data.nama_kategori;
          }
        });
      }
      return {
        ...state,
      };
    case SET_DELETE_CATEGORY_ARTICLE:
      state["listCategory"] = state["listCategory"].filter(
        (list) => list.id_kategori_artikel !== action.payload
      );
      return state;
    case SET_ADD_OR_EDIT_ARTICLE:
      if (action.payload && action.payload.data && !action.edit) {
        state["data"].push(action.payload.data);
      } else {
        state["data"].map((val, idx) => {
          if (val.id_artikel === action.payload.data.id_artikel) {
            state["data"][idx] = action.payload.data;
          }
        });
      }
      return {
        ...state,
      };
    case SET_DELETE_ARTICLE:
      state["data"] = state["data"].filter(
        (list) => list.id_artikel !== action.payload.data.id_artikel
      );
      return state;
    default:
      return state;
  }
}
