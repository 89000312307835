import Grid from "@mui/material/Grid";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as Icon } from "../../../../assets/icons/icon-link-maroon.svg";
import { motion, AnimatePresence } from "framer-motion";

function ListUrl({ data, setDataVideo, isVisible }) {
  const removeListArr = (x) => {
    let filter = data.filter((item) => {
      return item !== x;
    });
    setDataVideo(filter);
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
        {data.map((x, i) => (
          <Grid item sm={12} md={6}>
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 1, opacity: 0 }}
                transition={{ type: "spring" }}
                key={i}
                className="w-full flex items-center hover:bg-[#FFDCDC] m-1 justify-between border border-dashed border-gray-500 p-2 rounded-md"
              >
                <div className="flex items-center">
                  <Icon className="w-9 h-9" />
                  <span className="w-24 ml-2 truncate overflow-hidden break-words">
                    {x}
                  </span>
                </div>

                <span
                  className="cursor-pointer"
                  onClick={() => removeListArr(x)}
                >
                  <CloseIcon />
                </span>
              </motion.div>
            </AnimatePresence>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default ListUrl;
