import debounce from "debounce-promise";
import {
  CLEAR_ERROR,
  ERROR_FETCHING_PAKET_FEE,
  SET_KEYWORD_PAKET_FEE,
  SET_LOADING,
  SET_PAGE_PAKET_FEE,
  SET_PAKET_FEE_BESIC,
  SET_PAKET_FEE_DATA_AGENT,
  SET_PAKET_FEE_DATA_CABANG,
  SET_PAKET_FEE_LIST_AGENT,
  SET_PAKET_FEE_LIST_CABANG,
  SET_SIZE_PAKET_FEE,
  SET_SIZE_TOTAL_PAKET_FEE,
  START_FETCHING_PAKET_FEE,
  SUCCESS_FETCHING_PAKET_FEE,
} from "./actionTypes";
import {
  PaketFeeAPI,
  PaketFeeAddUpdatedDel,
  PaketFeeBesic,
  PaketFeeCustomeList,
  PaketFeeListCabangAndBranch,
} from "utils/api/paket/fee";
import { handleError, handleErrorBE } from "stores/actions/errorGeneral";
let debounceGetAdmin = debounce(PaketFeeAPI.getList, 1500);
let debounceGetFeeCabang = debounce(PaketFeeAPI.getListFeeCabang, 1500);

const startFetchingPaketFee = () => ({
  type: START_FETCHING_PAKET_FEE,
});

const errorFetchingPaketFee = () => ({
  type: ERROR_FETCHING_PAKET_FEE,
});

const successFetchingPaketFee = (payload) => ({
  type: SUCCESS_FETCHING_PAKET_FEE,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

const setPage = (page) => ({
  type: SET_PAGE_PAKET_FEE,
  page,
});

const setSize = (size) => ({
  type: SET_SIZE_PAKET_FEE,
  size,
});

const setTotal = (total) => ({
  type: SET_SIZE_TOTAL_PAKET_FEE,
  total,
});

const setKeyword = (keyword) => ({
  type: SET_KEYWORD_PAKET_FEE,
  keyword,
});
const setPaketBesicFee = (payload) => ({
  type: SET_PAKET_FEE_BESIC,
  payload,
});
const setPaketListAgen = (payload) => ({
  type: SET_PAKET_FEE_LIST_AGENT,
  payload,
});
const setPaketListCabang = (payload) => ({
  type: SET_PAKET_FEE_LIST_CABANG,
  payload,
});
const setPaketFeeAgent = (payload) => ({
  type: SET_PAKET_FEE_DATA_AGENT,
  payload,
});

const setPaketFeeCabang = (payload) => ({
  type: SET_PAKET_FEE_DATA_CABANG,
  payload,
});

const handleFetchDataPaketFee = (payload) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingPaketFee());
    try {
      let per_page = getState().paketFee.per_page;
      let page_number = getState().paketFee.page_number;
      let sort_column = getState().paketFee.sort_column;
      let sort_order = getState().paketFee.sort_order;
      let keyword = getState().paketFee.keyword;
      const container = {
        id_paket: payload.id_paket,
        agen: payload.agen,
        per_page,
        page_number,
        sort_column,
        sort_order,
        keyword,
      };

      let res = await debounceGetAdmin(container);
      if (res.data.code === 401) {
        window.location.href = "/login";
      }
      let data = res.data.data === "" ? [] : res.data.data;
      dispatch(successFetchingPaketFee(data));
      dispatch(setTotal(res.data.total_data));
      dispatch(setPage(res.data.page));
    } catch (error) {
      console.log("error: ", error.response);
      dispatch(errorFetchingPaketFee());
    }
  };
};

const handleFetchDataPaketFeeCabang = (payload) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingPaketFee());
    try {
      let per_page = getState().paketFee.per_page;
      let page_number = getState().paketFee.page_number;
      let sort_column = getState().paketFee.sort_column;
      let sort_order = getState().paketFee.sort_order;
      let keyword = getState().paketFee.keyword;
      const container = {
        paket_id: payload.paket_id,
        per_page,
        page_number,
        sort_column,
        sort_order,
        keyword,
      };

      let res = await debounceGetFeeCabang(container);
      if (res.data.code === 401) {
        window.location.href = "/login";
      }
      let data = res.data.data === "" ? [] : res.data.data;
      dispatch(successFetchingPaketFee(data));
      dispatch(setTotal(res.data.total_data));
      dispatch(setPage(res.data.page));
    } catch (error) {
      console.log("error: ", error.response);
      dispatch(errorFetchingPaketFee());
    }
  };
};
export const getPaketBesicFee = (payload) => {
  return async (dispatch) => {
    try {
      const res = await PaketFeeBesic.getBesic(payload);
      if (res.status === 200) {
        dispatch(setPaketBesicFee(res.data));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};
export const updatedPaketBesicFee = (
  id,
  payload,
  actionSuccess = (message) => {},
  actionError = (error) => {}
) => {
  return async (dispatch) => {
    try {
      const res = await PaketFeeBesic.updateBesic(id, payload);
      if (res.status === 200) {
        actionSuccess(res);
      } else {
        actionSuccess(res);
      }
    } catch (error) {
      dispatch(actionError(error));
    }
  };
};

export const getPaketListAgentFEE = (id, payload) => {
  return async (dispatch) => {
    try {
      const res = await PaketFeeListCabangAndBranch.getListAgent(id, payload);
      if (res.status === 200) {
        dispatch(setPaketListAgen(res.data));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};
export const getPaketListCabangFEE = (id, payload) => {
  return async (dispatch) => {
    try {
      const res = await PaketFeeListCabangAndBranch.getListCabang(id, payload);
      if (res.status === 200) {
        dispatch(setPaketListCabang(res.data));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const addPaketFeeAgent = (
  id,
  payload,
  actionSuccess = (message) => {},
  actionError = (error) => {}
) => {
  return async (dispatch) => {
    try {
      const res = await PaketFeeAddUpdatedDel.addCustomeAgent(id, payload);
      if (res.status === 200) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const addPaketFeeCabang = (
  id,
  payload,
  actionSuccess = (message) => {},
  actionError = (error) => {}
) => {
  return async (dispatch) => {
    try {
      const res = await PaketFeeAddUpdatedDel.addCustomeCabang(id, payload);
      if (res.status === 200) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};
export const updatedPaketFeeAgent = (
  id,
  payload,
  actionSuccess = (message) => {},
  actionError = (error) => {}
) => {
  return async (dispatch) => {
    try {
      const res = await PaketFeeAddUpdatedDel.updatedCustomeAgent(id, payload);
      if (res.status === 200) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const updatedPaketFeeCabang = (
  id,
  payload,
  actionSuccess = (message) => {},
  actionError = (error) => {}
) => {
  return async (dispatch) => {
    try {
      const res = await PaketFeeAddUpdatedDel.updatedCustomeCabang(id, payload);
      if (res.status === 200) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const getPaketListTabelAgent = (id, payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await PaketFeeCustomeList.getAgent(id, payload);
      dispatch(setLoading(false));
      if (res.status === 200) {
        dispatch(setPaketFeeAgent(res.data));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const getPaketListTabelCabang = (id, payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await PaketFeeCustomeList.getCabang(id, payload);
      dispatch(setLoading(false));
      if (res.status === 200) {
        dispatch(setPaketFeeCabang(res.data));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const PaketFeeAction = {
  fetchData: handleFetchDataPaketFee,
  fetchDataFeeCabang: handleFetchDataPaketFeeCabang,
  setPage: setPage,
  setSize: setSize,
  setKeyword: setKeyword,
};

export default PaketFeeAction;
