// akomodasi Actions
// --------------------------------------------------------

import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import {
  createPaketAkomodasiApi,
  deletePaketAkomodasiApi,
  paketAkomodasiApi,
} from "utils/api/paket/akomodasi";
import { setValidation, set_after_validation } from "stores/actions/general";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "akomodasi/SET_LOADING";
export const CLEAR_ERROR = "akomodasi/CLEAR_ERROR";
export const SET_ERROR = "akomodasi/SET_ERROR";
export const INIT_DATA = "akomodasi/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "akomodasi/SET_DOUBLE_SUBMIT";
export const SET_PAKET_AKOMODASI = "akomodasi/SET_PAKET_AKOMODASI";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});
export const setPaketAkomodasi = (payload) => ({
  type: SET_PAKET_AKOMODASI,
  payload,
});

export const getPaketAkomodasi = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await paketAkomodasiApi(payload);
    dispatch(setLoading(false));
    const { status, data } = response;

    if (status === 200) {
      dispatch(setPaketAkomodasi(data.data));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const createPaketAkomodasi =
  (payload, actionSuccess = (message) => {}, actionError = (error) => {}) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await createPaketAkomodasiApi(payload);
      dispatch(setLoading(false));
      const { status, data } = res;

      if (status === 200) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const deletePaketAkomodasi =
  (payload, actionSuccess = (message) => {}, actionError = (error) => {}) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await deletePaketAkomodasiApi(payload);
      dispatch(setLoading(false));
      const { status, data } = response;

      if (status === 200) {
        actionSuccess(data);
      } else {
        actionError(data);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
