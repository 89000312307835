import styled from "styled-components";

const ButtonsSocialStyle = styled.div`
  .custom-btn-social {
    padding: 16px 40px;
    min-height: 56px;
    border: none;
    border-radius: 8px;

    &.google {
      background: linear-gradient(218.94deg, #ffffff 20.37%, #f1f1f1 72.72%);
      box-shadow: 0px 2px 4px rgba(64, 72, 82, 0.1);
    }

    &.apple {
      background: linear-gradient(
        34.99deg,
        #151515 17.46%,
        #3d3d3d 58.62%,
        #595959 87.32%
      );
    }

    &.facebook {
      background: linear-gradient(204.44deg, #4590f4 -18.49%, #1361c9 84.72%);
    }
  }
`;

export default ButtonsSocialStyle;
