import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const privacyApi = {
  list: (params) =>
    axios.get(`${BASE_URL_ENDPOINT}/admin/settings`, { params }),
  post: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-setting`, payload),
};

export { privacyApi };
