import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

export const getStartingApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-starting`, payload);

export const getIdStartingApi = (id) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/starting/${id}`);

export const deleteIdStartingApi = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/starting/${id}`);

export const createOrUpdateStartingApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-starting`, payload);

export const duplicateStartingApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/starting/duplicate`, payload);
