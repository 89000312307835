import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import { getMasterId } from "stores/actions/datamaster";

export const columns = (dispatch, permissions) => [
  {
    title: "Musim",
    dataIndex: "thn_hijriyah",
    render: (thn_hijriyah, data) => (
      <div className="text-sm">{`${data?.thn_masehi} M/${thn_hijriyah} H`}</div>
    ),
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    className: "whitespace-nowrap relative",
    render: (s, data) => (
      <div className="w-full relative flex flex-row justify-between">
        {s !== null && s?.length > 150 ? `${s?.substr(0, 150)}...` : s}
        <div className="action">
          <div className="action-content flex">
            {permissions?.includes("delete_master-data") && (
              <div className="action-content-item">
                <Trash
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(getMasterId({ modal: true, data: data }))
                  }
                  size="16"
                  color="#141414"
                />
              </div>
            )}

            {permissions?.includes("update_master-data") && (
              <div className="action-content-item">
                <Edit
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(getMasterId({ modalEdit: true, data: data }))
                  }
                  size="16"
                  color="#141414"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ),
  },
];
