import React from "react";
import Progressbar from "../../../components/progressbars";
import Buttons from "../../../components/buttons";
import { IcArrowRsM } from "../../../assets/icons/dashboard";

const CardProgress = ({
  icon,
  title,
  onPress,
  onPressEdit,
  percent,
  kode,
  info,
  className,
  totalData
}) => {
  return (
    <div className={`flex-col p-4 pb-0 bg-white shadow-soft rounded-2xl ${className}`}>
      <section className="flex justify-between items-center">
        <div className="flex gap-x-2 items-center">
          <img alt="icon" src={icon} />
          <div>
            <div className="font-semibold">{title}</div>
            {kode && <div className="text-sm">{kode}</div>}
          </div>
        </div>
        {onPressEdit ? (
          <Buttons
            text="Edit"
            customClass="btn-outline w-full btn-medium !py-1 !px-5"
            onClick={onPressEdit}
          />
        ) : (
          <>
            {!!totalData && (
              <>
                <div className="">
                  <img
                    alt="left arrow"
                    src={IcArrowRsM}
                    className="object-cover cursor-pointer"
                    onClick={onPress}
                  />
                </div>
              </>
            )}
          </>
        )}
      </section>
      <Progressbar 
        containerClass="!mt-6"
        customInfoClass="!top-[-20px]"
        percent={percent} info={info} />
    </div>
  );
};

export default CardProgress;
