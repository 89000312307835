import { handleError, handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import {
  CLEAR_ERROR,
  ERROR_FETCHING_SLIDE,
  SET_ADD_OR_EDIT_SLIDE,
  SET_DELETE_SLIDE,
  SET_LOADING,
  START_FETCHING_SLIDE,
  SUCCESS_FETCHING_SLIDE,
} from "./actionTypes";
import { AppsSlideAPI } from "utils/api/apps/slide";

const startFetchingSlide = () => ({
  type: START_FETCHING_SLIDE,
});

const errorFetchingSlide = () => ({
  type: ERROR_FETCHING_SLIDE,
});

const successFetchingSlide = (payload) => ({
  type: SUCCESS_FETCHING_SLIDE,
  payload,
});

export const setLoading = () => ({
  type: SET_LOADING,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const setAddOrEditSlide = (payload, edit) => ({
  type: SET_ADD_OR_EDIT_SLIDE,
  payload,
  edit,
});

export const setDeleteSlide = (payload, id) => ({
  type: SET_DELETE_SLIDE,
  payload,
  id,
});

const handleFetchListSlide = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      dispatch(startFetchingSlide());
      const response = await AppsSlideAPI.getList(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(successFetchingSlide(response.data));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(errorFetchingSlide());
      dispatch(handleError(error));
    }
  };
};

const handleAddOrEditSlide = (payload) => {
  return async (dispatch, getState) => {
    let edit = payload.edit ? true : false;
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await AppsSlideAPI.postData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        if(edit) {
          // console.log("HIII", response.data.data.original.data)
          dispatch(setAddOrEditSlide(response.data, edit));
          dispatch(handleSuccess(null, {"message": "Slide Berhasil diupdate", "code": 200}));
        } else {
          dispatch(handleSuccess(null, {"message": "Slide Berhasil dutambahkan", "code": 200}));
        }
        return response.status;
      } else if (response.status === 401) {
        window.location.href = "/login";
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const handleDeleteSlide = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await AppsSlideAPI.deleteData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setDeleteSlide(response.data, payload));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const AppsSlideAction = {
  fetchData: handleFetchListSlide,
  postData: handleAddOrEditSlide,
  deleteData: handleDeleteSlide,
};

export default AppsSlideAction;
