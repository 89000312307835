import { Grid } from "@material-ui/core";
import AvatarIkhwan from "assets/images/Avatar-ikhwan-1.svg";
import Badge from "components/badge";
import Spinner from "components/spinner";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleError } from "stores/actions/errorGeneral";
import ILCoomingSoon from "assets/images/cooming-soon.svg";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";

import { getRatingQuestionSurveys } from "utils/api/paket/ibadahku";
import Avatar from "components/avatar";
import { toCapital } from "utils/helpers";

const PopupRatingQuestion = ({
  questionID,
  rating
}) => {
  const dispatch = useDispatch()
  const [reviews, setReviews] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleGetReviewsQuestion = async () => {
    setIsLoading(true)
    const res = await getRatingQuestionSurveys(questionID, rating)
    setIsLoading(false)
    
    if (res.status === 200) {
      setReviews(res.data.data)
    } else {
      dispatch(handleError(res.data))
    }
  }

  useEffect(() => {
    handleGetReviewsQuestion()
  }, [])

  return (
    <>
      <div>
        {isLoading ? (
          <>
            <div className="flex justify-center items-center h-[300px]">
              <Spinner />
            </div>
          </>
        ) : (
          <>
            {reviews.length > 0 ? (
              <>
                <Grid container spacing={2}>
                  {reviews.map((review, index) => (
                    <>
                      <Grid item md={6}>
                        <div className="flex flex-row p-[16px] gap-[16px] !w-[308px] !h-[84px] shadow-soft rounded-lg">
                          <div>
                            <Avatar value={review} />
                          </div>
                          <div className="flex flex-col items-start gap-[8px]">
                            <h2 className="text-xs">{`${toCapital(review.title)}. ${review.name}`}</h2>
                            <Badge
                              label={review.branch_name}
                              color={review.branch_text_color}
                              backgroundColor={review.branch_bg_color}
                            />
                          </div>
                        </div>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <div className="flex flex-col h-[300px] justify-center items-center bg-white">
                  <img
                    src={IconJamPasir}
                    alt="Cooming Soon"
                    className="mb-2 w-[152px] h-[160px]"
                  />
                  <p className="text-base text-black">
                    Belum ada data review
                  </p>  
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PopupRatingQuestion;
