
import { Modal } from "@material-ui/core";
import React, { useEffect, useState, createRef } from "react";
import PropTypes from "prop-types";

import {
    CloseOutlined,
    DeleteOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
  } from "@ant-design/icons";

const PreviewImage = ({
    open,
    imageUrl,
    onHandleClose
}) => {
    let imgRef = createRef();
    const [count, setCount] = useState(1);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(open);
    }, [open])

    return (
        <>
            <Modal open={isOpen}>
                <main className="flex justify-center h-screen items-center ln-random">
                <section className="drop-shadow z-50 absolute top-0 w-full items-center flex justify-between  py-4 px-10">
                    <div></div>
                    <div className="flex gap-x-8 items-center noselect">
                    <div
                        className={`btn-modal `}
                        onClick={() =>
                        count >= 4 ? setCount(4) : setCount(count + 0.5)
                        }
                    >
                        <ZoomInOutlined
                        className={`${count === 4 ? "text-gray-400" : "text-white"}`}
                        />
                    </div>
                    <div
                        className={`btn-modal `}
                        onClick={() =>
                        count <= 1 ? setCount(0.5) : setCount(count - 0.5)
                        }
                    >
                        <ZoomOutOutlined
                        className={`${
                            count === 0.5 ? "text-gray-400" : "text-white"
                        }`}
                        />
                    </div>
                    <div
                        className="bg-[#8D8D8D] w-7 h-7 flex justify-center items-center rounded-full cursor-pointer"
                        onClick={() => {
                            onHandleClose()
                        }}
                    >
                        <CloseOutlined className="text-white" />
                    </div>
                    </div>
                </section>
                <img
                    alt="gambar"
                    ref={imgRef}
                    style={{
                    transition: 2,
                    transitionDelay: 3,
                    transform: `scale(${count})`,
                    }}
                    src={imageUrl}
                    className="max-w-auto max-h-auto"
                />
                </main>
            </Modal>
        </>
    )
}

PreviewImage.propTypes = {
    open: PropTypes.bool.isRequired,
    imageUrl: PropTypes.string.isRequired,
};
  
PreviewImage.defaultProps = {
    open: false,
    imageUrl: "",
};

export default React.memo(PreviewImage)