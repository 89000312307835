import {
  START_FETCHING_MEMBER_ADMIN,
  SUCCESS_FETCHING_MEMBER_ADMIN,
  ERROR_FETCHING_MEMBER_ADMIN,
  SET_SIZE_ADMIN,
  SET_PAGE_ADMIN,
  SET_KEYWORD_ADMIN,
  SET_TOTAL_ADMIN,
  SET_ID_ROLE,
  SET_ID_ADMIN,
  SET_SAVE_ADMIN_LOADING
} from "../../../actions/member/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  total: 100,
  per_page: 10,
  page_number: 1,
  sort_column: "",
  sort_order: "Desc",
  keyword: "",
  id_role: "0",
  status: statuslist.idle,
  id_admin: "0",
  isSaveLoading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_MEMBER_ADMIN:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_MEMBER_ADMIN:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_MEMBER_ADMIN:
      return {
        ...state,
        data: action.data,
        status: statuslist.success,
      };
    case SET_KEYWORD_ADMIN:
      return { ...state, keyword: action.keyword };

    case SET_PAGE_ADMIN:
      return { ...state, page_number: action.page };

    case SET_SIZE_ADMIN:
      return { ...state, per_page: action.size };

    case SET_TOTAL_ADMIN:
      return { ...state, total: action.total };

    case SET_ID_ROLE:
      return { ...state, id_role: action.id_role };
    case SET_ID_ADMIN:
      return {
        ...state,
        id_admin: action.id_admin,
      };
    
    case SET_SAVE_ADMIN_LOADING:
      return {
        ...state,
        isSaveLoading: action.payload,
      };

    default:
      return state;
  }
}
