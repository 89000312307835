import React, { useEffect, useState } from "react";

import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import Buttons from "components/buttons";
import InputPhone from "components/form/inputPhone";
import { useParams } from "react-router-dom";

import Dropdowns, { closeDropdown } from "components/dropdowns";

import { useDispatch } from "react-redux";

import IconSearch from "assets/icons/search-normal.svg";

import { JamaahMemberAPI } from "utils/api/member";

import Quad from 'assets/icons/Quad.svg'
import Triple from 'assets/icons/Triple.svg'
import Double from 'assets/icons/Double.svg'
import Single from 'assets/icons/Single.svg'

import Scroll from "components/scroll";
import Badge from "components/badge";

import { handleStoreDataJamaahAPI } from "utils/api/booking"
import { handleGetDetailOrder, handleGetDataJamaah } from "stores/actions/booking";

import { getRoomPrices } from "utils/api/paket";

import './style.css'
import { IDRFormater, toCapital } from "utils/helpers";
import { handleError, handleSuccess, setLoading } from "stores/actions/errorGeneral";
import starting from "stores/reducers/datamaster/starting";
import Radio from "components/form/radio";
import Switch from "components/form/switch";
import { useFormik } from "formik";

import * as Yup from 'yup';
import Avatar from "components/avatar";
import useDebounce from "utils/helpers/useDebounce";

const AddJamaah = ({ dataJamaah, packageID, handleAddSuccess }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [startingRoomPrices, setStartingRoomPrices] = useState([])
  const [startingSelected, setStartingSelected] = useState({})
  const [tempStartingRoomPrices, setTempStartingRoomPrices] = useState([])

  const [startingLabel, setStartingLabel] = useState('Pilih Starting')
  const [roomPriceLabel, setRoomPriceLabel] = useState('Pilih Harga Kamar')
  const [keywordStarting, setKeywordStarting] = useState("")
  const searchStartingDebounce = useDebounce(keywordStarting, 1000)

  const [keywordJamaahOld, setKeywordJamaahOld] = useState("")
  const [jamaahOldList, setJamaahOldList] = useState([])
  const [jamaahOldNextCursor, setJamaahOldNextCursor] = useState(null)
  const [selectedJamaahOld, setSelectedJamaahOld] = useState({})
  const [jamaahOldSelectorLabel, setJamaahOldSelectorLabel] = useState("Pilih Jamaah")
  const [isLoadingMore, setIsloadingMore] = useState(false)
  const searchDebounce = useDebounce(keywordJamaahOld, 1000)

  const [isLoading, setIsLoading] = useState(false)

  const [isBusinessClass, setIsBusinessClass] = useState(false)
  const [isOldJamaah, setIsOldJamaah] = useState(false)

  const [isValid, setIsValid] = useState(false)

  const [oldJamaahLabel, setOldJamaahLabel] = useState("Pilih Jamaah")

  const [allowAddJamaah, setAllowAddJamaah] = useState(true)

  const titleOptions = [
    {
      label: "Tuan",
      value: 'tn'
    },
    {
      label: "Nyonya",
      value: 'ny'
    },
    {
      label: "Nona",
      value: 'nn'
    }
  ]  

  const handleFillOptions = (value) => {
    const title = titleOptions.find((item) => item.value === value?.toLowerCase())

    return [title]
  }

  useEffect(() => {
    if(packageID){
      handleRoomPrices()
    }
  }, [packageID])

  useEffect(() => {
    if(dataJamaah) {
      jamaahFormik.setFieldValue(`jamaah_name`, dataJamaah?.jamaah_name)
      jamaahFormik.setFieldValue(`jamaah_identity_number`, dataJamaah?.jamaah_identity_number)
      jamaahFormik.setFieldValue(`jamaah_title`, dataJamaah?.jamaah_title)
      jamaahFormik.setFieldValue(`jamaah_phone`, dataJamaah?.jamaah_phone)
      jamaahFormik.setFieldValue(`jamaah_email`, dataJamaah?.jamaah_email)
      
      dataJamaah?.user_jamaah_id ? setIsOldJamaah(true) : setIsOldJamaah(false)

      setTimeout(() => {
        jamaahFormik.setFieldTouched(`jamaah_name`, 1)
        jamaahFormik.setFieldTouched(`jamaah_identity_number`, 1)
        jamaahFormik.setFieldTouched(`jamaah_title`, 1)
        jamaahFormik.setFieldTouched(`jamaah_phone`, 1)
        jamaahFormik.setFieldTouched(`jamaah_email`, 1)
      })

      dataJamaah?.paket_kamar.kamar_name?.includes("_bussiness") &&
      setIsBusinessClass(true)
      
    }

    handleGetOldJamaah(true)

    
  }, [])

  useEffect(() => {

    if(Object.keys(selectedJamaahOld).length > 0) {
      setJamaahOldSelectorLabel(`${selectedJamaahOld.title ? `${selectedJamaahOld.title.toUpperCase()}.` : ''} ${selectedJamaahOld.name.toUpperCase()}`)
    } else {
      setJamaahOldSelectorLabel("Pilih Jamaah")
    }

  }, [selectedJamaahOld])

  useEffect(() => {
    handleGetOldJamaah(true)
  }, [searchDebounce])

  useEffect(() => {
    handleSearchStarting()
  }, [searchStartingDebounce])

  const handleRoomPrices = async () => {
    const response = await getRoomPrices(packageID)
    const { status, data } = response
    
    if(status == 200) {
      setStartingRoomPrices(data.data)

      setTempStartingRoomPrices(data.data)

      if(dataJamaah?.starting.name) {
        
        const newStartingSelected = data.data.find((item) => item.starting.name === dataJamaah?.starting.name)
        const roomName = dataJamaah?.paket_kamar.kamar_name?.replace('_bussiness', '')

        let roomDescription;

        if(roomName === 'quad') {
          roomDescription = '4 pax/room'
        }

        if(roomName === 'tripple') {
          roomDescription = '3 pax/room'
        }

        if(roomName === 'double') {
          roomDescription = '2 pax/room'
        }

        if(roomName === 'single') {
          roomDescription = '1 pax/room'
        }

        jamaahFormik.setFieldValue(`paket_kamar.id`, newStartingSelected.id_kamar)
        jamaahFormik.setFieldValue(`paket_kamar.kamar_name`, roomName)
        jamaahFormik.setFieldValue(`paket_kamar.kamar_description`, roomDescription)
        jamaahFormik.setFieldValue(`paket_kamar.price`, dataJamaah?.paket_kamar.price)

        setTimeout(() => {
          jamaahFormik.setFieldTouched(`paket_kamar.id`, 1)
          jamaahFormik.setFieldTouched(`paket_kamar.kamar_name`, 1)
          jamaahFormik.setFieldTouched(`paket_kamar.kamar_description`, 1)
          jamaahFormik.setFieldTouched(`paket_kamar.price`, 1)
        })

        setStartingLabel(newStartingSelected.starting.name)
        setStartingSelected(newStartingSelected)

        jamaahFormik.setFieldValue(`starting`, {
          name: newStartingSelected.starting.name,
          bg_color: newStartingSelected.starting.bg_color,
          text_color: newStartingSelected.starting.text_color,
          departure: newStartingSelected.starting.departure,
          arrival: newStartingSelected.starting.return
        })

        setTimeout(() => {
          jamaahFormik.setFieldTouched(`starting`, 1)
        })

        const label = `${toCapital(roomName)} - ${IDRFormater(dataJamaah?.paket_kamar.price)}`
        setRoomPriceLabel(label)
      }

    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleSetStarting = (starting) => {
    setStartingSelected(starting)
       
    setStartingLabel(starting.starting.name)
    closeDropdown();
  }

  const handleSetRoomPrice = (roomID, roomName, roomDescription, price) => {
    jamaahFormik.setFieldValue(`paket_kamar.id`, roomID)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_name`, `${roomName}${isBusinessClass ? '_bussiness' : ""}`)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_description`, roomDescription)
    jamaahFormik.setFieldValue(`paket_kamar.price`, price)

    setTimeout(() => {
      jamaahFormik.setFieldTouched(`paket_kamar.id`, 1)
      jamaahFormik.setFieldTouched(`paket_kamar.kamar_name`, 1)
      jamaahFormik.setFieldTouched(`paket_kamar.kamar_description`, 1)
      jamaahFormik.setFieldTouched(`paket_kamar.price`, 1)
    })

    jamaahFormik.setFieldValue(`starting`, {
      name: startingSelected.starting.name,
      bg_color: startingSelected.starting.bg_color,
      text_color: startingSelected.starting.text_color,
      departure: startingSelected.starting.departure,
      arrival: startingSelected.starting.return
    })

    const label = `${toCapital(roomName)} - ${IDRFormater(price)}`
    setRoomPriceLabel(label)
  }

  const handleChangeBusinnessClass = () => {
    setIsBusinessClass(!isBusinessClass);

    jamaahFormik.setFieldValue(`paket_kamar.id`, null)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_name`, null)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_description`, null)

    setRoomPriceLabel('Pilih Harga Kamar')
  }

  const handleSaveJamaah = async () => {

    setIsLoading(true)
    const response = await handleStoreDataJamaahAPI(params.orderNumber, jamaahFormik.values)
    setIsLoading(false)
    const { status, data } = response

    if(status === 200) {
      dispatch(handleSuccess(response))
      dispatch(handleGetDetailOrder(params.orderNumber))
      dispatch(handleGetDataJamaah(params.orderNumber))
      handleAddSuccess()

      setTimeout(() => {
        jamaahFormik.resetForm()
      }, 500)
    } else {
      dispatch(handleError(data))
    }
    
  }

  const handleSelectJamaahOld = (jamaah) => {
    setSelectedJamaahOld(jamaah)

    jamaahFormik.setFieldValue(`jamaah_title`, jamaah.title?.toLowerCase() ?? 'tn')
    jamaahFormik.setFieldValue(`jamaah_name`, jamaah.name)
    jamaahFormik.setFieldValue(`jamaah_identity_number`, jamaah.data_documents.ktp_nik)
    jamaahFormik.setFieldValue(`jamaah_phone`, jamaah.phone)
    jamaahFormik.setFieldValue(`jamaah_email`, jamaah.email) 
    jamaahFormik.setFieldValue(`user_jamaah_id`, jamaah.id)

    setTimeout(() => {
      jamaahFormik.setFieldTouched(`jamaah_title`, 1)
      jamaahFormik.setFieldTouched(`jamaah_name`, 1)
      jamaahFormik.setFieldTouched(`jamaah_identity_number`, 1)
      jamaahFormik.setFieldTouched(`jamaah_phone`, 1)
      jamaahFormik.setFieldTouched(`jamaah_email`, 1)
      jamaahFormik.setFieldTouched(`user_jamaah_id`, 1)
    })

    closeDropdown()
  }

  const jamaahFormik = useFormik({
    initialValues: {
      "is_available": false,
      "jamaah_title": "tn",
      "jamaah_name": null,
      "jamaah_passport_number": null,
      "jamaah_identity_number": null,
      "jamaah_phone": null,
      "jamaah_email": null,
      "user_jamaah_id": null,
      "paket_kamar": {
          "id": null,
          "kamar_name": null,
          "kamar_description": null,
          "price": null
      },
      "starting": {
        "name": null,
        "bg_color": null,
        "text_color": null,
        "departure": null,
        "return": null
      }
    },
    validationSchema: Yup.object({
      "jamaah_title": Yup.string().required("Harus pilih salah satu title"),
      "jamaah_name": Yup.string().required("Nama Jamaah harus diisi"),
      "jamaah_identity_number": Yup.string().required("NIK harus diisi")
                                            .matches(/^[0-9]+$/, "NIK Harus berupa angka")
                                            .min(16, "NIK harus 16 digit")
                                            .max(16, "NIK harus 16 digit"),
      "paket_kamar": Yup.object({
          "id": Yup.number().required("Harus pilih starting & Tipekamar"),
          "kamar_name": Yup.string().required("Harus pilih starting & Tipekamar"),
      })
    }),
  })

  const handleGetOldJamaah = async (first = true) => {
    let params = {
      'paginate': 'cursor',
      'per_page': 10,
      'mode'  : 'options-with-document',
      'keyword': keywordJamaahOld
    }

    if(!first) {
      params['cursor'] = jamaahOldNextCursor
    }

    const response = await JamaahMemberAPI.getJamaahList(params)
    const { status, data }  = response

    if(status == 200) {
      if(first) {
        setJamaahOldList(data.data)
      } else {
        let newJamaahOldList = jamaahOldList

        newJamaahOldList.push.apply(newJamaahOldList, data.data)
        setJamaahOldList(newJamaahOldList)
      }

      if(dataJamaah?.user_jamaah_id) {
        const newJamaahOldList = data.data.find((item) => item.id === dataJamaah?.user_jamaah_id)
        setTimeout(() => {
          setSelectedJamaahOld(newJamaahOldList)
          jamaahFormik.setFieldValue(`user_jamaah_id`, newJamaahOldList.id)
        })
      }

      setJamaahOldNextCursor(data.next_cursor)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleLoadMoreJamaahOld = async () => {
    setIsloadingMore(true)
    await handleGetOldJamaah(false)
    setIsloadingMore(false)
  }

  const handleSearchStarting = () => {
    const keyword = keywordStarting?.toLowerCase()
    
    const filterData = tempStartingRoomPrices.filter(item =>
      item.starting.name?.toLowerCase().includes(keyword) || 
      item.starting.return?.toLowerCase().includes(keyword) || 
      item.starting.departure?.toLowerCase().includes(keyword)
    )

    setStartingRoomPrices(filterData)
  }

  return (
      <>
        <div className="bg-white p-4 rounded-xl">
          
          <div className="mb-4">
            
            <div className="flex flex-row items-center mb-4">
              <Switch
                checked={isOldJamaah}
                onChange={() => {
                  setIsOldJamaah(!isOldJamaah);

                  if(!isOldJamaah) {
                    jamaahFormik.setFieldValue(`user_jamaah_id`, null)
                  }

                }}
              />
              <div className="text-sm font-semibold text-[#000] ml-2 w-full">
                Jamaah lama
              </div>
            </div>
            
            {isOldJamaah && (
              <>
                <Dropdowns
                  customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
                  placement="bottom"
                  labelDropdown={jamaahOldSelectorLabel}
                  dropdownArrow={true}
                  disabled={!packageID}
                >
                  <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
                    <div>
                      <Inputs
                        id="searchAdmin"
                        placeholder="Pencarian.."
                        customClass="!px-3 !py-3.5 !pl-[42px] text-sm my-2"
                        iconPosition="left"
                        value={keywordJamaahOld}
                        onChange={(e) =>
                          setKeywordJamaahOld(e.target.value)
                        }
                        icon={IconSearch}
                      />
                    </div>
                    <div>
                    <Scroll
                      height="350px"
                      customClass="pr-2"
                      >

                        {jamaahOldList && jamaahOldList.length !== 0 ? (
                            jamaahOldList.map((val, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                                  onClick={() => {
                                    handleSelectJamaahOld(val);
                                    closeDropdown();
                                  }}
                                >
                                    <Avatar
                                      value={val}
                                      containerClass={`items-center justify-center w-[56px] h-[56px] rounded-[100%]  bg-slate-100`}
                                      customClass={`object-cover w-[56px] h-[56px] rounded-[100%]`}
                                    />
                                  <div className="ml-4">
                                    <div className="font-semibold text-[20px]">
                                      {val.title ? toCapital(val.title) + `.` : "" } {toCapital(val.name)}
                                    </div>
                                    <div className="flex gap-2 mt-2">
                                      <Badge 
                                        backgroundColor={val.color_bg_role} 
                                        color={val.color_text_role} 
                                        label={val.role_name} />
                                      {/* <Badge backgroundColor={val.color_bg_cabang} color={val.color_text_cabang} label={val.nama_cabang} /> */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="mt-4">Jamaah tidak ditemukan.</div>
                          )}
                          {jamaahOldNextCursor && (
                            <>
                              <div className="flex justify-center items-center w-full m-4">
                                <Buttons
                                  onClick={() => handleLoadMoreJamaahOld()}
                                  loading={isLoadingMore}
                                  text="Lihat selengkapnya"
                                  customClass="btn-outline btn-secondary btn-sm"
                                  />
                              </div>
                            </>
                          )}

                    </Scroll>
                    </div>
                  </div>
                </Dropdowns>
              </>
            )}

          </div>

          <div className="flex gap-[10px] mb-4">
            <div className="w-[25%]">
              <Selects 
                label="Title"
                placeholder="Title"
                options={titleOptions}
                onChange={(e) => {
                  jamaahFormik.setFieldValue(`jamaah_title`, e[0].value)
                }}
                onBlur={jamaahFormik.handleBlur}
                values={handleFillOptions(jamaahFormik?.values?.jamaah_title)}
                error={jamaahFormik?.touched?.jamaah_title && jamaahFormik?.errors?.jamaah_title}
                />
            </div>
            <div className="w-[75%]">
              <Inputs 
                id="jamaah_name"
                label="Nama"
                placeholder="Masukan nama"
                name={`jamaah_name`}
                onChange={(e) => {
                  jamaahFormik.setFieldValue(`jamaah_name`, e.target.value.toUpperCase())
                }}
                onBlur={jamaahFormik.handleBlur}
                value={jamaahFormik?.values?.jamaah_name?.toUpperCase()}
                error={jamaahFormik?.touched?.jamaah_name && jamaahFormik?.errors?.jamaah_name}
                />
            </div>
          </div>
          <div className="flex gap-[10px] mb-4">
            <div className="w-[25%]">
              <Inputs 
                value={"KTP"}
                label="Identitas"
                disabled
                />
            </div>
            <div className="w-[75%]">
              <Inputs 
                label="No. KTP"
                placeholder="Masukan No. KTP"
                name={`jamaah_identity_number`}
                maxLength={16}
                onChange={jamaahFormik.handleChange}
                onBlur={jamaahFormik.handleBlur}
                value={jamaahFormik?.values.jamaah_identity_number}
                error={jamaahFormik?.touched?.jamaah_identity_number && jamaahFormik?.errors?.jamaah_identity_number}
                />
            </div>
          </div>
          <div className="mb-4 mt-2"> 
          <div className="flex flex-row items-center">
            <Switch
              checked={isBusinessClass}
              onChange={(val) => {
                handleChangeBusinnessClass()
                // handleWACabang(isChecked);
              }}
            />
            <div className="text-sm font-semibold text-[#000] ml-2 w-full">
              Business Class
            </div>
          </div>
          </div>
          <div>
            <div className="font-semibold text-sm mb-2">Starting</div>
            <Dropdowns
              customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
              placement="bottom"
              labelDropdown={startingLabel}
              dropdownArrow={true}
              disabled={!packageID}
            >
              <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
                <div>
                  <Inputs 
                    name="searchStarting"
                    placeholder="Cari Tipe Kamar"
                    customClass="!px-3 !py-3.5 !pl-[42px] text-sm"
                    iconPosition="left"
                    icon={IconSearch}
                    onChange={(e) => setKeywordStarting(e.target.value)}
                    />
                </div>
                <Scroll height="350px" customClass="pr-2">
                  {startingRoomPrices && startingRoomPrices.map((room, idx) => {
                    return (
                      <>
                        <div 
                          onClick={() => handleSetStarting(room)}
                          className="p-4 cursor-pointer active:bg-[#FF9C9A] hover:bg-red-5 hover:border-red-5 rounded-2xl flex justify-between items-start border border-gray-4 mt-[18px]">
                          <div className="flex gap-11.5 justify-between w-[40%]">
                            <div>
                              <div className="text-xs text-think mb-1">
                                Berangkat
                              </div>
                              <div className="font-semibold">
                                {room?.starting?.departure}
                              </div>
                            </div>
                            <div>
                              <div className="text-xs text-think mb-1">
                                Pulang
                              </div>
                              <div className="font-semibold">
                                {room?.starting?.return}
                              </div>
                            </div>
                          </div>
                          <Badge 
                            label={room?.starting?.name}
                            backgroundColor={room?.starting?.bg_color}
                            color={room?.starting?.text_color}
                            />
                        </div>
                      </>
                    )
                  })}
                </Scroll>
              </div>
            </Dropdowns>
          </div>
          <div className="mt-4">
            <div className="font-semibold text-sm mb-2 mt-4">Tipe Kamar</div>
            <Dropdowns
              customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
              placement="bottom"
              labelDropdown={roomPriceLabel}
              dropdownArrow={true}
              disabled={Object.keys(startingSelected).length === 0}
              error={jamaahFormik?.touched?.paket_kamar?.id && jamaahFormik?.errors?.paket_kamar?.id}
            >
              <div className="bg-white w-full soft-shadow rounded-2xl overflow-hidden">
                <Scroll height="350px">
                  
                  {!!startingSelected.is_active_price_quad && (
                    <div 
                      onClick={() => {
                        handleSetRoomPrice(
                            startingSelected.id_kamar, 
                            `quad`, 
                            `4 pax/room`,
                            isBusinessClass ? startingSelected.price_quad_business : startingSelected.price_quad
                        )
                        closeDropdown()
                      }}
                      className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                      <div className="flex items-center gap-2 mb-2">
                        <img 
                          src={Quad}
                          />
                        <div className="font-base font-semibold">
                          Quad
                        </div>
                      </div>
                      <div className="green-money font-semibold text-xl">
                        {isBusinessClass ? IDRFormater(startingSelected.price_quad_business) : IDRFormater(startingSelected.price_quad)}
                      </div>
                    </div>
                  )}
                  
                  {!!startingSelected.is_active_price_triple && (
                    <>
                      <div 
                        onClick={() => {
                          handleSetRoomPrice(
                              startingSelected.id_kamar, 
                              `tripple`, 
                              `3 pax/room`,
                              isBusinessClass ? startingSelected.price_triple_business : startingSelected.price_triple
                          )
                          closeDropdown()
                        }}
                        className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                        <div className="flex items-center gap-2 mb-2">
                          <img 
                            src={Triple}
                            />
                          <div className="font-base font-semibold">
                            Triple
                          </div>
                        </div>
                        <div className="green-money font-semibold text-xl">
                          {isBusinessClass ? IDRFormater(startingSelected.price_triple_business) : IDRFormater(startingSelected.price_triple)}
                        </div>
                      </div>
                    </>
                  )}

                  {!!startingSelected.is_active_price_double && (
                    <div 
                      onClick={() => {
                        handleSetRoomPrice(
                          startingSelected.id_kamar, 
                          `double`, 
                          `2 pax/room`,
                          isBusinessClass ? startingSelected.price_double_business : startingSelected.price_double
                        )
                        closeDropdown()
                      }}
                      className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                      <div className="flex items-center gap-2 mb-2">
                        <img 
                          src={Double}
                          />
                        <div className="font-base font-semibold">
                          Double
                        </div>
                      </div>
                      <div className="green-money font-semibold text-xl">
                        {isBusinessClass ? IDRFormater(startingSelected.price_double_business) : IDRFormater(startingSelected.price_double)}
                      </div>
                    </div>
                  )}

                  {!!startingSelected.is_active_price_single && (
                    <div 
                      onClick={() => {
                        handleSetRoomPrice(
                          startingSelected.id_kamar, 
                          `single`, 
                          `1 pax/room`,
                          isBusinessClass ? startingSelected.price_single_business : startingSelected.price_single
                        )
                        closeDropdown()
                      }}
                      className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                      <div className="flex items-center gap-2 mb-2">
                        <img 
                          src={Single}
                          />
                        <div className="font-base font-semibold">
                          Single
                        </div>
                      </div>
                      <div className="green-money font-semibold text-xl">
                        {isBusinessClass ? IDRFormater(startingSelected.price_single_business) : IDRFormater(startingSelected.price_single)}
                      </div>
                    </div>
                  )}
                </Scroll>
              </div>
            </Dropdowns>
          </div>
          <div className="mt-4">
            <InputPhone 
              id="jamaah_phone"
              label="Nomor Telepon"
              placeholder="Masukan Nomor Telepon"
              name={`jamaah_phone`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.jamaah_phone}
              onChange={(val) => {
                jamaahFormik.setFieldValue(`jamaah_phone`, val)
                jamaahFormik.setFieldTouched(`jamaah_phone`, true, true)
              }}
              error={jamaahFormik?.touched?.jamaah_phone && jamaahFormik?.errors?.jamaah_phone}
              />
          </div>
          <div className="mt-4">
            <Inputs 
              id="jamaah_email"
              label="Email"
              placeholder="Masukan Email"
              name={`jamaah_email`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.jamaah_email}
              onChange={jamaahFormik.handleChange}
              error={jamaahFormik?.touched?.jamaah_email && jamaahFormik?.errors?.jamaah_email}
              />
          </div>
          <div className="mt-4">
            <Buttons 
              text="Simpan"
              customClass="w-full btn-primary btn-medium"
              onClick={handleSaveJamaah}
              disabled={!(jamaahFormik.isValid && jamaahFormik.dirty)}
              loading={isLoading}
              />
          </div>
        </div>
      </>
  )
}

export default React.memo(AddJamaah);