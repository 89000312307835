import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Buttons from "components/buttons";
import Badge from "components/badge";

import IconCSV from "assets/icons/CSV.svg";
import IconTotalSeatWhite from "assets/icons/total-seat-white.svg";
import AvaIkhwan from "assets/icons/ava-ikhwan.svg";
import AvaAkhwat from "assets/icons/ava-akhwat.svg";
import IconSeatTerisi from "assets/icons/seat-terisi.svg";
import IconSeatTersisa from "assets/icons/seat-tersisa.svg";
import IconCabangPusat from "assets/icons/Cabang-Pusat.svg";

import { onHandleGetSeatReportAPI, exportLaporanSeatReportService } from "utils/api/paket/laporan";
import { handleError } from "stores/actions/errorGeneral";
import { formattorbase64, hexToRGB } from "utils/helpers";

const PaketLaporanSeat = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const [getting, setGetting] = useState(false)
  const [reports, setReports] = useState({})


  const handleDownloadCSV = async () => {
    const response = await exportLaporanSeatReportService(params.id);

    if (response.status === 200) {
      const { base64, type, file_name } = response.data.data;
      const insertPrefix = formattorbase64(type, base64);
      var a = document.createElement("a"); //Create <a>
      a.href = `${insertPrefix}`; //Image Base64 Goes here
      a.download = `${file_name}`; //File name Here
      a.click();
    } else {
      dispatch(handleError(response.data));
    }
  };


  const handleGetSeatReport = async () => {
    setGetting(true)
    const payload = {
      "sort_column" : "id_cabang",
      "sort_order" : "asc",
      "per_page" : 1000,
      "page_number" : 1
    }
    const response = await onHandleGetSeatReportAPI(params.id, payload)
    setGetting(false)

    if(response.status === 200) {
      setReports(response.data.data)
    } else {
      dispatch(handleError(response.data))
    }

  }


  useEffect(() => {
    handleGetSeatReport()
  }, [])

  return (
    <div className="bg-white border-[1px] border-[#4048521a] rounded-bl-lg rounded-br-lg">
      <div className="p-4">
        <div className="font-semibold text-[20px]">Seat</div>
      </div>
      <div className="divider"></div>
      <div className="p-4">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-col gap-4 bg-white border-[1px] border-[#4048521a] rounded-lg shadow-soft p-4">
            <div className="font-semibold text-[16px]">Semua Seat</div>
            <div className="flex flex-row items-center">
              <div className="!rounded-tl-lg !rounded-bl-lg !rounded-tr-none !rounded-br-none dark-grey-bg-shine p-4">
                <div className="flex flex-row items-center gap-3">
                  <div className="flex flex-row items-center gap-1">
                    <img src={IconTotalSeatWhite} alt="tripple" />
                    <div className="font-semibold text-white">Total</div>
                  </div>
                  <div className="txt-green text-[20px] font-semibold">
                    <span className="text-[16px]">{reports.data_summary?.total_seat}</span>/{reports.data_summary?.total_kuota}
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-6 p-4 border-[1px] border-[#4048521a] rounded-tr-lg rounded-br-lg">
                <div className="flex flex-row gap-2">
                  <img src={AvaIkhwan} alt="ikhwan" />
                  <div className="txt-green font-semibold text-[16px]">{reports.data_summary?.total_seat_ikhwan}</div>
                </div>
                <div className="flex flex-row gap-2">
                  <img src={AvaAkhwat} alt="akhwat" />
                  <div className="txt-green font-semibold text-[16px]">{reports.data_summary?.total_seat_akhwat}</div>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-2">
              <div className="flex flex-row">
                <img src={IconSeatTerisi} alt="terisi" />
                <div className="txt-blue font-medium">Terisi</div>
                <div className="txt-green font-semibold ml-2">{reports.data_summary?.total_seat}</div>
              </div>
              <div className="flex w-px h-full bg-[#4048521a]"></div>
              <div className="flex flex-row">
                <img src={IconSeatTersisa} alt="terisi" />
                <div className="txt-maroon font-medium">Tersisa</div>
                <div className="txt-green font-semibold ml-2">{reports.data_summary?.total_kuota - reports.data_summary?.total_seat}</div>
              </div>
            </div>
          </div>
          <Buttons
            text="Export CSV"
            customClass="btn-outline btn-small"
            icon={IconCSV}
            onClick={handleDownloadCSV}
          />
        </div>
        <div className="mt-5">
          <div className="grid grid-cols-4 gap-4">
            {reports.branches?.map(branch => (
              <>
                <div className="shadow-soft rounded-lg bg-white">
                  <div 
                    style={{ backgroundColor: `rgba(${hexToRGB(branch.branch_bg_color).r},${hexToRGB(branch.branch_bg_color).g}, ${hexToRGB(branch.branch_bg_color).b}, 0.5)` }}
                    className="bg-[#FBC8C8] px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                    {branch.branch_is_main === 1 && (
                      <>
                        <img src={IconCabangPusat} alt="cabang" />
                      </>
                    )}
                    <Badge 
                      label={branch.branch_name?.toUpperCase()} 
                      backgroundColor={branch?.branch_bg_color}
                      color={branch.branch_text_color}
                      />
                  </div>
                  <div className="divider"></div>
                  <div className="p-4">
                    <div className="border-[1px] border-[#4048521a] rounded-lg">
                      <div className="flex flex-row px-4 py-2">
                        <img src={IconSeatTerisi} alt="terisi" />
                        <div className="txt-blue text-[16px] font-semibold ml-1">
                          Seat Terisi
                        </div>
                        <div className="txt-green text-[16px] font-semibold ml-2">
                          {branch?.total_seat}
                        </div>
                      </div>
                      <div className="divider"></div>
                      <div className="flex flex-row px-4 py-3 gap-4">
                        <div className="flex flex-row gap-2">
                          <img src={AvaIkhwan} alt="ikhwan" />
                          <div className="txt-green font-semibold text-[16px]">
                            {branch?.total_seat_ikhwan}
                          </div>
                        </div>
                        <div className="flex flex-row gap-2">
                          <img src={AvaAkhwat} alt="akhwat" />
                          <div className="txt-green font-semibold text-[16px]">
                            {branch?.total_seat_akhwat}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PaketLaporanSeat);
