import Buttons from "components/buttons";
import React, { useState, useEffect, useRef } from "react";

import Step2JamaahForm from "./components/Step2JamaahForm";
import Step2JamaahCard from "./components/Step2JamaahCard";
import Alert from "components/popup/alert";
import Scroll from "components/scroll";


const Step2 = ({ bookingForm, formData, packageID, additionalsFormik }) => {
  const [isUpdateJamaah, setIsUpdateJamaah] = useState(false);
  const [dataOrders, setDataOrders] = useState([])
  const scrollRef = useRef(null)

  const [isOrderJamaahSelected, setIsOrderJamaahSelected] = useState(false)
  const [orderJamaahSelectedIndex, setOrderJamaahSelectedIndex] = useState(0)

  const [isRemove, setIsRemove] = useState(0)

  useEffect(() => {
    if(formData.values.order_kamars.length === 0) {
      handleNewOrderRoom()
    }
  }, [])

  const handleNewOrderRoom = async () => {
    const newOrderRoom = [
      {
        "jamaah_title": null,
        "jamaah_name": null,
        "without_ticket": false,
        "jamaah_passport_number": null,
        "jamaah_identity_number": null,
        "jamaah_phone": null,
        "jamaah_email": null,
        "user_jamaah_id": null,
        "paket_kamar": {
            "id": null,
            "kamar_name": null,
            "kamar_description": null,
            "price": null
        },
        "starting": {
          "name": null,
          "bg_color": null,
          "text_color": null
        },
        "coupons": []
      }
    ]

    if(formData.values.order_kamars.length === 0) {
      setOrderJamaahSelectedIndex(0)
    } else {
      const index = formData.values.order_kamars.length
      setOrderJamaahSelectedIndex(index)
    }

    formData.setFieldValue("order_kamars", formData.values.order_kamars.length === 0 ? newOrderRoom : formData.values.order_kamars.concat(newOrderRoom))
    await setIsOrderJamaahSelected(true)

    // scroll to new element
    const elementToScroll = document.getElementById(`jamaah-order-form-${formData.values.order_kamars.length}`);
    
    if(elementToScroll) {
      scrollRef.current.scrollTo({
        top: elementToScroll.offsetTop - 170,
        behavior: 'smooth'
      });
    }

  }

  const handleSaveOrderJamaah = (data) => {
    
    formData.setFieldValue(`order_kamars.${orderJamaahSelectedIndex}`, data)
    handleSyncAdditonalWajib(data)
    setIsOrderJamaahSelected(false)
  }

  const handleIsRemoveJamaah = (index) => {
    setIsRemove(true)
    setOrderJamaahSelectedIndex(index)
  }

  const handleRemovingJamaah = () => {
    let orderKamars = formData.values.order_kamars
    
    handleSyncAdditonalWajib(orderKamars[orderJamaahSelectedIndex], true)
    
    orderKamars.splice(orderJamaahSelectedIndex, 1)
    formData.setFieldValue('order_kamars', orderKamars)
    setIsRemove(false)
  }

  const handleEditJamaah = (index) => {
    setIsOrderJamaahSelected(true)
    setOrderJamaahSelectedIndex(index)
  }

  const handleSyncAdditonalWajib = (data, remove = false) => {
    const additionals = additionalsFormik.values.paket_additionals

    const matchingIndexes = additionals.reduce((indexes, item, index) => {
      indexes.push(index)
      return indexes
    }, [])

    matchingIndexes.map((item, idx) => {
      
      const additional = additionalsFormik.values.paket_additionals[item]
      const orderJamaah = data
      const index = additional.jamaah.findIndex(jamaah => jamaah.ktp_nik === orderJamaah.jamaah_identity_number)

      if(additional.jamaah.some(jamaah => jamaah.ktp_nik === orderJamaah.jamaah_identity_number)) {
        
        if(!remove) {
          const dataJamaahAdditional = additional.jamaah[index]
          const newDataJamaahAdditional = {
            ...dataJamaahAdditional,
            name: orderJamaah.jamaah_name,
            title: orderJamaah.jamaah_title,
            ktp_nik: orderJamaah.jamaah_identity_number,
          }

          additionalsFormik.setFieldValue(`paket_additionals[${item}].jamaah[${index}]`, newDataJamaahAdditional)

          setTimeout(() => {
            additionalsFormik.setFieldTouched(`paket_additionals[${item}].jamaah[${index}]`, 1)
          })
        } else {
          const newDataJamaahAdditional = additionalsFormik.values.paket_additionals[item].jamaah.filter(jamaah => jamaah.ktp_nik !== orderJamaah.jamaah_identity_number)

          additionalsFormik.setFieldValue(`paket_additionals[${item}].jamaah`, newDataJamaahAdditional)

          setTimeout(() => {
            additionalsFormik.setFieldTouched(`paket_additionals[${item}].jamaah`, 1)
          })
        }

      } else {

        if(additional.is_wajib === '1') {
          const newDataJamaahAdditional = {
            quantity: 1,
            name: orderJamaah.jamaah_name,
            title: orderJamaah.jamaah_title,
            ktp_nik: orderJamaah.jamaah_identity_number,
            note: null
          }

          const additional = additionalsFormik.values.paket_additionals[item]

          const newJamaah = [...additional.jamaah, newDataJamaahAdditional]

          additionalsFormik.setFieldValue(`paket_additionals[${item}].jamaah`, newJamaah)

          setTimeout(() => {
            additionalsFormik.setFieldTouched(`paket_additionals[${item}].jamaah`, 1)
          })

        }

      }

    })

  }


  return (
    <>

      <Alert 
        title="Hapus Jamaah?"
        subtitle="Anda yakin akan menghapus jamaah ini?"
        open={isRemove}
        handleClose={() => {
          setIsRemove(false)
        }}
        handleContinue={handleRemovingJamaah}
        />

      <div className="flex gap-2">
        <div className="w-full">
          <h3 className="text-sm flex justify-between items-center pb-4">
            <span className="font-semibold">Jamaah</span>
            <Buttons 
              text="Tambah Jamaah"
              customClass="btn btn-primary btn-small"
              disabled={isOrderJamaahSelected}
              onClick={handleNewOrderRoom}
              />
          </h3> 
            
          <Scroll
            scollRef={scrollRef}
            height="calc(100vh - 370px)" 
            customClass="bg-[#FBF3F2] rounded-2xl p-4">
              {formData?.values?.order_kamars?.map((val, idx) => (
                <>
                  {isOrderJamaahSelected && idx === orderJamaahSelectedIndex ? (
                    <>
                      <Step2JamaahForm
                        packageID={packageID}
                        index={idx}
                        bookingForm={bookingForm}
                        formData={formData}
                        handleSaveOrderJamaah={handleSaveOrderJamaah}
                      />
                    </>
                  ) : (
                    <>
                      <Step2JamaahCard 
                        bookingForm={bookingForm}
                        index={idx}
                        formData={formData}
                        triggerRemove={handleIsRemoveJamaah}
                        triggerEdit={handleEditJamaah}
                        />
                    </>
                  )}
                </>
              ))}
          </Scroll>

        </div>
      </div>
    </>
  )
}

export default Step2