import React, { useState } from "react";
import Cards from "components/cards";
import MoslemeTable from "components/Table";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import { ReactComponent as Back } from "assets/icons/arrow-left.svg";
import Badge from "components/badge";
import DateRangePicker from "components/datepicker/DateRangePicker";

const columns = [
  {
    title: "Nama",
    dataIndex: "name",
    render: (text) => <span>{text}</span>,
  },
  {
    title: "Data Master",
    dataIndex: "age",
    render: (text) => <Badge label={text} />,
  },
  {
    title: "Tanggal & Waktu",
    dataIndex: "address",
  },

  {
    title: "Deskripsi",
    dataIndex: "status_email",
    className: "whitespace-nowrap",
    render: (s) => (
      <div>
        <div>
          {s}
        </div>
        <div className="action">
          <div className="action-content">
            <div className="action-content-item">
              <DocumentCopy
                onClick={() => alert(`copy id:`)}
                size="15"
                color="#141414"
              />
            </div>
            <div className="action-content-item">
              <Edit onClick={() => alert(`edit id:`)} size="15" color="#141414" />
            </div>
            <div className="action-content-item">
              <Eye onClick={() => alert(`show id:`)} size="15" color="#141414" />
            </div>
            <div className="action-content-item">
              <Trash
                onClick={() => alert(`delete id:`)}
                size="15"
                color="#141414"
              />
            </div>
          </div>
        </div>
      </div>
    ),
  },
];

const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
  },
  {
    key: "4",
    name: "Disabled User",
    age: 99,
    address: "Sydney No. 1 Lake Park",
  },
];

function Activity() {
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Expaneble
  const [isExpand, setIsExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };
  // manipulated data with action
  const sourceData = data;

  const onDatePickerChange = (val) => {
    console.log(val);
  };
  return (
    <div>
      <Cards containerClass="!p-0">
        <div className="px-6 flex justify-between py-6">
          <div className=" flex items-center">
            <Back />
            <label className="ml-2 text-xl font-semibold">Log Aktivitas</label>
          </div>
          <div className="">
            <DateRangePicker
              customClass="!p-2"
              placeholder="Periode"
              onCalendarChange={onDatePickerChange}
              showRangePresets={true}
            />
          </div>
        </div>
        <MoslemeTable
          loading={isLoading}
          expandable={isExpand && expandable}
          columns={columns}
          data={sourceData}
          withSelection={withSelection}
          selectionType={selectionType}
          pagination={pagination}
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      </Cards>
    </div>
  );
}

export default Activity;
