import { useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import useClickOutside from "../../utils/helpers/useClickOutside";
import { SketchPicker } from "react-color";

import ColorIcon from "../../assets/icons/colored/color-icon.svg";

const ColorPicker = ({
  containerClass,
  customWrapper,
  customStyle,
  customClass,
  label,
  placeholder,
  onChange,
  color,
}) => {
  const popover = useRef();
  const [open, setOpen] = useState(false);

  const close = useCallback(() => setOpen(false), []);
  useClickOutside(popover, close);

  return (
    <div className={`${containerClass}`}>
      <div className="text-[#4f4f4f] font-semibold text-[14px] mb-2">
        {label}
      </div>
      <div className="relative">
        <div
          className={`rounded-xl bg-[#f2f2f2] p-4 w-full text-[14px] cursor-pointer relative ${customWrapper}`}
          onClick={() => setOpen(!open)}
          style={{ background: customStyle }}
        >
          {color || placeholder}
          <img
            src={ColorIcon}
            alt="icon"
            width={24}
            height={24}
            className="absolute right-4 top-4"
          />
        </div>
        {open && (
          <div className="absolute top-auto right-0 z-20" ref={popover}>
            <SketchPicker
              className={`${customClass}`}
              color={color}
              onChangeComplete={onChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ColorPicker.propTypes = {
  containerClass: PropTypes.string,
  customWrapper: PropTypes.string,
  customStyle: PropTypes.string,
  customClass: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

ColorPicker.defaultProps = {
  containerClass: "",
  customWrapper: "",
  customStyle: "",
  customClass: "",
  color: "",
  label: "",
  placeholder: "",
  onChange: () => {},
};

export default ColorPicker;
