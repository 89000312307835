import axios from "axios";
import { BASE_URL_ENDPOINT_V2 } from "constant/constanta";

export const getJamaahBlockSeatV2 = (id_paket) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_paket}/blockseat/airplane/jamaah`
  );

export const getSeatV2 = (id_paket, params) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_paket}/blockseat/airplane/seat`, {params: params}
  );

export const updatedSeatV2 = (id_paket, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_paket}/blockseat/airplane/jamaah/update`,
    payload
  );

export const handleGetTransportations = (packageID) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/package/${packageID}/blockseat/transportation`);

// BUS
export const getJamaahBlockSeatBusV2 = (packageID,busID) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${packageID}/blockseat/bus/${busID}/jamaah`
  );

export const getSeatBusV2 = (packageID, busID) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${packageID}/blockseat/bus/${busID}/seat`
  );

export const updatedSeatBusV2 = (id_paket, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_paket}/blockseat/bus/jamaah/update`,
    payload
  );
