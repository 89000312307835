import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

export const getMusimApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-musim`, payload);

export const getIdMusimApi = (id) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/musim/${id}`);

export const deleteIdMusimApi = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/musim/${id}`);

export const createOrUpdateMusimApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-musim`, payload);

export const duplicateMusimApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/musim/duplicate`, payload);
