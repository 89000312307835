import Buttons from "./index";
import IconBrush from "../../assets/icons/brush.svg";
import ButtonsSocial from "./social";

const ButtonDocs = () => {
  return (
    <>
      <div className="flex flex-row gap-2 items-center m-4">
        <Buttons text="Follow Up" customClass="btn-primary btn-large" />
        <Buttons text="Follow Up" customClass="btn-primary btn-medium" />
        <Buttons text="Follow Up" customClass="btn-primary btn-small" />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-primary disabled btn-large"
        />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-primary disabled btn-medium"
        />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-primary disabled btn-small"
        />
        <Buttons
          text="Follow Up"
          customClass="btn-primary loading btn-large"
          loading
        />
        <Buttons
          text="Follow Up"
          customClass="btn-primary loading btn-medium"
          loading
        />
        <Buttons
          text="Follow Up"
          customClass="btn-primary loading btn-small"
          loading
        />
      </div>
      <div className="flex flex-row gap-2 items-center m-4">
        <Buttons text="Follow Up" customClass="btn-secondary btn-large" />
        <Buttons text="Follow Up" customClass="btn-secondary btn-medium" />
        <Buttons text="Follow Up" customClass="btn-secondary btn-small" />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-secondary disabled btn-large"
        />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-secondary disabled btn-medium"
        />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-secondary disabled btn-small"
        />
        <Buttons
          text="Follow Up"
          customClass="btn-secondary loading btn-large"
          loading
        />
        <Buttons
          text="Follow Up"
          customClass="btn-secondary loading btn-medium"
          loading
        />
        <Buttons
          text="Follow Up"
          customClass="btn-secondary loading btn-small"
          loading
        />
      </div>

      <div className="flex flex-row gap-2 items-center m-4">
        <Buttons text="Follow Up" customClass="btn-outline btn-large" />
        <Buttons text="Follow Up" customClass="btn-outline btn-medium" />
        <Buttons text="Follow Up" customClass="btn-outline btn-small" />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-outline disabled btn-large"
        />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-outline disabled btn-medium"
        />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-outline disabled btn-small"
        />
        <Buttons
          text="Follow Up"
          customClass="btn-outline loading btn-large"
          loading
        />
        <Buttons
          text="Follow Up"
          customClass="btn-outline loading btn-medium"
          loading
        />
        <Buttons
          text="Follow Up"
          customClass="btn-outline loading btn-small"
          loading
        />
      </div>

      <div className="flex flex-row gap-2 items-center m-4">
        <Buttons
          text="Follow Up"
          customClass="btn-primary btn-large"
          icon={IconBrush}
        />
        <Buttons
          text="Follow Up"
          customClass="btn-secondary btn-medium"
          icon={IconBrush}
        />
        <Buttons
          text="Follow Up"
          customClass="btn-outline btn-small"
          icon={IconBrush}
        />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-primary disabled btn-large"
          icon={IconBrush}
        />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-secondary disabled btn-medium"
          icon={IconBrush}
        />
        <Buttons
          text="Follow Up"
          disabled
          customClass="btn-outline disabled btn-small"
          icon={IconBrush}
        />
      </div>
      <div className="flex flex-row gap-2 items-center m-4">
        <ButtonsSocial type="google" customClass="google" />
        <ButtonsSocial type="apple" customClass="apple" />
        <ButtonsSocial type="facebook" customClass="facebook" />
      </div>
    </>
  );
};

export default ButtonDocs;
