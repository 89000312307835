import Dashboard from "../assets/icons/Dashboard_new.svg";

import App from "../assets/icons/App_new.svg";
import DataBooking from "../assets/icons/Data_booking_new.svg";
import DataMaster from "../assets/icons/DataMaster_new.svg";
import Fee from "../assets/icons/Fee_new.svg";
import Kalkulator from "../assets/icons/Kalkulator_new.svg";
import Setting from "../assets/icons/Setting_new.svg";
import LayananIslami from "../assets/icons/Layanan_islami_new.svg";
import MarketingKit from "../assets/icons/Marketingkit_new.svg";
import Paket from "../assets/icons/Paket_new.svg";
import Member from "../assets/icons/Member_neww.svg";
import Tagihan from "../assets/icons/Tagihan_new.svg";
import Ticketing from "../assets/icons/Ticketing.svg";
import Cabang from "../assets/icons/Cabang_new.svg";
import Role from "../assets/icons/Role_new.svg";

export const menuSidebarList = [
  {
    title: "Dashboard",
    key: "dashboard",
    icon: Dashboard,
    iconActive: Dashboard,
    order: 0,
    notif: "99",
    allowAccessData: ["all_branches", 'self_branch'],
    allowPermissions: []
  },
  {
    title: "Paket",
    key: "paket",
    icon: Paket,
    iconActive: Paket,
    order: 1,
    notif: "99",
    allowAccessData: ["all_branches", 'self_branch'],
    allowPermissions: ['view_package']
  },
  {
    title: "Data Booking",
    key: "booking",
    icon: DataBooking,
    iconActive: DataBooking,
    order: 2,
    notif: "99",
    allowAccessData: ["all_branches", 'self_branch'],
    allowPermissions: ['view_booking-data']
  },
  {
    title: "Tagihan",
    key: "tagihan",
    icon: Tagihan,
    iconActive: Tagihan,
    order: 3,
    notif: "99",
    allowAccessData: ["all_branches", 'self_branch'],
    allowPermissions: ['view_bill']
  },
  {
    title: "Fee",
    key: "fee",
    icon: Fee,
    iconActive: Fee,
    order: 4,
    notif: "99",
    allowAccessData: ["all_branches"],
    allowPermissions: ['view_fee-management']
  },
  {
    title: "Member",
    key: "member",
    icon: Member,
    iconActive: Member,
    order: 5,
    notif: "99",
    allowAccessData: ["all_branches", 'self_branch'],
    allowPermissions: ['view_member'],
  },
  {
    title: "Apps",
    key: "apps",
    icon: App,
    iconActive: App,
    order: 5,
    notif: "99",
    allowAccessData: ["all_branches"],
    allowPermissions: ['view_apps']
  },
  {
    title: "Marketing",
    key: "marketing",
    icon: MarketingKit,
    iconActive: MarketingKit,
    order: 7,
    notif: "99",
    allowAccessData: ["all_branches", 'self_branch'],
    allowPermissions: ['view_marketing']
  },
  {
    title: "Cabang",
    key: "cabang",
    icon: Cabang,
    iconActive: Cabang,
    order: 8,
    notif: "99",
    allowAccessData: ["all_branches"],
    allowPermissions: ['view_branch']
  },
  {
    title: "Role",
    key: "role",
    icon: Role,
    iconActive: Role,
    order: 9,
    notif: "99",
    allowAccessData: ["all_branches"],
    allowPermissions: ['view_role']
  },
  {
    title: "Data Master",
    key: "data_master",
    icon: DataMaster,
    iconActive: DataMaster,
    order: 10,
    notif: "99",
    allowAccessData: ["all_branches"],
    allowPermissions: ['view_master-data']
  },
  {
    title: "Layanan Islami",
    key: "layanan_islami",
    icon: LayananIslami,
    iconActive: LayananIslami,
    order: 11,
    notif: "99",
    allowAccessData: ["all_branches"],
    allowPermissions: ['view_islamic_services']
  },
  {
    title: "Kalkulator Paket",
    key: "calculator",
    icon: Kalkulator,
    iconActive: Kalkulator,
    order: 12,
    notif: "99",
    allowAccessData: ["all_branches"],
    allowPermissions: ['view_calculator-package']
  },
  {
    title: "Setting",
    key: "setting",
    icon: Setting,
    iconActive: Setting,
    order: 12,
    notif: "99",
    allowAccessData: ["all_branches"],
    allowPermissions: ['view_setting']
  },
];
