import styled from 'styled-components';

const CheckboxContainer = styled.div`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: black;
    border-color: black;
  }
`;

export default CheckboxContainer;
