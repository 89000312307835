import styled from "styled-components";

const TabTagihanStyle = styled.div`
  padding: 16px;

  .ant-tabs-nav {
    width: max-content;
  }

  .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
    border: 0.5px solid #bdbdbd;
    border-radius: 8px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    background: #ffffff;
  }

  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border: none;
  }

  .ant-table-tbody .ant-table-row:last-child .ant-table-cell:first-child {
    border-bottom-left-radius: 7px;
  }

  .ant-table-tbody .ant-table-row:last-child .ant-table-cell:last-child {
    border-bottom-right-radius: 7px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 8px;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > :first-child {
    border-start-start-radius: 8px !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > :last-child {
    border-start-end-radius: 8px !important;
  }
`;

export default TabTagihanStyle;
