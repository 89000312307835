import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import uuid from "react-uuid";
import {
  editDataMaster,
  getDetailDataBandara,
} from "../../../../../stores/actions/datamaster";

export const columns = (
  handleOpen,
  setActionHandle,
  setIsModal,
  openModal,
  dispatch,
  formikValue,
  setCheckName,
  permissions
) => [
  {
    title: "Logo",
    dataIndex: "logo",
    render: (value) => (
      <img
        className="rounded-full w-10 h-10 object-cover"
        alt="logo maskapai"
        src={value}
      />
    ),
  },
  {
    title: "Nama Bandara",
    dataIndex: "name",
  },
  {
    title: "Kode Bandara",
    dataIndex: "domestic_code",
  },
  {
    title: "Map",
    dataIndex: "address_details",
  },

  {
    title: "Deskripsi",
    dataIndex: "description",

    className: "whitespace-nowrap relative",
    render: (s, record) => (
      <div className="w-full relative flex flex-row justify-between">
        {s !== null && s?.length > 150 ? `${s?.substr(0, 150)}...` : s}
        <div className="action">
          <div className="action-content">
            {permissions?.includes("delete_master-data") && (
              <div className="action-content-item">
                <Trash
                  onClick={() => {
                    handleOpen();
                    setActionHandle(record.id_bandara);
                  }}
                  size="16"
                  color="#141414"
                  className="cursor-pointer"
                />
              </div>
            )}
            {permissions?.includes("create_master-data") && (
              <div className="action-content-item">
                <DocumentCopy
                  onClick={() => {
                    formikValue("name", `${record.name} - CLONE`);
                    formikValue("id", record.id_bandara);
                    setCheckName(record.name);
                    setIsModal(true);
                  }}
                  size="16"
                  color="#141414"
                  className="cursor-pointer"
                />
              </div>
            )}
            {permissions?.includes("update_master-data") && (
              <div className="action-content-item">
                <Edit
                  className="cursor-pointer"
                  onClick={() => {
                    openModal(true);
                    dispatch(editDataMaster("edit bandara"));
                    dispatch(getDetailDataBandara(record.id_bandara, uuid()));
                  }}
                  size="16"
                  color="#141414"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ),
  },
];
