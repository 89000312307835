import {
  START_FETCHING_MEMBER_JAMAAH,
  SUCCESS_FETCHING_MEMBER_JAMAAH,
  ERROR_FETCHING_MEMBER_JAMAAH,
  SET_PAGE_JAMAAH,
  SET_SIZE_JAMAAH,
  SET_KEYWORD_JAMAAH,
  SET_TOTAL_JAMAAH,
  SET_ID_JAMAAH,
} from "../actionTypes";
import { JamaahMemberAPI } from "../../../../utils/api/member";
import debounce from "debounce-promise";
import { handleError, handleErrorBE } from "stores/actions/errorGeneral";
let debounceGetJamaah = debounce(JamaahMemberAPI.getListPage, 1500);

const startFetchingPMemberJamaah = () => ({
  type: START_FETCHING_MEMBER_JAMAAH,
});

const errorFetchingPMemberJamaah = () => ({
  type: ERROR_FETCHING_MEMBER_JAMAAH,
});

const successFetchingPMemberJamaah = (data) => ({
  type: SUCCESS_FETCHING_MEMBER_JAMAAH,
  data,
});

const setPage = (page) => ({
  type: SET_PAGE_JAMAAH,
  page,
});

const setSize = (size) => ({
  type: SET_SIZE_JAMAAH,
  size,
});

const setTotal = (total) => ({
  type: SET_TOTAL_JAMAAH,
  total,
});

const setKeyword = (keyword) => ({
  type: SET_KEYWORD_JAMAAH,
  keyword,
});

const setJamaahId = (id) => ({
  type: SET_ID_JAMAAH,
  id_jamaah: id,
});

const fetchDataJamaah = (params) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingPMemberJamaah());
    try {
      let per_page = getState().jamaah.per_page;
      let page_number = getState().jamaah.page_number;
      let sort_column = getState().jamaah.sort_column;
      let sort_order = getState().jamaah.sort_order;
      let keyword = getState().jamaah.keyword;
      let id_role = getState().jamaah.id_role;
      let payload;
      if (params) {
        payload = params;
      } else {
        payload = {
          per_page,
          page_number,
          sort_column,
          sort_order,
          keyword,
        };
      }

      if (id_role !== "0") {
        payload.id_role = id_role;
      }
      let res = await debounceGetJamaah(payload);
      if (res.data.code === 401) {
        window.location.href = "/example";
      }
      let data = res.data.data === "" ? [] : res.data.data;
      dispatch(successFetchingPMemberJamaah(data));
      dispatch(setTotal(res.data.total_data));
      dispatch(setPage(res.data.page));
    } catch (error) {
      console.log("error: ", error.response);
      dispatch(errorFetchingPMemberJamaah());
    }
  };
};

const createJamaah = (payload, action, actionError = (message) => {}) => {
  return async (dispatch) => {
    try {
      let res = await JamaahMemberAPI.postData(payload);

      if (res.status === 200) {
        action(res.data);
      } else {
        actionError(res.data);
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const deleteJamaah = (id, action) => {
  return async (dispatch) => {
    dispatch(startFetchingPMemberJamaah());
    try {
      let res = await JamaahMemberAPI.deleteData(id);
      if (res.status === 200) {
        action(res.data);
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const JamaahAction = {
  fetchData: fetchDataJamaah,
  createData: createJamaah,
  deleteData: deleteJamaah,
  setPage: setPage,
  setSize: setSize,
  setKeyword: setKeyword,
  setJamaahId: setJamaahId,
};

export default JamaahAction;
