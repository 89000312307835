import MoslemeTable from "components/Table";

const ShortcodeModal = ({
    shortcodeData,
    renderShortcode
}) => {

    const columns = [
        {
            title: "Shortcode",
            dataIndex: "shortcode",
            width: "250px",
        },
        {
            title: "Deskripsi",
            dataIndex: "description",
            
        }
    ]

    return (
        <div>
            {!renderShortcode && (
                <>
                    <MoslemeTable
                        columns={columns}
                        data={shortcodeData}
                        withSelection={false}
                        selectionType={false}
                        withPagination={false}
                        customClass="w-full shadow-soft !rounded-2xl overflow-hidden"
                        />
                </>
            )}
            {renderShortcode}
        </div>
    );
};

export default ShortcodeModal