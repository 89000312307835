import React from "react";
import QRCode from "react-qr-code";
import PropTypes from "prop-types";

const ScanQR = ({ value, size, style }) => {
  return <QRCode value={value} size={size} style={style} />;
};

ScanQR.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

ScanQR.defaultProps = {
  value: "https://unsplash.com/s/photos/cumputer",
  size: 256,
  style: {},
};

export default React.memo(ScanQR);
