// adminRekening Actions
// --------------------------------------------------------

import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import { clearData } from "stores/actions/uploadFile";
import {
  createOrUpdateRekeningApi,
  deleteIdRekeningApi,
  duplicateRekeningApi,
  getRekeningApi,
} from "utils/api/datamaster/adminRekening";
import { getAllDataMaster } from "..";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "adminRekening/SET_LOADING";
export const CLEAR_ERROR = "adminRekening/CLEAR_ERROR";
export const SET_ERROR = "adminRekening/SET_ERROR";
export const INIT_DATA = "adminRekening/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "adminRekening/SET_DOUBLE_SUBMIT";
export const SET_DATA_REKENING = "adminRekening/SET_DATA_REKENING";
export const SET_DELETE_ID_REKENING = "adminRekening/SET_DELETE_ID_REKENING";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setDataRekening = (payload) => ({
  type: SET_DATA_REKENING,
  payload,
});

export const setDeleteIdRekening = (payload) => ({
  type: SET_DELETE_ID_REKENING,
  payload,
});

export const getMasterRekening = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await getRekeningApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setDataRekening(res));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteIdMasterRekening = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await deleteIdRekeningApi(id);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setDeleteIdRekening(res));
      dispatch(getAllDataMaster());
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createOrUpdateRekening = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await createOrUpdateRekeningApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(clearData());
      dispatch(handleSuccess(res));
      dispatch(getAllDataMaster());
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicateRekening = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await duplicateRekeningApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      // dispatch(handleSuccess(res));
      dispatch(getAllDataMaster());
      dispatch(handleSuccess(null, {message: "Berhasil menduplikat rekening", code: 200}))
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};
