import Rates from "components/form/rate";
import { Trash, Edit } from "iconsax-react";
import moment from "moment";
import { detailById } from "stores/actions/general";
import Rating from "components/rating";

export const columns = (
  dispatch,
  paketAkomodasi,
  setIsDelete,
  setChoce,
  setIsOpen,
  auth,
  permissions
) => [
  {
    title: "Nama Hotel",
    dataIndex: "kategori",
    render: (_, _akomodasi) => (
      <div className="flex flex-row">
        <div>{_akomodasi.name}</div>
      </div>
    ),
  },
  {
    title: "Kota",
    dataIndex: "alamat_lengkap",
    render: (_, item) => (
      <div className="flex flex-row">
        <div>{item.city}</div>
      </div>
    ),
  },
  {
    title: "Bintang",
    dataIndex: "rating",
    render: (_, item) => (
      <>
        <Rating value={item?.bintang} />
      </>
    ),
  },
  {
    title: "Tanggal Check In",
    dataIndex: "checkin_date",
    render: (_, item) => {
      return (
        <div className="flex flex-row">
          <div>{moment(item.checkin_date).format("DD/MM/YYYY")}</div>
        </div>
      );
    },
  },
  {
    title: "Tanggal Check Out",
    dataIndex: "checkout_date",
    render: (checkout_date, item) => (
      <>
        <div className="flex flex-row">
          <div>{moment(checkout_date).format("DD/MM/YYYY")}</div>
        </div>

        <div className="action">
          {auth.user?.access_data === 'all_branches' && (
            <>
              {permissions?.includes('update_package') && (
                <div className="action-content">
                  <div className="action-content-item">
                    <Trash
                      className="cursor-pointer"
                      size="15"
                      color="#141414"
                      onClick={() => {
                        setIsDelete(true);
                        setChoce(item);
                      }}
                    />
                  </div>
                  <div className="action-content-item">
                    <Edit
                      onClick={() => {
                        setIsOpen(true);
                        setChoce(item);
                      }}
                      size="15"
                      color="#141414"
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    ),
  },
];
