import React, { useState } from "react";
import PropType from "prop-types";

import { useDispatch } from "react-redux";

import Buttons from "components/buttons";
import Tab from "components/tab";
import FollowUpOne from "./components/FollowUpOne";
import FollowUpTwo from "./components/FollowUpTwo";
import FollowUpThree from "./components/FollowUpThree";
import FollowUpFour from "./components/FollowUpFour";
import Scroll from "components/scroll";
import { useFormik } from "formik";

import { handleUpdateFollowupTemplate } from "utils/api/booking";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

const EditPesan = ({ setOpenPopupEditPesan }) => {

  const dispatch = useDispatch()

  const [selectedType, setSelectedType] = useState("dp");
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      message_to_orderer: "",
      message_to_jamaah: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true)
      const res = await handleUpdateFollowupTemplate(selectedType, values)
      setIsLoading(false)
      
      if(res.status === 200) {
        dispatch(handleSuccess(res))
      } else {
        dispatch(handleError(res.data))
      }
    },
  });
  

  const followupTypes = [
    {
      key: "dp",
      label: "Follow Up 1 (DP)",
    },
    {
      key: "payment",
      label: "Follow Up 2 (Pelunasan)",
    },
  ]

  return (
    <>
      <div className="flex gap-4">

        <div className="bg-white py-3 px-4 shadow-hard h-[calc(100vh-200px)] rounded-xl text-sm w-[280px]">
          {followupTypes.map((type, index) => (
            <div 
              key={index} 
              onClick={() => setSelectedType(type.key)}
              className={`mb-2 cursor-pointer text-gray-400 ${selectedType === type.key && "text-red-3 font-semibold"} `}>
              {type.label}
            </div>
          ))}
        </div>
        
        <div className="w-full">
          <div className="bg-white border rounded-tr-2xl rounded-tl-2xl p-4 w-full font-semibold">
            {selectedType === "dp" && (
              <>
                Follow Up 1 (DP)
              </>
            )}
            {selectedType === "payment" && (
              <>
                Follow Up 2 (Pelunasan)
              </>
            )}
          </div>
          <div className="border border-y-0 h-[calc(100vh-340px)] overflow-y-auto costumScrollbar px-4 pb-4">
              {selectedType === "dp" && (
                <FollowUpOne formik={formik} />
              )}
              {selectedType === "payment" && (
                <FollowUpThree formik={formik} />
              )}
          </div>
          <div className="p-4 bg-white border rounded-bl-2xl rounded-br-2xl flex justify-end">
              <div className="flex gap-4">
                <Buttons text="Kembali" customClass="btn-outline btn-medium" onClick={() => setOpenPopupEditPesan(false)} />
                <Buttons 
                  text="Simpan" 
                  customClass="btn-primary btn-medium" 
                  loading={isLoading}
                  onClick={() => formik.handleSubmit()}
                  />
              </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default EditPesan;
