import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Buttons from "components/buttons";
import Popup from "components/popup";

import AvatarMale from "assets/icons/male.svg";
import AvatarFemale from "assets/icons/female.svg";
import SeatIkhwan from "assets/icons/colored/seat-ikhwan.svg";
import SeatAkhwat from "assets/icons/colored/seat-akhwat.svg";
import SeatBlank from "assets/icons/seat-blank.svg";
import Switch from "components/form/switch";
import IconSearch from "assets/icons/search-normal.svg";
import Inputs from "components/form/inputs";
import Scroll from "components/scroll";

import { getJamaahBlockSeatBusV2, getSeatBusV2, updatedSeatBusV2 } from "utils/api/paket/blockseat";

import { handleError } from "stores/actions/errorGeneral";
import Avatar from "components/avatar";
import { toCapital } from "utils/helpers";
import Badge from "components/badge";

const Bus = ({
  packageID, busID
}) => {
  const params = useParams()
  const dispatch = useDispatch()

  const [openPopup, setOpenPopup] = useState(false);
  const [selectedCard, setSelected] = useState(null);
  const [selectedJamaah, setSelectedJamaah] = useState({})

  const [isLoadingGetJamaah, setIsloadingGetJamaah] = useState(false)
  const [isLoadingGetSeat, setIsloadingGetSeat] = useState(false)
  const [totalJamaahNoSeat, setTotalJamaahNoSeat] = useState(0)

  const [jamaah, setJamaah] = useState([])
  const [jamaahFull, setJamaahFull] = useState([])
  const [keywoard, setKeywoard] = useState('')

  const [seatsNative, setSeatsNative] = useState([])
  const [seats, setSeats] = useState([])

  const handleGetJamaah = async (fetchJamaah = true) => {
    setIsloadingGetJamaah(true)
    const response = await getJamaahBlockSeatBusV2(packageID, busID)
    const { status, data } = response
    setIsloadingGetJamaah(false)

    if (status === 200) {
      
      if(fetchJamaah) {
        setJamaah(data?.data?.jamaah)
        setJamaahFull(data?.data?.jamaah)
      }

      setTotalJamaahNoSeat(data?.data?.total_jamaah_no_seat)
    } else {
      dispatch(handleError(data))
    }

  }

  const handleSelectedJamaah = (jamaah) => {
    console.log(jamaah);
    setSelectedJamaah(jamaah)
  }

  const handleSearch = (keywoard) => {
    const newJamaah = [...jamaahFull]
    
    const resultJamaahSearched = newJamaah.filter(item => 
      item.jamaah.name && item.jamaah.name.toLowerCase().includes(keywoard.toLowerCase())  
    )
    
    setJamaah(resultJamaahSearched)
  }

  const handleGetSeat = async () => {
    setIsloadingGetSeat(true)
    const response = await getSeatBusV2(packageID, busID)
    const { status, data } = response
    setIsloadingGetSeat(false)

    if (status === 200) {
      setSeatsNative(data?.data)
      setSeats(groupSeat(data?.data))
    } else {
      dispatch(handleError(data))
    }

  }

  useEffect(()=> {
    
    if(jamaahFull.length) {
      const handler = setTimeout(() => {
        if(keywoard) {
          console.log("ALLO");
          handleSearch(keywoard)
        } else {
          setJamaah(jamaahFull)
        }
      }, 800)
  
      return () => {
        clearTimeout(handler)
      }
    }

  }, [keywoard])

  const groupSeat = (array) => {
    return array.reduce((result, currentItem) => {
      const seat = currentItem['seat'];
      if (seat.includes('01') || seat.includes('02')) {
        result[0] = (result[0] || []).concat(currentItem);
      } else if (seat.includes('03') || seat.includes('04')) {
        result[1] = (result[1] || []).concat(currentItem);
      }

      return Object.values(result);
    }, []);
  };

  const handleSetSeat = (seat) => {

    const newSeats = seatsNative

    // remove exisiting seats
    const seatIndexRemoving = seatsNative.findIndex(seatItem => seatItem['order_kamar_id'] === selectedJamaah?.id_kamar_mapping);
    if(seatIndexRemoving !== -1) {
      newSeats[seatIndexRemoving]['jamaah'] = null 
      newSeats[seatIndexRemoving]['order_kamar_id'] = null
    }

    // select new seat
    const seatIndexNew = newSeats.findIndex(seatItem => seatItem['seat'] === seat.seat);
    newSeats[seatIndexNew]['jamaah'] = selectedJamaah?.jamaah
    newSeats[seatIndexNew]['jamaah']['branch'] = selectedJamaah?.branch
    newSeats[seatIndexNew]['order_kamar_id'] = selectedJamaah?.id_kamar_mapping
    
    // replace seats
    setSeatsNative(newSeats)

    // const bussinesSeat = groupSeatBusiness(newSeats.filter(item => item.type_class == "Business"))
    // const economicSeat = groupSeatEconomics(newSeats.filter(item => item.type_class == "Economy"))
    
    setSeats(groupSeat(newSeats))

    // set seat selected jamaah
    let newJamaah = [...jamaahFull]
    
    const jamaahIndex = newJamaah.findIndex(jamaahItem => jamaahItem?.id_kamar_mapping === selectedJamaah?.id_kamar_mapping);
    newJamaah[jamaahIndex]['blockseat']['id'] = seat.id
    newJamaah[jamaahIndex]['blockseat']['seat'] = seat.seat

    
    setSelectedJamaah(newJamaah[jamaahIndex])
    setJamaah(newJamaah)
    setJamaahFull(newJamaah)
    
    handleUpdateSeat()

  }
  
  const handleUpdateSeat = async () => {
    const payload = {
      kamar_mapping_id: selectedJamaah?.id_kamar_mapping,
      seat_id: selectedJamaah?.blockseat?.id
    }
    const response = await updatedSeatBusV2(packageID, payload)
    const { status, data } = response 

    if(status === 200) {
      handleGetJamaah(false)
    } else {
      dispatch(handleError(data))
      handleGetJamaah()
      handleGetSeat()
    }

  }

  useEffect(() => {
    handleGetJamaah()
    handleGetSeat()
  },[])

  useEffect(() => {
    console.log('gagal');
  }, [selectedJamaah])

  const JamaahCard = ({ onSelect, item }) => {
    return (
      <div
        className="rounded-xl cursor-pointer"
        onClick={() => onSelect(item)}
      >
        <div
          className={`p-4 border ${
            item.id_kamar_mapping === selectedJamaah.id_kamar_mapping
              ? "border-[#E82320] bg-[#FFDCDC]"
              : "border-[#E0E0E0]"
          }  border-solid rounded-2xl flex gap-8 items-center`}
        >
          <div className="flex gap-6 w-full items-center">
            <Avatar 
              value={item.jamaah}
              containerClass="items-center justify-center h-10 w-10 rounded-[100%]  bg-slate-100"
              customClass="object-cover w-10 h-10 rounded-[100%]"
              />
            <div className="self-center">
              <h5 className="font-semibold">
                {item?.jamaah?.title ? `${toCapital(item?.jamaah?.title)}.` : ""} {item?.jamaah?.name}
              </h5>
              <div className="flex flex-row items-center gap-3 mt-3">
                <Badge
                  label={item?.branch?.name}
                  backgroundColor={item?.branch?.bg_color}
                  color={item?.branch?.text_color}
                  />
                <div className="bg-[#F2F2F2] w-[6px] h-[6px] rounded-full"></div>
                <Badge 
                  label={item?.starting?.name}
                  backgroundColor={item?.starting?.bg_color}
                  color={item?.starting?.text_color}
                  />
              </div>
            </div>
          </div>

          <div className="w-1/2 flex justify-end">
            <div className="self-center">
              {item?.blockseat?.id && (
                <img
                  src={item?.jamaah?.gender === 'Perempuan' ? SeatAkhwat : SeatIkhwan}
                  alt="avatar"
                  className="w-8 h-8 mx-auto"
                />
              )}
              {!item?.blockseat?.id && (
                <img
                  src={SeatBlank}
                  alt="avatar"
                  className="w-8 h-8 mx-auto"
                />
              )}
              {item?.blockseat?.class === 'business' ? (
                <>
                  <div className="px-2 py-1 border border-blue-500 text-blue-500 text-xs rounded font-semibold">
                    Business Class
                  </div>
                </>
              ) : (
                <>
                  <div className="px-2 py-1 border border-gray-500 text-gray-500 text-xs rounded font-semibold">
                    Ekonomi Class
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>

      <div className="border-t grid grid-cols-2 gap-5">
        <div className="py-6">
          <div className="border bg-[#FFDCDC] border-red-700 text-red-600 p-5 rounded-lg text-sm">
            Keterangan: Seat ini tidak menunjukan posisi tempat duduk di
            lapangan. Hanya Menunjukan jamaah duduk bersama siapa
          </div>
          <div className="flex flex-row justify-between items-center soft-shadow px-4 py-3 rounded-xl mt-4">
            <div className="flex flex-col gap-2">
              <div className="text-[#4F4F4F]">Jamaah belum memilih seat</div>
            </div>
            <div className="font-semibold">{totalJamaahNoSeat}</div>
          </div>
          <div className="@apply shadow-[0px_4px_80px_-4px_#0000000A,0px_10px_16px_-6px_#0000000A] flex flex-col justify-start p-4 gap-y-4 rounded-[12px] mt-4">
            <Scroll customClass="!px-4 !py-3" height="648px">
              <Inputs
                id="search"
                placeholder="Search"
                // customClass="mx-3"
                iconPosition="left"
                icon={IconSearch}
                onChange={(e) => setKeywoard(e.target.value)}
              />
              <div className="flex flex-col gap-4 mt-4">
                {jamaah?.map((item) => (
                  <JamaahCard
                    item={item}
                    onSelect={(item) => handleSelectedJamaah(item)}
                  />
                ))}
              </div>
            </Scroll>
          </div>
        </div>

        <div className="flex justify-center bg-[#F8F8F8] px-8 overflow-hidden">
          <div className="bg-white w-[450px] p-10 rounded-b-[24px] h-full shadow">
            <div className="py-5 flex flex-col items-center">
            <div className="grid grid-cols-2 gap-12 mt-8 mb-6">
                {seats?.map((seat, rowIndex) => (
                  <div className="grid grid-cols-2 gap-4">
                  {seat?.map((item, index) => (
                    <>
                      {item?.order_kamar_id ? (
                        <div className={`
                          ${selectedJamaah?.id_kamar_mapping == item.order_kamar_id && `!bg-red-5 !border-red-3`} 
                          border border-white p-1 flex justify-center items-center rounded-[4px] relative group/item
                          `}>
                          <img
                            src={item?.jamaah?.gender === 'Perempuan' ? SeatAkhwat : SeatIkhwan }
                            alt="avatar"
                            className="w-6 h-6 mx-auto"
                          />
                          <div className="
                            absolute bg-black text-white p-3 rounded-lg text-sm
                            left-[110%] hidden group-hover/item:inline-block z-10
                          ">
                            <div className="font-semibold whitespace-nowrap">
                              {item?.jamaah?.title ? `${toCapital(item?.jamaah?.title)}.` : ""} {item?.jamaah?.name}
                            </div>
                            <div className="mt-1">
                              {item?.jamaah?.branch?.name}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div 
                          className={`
                            ${selectedJamaah.id_kamar_mapping && `cursor-pointer`}
                            border border-white p-1 flex justify-center items-center  rounded-[4px] `}
                          onClick={() => { 
                            selectedJamaah?.id_kamar_mapping && handleSetSeat(item)
                          }}
                          >
                          <img
                            src={SeatBlank}
                            alt="avatar"
                            className="w-6 h-6 mx-auto"
                          />
                        </div>
                      )}                    
                    </>
                  ))}
                </div>
                ))}
              </div>
            </div>

            <div className="flex justify-between border rounded-lg w-full mt-10 p-4 gap-5 text-sm">
              <div className="flex gap-2">
                <div className="h-6 w-6 rounded-md bg-[#3020E8]"></div>
                <span>Seat Ikhwan</span>
              </div>
              <div className="flex gap-2">
                <div className="h-6 w-6 rounded-md bg-[#E82068]"></div>
                <span>Seat Akhwat</span>
              </div>
              <div className="flex gap-2">
                <div className="h-6 w-6 rounded-md bg-[#CECECE]"></div>
                <span>Seat Kosong</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bus;
