// import { Input } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// import ArrowDown from "../../../assets/icons/Down-Arrow.svg";
import InputPhoneStyle from "./InputPhone.style";
import Close from "../../../assets/icons/Close.svg";
import Check from "../../../assets/icons/Check.svg";

const InputPhone = ({
  containerClass,
  customClass,
  id,
  label,
  name,
  icon,
  phoneRegion,
  placeholder,
  value,
  success,
  error,
  disabled,
  enableSearch,
  onChange,
  onClickIcon,
  onPressEnter,
  onBlur,
  customeLabel,
}) => {

  return (
    <InputPhoneStyle className={`${containerClass}`}>
      {label !== "" && (
        <div
          className={`font-semibold min-h-[24px] mb-2 text-[14px] text-[#4F4F4F] ${customeLabel}`}
        >
          {label}
        </div>
      )}
      <PhoneInput
        id={id}
        className={`custom-input ${customClass} ${error !== "" && 'error'}`}
        country={"id"}
        value={value}
        onChange={onChange}
        disabled={disabled}
        enableSearch={enableSearch}
        placeholder={placeholder}
        onBlur={onBlur}
      />
      {success !== "" && (
        <div className="flex flex-row items-center">
          <img src={Check} alt="check" />
          <div className="input-success-msg">{success}</div>
        </div>
      )}
      {error !== "" && (
        <div className="flex flex-row items-center mt-1">
          <img src={Close} alt="close" />
          <div className="input-error-msg">{error}</div>
        </div>
      )}
    </InputPhoneStyle>
  );
};

InputPhone.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.any,
  phoneRegion: PropTypes.string,
  placeholder: PropTypes.string,
  success: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  enableSearch: PropTypes.bool,
  onChange: PropTypes.func,
  onClickIcon: PropTypes.func,
  onPressEnter: PropTypes.func,
  customeLabel: PropTypes.string,
};

InputPhone.defaultProps = {
  containerClass: "",
  customClass: "",
  id: "",
  label: "",
  name: "",
  icon: "",
  phoneRegion: "+62",
  placeholder: "",
  success: "",
  error: "",
  disabled: false,
  enableSearch: false,
  onChange: () => {},
  onClickIcon: () => {},
  onPressEnter: () => {},
  customeLabel: "",
};

export default InputPhone;
