import Sliders from ".";
import Buttons from "../buttons";

const SliderDocs = () => {
  return (
    <>
      <div className="w-[800px] m-auto mt-4">
        <Sliders customClass="flex gap-3">
          <div>
            <Buttons text="1" customClass="btn-primary btn-medium" />
          </div>
          <div>
            <Buttons text="2" customClass="btn-secondary btn-medium" />
          </div>
          <div>
            <Buttons text="3" customClass="btn-outline btn-medium" />
          </div>
          <div>
            <Buttons text="4" customClass="btn-primary btn-medium" />
          </div>
          <div>
            <Buttons text="5" customClass="btn-primary btn-medium" />
          </div>
          <div>
            <Buttons text="6" customClass="btn-primary btn-medium" />
          </div>
        </Sliders>
      </div>
      <div className="w-[800px] m-auto mt-20">
        <Sliders dots={true}>
          <div>
            <Buttons text="1" customClass="btn-primary btn-medium" />
          </div>
          <div>
            <Buttons text="2" customClass="btn-secondary btn-medium" />
          </div>
          <div>
            <Buttons text="3" customClass="btn-outline btn-medium" />
          </div>
          <div>
            <Buttons text="4" customClass="btn-primary btn-medium" />
          </div>
          <div>
            <Buttons text="5" customClass="btn-primary btn-medium" />
          </div>
          <div>
            <Buttons text="6" customClass="btn-primary btn-medium" />
          </div>
        </Sliders>
      </div>
    </>
  );
};

export default SliderDocs;
