import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Buttons from "../../buttons";
import IconError from "../../../assets/icons/icon-error.svg";
import IconSucces from "../../../assets/icons/icon-success.svg";
import IconWarning from "../../../assets/icons/icon-warning.svg";
const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "388px",
    // height: "276px",
    borderRadius: "16px",
    boxShadow:
      "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
    background: "#fff",
    backgroundColor: "#fff",
    "& .card_column": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      alignContent: "center",
      padding: "24px",

      "& .icon": {},
      "& .title": {
        marginTop: "24px",
        fontFamily: "GeneralSansRegular",
        fontWeight: "600",
        color: "#101010",
        fontSize: "20px",
      },
      "& .message": {
        fontFamily: "GeneralSansRegular",
        fontSize: "16px",
        color: "#4F4F4F",
        fontWeight: "400",
        marginTop: "16px"
      },
      "& .button-container": {
        marginTop: "24px",
      },
    },
  },
}));

const PopupRenderConnfrim = ({
  type,
  classes,
  handleClose,
  title,
  subtitle,
}) => {
  switch (type) {
    case "success":
      return (
        <div className={classes.paper}>
          <div className="card_column">
            <div className="icon">
              <img src={IconSucces} alt="icon-success" />
            </div>
            <div className="title">{title}</div>
            <div className="message">{subtitle}</div>
            <div className="button-container">
              <Buttons
                text="Ok"
                customClass="btn-primary btn-large"
                onClick={handleClose}
              />
            </div>
          </div>
        </div>
      );

    case "error":
      return (
        <div className={classes.paper}>
          <div className="card_column">
            <div className="icon">
              <img src={IconError} alt="icon-error" />
            </div>
            <div className="title">{title}</div>
            <div className="message">{subtitle}</div>
            <div className="button-container">
              <Buttons
                text="Ok"
                customClass="btn-primary btn-large"
                onClick={handleClose}
              />
            </div>
          </div>
        </div>
      );

    case "warning":
      return (
        <div className={classes.paper}>
          <div className="card_column">
            <div className="icon">
              <img src={IconWarning} alt="icon-warning" />
            </div>
            <div className="title">{title}</div>
            <div className="message">{subtitle}</div>
            <div className="button-container">
              <Buttons
                text="Ok"
                customClass="btn-primary btn-large"
                onClick={handleClose}
              />
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div className={classes.paper}>
          <div className="card_column">
            <div className="icon">
              <img src={IconError} alt="icon-error" />
            </div>
            <div className="title">{title}</div>
            <div className="message">{subtitle}</div>
            <div className="button-container">
              <Buttons
                text="Ok"
                customClass="btn-primary btn-large"
                onClick={handleClose}
              />
            </div>
          </div>
        </div>
      );
  }
};

const PopupConfirm = ({ open, handleClose, type, title, subtitle }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {
            <PopupRenderConnfrim
              title={title}
              type={type}
              classes={classes}
              handleClose={handleClose}
              subtitle={subtitle}
            />
          }
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

PopupConfirm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.oneOfType(["success", "warning", "error"]),
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

PopupConfirm.defaultProps = {
  handleClose: () => {},
  type: "success",
  open: false,
  title: "",
  subtitle: "",
};

export default React.memo(PopupConfirm);
