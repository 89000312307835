import axios from "axios";
import {
  BASE_URL_ENDPOINT,
  BASE_URL_ENDPOINT_V2,
} from "../../../constant/constanta";

export const getAllRoleService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-role`, payload);

export const saveRoleService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-role`, payload);

export const deleteRoleService = (payload) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/role/${payload.id}`);

export const updatePermissionRoleService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/role/update-permission`, payload);

export const getAllRoleServiceV2 = (payload) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/roles?main=${payload.main}&offset=${payload.offset}&page=${payload.page}&pagination=${payload.pagination}&keyword=${payload.keyword} `
  );

export const getAllPermissionV2 = () =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/permissions`);

export const deleteRoleServiceV2 = (payload) =>
  axios.delete(`${BASE_URL_ENDPOINT_V2}/admin/role/${payload.name}`);
export const saveRoleServiceV2 = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/role`, payload);

export const dupicatedRoleServiceV2 = (payload) =>
  axios.put(`${BASE_URL_ENDPOINT_V2}/admin/role/${payload.name}/duplicate`);

export const getDetailRoleV2 = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/role/${payload.name}`);

export const updatedRoleServiceV2 = (payload, display_name) =>
  axios.put(`${BASE_URL_ENDPOINT_V2}/admin/role/${display_name}`, payload);
export const updatedPermisiionsServiceV2 = (payload, display_name) =>
  axios.put(
    `${BASE_URL_ENDPOINT_V2}/admin/role/${display_name}/permissions`,
    payload
  );

export const getRolePermisionV2 = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/role/${payload.name}/permissions`);
