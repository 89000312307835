import React from "react";
import Segment from ".";

const SegmentDoc = () => {
  return (
    <Segment
      options={[
        {
          label: <div>Terverifikasi</div>,
          value: "Terverifikasi",
        },
        {
          label: <div className="segment-label-error">Belum Terverifikasi</div>,
          value: "Belum Terverifikasi",
        },
      ]}
    />
  );
};

export default SegmentDoc;
