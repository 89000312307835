import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { getLocalStorage } from "utils/helpers";
import { useSelector } from "react-redux"

import propTypes from "prop-types";

import { useLocation } from "react-router-dom";

import { menuSidebarList } from "configuration";

const useStyle = makeStyles({
  container: {
    width: "96px",
    height: "100vh",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    position: "fixed",
    paddingBottom: "32px",
    backgroundColor: "#fff",
    boxShadow:
      "0px 8px 8px -4px rgba(0, 0, 0, 0.04), 0px 18px 24px -10px rgba(0, 0, 0, 0.1), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
    top: "0",
    left: "0",
    zIndex: "998",
    "& .item": {
      boxSizing: "border-box",
      marginTop: "20px",
      cursor: "pointer",
      // width: "64px",
      // height: "74px",
      borderRadius: "8px",
      transition: "box-shadow 0.3s ease-in-out",
      "&:hover": {
        boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1);",
      },
      "& .card": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        paddingLeft: "4px",
        paddingRight: "4px",
        paddingBottom: "4px",
        "& .card_icon": {
          zIndex: "1",
          position: "absolute",
          width: "16px",
          height: "16px",
          right: "8px",
          top: "0px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(180deg, #F5E97C 0%, #E2A246 100%)",
          borderRadius: "48px",
          "& .title_icon": {
            fontFamily: "GeneralSans",
            fontWeight: "600",
            color: "#151515",
            fontSize: "6px",
            textAlign: "center",
          },
        },

        "& .icon": {
          marginTop: "10px",
          width: "100%",
          height: "32px",
          marginBottom: "10px",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .title": {
          fontSize: "12px",
          lineHeight: "16px",
          marginTop: "5px",
          color: "#151515 !important",
          textAlign: "center",
          fontFamily: "GeneralSansRegular",
        },
      },
    },
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "4px",
      // height: scrollHorizontal ? "5px" : "100px",
    },
    "&::-webkit-scrollbar-track": {
      width: "4px",
      borderRight: "10px solid #fff",
      borderLeft: "10px solid #fff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E0E0E0",
      height: "30px",
      width: "4px",
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-track-piece:start": {
      background: "#fff",
    },
    "&::-webkit-scrollbar-track-piece:end": {
      background: "#fff",
    },
    scrollBehavior: "smooth",
  },
});

const Sidebar = ({ menuList, handleClick }) => {
  const classes = useStyle();
  const location = useLocation();
  const [choice, setChoice] = useState(null);

  const auth = useSelector(({ auth }) => auth)

  const accessData = auth?.user?.access_data;
  const permissions = auth?.user?.permissions;

  const onHandleCheckPermissions = (menuPermissions) => {
    return permissions.some(permission => menuPermissions.includes(permission));
  }

  const handleActiveSidebar = () => {
    // ambil path pertama dari location.pathname
    const mainPath = location.pathname.split("/")[1];

    // cari index dari menuSidebarList yang mengandung mainPath berdasarkan key dari menuSidebarList
    const menuSelectedIndex = menuSidebarList.findIndex(menu => menu.key === mainPath);

    setChoice(menuSelectedIndex)
  }
  
  useEffect(() => {
    handleActiveSidebar()
  }, [location])

  return (
    <div className={classes.container}>
      {menuList?.map((item, i) => {
        return (
          // cek Allow AccessData
          item.allowAccessData.includes(accessData) &&  (

            // cek Allow Permissions
            item.allowPermissions.length ? (
              
              onHandleCheckPermissions(item.allowPermissions) && (
                <div
                  className="item"
                  key={i}
                  style={{
                    boxShadow:
                      choice === i
                        ? "0px 6px 64px -4px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.04), 0px 6px 32px rgba(0, 0, 0, 0.04)"
                        : null,
                  }}
                  onClick={() => {
                    handleClick(item);
                    setChoice(i);
                  }}
                >
                  <div className="card">
                    {/* <div className="card_icon">
                      <div className="title_icon">{item?.notif}+</div>
                    </div> */}
                    <div>
                      <div className="icon">
                        {choice === i ? (
                          <img src={item.iconActive} alt={item?.key} />
                        ) : (
                          <img src={item.icon} alt={item?.key} />
                        )}
                      </div>
                      <div className="title">{item?.title}</div>
                    </div>
                  </div>
                </div>
              )

            ) : (
              <div
                className="item"
                key={i}
                style={{
                  boxShadow:
                    choice === i
                      ? "0px 6px 64px -4px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.04), 0px 6px 32px rgba(0, 0, 0, 0.04)"
                      : null,
                }}
                onClick={() => {
                  handleClick(item);
                  setChoice(i);
                }}
              >
                <div className="card">
                  {/* <div className="card_icon">
                    <div className="title_icon">{item?.notif}+</div>
                  </div> */}
                  <div>
                    <div className="icon">
                      {choice === i ? (
                        <img src={item.iconActive} alt={item?.key} />
                      ) : (
                        <img src={item.icon} alt={item?.key} />
                      )}
                    </div>
                    <div className="title">{item?.title}</div>
                  </div>
                </div>
              </div>
            )
          )
        );
      })}
    </div>
  );
};

Sidebar.propTypes = {
  menuList: propTypes.array,
  handleClick: propTypes.func,
};

Sidebar.defaultProps = {
  menuList: [
    {
      title: "Dashboard",
      key: "dashboard",
      notif: "99++",
    },
  ],
  handleClick: () => {},
};
export default React.memo(Sidebar);
