import React, { useEffect, useState } from "react";

import EditorToolbar, {
  formats,
  modules,
} from "components/richTextEditor/EditorToolbar";
import ReactQuill from "react-quill";
import RichTextEditor from "components/richTextEditor";
import Tab from "components/tab";

import { handleGetFollowupTemplate } from "utils/api/booking";
import { useFormik } from "formik";

const FollowUpThree = ({
  formik
}) => {
  const [input, setInput] = useState();

  const handleGetTemplate = async () => {
    const res = await handleGetFollowupTemplate('payment');
    const templateDP = res.data.data[0]

    formik.setFieldValue('message_to_orderer', templateDP.message_to_orderer)
    formik.setFieldValue('message_to_jamaah', templateDP.message_to_jamaah)
  }

  const shortcodes = [
    {
      shortcode: "[nama]",
      description: "Nama yang ditagihkan"
    },
    {
      shortcode: "[nama_pemesan]",
      description: "Nama Pemesan"
    },
    {
      shortcode: "[no_whatsapp_pemesan]",
      description: "Nomor Whatsapp Pemesan"
    },
    {
      shortcode: "[email_pemesan]",
      description: "Email Pemesan"
    },
    {
      shortcode: "[role_pemesan]",
      description: "Role Pemesan"
    },
    {
      shortcode: "[nama_travel]",
      description: "Nama Travel"
    },
    {
      shortcode: "[jumlah_pax]",
      description: "Menampilkan Jumlah Pax"
    },
    {
      shortcode: "[judul_paket]",
      description: "Menampilkan judul paket"
    },
    {
      shortcode: "[booking_id]",
      description: "Menampilkan Booking ID"
    },
    {
      shortcode: "[total_dp]",
      description: "Nominal DP tanpa kode unik"
    },
    {
      shortcode: "[tgl_batas_pelunasan]",
      description: "Menampilkan Tanggal Batas Pelunasan"
    },
    {
      shortcode: "[nominal_terbayarkan]",
      description: "Menampilkan Nominal yang Sudah Terbayarkan"
    },
    {
      shortcode: "[nominal_sisa]",
      description: "Menampilkan Nominal yang Belum Terbayarkan"
    },
    {
      shortcode: "[starting]",
      description: "Menampilkan Starting yang jamaah pilih"
    },
    {
      shortcode: "[tipe_kamar]",
      description: "Menampilkan Tipe Kamar yang jamaah pilih"
    },
  ]

  useEffect(() => {
    handleGetTemplate();
  }, [])

  return (
    <>
    <div>
      <Tab
        defaultActiveKey="1"
        destroyInactiveTabPane={true}
        items={[
          {
            label: "Pesan ke Pemesan",
            key: "1",
            children: (
              <>
                <div className="mt-4">
                    <RichTextEditor 
                      placeholder="Masukan pesan template untuk pemesan"
                      label="" 
                      shortcode={true}
                      shortcodeData={shortcodes}
                      data={{value: formik.values.message_to_orderer}}
                      onChange={(value) => formik.setFieldValue('message_to_orderer', value)}
                      />
                </div>
              </>
            ),
          },
          {
            label: "Pesan ke Jamaah",
            key: "2",
            children: (
              <>
                <div className="mt-4">
                    <RichTextEditor 
                      label="" 
                      placeholder="Masukan pesan template untuk jamaah" 
                      shortcode={true}
                      shortcodeData={shortcodes}
                      data={{value: formik.values.message_to_jamaah}}
                      onChange={(value) => formik.setFieldValue('message_to_jamaah', value)}
                      />
                </div>
              </>
            ),
          },
        ]}
      />
      </div>
    </>
  );
};

export default React.memo(FollowUpThree);
