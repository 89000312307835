import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Cards from "components/cards";
import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import Tab from "components/tab";
import BookingTable from "./BookingTable";
import { useParams } from 'react-router-dom'

import IconSearch from "assets/icons/search-normal.svg";
import IconSquare from "assets/icons/send-square.svg";
import IconActivity from "assets/icons/activity.svg";
import Jamaah from "./Jamaah";

import useDebounce from "utils/helpers/useDebounce";
import CreateNewBooking from "./CreateNewBooking";
import Dropdowns, { closeDropdown } from "components/dropdowns";
import { Filter } from "iconsax-react";
import Checkbox from "components/checkbox";

import { handleGetBookingList, handleGetJamaahBookingList, handleCancelBooking } from "utils/api/booking";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import Alert from "components/popup/alert";
import DropdownsKu from "components/DropdownsKu";
import LogActivities from "components/LogActivities";

import { ReactComponent as Activity } from "assets/icons/activity.svg";
import ReasonCancelBooking from "./ReasonCancelBooking";
import Popup from "components/popup";


const CardBookingTable = ({ setPopupAddBooking, setOpenCancelReasonPopup }) => {
  const dispatch = useDispatch();
  const params = useParams()
  
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  const [keyword, setKeyword] = useState('')
  const searchDebounce = useDebounce(keyword, 1000)
  const [tabActive, setTabActive] = useState(1)

  const [isCreateNewBooking, setIsCreateNewBooking] = useState(false)

  const [isLoading, setIsloading] = useState(false)

  const [bookingList, setBookingList] = useState([]);
  const [totalBookingList, setTotalBookingList] = useState(0)

  const [isLoadingCancel, setIsLoadingCancel] = useState(false)

  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  const [jamaahOrderList, setJamaahOrderList] = useState([])
  const [totalJamaahOrderList, setTotalJamaahOrderList] = useState(0)

  const [isCancelOrder, setIsCancelOrder] = useState(false)
  const [orderNumberSelected, setOrderNumberSelected] = useState(null)

  const [packageType, setPackageType] = useState(2)

  const [reasonCancel, setReasonCancel] = useState('')

  const [filterPackageStatusOptions, setFilterPackageStatusOptions] = useState({
    belum_berangkat: false,
    sedang_berangkat: false,
    sudah_berangkat: false,
  })
  
  const [filterPaymentStatusOptions, setFilterPaymentStatusOptions] = useState({
    belum_dp: false,
    dp: false,
    dibayar_sebagian: false,
    lunas: false,
    dibatalkan: false
  })

  const [filterPackageStatusData, setFilterPackageStatusData] = useState([])
  const [filterPaymentStatusData, setFilterPaymentStatusData] = useState([])

  const [showLogActivities, setShowLogActivities] = useState(false)
  
  const handleFilterStatusPackageType = () => {
    let data = [];
    
    if (filterPackageStatusOptions.belum_berangkat) data.push(0);
    if (filterPackageStatusOptions.sedang_berangkat) data.push(1);
    if (filterPackageStatusOptions.sudah_berangkat) data.push(2);
    
    return data;
  }

  const handleFilterDataPaymentStatus = () => {
    let data = [];

    if (filterPaymentStatusOptions.belum_dp) data.push(0);
    if (filterPaymentStatusOptions.dp) data.push(1);
    if (filterPaymentStatusOptions.dibayar_sebagian) data.push(2);
    if (filterPaymentStatusOptions.lunas) data.push(3);
    if (filterPaymentStatusOptions.dibatalkan) data.push(4);

    return data;
  }

  const applyFilter = () => {
    const anyFilterPackageType = Object.values(filterPackageStatusOptions).some(value => value === true);
    const anyFilterPaymentStatus = Object.values(filterPaymentStatusOptions).some(value => value === true);

    return anyFilterPackageType || anyFilterPaymentStatus
  }

  const handleResetFilter = () => {
    setFilterPackageStatusOptions({
      belum_berangkat: false,
      sedang_berangkat: false,
      sudah_berangkat: false,
    })
    setFilterPaymentStatusOptions({
      belum_dp: false,
      dp: false,
      dibayar_sebagian: false,
      lunas: false,
      dibatalkan: false
    })
  }

  const handleChangeTab = (key) => {
    setTabActive(key)
    setKeyword('')
  }

  const handelChecklistPackageType = (packageType, isChecked) => {
    setFilterPackageStatusOptions({ ...filterPackageStatusOptions, [packageType]: isChecked });
  }

  const handelChecklistPaymentStatus = (status, isChecked) => {
    setFilterPaymentStatusOptions({ ...filterPaymentStatusOptions, [status]: isChecked });
  }

  const handleGetBooking = async (filter = false) => {
    setIsloading(true);
    let params = {
      keyword: keyword,
      per_page: pageSize,
      page: currentPage,
      package_type: [packageType],
      package_status: [],
      payment_status: [],
    }

    if(filter) {
      params = {
        ...params,
        package_status: filter.package_status,
        payment_status: filter.payment_status
      }
    }
    
    const response = await handleGetBookingList(params)
    const { status, data } = response
    setIsloading(false)
    if(status == 200){
      const dataList = data.data.map((item) => ({
        ...item,
        key: item.id
      }))
      setBookingList(dataList)
      setTotalBookingList(data.total)
    } else {
      dispatch(handleError(data))
    }
  }

  const handleGetJamaahOrder = async (filter = false) => {
    setIsloading(true);
    let params = {
      keyword: keyword,
      per_page: pageSize,
      page: currentPage,
    }

    if(filter) {
      params = {
        ...params,
        package_status: filter.package_status,
        payment_status: filter.payment_status
      }
    }

    const response = await handleGetJamaahBookingList(params);
    setIsloading(false)
    const { status, data } = response;
    if(status == 200) {
      const dataList = data.data.map((item) => ({
        ...item,
        key: item.id
      }));

      setJamaahOrderList(dataList)
      setTotalJamaahOrderList(data.total)
    } else {
      dispatch(handleError(data))
    }

  }

  const handleCancelOrder = (orderNumber) => {
    // console.log("HEHE", orderNumber);
    setIsCancelOrder(true)
    setOrderNumberSelected(orderNumber)
  }

  const handleCancelOrderConfirm =  async () => {
    setIsLoadingCancel(true)
    const payload = {
      cancel_reason: reasonCancel
    }
    const response = await handleCancelBooking(orderNumberSelected, payload)
    const { status, data } = response
    setIsLoadingCancel(false)

    if(status === 200 || status === 201) {
      dispatch(handleSuccess(response))
      handleGetBooking()
      setIsCancelOrder(false)
    } else {
      dispatch(handleError(data))
    }

  }

 
  // useEffect(() => {
  //   if(tabActive === 1) 
  //   {
  //     handleGetBooking()
  //   } else {    
  //     handleGetJamaahOrder()
  //   }
  //  }, [packageType])

  useEffect(() => {
    
    switch (params.packageType) {
      case 'umroh':
        setPackageType(2)
        break;
      case 'haji':
        setPackageType(1)
        break;
      case 'tour':
        setPackageType(3)
        break;
      case 'jasabadal':
        setPackageType(4)
        break;
      case 'tabungan':
        setPackageType(5)
        break;
      default:
        setPackageType(2)
        break;
    }
    
  }, [params])


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if(tabActive === 1) 
  //     {
  //       handleGetBooking()
  //     } else {    
  //       handleGetJamaahOrder()
  //     }
  //   }, 600000); // 600000 milidetik = 10 menit

  //   return () => clearInterval(interval); // Membersihkan interval saat komponen di-unmount
  // }, []);

  useEffect(() => {
    // setCurrentPage(1)
    const filter = {
      package_status: handleFilterStatusPackageType(),
      payment_status: handleFilterDataPaymentStatus()
    }

    if(tabActive == 1) 
    {
      handleGetBooking(filter)
    } else {    
      handleGetJamaahOrder(filter)
    }
    
  }, [filterPackageStatusOptions,packageType,filterPaymentStatusOptions, tabActive, searchDebounce, currentPage, pageSize])

  return (
    <>
      <CreateNewBooking 
        open={isCreateNewBooking}
        handleClose={() => setIsCreateNewBooking(false)}
        />

      <Popup
        open={isCancelOrder}
        handleClose={() => setIsCancelOrder(false)}
        title={`Yakin Membatalkan Bookingan #${orderNumberSelected}?`}
        width="550px"
        containerClass=""
        children={
          <ReasonCancelBooking 
            handleContinue={() => handleCancelOrderConfirm()}
            loading={isLoadingCancel}
            handleCancel={() => setIsCancelOrder(false)}
            onChange={(value) => setReasonCancel(value)}
          />
        }
        
      />


      <Cards containerClass="card-table !mr-0 !rounded-2xl !shadow-none !p-0">
        {showLogActivities && (
          <>
            <LogActivities 
              handleBack={() => setShowLogActivities(false)}
              masterData={'booking-data'}
              />
          </>
        )}
        {!showLogActivities && (
          <>
            <div className="card-table-header">
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <div className="flex gap-2">
                    <div>
                      <Inputs
                        id="search"
                        placeholder="Cari..."
                        customClass="!w-[245px]"
                        iconPosition="left"
                        value={keyword}
                        icon={IconSearch}
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                    </div>
                    <div className="flex items-center gap-2 whitespace-nowrap">
                      <DropdownsKu
                        classLabel={`flex items-center bg-gray-5 p-4 gap-2 rounded-xl`}
                        costumLabel={
                          <>
                            <div>
                              <Filter 
                                size={16}
                                />
                            </div>
                            <span>Filter</span>
                          </>
                        }
                        >
                          <>
                            <div className="bg-white p-4 mt-2 shadow-custom-1 w-[250px] rounded-2xl z-50">
                              <div>
                                <div className="font-semibold mb-4">
                                  Status Keberangkatan Paket
                                </div>
                                <div className="flex flex-col gap-2">
                                  <div className="flex items-center gap-2">
                                    <Checkbox 
                                      checked={filterPackageStatusOptions.belum_berangkat} 
                                      onChange={() => {handelChecklistPackageType('belum_berangkat', !filterPackageStatusOptions.belum_berangkat)}}
                                      /> <span>Belum Berangkat</span>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <Checkbox 
                                      checked={filterPackageStatusOptions.sedang_berangkat} 
                                      onChange={() => handelChecklistPackageType('sedang_berangkat', !filterPackageStatusOptions.sedang_berangkat)}
                                      /> <span>Sedang Berangkat</span>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <Checkbox 
                                      checked={filterPackageStatusOptions.sudah_berangkat} 
                                      onChange={() => handelChecklistPackageType('sudah_berangkat', !filterPackageStatusOptions.sudah_berangkat)}
                                      /> <span>Sudah Berangkat</span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="font-semibold my-4">
                                  Status Pembayaran
                                </div>
                                <div className="flex flex-col gap-2">
                                  <div className="flex items-center gap-2">
                                    <Checkbox 
                                      checked={filterPaymentStatusOptions.belum_dp}
                                      onChange={() => handelChecklistPaymentStatus('belum_dp', !filterPaymentStatusOptions.belum_dp)}
                                      /> <span>Belum DP</span>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <Checkbox 
                                      onChange={() => handelChecklistPaymentStatus('dp', !filterPaymentStatusOptions.dp)}
                                      checked={filterPaymentStatusOptions.dp}
                                      /> <span>DP</span>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <Checkbox 
                                      checked={filterPaymentStatusOptions.dibayar_sebagian}
                                      onChange={() => handelChecklistPaymentStatus('dibayar_sebagian', !filterPaymentStatusOptions.dibayar_sebagian)}
                                      /> <span>Dibayar Sebagian</span>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <Checkbox 
                                      checked={filterPaymentStatusOptions.lunas}
                                      onChange={() => handelChecklistPaymentStatus('lunas', !filterPaymentStatusOptions.lunas)}
                                      /> <span>Lunas</span>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <Checkbox 
                                      checked={filterPaymentStatusOptions.dibatalkan}
                                      onChange={() => handelChecklistPaymentStatus('dibatalkan', !filterPaymentStatusOptions.dibatalkan)}
                                      /> <span>Dibatalkan</span>
                                  </div>
                                </div>
                                </div>
                            </div>
                          </>
                      </DropdownsKu>
                      {applyFilter() && (<span onClick={handleResetFilter} className="text-primary text-red-1 cursor-pointer">Reset Filter</span>)}
                    </div>
                  </div>
                </Grid>
                <Grid item md={8} className="flex justify-end items-center gap-2">
                  {!!permissions?.includes('create_booking-data') && (
                    <>
                      <Buttons
                        text="Tambah Booking"
                        customClass="btn-primary btn-small !p-[10px] !w-fit"
                        // onClick={() => setPopupAddBooking(true)}
                        onClick={() => setIsCreateNewBooking(true)}
                      />
                    </>
                  )}
                  {(auth.user?.role_key === 'super_admin' ||  auth.user?.role_key === 'super_admin_root') && (
                    <Activity 
                      onClick={() => setShowLogActivities(true)}
                      className=" w-4 h-4 mx-2 cursor-pointer" />
                  )}
                </Grid>
              </Grid>
            </div>
            <div className="divider"></div>
            <div className="px-4 py-2">
              <Tab
                defaultActiveKey="1"
                onChange={(key) => handleChangeTab(key)}
                items={[
                  { 
                    label: "Per-Booking", 
                    key: "1", 
                    children: 
                      <BookingTable 
                        tabActive={tabActive}
                        keyword={keyword}
                        searchDebounce={searchDebounce}
                        bookingList={bookingList}
                        totalBookingList={totalBookingList}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        isLoading={isLoading}
                        handleChangePage={(page) => setCurrentPage(page)}
                        handleChangePageSize={(pageSize) => {
                          setCurrentPage(1)
                          setPageSize(pageSize)
                        }}
                        handleCancelOrder={handleCancelOrder}
                        reloadData={handleGetBooking}
                        /> 
                  },
                  { 
                    label: "Semua Jamaah", 
                    key: "2", 
                    children: 
                    <Jamaah 
                      tabActive={tabActive}
                      keyword={keyword}
                      searchDebounce={searchDebounce}
                      jamaahOrderList={jamaahOrderList}
                      totalJamaahOrderList={totalJamaahOrderList}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      isLoading={isLoading}
                      handleChangePage={(page) => setCurrentPage(page)}
                      handleChangePageSize={(pageSize) => setPageSize(pageSize)}
                      handleCancelOrder={handleCancelOrder}
                      /> 
                  },
                ]}
              />
            </div>
          </>
        )}
      </Cards>
    </>
  );
};

export default React.memo(CardBookingTable);
