import Buttons from "components/buttons";
import Cards from "components/cards";
import Selects from "components/form/selects";
import React, { useCallback, useEffect, useState } from "react";
import TableDoa from "./TableDoa";
import Popup from "components/popup";
import PopupAddTambahDoa from "./PopupAddTambahDoa";
import { useDispatch, useSelector } from "react-redux";
import {
    getDoaAction,
    getDoaCategoryAction,
} from "stores/actions/layananIslami/doa";

const Doa = () => {
    const dispatch = useDispatch();
    const { doa, doa_category } = useSelector((state) => state.doa);
    const [data, setData] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [selectedCategory, setSelectedCategory] = useState("");

    const [open, setOpen] = useState(false);
    const handleClosePopup = useCallback(() => {
        setOpen(false);
    }, []);

    const [optionsPublish, setOptionsPublish] = useState([
        {
            value: "publish",
            label: "publish",
        },
        {
            value: "unpublish",
            label: "unpublish",
        },
    ]);

    const [optionsDoa, setOptionsDoa] = useState([]);

    const payload = {
        page_number: currentPage,
        per_page: size,
        keyword: "",
        sort_column: "",
        sort_order: "DESC",
        status: "",
        kategori_doa_id: selectedCategory.value,
    };

    const getDoa = () => {
        dispatch(getDoaAction(payload));
    };

    useEffect(() => {
        dispatch(getDoaCategoryAction());
    }, []);

    useEffect(() => {
        getDoa();
    }, [currentPage, size, selectedCategory]);

    useEffect(() => {
        if (doa_category?.data?.length) {
            const cateogry_mapped = doa_category.data.map((item) => ({
                value: item.id_kategori_doa,
                label: item.nama_kategori_doa,
            }));
            console.log(cateogry_mapped);
            setOptionsDoa(cateogry_mapped);
        }
    }, [doa_category]);

    useEffect(() => {
        if (doa?.data?.length) {
            const data_mapped = doa.data.map((item) => ({
                key: item.id_doa,
                judul: item.judul,
                kategori: {
                    tags: item.nama_kategori,
                    color: "#333D29",
                    backgroundColor: "rgba(46, 178, 67, 0.2)",
                },
                status: {
                    tags: item.status ? "Publish" : "Draft",
                    color: "#564592",
                    backgroundColor: "rgba(86, 69, 146, 0.2)",
                },
                action: item,
            }));
            setData(data_mapped);
        } else {
            setData([]);
        }
    }, [doa]);

    return (
        <>
            <Popup
                open={open}
                handleClose={handleClosePopup}
                title="Tambah Doa"
                width="982px"
                height="648px"
            >
                <PopupAddTambahDoa
                    handleClosePopup={handleClosePopup}
                    getDoa={getDoa}
                />
            </Popup>
            <Cards containerClass="!p-0">
                <div className="flex flex-row justify-between items-center p-4">
                    <h1 className="font-xl text-black font-semibold">Doa</h1>
                    <div className="flex flex-row gap-[8px]">
                        <Buttons
                            text="Kelola Kategori Artikel"
                            customClass="btn-secondary btn-medium !w-[146px]"
                        />
                        <Buttons
                            text="Tambah Doa"
                            customClass="btn-primary btn-medium !w-[95px]"
                            onClick={() => setOpen(true)}
                        />
                    </div>
                </div>
                <div className="divider"></div>
                <div className="p-4 flex-col gap-[16px]">
                    <div className="flex flex-row gap-[8px]">
                        <Selects
                            label="Tipe Post"
                            placeholder="Tipe Post"
                            options={optionsPublish}
                            customClass="!w-[200px] !h-[52px]"
                            containerClass="!w-[200px] !h-[52px]"
                            itemRenderer={({ state, item, methods }) => {
                                return (
                                    <div
                                        className={`react-dropdown-select-item ${
                                            state.values.length !== 0 &&
                                            state.values[0].value ===
                                                item.value &&
                                            "react-dropdown-select-item-selected"
                                        }`}
                                        onClick={() => methods.addItem(item)}
                                    >
                                        {item.label}
                                    </div>
                                );
                            }}
                        />
                        <Selects
                            label="Kategori Doa"
                            placeholder="Kategori Doa"
                            options={optionsDoa}
                            customClass="!w-[200px] !h-[52px]"
                            containerClass="!w-[200px] !h-[52px]"
                            onChange={(e) => setSelectedCategory(e[0])}
                            itemRenderer={({ state, item, methods }) => {
                                return (
                                    <>
                                        <div
                                            className={`react-dropdown-select-item ${
                                                state.values.length !== 0 &&
                                                state.values[0].value ===
                                                    item.value &&
                                                "react-dropdown-select-item-selected"
                                            }`}
                                            onClick={() =>
                                                methods.addItem(item)
                                            }
                                        >
                                            {item.label}
                                        </div>
                                    </>
                                );
                            }}
                        />
                    </div>
                    <div className="mt-[56px] shadow-md rounded-lg">
                        <TableDoa
                            size={size}
                            setSize={setSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            data={data}
                            getDoa={getDoa}
                        />
                    </div>
                </div>
            </Cards>
        </>
    );
};

export default Doa;
