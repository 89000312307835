import axios from "axios";
import { getLocalStorage } from "../helpers";

export const requestInterceptors = async () => {
  await axios.interceptors.request.use(
    (config) => {
      const accessToken = getLocalStorage("accessToken");

      if (accessToken) {
        config.headers.Authorization = `Bearer ${
          accessToken && JSON.parse(accessToken)
        }`;
      }

      return config;
    },

    (err) => {
      Promise.reject(err);
    }
  );
};

export const responseInterceptors = async () => {
  await axios.interceptors.response.use(
    (response) => {
      const isHtml =
        response.headers["Content-Type"] === "text/html;charset=utf-8";
      if (isHtml) {
        // Store.dispatch({ type: SET_ERROR_WAF, payload: response });
      }

      // let token_login = getLocalStorage("userLogin");
      // console.log("token_login:", token_login);

      return response;
    },
    (error) => {
      return error.response;
    }
  );
};
