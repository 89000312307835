import React, { useCallback, useState } from "react";

import SidebarChild from "components/template_layout/Siderbar/SidebarChild";
// icons
// import AdminIC from './icons/admin.svg';
// import AdminActiveIC from './icons/admin_active.svg';
// import AgenIC from './icons/agen.svg';
// import AgenActiveIC from './icons/agen_active.svg';
// import JamaahIC from './icons/jamaah.svg';
// import JamaahActiveIC from './icons/jamaah_active.svg';

import { memberMenuList } from "./../../configuration/memberMenuList";
import Cards from "../../components/cards";

// tab component
import Admin from './Admin';
import Agen from './Agen';
import Jamaah from './Jamaah';

function Member() {
  // const [menu, setMenu] = React.useState([
  //   {
  //     id: 0,
  //     label: 'Admin',
  //     active: true,
  //     inactiveIc: AdminIC,
  //     activeIc: AdminActiveIC,
  //     component: <Admin />,
  //   },
  //   {
  //     id: 1,
  //     label: 'Agen',
  //     active: false,
  //     inactiveIc: AgenIC,
  //     activeIc: AgenActiveIC,
  //     component: <Agen />,
  //   },
  //   {
  //     id: 2,
  //     label: 'Jamaah',
  //     active: false,
  //     inactiveIc: JamaahIC,
  //     activeIc: JamaahActiveIC,
  //     component: <Jamaah />,
  //   },
  // ]);
  // const [menuSelected, setMenuSelected] = React.useState(0);

  // const handleMenu = (index, id) => {
  //   let menus = [...menu];
  //   menus.forEach((v) => {
  //     if (v.id === id) {
  //       v.active = true;
  //     } else {
  //       v.active = false;
  //     }
  //   });
  //   setMenu(menus);
  //   setMenuSelected(index);
  // };

  const [showContent, setShowContent] = useState("admin");

  const getContent = useCallback(() => {
    switch (showContent) {
      case "admin":
        return <Admin />;
      case "agen":
        return <Agen />;
      case "jamaah":
          return <Jamaah />;
      default:
        return <Admin />;
    }
  }, [showContent]);

  return (
    // <div className="w-full relative flex">
    //   <div className="absolute w-48 rounded-r-lg h-[500px] bg-white ">
    //     <ul className="p-6">
    //       {menu.map((v, i) => {
    //         return (
    //           <li
    //             onClick={() => handleMenu(i, v.id)}
    //             key={v.id}
    //             className={`mb-5 flex items-center cursor-pointer ${
    //               v.active ? 'text-[#F90601] font-extrabold' : 'text-black'
    //             }`}
    //           >
    //             <img className="mr-2" src={v.active ? v.activeIc : v.inactiveIc} alt="ic" />
    //             <p>{v.label}</p>
    //           </li>
    //         );
    //       })}
    //     </ul>
    //   </div>
    //   <div className="bg-white rounded-lg w-[80%] ml-[178px]">{menu[menuSelected].component}</div>
    // </div>
    <div>
      <SidebarChild
        menuList={memberMenuList}
        handleClick={setShowContent}
        showContent={showContent}
      />
      <div style={{ marginLeft: '174px', paddingLeft: '16px'}}>
        <Cards containerClass="!p-0">
          {getContent()}
        </Cards>
        <div className="h-5"></div>
      </div>
    </div>
  );
}

export default Member;
